import React from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from "react";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { apiurl } from "../../../../Environment/Environment";
import Apiservice from "../../../../Services/Apiservice";
import SuccessPopup from "../../../../Shared/Popups/SuccessPopup/SuccessPopup"


function PreferredChannel() {
	const isBrowser = () => typeof window !== "undefined";
	const Navigate = useNavigate();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = React.useState(0);
	const [noemailPhone, setnoemailPhone] = React.useState("");
	const [pref_channelupdated, setpref_channelupdated] = useState("")
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [memberphone, setMemberphone] = useState("");
	const [membermail, setMembermail] = useState("");
	const [islandline, setIslandline] = useState("");
	const [alternatenum, setAlternatenum] = useState("");
	const [freq_onload, setfreq_onload] = useState("");
	const [renderflag, setrenderflag] = useState(false);
	const [changeflag, setChangeflag] = useState(false);

	const [buttonColors, setButtonColors] = useState([
		{
			id: "0",
			name: "",
			backgroundcolor: "#FFFFFF",
			color: "var(--brandcolor)",
			cursor: "pointer",
			pointerevents: "auto"
		},
		{
			id: "1",
			name: "",
			backgroundcolor: "#FFFFFF",
			color: "var(--brandcolor)",
			cursor: "pointer",
			pointerevents: "auto"
		},
		{
			id: "2",
			name: "",
			backgroundcolor: "#FFFFFF",
			color: "var(--brandcolor)",
			cursor: "pointer",
			pointerevents: "auto"
		}
	]);

	const [buttonselectedname, setbuttonselectedname] = useState('');
	const [buttonselectedid, setbuttonselectedid] = useState('');


	let ProfileInformationapiDataemail;
	let contactType;
	let currentPath = location.pathname;
	// localisation data
	let language;
	let pref_channel;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	let tableHeadceCommunicationPreferencell = CustomLanguageservice.getMethod("Communication Preference", language);
	let TextMessage = CustomLanguageservice.getMethod("Text Message", language);
	let Phone = CustomLanguageservice.getMethod("Phone", language);
	let Email = CustomLanguageservice.getMethod("Email", language);
	let PreferredChannel = CustomLanguageservice.getMethod("Preferred Channel", language);
	let Pleaseletusknowwhichmethodisbestforreachingouttoyou = CustomLanguageservice.getMethod("Please let us know which method is best for reaching out to you.", language);
	let Next = CustomLanguageservice.getMethod("Next", language);
	let Wewilltextat = CustomLanguageservice.getMethod("We will text at", language);
	let Wewillemailat = CustomLanguageservice.getMethod("We will email at", language);
	let Wewillcallat = CustomLanguageservice.getMethod("We will call at", language);
	let Wewillreachouttoyoubyphonefromtimetotimewithquestionaboutyourcare = CustomLanguageservice.getMethod("We will reach out to you by phone from time to time with question about your care.", language);

	let Wewillreachouttoyouat = CustomLanguageservice.getMethod("We will reach out to you at",language);
	let withshortsurveysandnotificationsrelatedtoyourhealthcare = CustomLanguageservice.getMethod("with short surveys and notifications related to your healthcare.",language);

	useEffect(() => {
		setProgress((100 / 8) * 5)
	}, []);


	useEffect(() => {
		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath,
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			setMemberphone(response.data.data.phone)
			setMembermail(response.data.data.email)
			setIslandline(response.data.data.is_landline)
			setAlternatenum(response.data.data.mobile)
			ProfileInformationapiDataemail = response.data.data.email;
			pref_channel = response.data.data.pref_channel;
			setfreq_onload(pref_channel)
			// if(response.data.data.mobile ==="" || response.data.data.mobile ===null){
			if (response.data.data.is_landline === "false") {
				// then its a Cell Phone
				contactType = "Cell Phone"
			}
			else {
				if (response.data.data.mobile === "" || response.data.data.mobile === null) {
					contactType = "Landline"
				}
				else {
					contactType = "Cell Phone"
				}

			}

			if (ProfileInformationapiDataemail === "") {
				if (pref_channel === "1") {
					setpref_channelupdated("")

				}
				setrenderflag(true)

			}
			else {
				setrenderflag(true)
				setpref_channelupdated(pref_channel)
			}
			// setpref_channelupdated(pref_channel)

			if (contactType === "Cell Phone" && ProfileInformationapiDataemail !== "") {
				setButtonColors([
					{
						id: "0",
						name: TextMessage,
						backgroundcolor: "var(--lightblue)",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					},
					{
						id: "1",
						name: Email,
						backgroundcolor: "#FFFFFF",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					},
					{
						id: "2",
						name: Phone,
						backgroundcolor: "#FFFFFF",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					}
				]
				)


				if (pref_channel === "1") {
					setButtonColors([
						{
							id: "0",
							name: TextMessage,
							backgroundcolor: "#FFFFFF",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},
						{
							id: "1",
							name: Email,
							backgroundcolor: "var(--lightblue)",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},
						{
							id: "2",
							name: Phone,
							backgroundcolor: "#FFFFFF",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						}
					]
					)
				}
				else if (pref_channel === "2") {
					setButtonColors([
						{
							id: "0",
							name: TextMessage,
							backgroundcolor: "#FFFFFF",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},
						{
							id: "1",
							name: Email,
							backgroundcolor: "#FFFFFF",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},
						{
							id: "2",
							name: Phone,
							backgroundcolor: "var(--lightblue)",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						}
					]
					)

				}
			}
			else if (contactType === "Landline" && ProfileInformationapiDataemail !== "") {
				setButtonColors([
					{
						id: "1",
						name: Email,
						backgroundcolor: "var(--lightblue)",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					},
					{
						id: "2",
						name: Phone,
						backgroundcolor: "#FFFFFF",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					},

				]
				)

				if (pref_channel === "2") {
					setButtonColors([
						{
							id: "1",
							name: Email,
							backgroundcolor: "#FFFFFF",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},
						{
							id: "2",
							name: Phone,
							backgroundcolor: "var(--lightblue)",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},

					]
					)
				}
			}
			else if (contactType === "Cell Phone" && ProfileInformationapiDataemail === "") {
				setButtonColors([
					{
						id: "0",
						name: TextMessage,
						backgroundcolor: "var(--lightblue)",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					},
					{
						id: "2",
						name: Phone,
						backgroundcolor: "#FFFFFF",
						color: "var(--brandcolor)",
						cursor: "pointer",
						pointerevents: "auto"
					},

				]
				)

				if (pref_channel === "2") {
					setButtonColors([
						{
							id: "0",
							name: TextMessage,
							backgroundcolor: "#FFFFFF",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},
						{
							id: "2",
							name: Phone,
							backgroundcolor: "var(--lightblue)",
							color: "var(--brandcolor)",
							cursor: "pointer",
							pointerevents: "auto"
						},

					]
					)
				}

			}
			else if (contactType === "Landline" && ProfileInformationapiDataemail === "") {
				setnoemailPhone(true)
			}
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}, [])






	const handleButtonClick = (index, name, id) => {
		setChangeflag(true)
		const newButtonColors = buttonColors.map((data, i) => {
			if (i === index) {
				return {
					...data,
					backgroundcolor: 'var(--lightblue)'
				};
			} else if (data.pointerevents !== "none") {
				return {
					...data,
					backgroundcolor: '#FFFFFF'
				};
			} else {
				return data; // Retain original color for buttons with pointerevents: "none"
			}
		});
		// Set the state with the new array
		setButtonColors(newButtonColors);
		setbuttonselectedname(name);
		setbuttonselectedid(id);
	};


	function nextButtonClick() {

		let data2;
		let apidata2;
		let data5;
		let data6;
		let element
		// if button is not clicked
		if (buttonselectedname === "") {
			if (pref_channelupdated === "" || pref_channelupdated === undefined) {

				// if button is not clicked and no default value from api
				
				element = buttonColors.find(color => color.id === freq_onload);
				data5 = element.name
				// apidata2 = buttonColors[0].id;
				apidata2 = freq_onload
				if (data5 === TextMessage) {
					if (islandline == "true") {
						data6 = Wewilltextat + " " + alternatenum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
					else {
						data6 = Wewilltextat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
				}
				if (data5 === Phone) {
					data6 = Wewillcallat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
				}
				if (data5 === Email) {
					data6 = Wewillemailat + " " + membermail
				}
				if (noemailPhone === true) {
					data6 = Wewillcallat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					apidata2 = "2"
				}
			}
			else {
				for (let i = 0; i < buttonColors.length; i++) {
					// if button is not clicked and  default value present from api
					if (buttonColors[i].id === pref_channelupdated) {
						data5 = buttonColors[i].name;
						apidata2 = buttonColors[i].id;
					}
				}
				if (data5 === TextMessage) {
					if (islandline == "true") {
						data6 = Wewilltextat + " " + alternatenum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
					else {
						data6 = Wewilltextat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
				}
				if (data5 === Phone) {
					data6 = Wewillcallat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
				}
				if (data5 === Email) {
					data6 = Wewillemailat + " " + membermail
				}
			}

		}
		//button is clicked and value changed
		else {
			data5 = buttonselectedname;
			if (data5 === TextMessage) {
				if (islandline == "true") {
					data6 = Wewilltextat + " " + alternatenum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
				}
				else {
					data6 = Wewilltextat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
				}
			}
			if (data5 === Phone) {
				data6 = Wewillcallat + " " + memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
			}
			if (data5 === Email) {
				data6 = Wewillemailat + " " + membermail
			}
			apidata2 = buttonselectedid;
		}

		let data1 = PreferredChannel;
		const jsonData =
		{
			componentname: "Preferred channel",
			image: "/asset/tick.png",
			datatitle: "",
			heading1: data1,
			heading2: "",
			heading3: "",
			heading4: data5,
			heading5: "",
			heading6: data6

		};


		// api call
		let endpoint = "/user/save_comm_preferences"
		let url = apiurl + endpoint;
		let apidata = {
			"pref_channel": apidata2,
			"route_name": currentPath,

		}
		setIsLoading(true);
		Apiservice.putMethod(url, apidata).then(response => {
			setIsLoading(false);
			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			if (changeflag === true){
				setIsModalOpen(true);
			setTimeout(() => {
				setIsModalOpen(false);
				Navigate('/PreferredFrequency');
			}, 3000);
			}
			else{
				Navigate('/PreferredFrequency');
			}
			

		})
			.catch(error => {
				setIsLoading(false);
				setIsModalOpen(false);
			})

	}


	function back() {
		Navigate('/ContactInformation');
	}

	return (
		<Box className={"Preferredchannelcontainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />
			<SuccessPopup
				isOpen={isModalOpen}
				title="User Modal Title"
				description="This is the user modal description."
			/>
			{
				noemailPhone === true ?

					<Grid container xs={12} className={"bodygrid_Preferredchannelnoemailphone"}>
						<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }}>  <br></br>
							<img src="/asset/leftarrow.png" tabIndex="0" alt="leftarrow" onClick={() => back()} 
							onKeyDown={(e) => {
								if (e.key === "Enter") {
								back();
								}
								}}/>
						</Grid>


						<Grid xs={11} >  <br></br>
							<div className={"Profileinfo"} tabIndex="0">
								{tableHeadceCommunicationPreferencell}
							</div>

						</Grid>

						<Grid xs={12} > <br></br>
							<LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
						</Grid>

						{/* <Grid xs={12} > <br></br>
							<div className="textheading">
								{PreferredChannel}
							</div>
						</Grid> */}

						<Grid xs={12} > <br></br>
							<div className="textheadingnoemailphone" tabIndex="0">
								{
									renderflag &&
									<>
										{/* {Wewillreachouttoyoubyphonefromtimetotimewithquestionaboutyourcare} */}
										{Wewillreachouttoyouat} {memberphone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')} {withshortsurveysandnotificationsrelatedtoyourhealthcare}
									</>
								}

							</div>
						</Grid>

						<Grid xs={12}>
							<div>
								<Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"} onClick={() => nextButtonClick()}
								>Got It!</Button>
							</div>
						</Grid>

					</Grid>



					:

					<Grid container xs={12} className={"bodygrid_Preferredchannel"}>
						<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }}>  <br></br>
							<img src="/asset/leftarrow.png" tabIndex="0" alt="leftarrow" onClick={() => back()} 
							onKeyDown={(e) => {
								if (e.key === "Enter") {
								back();
								}
								}} />
						</Grid>


						<Grid xs={11} >  <br></br>
							<div className={"Profileinfo"} tabIndex="0">
								{tableHeadceCommunicationPreferencell}
							</div>

						</Grid>

						<Grid xs={12} > <br></br>
							<LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0" />
						</Grid>


						{/* <Grid xs={12} > <br></br>
							<div className="textheading">
								{PreferredChannel}
							</div>
						</Grid> */}

						<Grid xs={12} > <br></br>
							<div className="text1_profile" tabIndex="0">
								{Pleaseletusknowwhichmethodisbestforreachingouttoyou}
							</div>
						</Grid>


						<Grid xs={12} className={"gridmain"}>
							<div>
								{
									renderflag &&
									<>


										{buttonColors.map((data, index) => (
											<div
												key={index} tabIndex="0"
												variant="contained" type="submit" className={"dynamicMessagebtn"}
												style={{ backgroundColor: data.backgroundcolor, color: data.color, cursor: data.cursor, pointerEvents: data.pointerevents }}

												onClick={() => handleButtonClick(index, data.name, data.id)}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														handleButtonClick(index, data.name, data.id)
													}
												}}
											>
												{data.name}
											</div>
										))}
									</>
								}

							</div>
						</Grid>


						<Grid xs={12}>
							<div>
								<Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"} onClick={() => nextButtonClick()}
								>{Next}</Button>
							</div>
						</Grid>



					</Grid>
			}
		</Box>
	)

}

export default PreferredChannel