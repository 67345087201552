import { ERROR } from './actions';

const initialState = {
  data:""
  // other relevant state
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
         data:action.data
        // Update other relevant state
      };
    default:
      return state;
  }
};

export default authReducer;