import "./Reports.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));


export default function DetailsSummaryComponent({ report }) {
	const additionalData = report?.additional_data;
	return (
		<Box className="campaign-container" sx={{ flexGrow: 1 }}>
			<Grid className="campaign-grid-container" container spacing={4}>
				<Grid item xs={7}>
					<Item className="campaign-item-1">
						<div className="campaign-details-banner">
							<p className="banner-text">Campaign Details</p>
						</div>
						<div className="campaign-details data">
							<p className="details-data-text-name">
								Hello{" "}
								{additionalData?.agent_first_name +
									" " +
									additionalData?.agent_last_name}
								,
							</p>
							<p className="details-data-text-body">
								You conducted a{" "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.survey_name}{" "}
								</span>{" "}
								survey campaign from{" "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.start_date +
										" " +
										additionalData?.start_time}{" "}
								</span>{" "}
								to{"   "}{" "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.end_date +
										" " +
										additionalData?.end_time}{" "}
								</span>
								Out of{" "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.total_invitations +
										" " +
										"invitations"}
								</span>{" "}
								sent,{" "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.count_started}
								</span>{" "}
								recipients clicked through the surveys, with
								{"  "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.count_completed}
								</span>
								{"  "}successfully completing the survey,
								achieving{" "}
								<span style={{ fontWeight: "700" }}>
									{additionalData?.completion_rate +
										" " +
										"completion rate"}
								</span>
								.
							</p>
						</div>
					</Item>
				</Grid>
				{/* summary block */}
				<Grid item xs={5}>
					<Item className="campaign-item-2">
						<div className="campaign-details-banner">
							<p className="banner-text">Summary</p>
						</div>
						<Box
							sx={{ flexGrow: 1 }}
							className="campaign-details-data"
						>
							<Grid
								className="summary-grid-container"
								container
								spacing={2}
							>
								<Grid item xs={12}>
									<Item className="summary-grid-data">
										<div
											className="summary-data-point"
											style={{
												color: "rgba(129, 129, 221, 1)",
											}}
										>
											{"0"}
										</div>{" "}
										<div className="summary-data-variable">
											Appointments made
										</div>
									</Item>
								</Grid>
								<Grid item xs={12}>
									<Item className="summary-grid-data">
										<div
											className="summary-data-point"
											style={{
												color: "rgba(143, 234, 100, 1)",
											}}
										>
											{
												report?.additional_data
													.total_notification_count
											}
										</div>{" "}
										<div className="summary-data-variable">
											Total responses flagged for
											administrator confirmation
										</div>
									</Item>
								</Grid>
								<Grid item xs={12}>
									<Item className="summary-grid-data">
										<div
											className="summary-data-point"
											style={{
												color: "rgba(19, 85, 253, 1)",
											}}
										>
											{
												report?.additional_data
													.resolved_notification_count
											}
										</div>{" "}
										<div className="summary-data-variable">
											Total number of notifications
											Resolved
										</div>
									</Item>
								</Grid>
							</Grid>
						</Box>
					</Item>
				</Grid>
				{/* 2-blocks end */}
			</Grid>
		</Box>
	);
}
