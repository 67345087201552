import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { ROUTES_AGENT_LOGIN } from '../../../Constants/routes';
import { URL } from "../../../Constants/apiUrl";
import { languageId } from "../../../Constants/static";
import './AgentLogin.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function AgentLogin() {
	const isBrowser = () => typeof window !== "undefined";
	const Navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [validcred, Setvalidcred] = useState(true);
	const [Submitclicked, setSubmitclicked] = useState(false);
	const [EmailAddressdefault, setEmailAddressdefault] = useState("");
	const [Password, setPassword] = useState("");
	const [ConfirmPassword, setConfirmPassword] = useState("");
	const [logo, setlogo] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false); // State for password visibility
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [response_api, setresponse_api] = useState("");

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		mode: "onChange"
	});


	useEffect(() => {
		// get api data
		setlogo("http://d23bj7ser2frgh.cloudfront.net/asset/customcompanylogo.png");

		let email_address = localStorage.getItem("email_address");
		if (email_address === "" || email_address === undefined || email_address === null) {
			setTimeout(() => {
				reset({
					EmailAddress: "",
				});
			}, 500);
		} else {
			let TokenExpired = localStorage.getItem("TokenExpired");
			if (TokenExpired === "true") {
				setTimeout(() => {
					reset({
						EmailAddress: email_address,
					});
					setEmailAddressdefault(email_address);
				}, 500);
			}
		}
	}, []);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleOKButton = () => {
		handleCloseModal();
		Navigate(ROUTES_AGENT_LOGIN)
	};

	function loginval(data) {
		let EmailAddressdefault = sessionStorage.getItem("AgentEmail");
		setSubmitclicked(true);

		if (Password === "" || ConfirmPassword === "" || Password !== ConfirmPassword) {
		} else {
			setIsLoading(true);
			let url = URL.UPDATE_PASSWORD;
			let apidata = {
				"email_address": EmailAddressdefault,
				"new_password": data.NewPassword,
				"confirm_password": data.ConfirmPassword,
				"otp": data.Code
			}

			Apiservice.patchMethod(url, apidata).then(response => {
				setresponse_api(response)
				setIsLoading(false);

				setIsModalOpen(true);
			})
				.catch(error => {
					setresponse_api(error)
					console.log(error)
					setIsLoading(false);
					Setvalidcred(false);
					
				});
		}
	}

	const handleEmailChange = (event, field) => {
		let inputValue = event.target.value;
		// Allow only digits
		if (/^\d*$/.test(inputValue)) {
			field.onChange(event); // Update form state
		}
		setEmailAddressdefault(event.target.value);
		Setvalidcred(true);

	};

	const handlePasswordChange = (event, field) => {
		let inputValue = event.target.value;
		// Allow only digits
		// if (/^\d*$/.test(inputValue)) {
		// 	field.onChange(event); // Update form state
		// }
		field.onChange(inputValue);
		setPassword(event.target.value);
		Setvalidcred(true);
	};

	const handleConfirmPasswordChange = (event) => {
		setConfirmPassword(event.target.value);
		Setvalidcred(true);
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	const isButtonDisabled = EmailAddressdefault === "" || Password === "" || ConfirmPassword === "" || Password !== ConfirmPassword;

	return (
		<Box>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container sx={{ background: "white" }} alignItems={"center"}>
				<Grid xs={12} lg={6} sx={{ background: "#004791", height: { lg: '100vh', xs: '20vh' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div >
						<img src={logo} alt="Companylogo"
						/>
					</div>
				</Grid>
				<Grid xs={12} lg={6} justifyContent={"center"} alignItems={"center"} sx={{ height: { lg: '100vh', xs: '80vh' }, display: { lg: "inherit" } }} >
					<br />
					<br />
					<Grid item xs={12} md={8} lg={6} sx={{ padding: 2 }}>
						<div className="AgentLogincontainer">
							<div className="boxcontent" translate="no">
								Reset My Password
							</div>
						</div>
						<br />
						<br />
						<form onSubmit={handleSubmit(loginval)}>
							<Grid className="AgentLogincontainer" justifyContent={"center"} xs={10} lg={12} sx={{ margin: "auto" }}>
								<Controller
									name="Code"
									control={control}
									rules={{ required: true, pattern: /^\d{5}$/ }}
									render={({ field }) =>
										<div >
											<InputLabel htmlFor="my-input" className="inputlabel" translate="no">Code </InputLabel>
											<div className={"textbox1"} >
												<TextField
													translate="no"
													type="text"
													className={"inputcode"}
													variant="outlined"
													placeholder="Enter Code"
													value={field.value || ''}
													onChange={(e) => handleEmailChange(e, field)}
													sx={{ width: "100%" }}
													InputProps={{
														style: {
															border: errors?.Code?.type === "required" ? "1px solid red" : "transparent",
														}
													}}

												/>
											</div>
											{errors?.Code?.type === "required" &&
												<div className={"errormsg"}>Code cannot be empty</div>}

											{errors?.Code?.type === "pattern" &&
												<div className={"errormsg"}>Invalid code</div>}

											{validcred === false &&
												<div className={"errormsg"}>{response_api.message}</div>}
										</div>

									}
								/>
								<Controller
									name="NewPassword"
									control={control}
									rules={{ required: true, pattern: /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/~`]{8,16}$/ }}
									render={({ field }) =>
										<div><br />
											<InputLabel htmlFor="my-input" className={"inputlabel"} translate="no">New Password</InputLabel>
											<div>
												<div className={"textbox2"}>
													<TextField
														translate="no"
														type={showPassword ? 'text' : 'password'} // Toggle between text and password type
														variant="outlined"
														placeholder="Enter Your New Password"
														value={field.value || ''}
														onChange={(e) => handlePasswordChange(e, field)}
														// onKeyUp={handlePasswordChange}
														InputProps={{
															className: 'customInput',
															endAdornment: (
																<InputAdornment position="end" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
																	<img src={showPassword ? '/asset/eye-visible.png' : '/asset/eye-invisible.png'} alt="TogglePasswordVisibility" style={{ width: '24px' }} />
																</InputAdornment>
															),
															style: {
																border: Password === "" && Submitclicked === true ? "1px solid red" : "transparent",
															}
														}}
													// {...register('NewPassword', { required: true })}

													/>
												</div>
											</div>

											{errors?.NewPassword?.type === "required" &&
												<div className={"errormsg"}>New Password cannot be empty</div>}

											{errors?.NewPassword?.type === "pattern" &&
												<div className={"errormsg"}>Enter a valid password with 8-16 characters, which can include letters, numbers, and/or special characters</div>}

										</div>
									}
								/>
								<Controller
									name="ConfirmPassword"
									control={control}
									render={() =>
										<div><br />
											<InputLabel htmlFor="my-input" className={"inputlabel"} translate="no">Confirm Password</InputLabel>
											<div>
												<div className={"textbox2"}>
													<TextField
														translate="no"
														type={showConfirmPassword ? 'text' : 'password'} // Toggle between text and password type
														variant="outlined"
														placeholder="Re-Enter Your New Password"
														onKeyUp={handleConfirmPasswordChange}
														InputProps={{
															className: 'customInput',
															endAdornment: (
																<InputAdornment position="end" onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
																	<img src={showConfirmPassword ? '/asset/eye-visible.png' : '/asset/eye-invisible.png'} alt="TogglePasswordVisibility" style={{ width: '24px' }} />
																</InputAdornment>
															),
															style: {
																border: ConfirmPassword === "" && Submitclicked === true ? "1px solid red" : "transparent",
															}
														}}
														{...register('ConfirmPassword', { required: true })}
													/>
												</div>

												{errors?.ConfirmPassword?.type === "required" &&
													<div className={"errormsg"}>Confirm Password cannot be empty</div>}

												{ConfirmPassword !== "" && Password !== ConfirmPassword &&
													<div className={"errormsg"}>Password did not match</div>
												}
											</div>

										</div>
									}
								/>
								<br />
								<br />
								<Button
									variant="contained"
									aria-label="LogIn"
									type="submit"
									className={isButtonDisabled || errors?.NewPassword?.type === "pattern" ? "submitbtndisabled" : "submitbtn"}
									translate="no"
									id="submitButton"
								>
									Reset
								</Button>
							</Grid>
							<Dialog
								open={isModalOpen}
								// onClose={handleCloseModal}
								aria-labelledby="forgot-password-dialog-title"
								className="AgentLogincontainer"
								container={() => document.getElementById('right-grid-container')}
								sx={{
									position: 'absolute',
									top: '50%',
									transform: 'translateY(-50%)',
									height: '100%',
									margin: 0,
								}}
							>
								<IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: 8, right: 8, }}>
									<CloseIcon />
								</IconButton>
								<br />
								<br />
								<DialogTitle id="forgot-password-dialog-title" sx={{ textAlign: 'left', marginLeft: '10px', marginBottom: '10px' }} className="boxcontent" >
									Success!
								</DialogTitle>
								<DialogContent>
									<Typography variant="body1" sx={{ marginLeft: '10px' }}>
										Your password has been successfully reset.You can now login with your new password.
									</Typography>
								</DialogContent>
								<DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
									<Button variant='contained' className="popcustombutton" onClick={handleOKButton}>
										Login
									</Button>
								</DialogActions>
								<br />
							</Dialog>
						</form>
					</Grid>

				</Grid>
			</Grid>
		</Box>
	);
}

export default AgentLogin;
