import * as React from "react"
import Modal from '@mui/material/Modal'
import { TextField, InputLabel, FormControl, MenuItem, Checkbox, Select, Box, Button, Radio, RadioGroup, FormControlLabel, Grid, InputAdornment, IconButton } from '@mui/material';
import "./UpdateSurveyNamePopup.css"
import { useEffect } from "react";


const childStyle = {
	position: "absolute",
	top: "40%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "750px",
	height: "auto",
	bgcolor: "background.paper",
	//   border: "2px solid #000",
	boxShadow: 24,
	borderRadius: "12px",
	pt: 2,
	px: 4,
	pb: 3,
};

function UpdateSurveyName({ childModalopen, setChildModalOpen, surveyName, updatedSurveyName, campaignType, surveyUpdateVariant, namesArray }) {
	// const [childModalopen, setChildModalOpen] = React.useState(false);
	
	const [updatedSurvey, setUpdatedSurvey] = React.useState('');

	const handleOpen = () => {
		setChildModalOpen(true);
	};
	const handleClose = () => {
		if (namesArray.includes(surveyName)) {
			setChildModalOpen(false);
		}
		// if (updatedSurvey.trim() !== surveyName.trim() && updatedSurvey !== "") {
		// 	setChildModalOpen(false);
		// }
		// setChildModalOpen(false);
	};

	const handleTextchange = (data) => {
		setUpdatedSurvey(data);
	};
	
	const handleSubmitText = (e) => {
		// if (updatedSurvey.trim() !== surveyName.trim() && updatedSurvey !== "") {
		// 	updatedSurveyName(updatedSurvey, surveyUpdateVariant)
		// 	setChildModalOpen(false);
		// }
		
		if (namesArray.includes(updatedSurvey)) {
			setChildModalOpen(true);
		}
		else{
			updatedSurveyName(updatedSurvey, surveyUpdateVariant)
			setChildModalOpen(false);
		}

		// updatedSurveyName(updatedSurvey, surveyUpdateVariant)
		// setChildModalOpen(false);
	};

	// const d = new Date().getTime().toString().slice(7, 13);
	// console.log(d);

	useEffect(() => {
		const uniqueCode = new Date().getTime().toString().slice(7);
		setUpdatedSurvey(surveyName + " - " + uniqueCode)
	}, [childModalopen])


	return (
		<React.Fragment>
			{/* <Button onClick={handleOpen}>Open Child Modal</Button> */}
			<Modal
				open={childModalopen}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Box sx={{ ...childStyle }}>
					<button className="close-child" onClick={handleClose}>
						❌
					</button>
					<div className="text-btn-container">

						{campaignType == 'survey' &&
							<div className='child-modal-banner'>Update Survey Name</div>
						}
						{campaignType == 'promotion' &&
							<div className='child-modal-banner'>Update Promotion Name</div>
						}
						<form onSubmit={handleSubmitText} style={{
							width: '100%', display: 'flex',
							'align-items': 'center',
							'justify-content': 'space-between', marginTop: '25px'
						}}>
							{/* <label htmlFor="child-modal-text-id" style={{width: '100%'}}></label> */}
							<TextField
								name='child-modal-text'
								className="input-text"
								id="child-modal-text-id"
								variant="outlined"
								value={updatedSurvey}
								onChange={(e) => handleTextchange(e.target.value)}
							/>

							<Button
								onClick={handleSubmitText}
								className="update-btn"
								variant="contained"
							>
								Update
							</Button>
						</form>
					</div>
				</Box>
			</Modal>
		</React.Fragment>
	);
}

export default UpdateSurveyName
