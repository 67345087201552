import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

function CommoncircularProgress() {
    return (
         
        <div className="CircularProgresscenterdiv">
            <CircularProgress className={"CircularProgresscenter"} />
        </div>
	

		
    )
}

export default CommoncircularProgress;