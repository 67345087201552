import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState, useCallback } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichTextEditor = forwardRef(({ value, onChange, placeholder }, ref) => {
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [prevSelection, setPrevSelection] = useState(null);

  useEffect(() => {
    if (typeof value === 'string') {
      const blocksFromHTML = htmlToDraft(value);
      if (blocksFromHTML) {
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);

  const onEditorStateChange = (newEditorState) => {
    // Save the current selection
    const selection = newEditorState.getSelection();
    setPrevSelection(selection);

    // Update editor state and notify parent
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const html = stateToHTML(contentState);
    // if (onChange) {
    //   onChange(html);
    // }

    // Restore the selection after state update
    // requestAnimationFrame(() => {
    //   if (editorRef.current && prevSelection) {
    //     const updatedState = EditorState.forceSelection(newEditorState, prevSelection);
    //     setEditorState(updatedState);
    //   }
    // });
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    }
  }), []);

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        placeholder={placeholder}
        ref={editorRef}
        toolbar={{
          options: ['history', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
          inline: { inDropdown: true },
          list: { inDropdown: false },
          textAlign: { inDropdown: false },
          link: { inDropdown: false },
          history: { inDropdown: false },
        }}
      />
    </div>
  );
});

export default RichTextEditor;
