import React, { useState, useEffect, useCallback, useRef } from 'react';
import './InactivityChecker.css';
import GlobalAlertPopup2 from "../../../Shared/Popups/GlobalAlertPopup2/GlobalAlertPopup2";
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { ROUTES_AGENT_LOGIN } from "../../../Constants/routes";

const InactivityChecker = ({ timeout, sessionExpiredTimeout }) => {
	// 30 minutes timeout for inactivity and 1 minute timeout for session expiration ise 
	const [showInactivityModal, setShowInactivityModal] = useState(false);
	const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
	const inactivityTimerRef = useRef(null);
	const sessionExpiredTimerRef = useRef(null);
	const Navigate = useNavigate();

	const startSessionExpirationTimer = useCallback(() => {
		if (sessionExpiredTimerRef.current) {
		  clearTimeout(sessionExpiredTimerRef.current);
		}
		sessionExpiredTimerRef.current = setTimeout(() => {
		  setShowInactivityModal(false);
		  setShowSessionExpiredModal(true);
		}, sessionExpiredTimeout-timeout);
	  }, [sessionExpiredTimeout,timeout]);
	
	  const resetInactivityTimer = useCallback(() => {
		if (showSessionExpiredModal) return;
	
		if (inactivityTimerRef.current) {
		  clearTimeout(inactivityTimerRef.current);
		}
	
		inactivityTimerRef.current = setTimeout(() => {
		  setShowInactivityModal(true);
		  startSessionExpirationTimer(); // Start session expiration timer
		}, timeout);
	  }, [timeout, showSessionExpiredModal, startSessionExpirationTimer]);
	
	  const handleUserActivity = useCallback(() => {
		if (showInactivityModal || showSessionExpiredModal) return; // Ignore activity if inactivity modal is showing and "Okay" is not pressed
		resetInactivityTimer();
	  }, [resetInactivityTimer, showInactivityModal, showSessionExpiredModal]);
	
	  useEffect(() => {
		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keypress', handleUserActivity);
		window.addEventListener('click', handleUserActivity);
		window.addEventListener('scroll', handleUserActivity);
	
		resetInactivityTimer(); // Start the inactivity timer initially
	
		return () => {
		  if (inactivityTimerRef.current) {
			clearTimeout(inactivityTimerRef.current);
		  }
		  if (sessionExpiredTimerRef.current) {
			clearTimeout(sessionExpiredTimerRef.current);
		  }
		  window.removeEventListener('mousemove', handleUserActivity);
		  window.removeEventListener('keypress', handleUserActivity);
		  window.removeEventListener('click', handleUserActivity);
		  window.removeEventListener('scroll', handleUserActivity);
		};
	  }, [handleUserActivity, resetInactivityTimer]);
	
	  const handleOkayClick = () => {
		setShowInactivityModal(false);
		if (sessionExpiredTimerRef.current) {
		  clearTimeout(sessionExpiredTimerRef.current); // Clear session expired timer
		}
		resetInactivityTimer(); // Restart the inactivity timer
	  };
	
	  const handleSessionExpiredOkayClick = () => {
		setShowSessionExpiredModal(false);
		// No need to reset any timers here since the session has expired
		Navigate(ROUTES_AGENT_LOGIN)
		sessionStorage.removeItem("AgentEmail");
		sessionStorage.removeItem("campaign_code");
		sessionStorage.removeItem("filterdata");
		sessionStorage.removeItem("tabselected");
	  };


	return (
		<Box className="maindivcontent">
			<GlobalAlertPopup2
				isOpen={showInactivityModal}
				Heading="You have been inactive for 30 minutes. If you remain inactive for another 30 minute, you will be logged out and any unsaved data will be lost."
				body=""
				btnname1="OK"
				handleOKButton={handleOkayClick}
			/>



			<GlobalAlertPopup2
				isOpen={showSessionExpiredModal}
				Heading="Your session has expired. Please log in again to continue."
				body=""
				btnname1="OK"
				handleOKButton={handleSessionExpiredOkayClick}
			/>

		</Box>
	);
};

export default InactivityChecker;
