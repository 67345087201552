import React, { useEffect, useState } from "react";

import { Box, Button, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import "./MyAgent.css"
import RequestCallbackPopup from "../../../Shared/Popups/RequestCallbackPopup/RequestCallbackPopup";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import ScheduleCallback from "../../../Shared/Popups/ScheduleCallback/ScheduleCallback";
import Apiservice from "../../../Services/Apiservice";
import { URL } from "../../../Constants/apiUrl";
import CallbackAlertPopup from "../../../Shared/Popups/CallbackAlertPopup/CallbackAlertPopup";
import ButtonComponent from "../../../Shared/Commonfields/ButtonComponent";

function MyAgent() {
	const isBrowser = () => typeof window !== "undefined";
	const [isLoading, setIsLoading] = useState(false);
	const [agentdetails, setAgentDetails] = useState("")
	const [slot, setSlot] = useState("Mon-Sat")
	const [slottime, setSlottime] = useState("9:00 AM - 5:00 PM (EST)")
	const [isavailable, setIsavailable] = useState("")
	const [reqcallbackDetails, setRequestCallbackDetails] = useState("")
	const [cancelcallDetails, setCancelCallDetails] = useState("")
	const [agentid, setAgentid] = useState("")
	const [agentlogo, setAgentlogo] = useState("/asset/Member/agent-profile.png")
	const [agentphone, setAgentphone] = useState("")
	const [memberphone, setMemberphone] = useState("")
	const [appointment, setAppointment] = useState("")
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);


	let agentUrl = URL.MEMBER_MY_AGENT
	let scheduleCall = URL.MEMBER_SCHEDULE_CALLBACK;
	let confirmCall = URL.MEMBER_CONFIRM_CALLBACK;

	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	let Email = CustomLanguageservice.getMethod("Email", language)
	let Contact = CustomLanguageservice.getMethod("Contact", language)
	let MyAgent = CustomLanguageservice.getMethod("My Agent", language)
	let UpcomingAppointment = CustomLanguageservice.getMethod("Upcoming Appointment", language)
	let CancelorReschedule = CustomLanguageservice.getMethod("Cancel or Reschedule", language)
	let Confirm = CustomLanguageservice.getMethod("Confirm", language)
	let Cancel = CustomLanguageservice.getMethod("Cancel", language)
	let CallNow = CustomLanguageservice.getMethod("Call Now", language)
	let ScheduleaCallback = CustomLanguageservice.getMethod("Schedule a Callback", language)
	let UpcommingCalls = CustomLanguageservice.getMethod("Upcomming Calls", language)
	let Youdonthaveanyupcommingcalls = CustomLanguageservice.getMethod("You dont have any upcomming calls", language)
	let RequestaCallback = CustomLanguageservice.getMethod("Request a Callback", language)
	let CallbackRequestinQueue = CustomLanguageservice.getMethod("Callback Request in Queue", language)
	let Alert = CustomLanguageservice.getMethod("Alert", language)
	let OK = CustomLanguageservice.getMethod("Ok", language)
	let error = CustomLanguageservice.getMethod("error", language)
	let Done_text = CustomLanguageservice.getMethod("Done", language);
	let Hi = CustomLanguageservice.getMethod("Hi", language);
	let youragent = CustomLanguageservice.getMethod("your agent", language);
	let willcallyouat = CustomLanguageservice.getMethod("will call you at", language);
	let tocancelthecall = CustomLanguageservice.getMethod("to cancel the call", language);

	const handleSelect = (date, time, comment, varient) => {
		// Accessing the date time and text from child component

		setIsLoading(true);

		if (isavailable === true) {
			let data = {
				"preferred_date": date,
				"preferred_time": time,
				"message": (varient === "requestCall") ? comment : "Want to cancel an appointment.",
				"agent_id": agentid.toString()
			}
			apiCall(confirmCall, data)
		}
		else {

			let data = {
				"preferred_date": date,
				"preferred_time": time,
				"message": comment,
				"agent_id": agentid.toString()
			}
			apiCall(scheduleCall, data)
		}
	}

	useEffect(() => {
		setIsLoading(true);
		Apiservice.getMethod(agentUrl).then((response) => {
			setIsLoading(false);
			let details = response.data.data
			let memberPhone = details.member_phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
			setMemberphone(memberPhone)
			setAgentDetails(details)
			setAgentid(details.agent_id)
			setSlot(details.agent_availability[0].slot_name)
			setSlottime(details.agent_availability[0].slot_time)
			setIsavailable(details.agent_availability[0].is_available)
			setAgentphone(details.agent_phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'))
			if (details.agent_logo !== "" && details.agent_logo !== null && details.agent_logo !== undefined) {
				setAgentlogo(details.agent_logo)
			}
			if (details.upcoming_calls !== "" && details.upcoming_calls !== null && details.upcoming_calls !== undefined) {
				setAppointment(details.upcoming_calls)
			}

			let requestCallText = Hi + " " + "<strong>" + details.member_name + "</strong>" + " " + youragent + " " + details.agent_name + " " + willcallyouat + " " + "<strong>" + memberPhone + "</strong>"
			setRequestCallbackDetails(requestCallText)
			let cancelCallText = Hi + " " + "<strong>" + details.member_name + "</strong>" + " " + youragent + " " + details.agent_name + " " + willcallyouat + " " + "<strong>" + memberPhone + "</strong>" + " " + tocancelthecall + "!"
			setCancelCallDetails(cancelCallText)
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})

	}, [])


	const apiCall = (url, data) => {

		Apiservice.postMethod(url, data).then(response => {
			setIsLoading(false);
			let val = response.data.message

			if (val === "You already have a pending request." || val === "There is existing callback exists") {
				CallbackAlertPopup(CallbackRequestinQueue, OK, Alert, error)
			}
			else {
				window.location.reload();
			}

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}


	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);



	const skeletonLoad = (value) => {
		return isLoading ? <Skeleton animation="wave" sx={{ width: "100px" }} /> : value
	}

	const submitButtonClick = (data) => {
		sessionStorage.removeItem("isnavigated");
		window.location.reload();
	}



	return (

		<Box className={"MyagentContainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container sx={{ justifyContent: "center" }} className={"parentAgent"}>
				<Grid item xs={12} className={"agentPageheading"} pt={1}>
					<p tabIndex="0">{MyAgent}</p>
				</Grid>
				<Grid container item xs={11} md={8} mt={{ xs: 1, md: 5 }} spacing={0} >
					<Grid xs={12} md={3} alignSelf={"end"}>
						<img src={agentlogo} alt="agentlogo" className="agentImage" tabIndex="0"></img>
					</Grid>
					<Grid container xs={12} md={5} sx={{ textAlign: "left" }}>
						<Grid xs={12} sx={{ mt: { xs: 3, md: "auto" } }}>
							<p className="myagentSubheading agentName" tabIndex="0">{skeletonLoad(agentdetails.agent_name)}</p>
							<div className="aboutAgentFlex aboutAgentmargin"><p className="myagentSubheading" tabIndex="0">{Email}:&nbsp; </p>
								{/* <p>{agentdetails.agent_email}</p></div> */}
								<p style={{maxWidth:"100%", wordWrap:"break-word", overflow:"hidden"}}>{isLoading ?
									<Skeleton animation="wave" sx={{ width: "100px" }} /> :
									<>
										{(agentdetails.agent_email !== "" && agentdetails.agent_email !== null && agentdetails.agent_email !== undefined) ?
											(
												<p style={{ borderBottom: "1px solid black"}}>
													<a style={{ borderBottom: "1px solid black", cursor: "pointer" }} tabIndex="0"
														onClick={() => window.location.href = `mailto:${agentdetails.agent_email}`}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																window.location.href = `mailto:${agentdetails.agent_email}`
															}
															}} 
															>
														{agentdetails.agent_email}
													</a>
												</p>
											)
											: (
												"-"
											)

										}
									</>
								}
								</p>

							</div>
							<div className="aboutAgentFlex aboutAgentmargin" tabIndex="0"><p><strong>{slot}:&nbsp;</strong>{skeletonLoad(slottime)}</p></div>
							<div className="aboutAgentFlex aboutAgentmargin" tabIndex="0"><p className="myagentSubheading">{Contact}: &nbsp;</p>
								<p>{skeletonLoad(agentphone)}</p>
							</div>
						</Grid>
					</Grid>
					<Grid container xs={12} md={4} sx={{ textAlign: "left", mt: { xs: 3, md: "auto" } }} >
						{/* Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted*/}
						{agentphone !== "" && (screenWidth < 799) ?
							(isLoading ? <Skeleton animation="wave" sx={{ width: "100%", height: "90px", borderRadius: "10px", background: "var(--btncolor)" }} /> :
								<Button variant="contained" aria-label="confirmAppointmentbtn" className="callNow"
									onClick={() => window.location.href = `tel:${agentphone}`}>{CallNow}
								</Button>
							) :
							(<>
								{isavailable ?
									(
										// if agent is available request a callback option.
										/* Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted 
											varient={"cancelCall"} is for making the button white color apply different css */
										<RequestCallbackPopup 
											text={
												`${Hi} <strong>${agentdetails.member_name}</strong>, ${youragent} ${agentdetails.agent_name} ${willcallyouat} <strong>${memberphone}</strong>.`
											}
											handleSelect={handleSelect} 
											heading={RequestaCallback} 
											btn1={Confirm} 
											btn2={Cancel} 
											varient={"requestCall"} />

									)
									:
									(isLoading ? <Skeleton animation="wave" sx={{ width: "100%", height: "90px", borderRadius: "10px", background: "var(--btncolor)" }} /> :
										// if agent is not available then schedule a callback option.
										// Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted 
										<ScheduleCallback handleSelect={handleSelect} heading={ScheduleaCallback} btn1={Confirm} btn2={Cancel} language={language} />
									)
								}
							</>)

						}

					</Grid>
					<Grid xs={12}>
						<br></br>
						<hr></hr>
					</Grid>

				</Grid>

				<Grid container xs={11} md={8} sx={{ mt: "24px !important", mb: 5 }} mt={3} mb={5}>
					<Grid xs={12} textAlign={"left"}>
						<p className="myagentSubheading" tabIndex="0">{UpcommingCalls}</p>
					</Grid>

					{isLoading ? <Skeleton animation="wave" sx={{ width: "100%", height: "100px", borderRadius: "10px" }} /> :
						(<>
							{appointment === "" ?
								(<Grid xs={12} mt={3} p={3} className={"upcommingAppointment"}>
									<Typography variant="body1" align="left" sx={{ fontSize: "var(--fontsizesmall)" }}>
										{/* <span dangerouslySetInnerHTML={{ __html: agentdetails.upcoming_calls }} /> */}
										<span tabIndex="0">{Youdonthaveanyupcommingcalls}</span>
									</Typography>
								</Grid>
								)
								:
								(<>
									<Grid xs={12} mt={3} p={3} className={"upcommingAppointment"}>
										<Typography variant="body1" align="left" sx={{ fontSize: "var(--fontsizesmall)" }}
										>
											<span tabIndex="0" dangerouslySetInnerHTML={{ __html: agentdetails.upcoming_calls }} />
										</Typography>
									</Grid>
									<Grid container xs={12} mt={3} justifyContent={"flex-end"}>
										<Grid xs={12} md={4}>
											{/* Cancellation of appointment */}
											{/* Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted 
												varient={"cancelCall"} is for making the button white color apply different css*/}
											<RequestCallbackPopup text={cancelcallDetails} handleSelect={handleSelect} heading={CancelorReschedule} btn1={Confirm} btn2={Cancel} varient={"cancelCall"} />
										</Grid>
									</Grid>
								</>
								)
							}
						</>
						)
					}


				</Grid>

				<Grid container xs={11} md={8}>
					<ButtonComponent
						id="submitID"
						variant="contained"
						arialabel="Donebtn"
						type="submit"
						onClick={() => submitButtonClick("Some Data")}
						className="Donebtn"
						buttonname={Done_text}
					/>
				</Grid>

			</Grid>

		</Box>
	)
}

export default MyAgent;