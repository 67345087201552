
import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CustomLanguageservice from "../../Services/CustomLanguageservice";
import HeaderOnBoarding from "../HeaderOnBoarding/HeaderOnBoarding";

const CommonLoader = () => {
    let { data } = useParams();
    const decodedData = JSON.parse(decodeURIComponent(data));
    const isBrowser = () => typeof window !== "undefined";

    let language;
    if (isBrowser() === true) {
        language = localStorage.getItem("Preferredlanguage");
    }
    let ThanksforUpdatingyourProfileInformation = CustomLanguageservice.getMethod("Thanks for Updating your Profile Information", language);
    let Addresstitle = CustomLanguageservice.getMethod("Address", language);
    let Phonetitle = CustomLanguageservice.getMethod("Phone", language);
    let Emailtitle = CustomLanguageservice.getMethod("Email", language);


    let componentname;
    let image;
    let name;
    let streetaddress;
    let city;
    let state;
    let zipcode;
    let PhoneNumber;
    let Emailaddress;
    let datatitle;
    let heading1;
    let heading2;
    let heading3;
    let heading4;

    if (decodedData.componentname === "Profile Information") {
        componentname = decodedData.componentname;
        name = decodedData.name;
        image = decodedData.image;
        streetaddress = decodedData.streetaddress;
        city = decodedData.city;
        state = decodedData.state;
        zipcode = decodedData.zipcode;
        PhoneNumber = decodedData.PhoneNumber;
        Emailaddress = decodedData.Emailaddress;
    }
    else {
        componentname = decodedData.componentname;
        datatitle = decodedData.datatitle;
        heading1 = decodedData.heading1;
        heading2 = decodedData.heading2;
        heading3 = decodedData.heading3;
        heading4 = decodedData.heading4;
        image = decodedData.image;

    }

    return (

        <Box className={"CommonLoader"} >
            <HeaderOnBoarding/>
            {componentname === "Profile Information" ?
                <Grid container xs={12} className={"CommonLoadermaingrid"}>
                    <Grid xs={12} >
                        <div >
                            <img src={image} alt="success" />
                        </div>
                        <div className={"informationbox"}>
                            <div className={"namelabelLight"}> <br></br>
                                {ThanksforUpdatingyourProfileInformation}
                            </div>

                            <div className={"namelabel"}> <br></br><br></br>
                                {name}
                            </div>

                            <div className={"namelabel2"}> <br></br>
                                {Phonetitle}:
                            </div>

                            <div className={"namelabeltitle"}>
                                {PhoneNumber}
                            </div>

                            <div className={"namelabel2"}> <br></br>
                                {Emailtitle}:
                            </div>

                            <div className={"namelabeltitle"}>
                                {Emailaddress}
                            </div>

                            <div className={"namelabel2"}> <br></br>
                                {Addresstitle}:
                            </div>

                            <div className={"namelabeltitle"}>
                                {streetaddress},  {city},  {state},  {zipcode}
                            </div>

                           

                          


                        </div>

                    </Grid>
                </Grid>
                :
                <div>
                    <Grid container xs={12} className={"CommonLoadermaingrid"}>
                        <Grid xs={12} >
                            <div >
                                <img src={image} alt="success" />
                            </div>

                            <div className={"informationbox2"}>
                            <div className={"namelabeltitle"}>
                                {datatitle}
                            </div>

                            <div className={"namelabeltitle"}> <br></br>
                                {heading1}
                            </div>

                            <div className={heading3 === "" ? "namelabel":"namelabelFade"}> <br></br>
                               {heading2}
                            </div>

                            <div className={"namelabeltitle"}> <br></br>
                                {heading3}
                            </div>

                            <div className={"namelabel"}> <br></br>
                               {heading4}
                            </div>

                           
                            </div>

                            <div><br></br><br></br>
                            <CircularProgress className={"CircularProgresscenter" }/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }

        </Box>

    )

}

export default CommonLoader;