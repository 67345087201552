import React, { useState } from 'react';
import dayjs from 'dayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import "./FormDatepicker.css";
import { MobileDatePicker } from '@mui/x-date-pickers';

const FormDatepicker = (props) => {

	// 'selectDate' function from the parent to send the selected date value to parent
	const { selectDate, handleError } = props;

	const today = dayjs();
	const oneYearAhead = today.add(1, 'year');

	const [date, setDate] = useState(today);

	const handleChange = (value) => {
		if (dayjs(value).isAfter(today) || dayjs(value).isSame(today, 'day')) {
			handleError(false)

			const dateObject = new Date(value);
			const date = dateObject.getDate();
			const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
			const year = dateObject.getFullYear();

			const formattedDate = (month < 10 ? '0' + month : month) + '/' + (date < 10 ? '0' + date : date) + '/' + year;
			setDate(value);
			// Updating the parent function by sending the 'formattedDate' as value
			selectDate(formattedDate);
		}
		else {
			setDate(date)
			// send error state true to parent.
			// Error handling is commented in future if needed uncomment the below line
			// handleError(true)
		}



	};


	return (

		<LocalizationProvider dateAdapter={AdapterDayjs}>
			{/* This was used for manual entry of the Date. */}
			{/* <DatePicker
				onChange={handleChange}
				value={date}
				minDate={today}
				disablePast = {true}
				className='datepickerForm'
				sx={{
					"& input": {
						fontFamily: "var(--fontFamily)",
						fontWeight: "bold",
					},
				}}
			/> */}

			{/* This will not allow the manual entry of Date */}
			<MobileDatePicker
				onChange={handleChange}
				value={date}
				minDate={today}
				maxDate={oneYearAhead}
				disablePast={true}
				className='datepickerForm'
				sx={{
					"& input": {
						fontFamily: "var(--fontFamily)",
						fontWeight: "bold",
					},
				}}
				slotProps={{
					mobilePaper: {
						sx: { marginLeft: { xl: "13vw" } }
					}
				}}
			/>

		</LocalizationProvider>
	);
};

export default FormDatepicker;
