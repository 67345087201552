import React from "react";
import "./EmptyTemplate.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonComponent from "../../../../Shared/Commonfields/ButtonComponent";

import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import { languageId } from "../../../../Constants/static";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";


function EmptyTemplate({ data, NextQuestion, BackTracking, surveyname, Next, IDontKnow}) {

	const isBrowser = () => typeof window !== "undefined";

	const [isLoading, setIsLoading] = useState(false);
	const [nextqn, setNextQn] = useState("")
	const [otheranswertext, setOtheranswertext] = useState("");


	const member_id = localStorage.getItem("Survey_member_id")
	const campaign_id = localStorage.getItem("Survey_campaign_id")
	const language_code = localStorage.getItem("Survey_language_code")
	const Survey_reference=  localStorage.getItem("Survey_reference")
	const Survey_reference_order = localStorage.getItem("Survey_reference_order")

	const questionData = data;

	let language
	if (isBrowser() === true) {
		language = languageId[language_code]
	}

	let Pleasespecify = CustomLanguageservice.getMethod("Please specify", language)


	useEffect(() => {
		// Find the selected answer
		const selectedAnswer = questionData.answers.find(answer => answer.is_selected);
		setNextQn(selectedAnswer)
		// If a selected answer is found, set optionselected to its answer_id
		// if (selectedAnswer) {
		// 	setoptionselected(selectedAnswer.answer_id);
		// }
	}, [questionData]);



	const submitButtonClick = (btnname) => {

		let previous_question_id = questionData.previous_question_id
		previous_question_id = previous_question_id === "" ? 0 : parseInt(previous_question_id)

		let NextQnPayload = {
			"member_id": member_id,
			"language_code": "en",
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": questionData.question_id,
			"answers": [
				{
					"answer_id": questionData.answers[0].answer_id,
					"next_question_id": questionData.answers[0].next_question_id !== null ? questionData.answers[0].next_question_id : 0,
					"future_question_id": questionData.answers[0].future_question_id !== null ? questionData.answers[0].future_question_id : 0,
					"other_answer_text": "",
					"save_futre_text": JSON.stringify(questionData.question),
					"future_questions": questionData.answers[0].future_questions !== null ? questionData.answers[0].future_questions : [],
					"notification_id": questionData.answers[0].notification_id
				}
			],
			"previous_question_id": previous_question_id
		}
		NextQuestion(NextQnPayload);
	}

	const PreviousQuestion = () => {
		let previous_question_id = questionData.previous_question_id
		let PreviousQnPayload = {
			"member_id": member_id,
			"language_code": language_code,
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": parseInt(previous_question_id)
		}

		if (previous_question_id !== "" && previous_question_id !== 0 && previous_question_id !== undefined) {
			BackTracking(PreviousQnPayload)
		}

	}

	return (
		<Box className={"SurveyEmptyTemplatecontainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />
			<Grid container xs={12} className={"Survey_bodygrid_Template1"}>
				{questionData.previous_question_id !== "" && questionData.previous_question_id !== 0 && questionData.previous_question_id !== undefined &&
					<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }} >
						<img src="/asset/leftarrow.png" alt="leftarrow" tabIndex="0"  onClick={PreviousQuestion} 
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								PreviousQuestion();
							}
						  }}/>
					</Grid>
				}

				<Grid xs={11} >
					<div className={"Heading"}>
						{surveyname}
					</div>

				</Grid>

				<Grid xs={12} > <br></br><br></br>
					<div className={"questiontext"} tabIndex="0">
						<span dangerouslySetInnerHTML={{ __html: questionData.question[language_code] }} />
					</div>

				</Grid>


				<Grid xs={12} lg={12}><br></br><br></br><br></br>
					<ButtonComponent
						id="submitID"
						variant="contained"
						arialabel="Next"
						type="submit"
						onClick={() => submitButtonClick("")}
						className={"Nextbtn"}
						buttonname={Next}
					/>

				</Grid>

			</Grid>

		</Box>
	)
}

export default EmptyTemplate;

