
import React, { useState, useRef, useEffect } from 'react';
import "./AutoResponsePopup.css"
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { useForm, Controller } from "react-hook-form";
import { TextField, InputLabel, FormControl, MenuItem, Checkbox, Select, Box, Button, Radio, RadioGroup, FormControlLabel, Grid, InputAdornment, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import axios from 'axios';
import RichTextEditor from './../../Commonfields/RichTextEditorComponent';
import { URL } from "../../../Constants/apiUrl";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js';
// import { convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { htmlToDraft } from 'draft-js-import-html';
import { stateFromHTML } from 'draft-js-import-html';

function AutoResponsePopup({ isOpen, handleOKButton, campaignCode, autoresponseapidata }) {

	const invitations_reminders = [
		{
			"id": "sms_invitation",
			"title": "SMS Invitation",
			"description": "SMS Invitation Description"
		},
		{
			"id": "email_invitation",
			"title": "Email Invitation",
			"description": "Email Invitation Description"
		},
		{
			"id": "first_reminder_sms",
			"title": "First Reminder SMS",
			"description": "First Reminder SMS Description"
		},
		{
			"id": "first_reminder_email",
			"title": "First Reminder Email",
			"description": "First Reminder Email Description"
		},
		{
			"id": "final_reminder_sms",
			"title": "Final Reminder SMS",
			"description": "Final Reminder SMS Description"
		},
		{
			"id": "final_reminder_email",
			"title": "Final Reminder Email",
			"description": "Final Reminder Email Description"
		},
	]

	const [focusStates, setFocusStates] = useState({
		promotionName: false,
		promotionDescription: false,
		question: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [question, setQuestion] = useState('');
	const [questionID, setQuestionID] = useState('');
	const [answers, setAnswers] = useState([]);
	const [language, setLanguage] = useState('en');
	const [questionIndex, setQuestionIndex] = useState(null);
	const [promotionTemplateDisplay, setpromotionTemplateDisplay] = useState(true);
	const [surveyQuestionDisplay, setSurveyQuestionDisplay] = useState(false);
	const [smsInvitationDisplay, setSmsInvitationDisplay] = useState(false);
	const [emailInvitationDisplay, setEmailInvitationDisplay] = useState(false);
	const [selectedSmsEmail, setSelectedSmsEmail] = useState('');
	const [emailSubject, setEmailSubject] = useState('');
	const [emailBody, setEmailBody] = useState('');
	const [messageText, setMessageText] = useState('');

	const containerRef = useRef(null);
	const [result, setResult] = useState(null);

	const [previousValue, setPreviousValue] = useState('');
	const [currentValue, setCurrentValue] = useState('');

	const [selectedDescription, setSelectedDescription] = useState('');

	const [promotionName, setPromotionName] = useState('')
	const [promotionDesc, setPromotionDesc] = useState('')

	const [promotionNameError, setPromotionNameError] = useState('');
	const [promotionDescError, setPromotionDescError] = useState('');
	const [questionError, setQuestionError] = useState('');
	const [messageTextError, setMessageTextError] = useState('');
	const [subjectError, setSubjectError] = useState('');
	const [bodyTextError, setBodyTextError] = useState('');

	const editorRef = useRef(null);


	// Event handler for focus
	const handleFocus = (fieldName, value) => {
		setPreviousValue(value);
		setFocusStates({ ...focusStates, [fieldName]: true });
	};

	// Event handler for blur
	const handleBlur = (fieldName) => {
		console.log('Previous value:', previousValue);
		console.log('Current value:', currentValue);
		setFocusStates({ ...focusStates, [fieldName]: false });
		if (fieldName === 'promotionName') {
			if (currentValue === '') {
				setPromotionName(previousValue)
				setPromotionNameError('')
			}
		} else if (fieldName === 'promotionDescription') {
			if (currentValue === '') {
				setPromotionDesc(previousValue)
				setPromotionDescError('')
			}
		}
	};

	const handleChange = (event) => {
		setCurrentValue(event.target.value);
		setQuestion(event.target.value);
		// setInputValue(event.target.value);
	};

	const questionChange = (event) => {
		setQuestion(event.target.value);
	};

	const handlePromNameChange = (event) => {
		setPromotionName(event.target.value)
		if (event.target.value === '') {
			setPromotionNameError('Promotion name cannot be empty');
		} else {
			setPromotionNameError('');
		}
	}

	const handlePromDescChange = (event) => {
		setPromotionDesc(event.target.value)
		if (event.target.value === '') {
			setPromotionDescError('Description cannot be empty');
		} else {
			setPromotionDescError('');
		}
	}

	const messageTextChange = (event) => {
		setMessageText(event.target.value);
		if (event.target.value === '') {
			setMessageTextError('SMS cannot be empty');
		} else {
			setMessageTextError('');
		}
	};

	const emailSubjectChange = (event) => {
		setEmailSubject(event.target.value);
		if (event.target.value === '') {
			setSubjectError('Subject cannot be empty');
		} else {
			setSubjectError('');
		}
	};

	function handleClickcancel(data) {
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOKButton(result);
	};

	const languageChange = (event) => {
		setLanguage(event.target.value);
		setMessageText(result.sms.body[event.target.value])
		setEmailSubject(result.email.subject[event.target.value])
		setEmailBody(convertHtmlToEditorState(result.email.body[event.target.value]))

	};

	const handleEmailBodyTextChange = (value) => {
		const rawContent = convertToRaw(value.getCurrentContent());
		const htmlContent = stateToHTML(value.getCurrentContent());
		setEmailBody(value);

		const currentContent = value.getCurrentContent();
		if (currentContent.hasText() === false) {
			setBodyTextError('Body cannot be empty');
		} else {
			setBodyTextError('');
		}
	};

	const convertHtmlToEditorState = (html) => {
		const blocksFromHTML = convertFromHTML(html);
		const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
		return EditorState.createWithContent(contentState);
	};


	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (isOpen) {
			const jsonString = JSON.stringify(autoresponseapidata);
			const response = JSON.parse(jsonString);
			setResult(response)
			setMessageText(response.sms.body[language])
			setEmailSubject(response.email.subject[language])
			setEmailBody(convertHtmlToEditorState(response.email.body[language]))
		}
	}, [isOpen]);


	const getQuestion = (index) => {
		setSurveyQuestionDisplay(true)
		setpromotionTemplateDisplay(false)
		setSmsInvitationDisplay(false)
		setEmailInvitationDisplay(false)
		setQuestionIndex(index)
		setSelectedSmsEmail('')
		let question_text = result.data.survey_template.ref_questions[index].question_text[language]
		setQuestion(question_text)
		setQuestionID(result.data.survey_template.ref_questions[index].question_id)
		let question_id = result.data.survey_template.ref_questions[index].question_id
		getAnswers(question_id)
	};

	const getAnswers = (question_id) => {
		setAnswers(result.data.survey_template.ref_answers[question_id])
	};

	const surveyTemplate = () => {
		setpromotionTemplateDisplay(true)
		setSurveyQuestionDisplay(false)
		setSmsInvitationDisplay(false)
		setEmailInvitationDisplay(false)
		setQuestionIndex(null)
		setSelectedSmsEmail('')
	};

	const getInvitationReminder = (data) => {
		setBodyTextError('')
		setSubjectError('')
		setMessageTextError('')
		setSelectedSmsEmail(data)
		findDescriptionById(data)
		if (data === 'sms_invitation' || data === 'first_reminder_sms' || data === 'final_reminder_sms') {
			setMessageText(result.data.invitaions_reminders[data].body[language])
			setSmsInvitationDisplay(true)
			setpromotionTemplateDisplay(false)
			setSurveyQuestionDisplay(false)
			setEmailInvitationDisplay(false)
			setQuestionIndex(null)
		} else if (data === 'email_invitation' || data === 'first_reminder_email' || data === 'final_reminder_email') {
			setEmailSubject(result.data.invitaions_reminders[data].subject[language])
			setEmailBody(result.data.invitaions_reminders[data].body[language])
			setEmailInvitationDisplay(true)
			setSmsInvitationDisplay(false)
			setpromotionTemplateDisplay(false)
			setSurveyQuestionDisplay(false)
			setQuestionIndex(null)
		}

	}

	const prevQuestion = () => {
		if (questionIndex > 0)
			setQuestionIndex(questionIndex - 1)
		if (containerRef.current) {
			const element = containerRef.current.children[questionIndex - 1];
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	};

	const nextQuestion = () => {
		setQuestionIndex(questionIndex + 1)
		if (containerRef.current) {
			const element = containerRef.current.children[questionIndex + 1];
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	};

	const findDescriptionById = (id) => {
		const foundItem = invitations_reminders.find(item => item.id === id);
		if (foundItem) {
			setSelectedDescription(foundItem.description);
		} else {
			setSelectedDescription('');
		}
	};

	const handleClicksave = () => {
		result.sms.body[language] = messageText
		result.email.subject[language] = emailSubject
		result.email.body[language] = stateToHTML(emailBody.getCurrentContent())
		handleClickcancel()
	}

	return (
		<div >
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className={"autoResponsePopupcontainer"}>
					{isLoading &&
						<CommoncircularProgress></CommoncircularProgress>
					}
					<Grid container >
						<Grid item xs={12} className={"GridIcon"}>
							<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="15px" height="15px" onClick={handleClickcancel} />
						</Grid>

						<Grid item xs={12} className={"Gridtext"}>
							<Grid container className={"accordian"}>
								<Grid item xs={12} >
									<Grid item xs={12} className='surveyQuestionDiv' >
										<Grid item xs={10} >
											<h3 className='surveyText'>Auto-response</h3>
										</Grid>
										<Grid item xs={2}>
											<TextField
												className={"textField"}
												fullWidth
												select

												variant="outlined"
												value={language}
												onChange={languageChange}
											>
												<MenuItem value="en">English</MenuItem>
												<MenuItem value="es">Spanish</MenuItem>
												<MenuItem value="vi">Vietnamese</MenuItem>
											</TextField>
										</Grid>
										<Grid item xs={12} className='descDiv'>
											<div className="label_text">SMS</div>
											<TextField
												className={"textField"}
												id="outlined-multiline-flexible"
												multiline
												value={messageText}
												rows={2}
												onChange={messageTextChange}
												onFocus={handleFocus}
												onBlur={handleBlur}
												fullWidth
												variant="outlined"
												placeholder="Enter your text here..." />
											{messageTextError && <span className='errorMsg'>{messageTextError}</span>}

										</Grid>

										<Grid item xs={12} className='descDiv'>
											<div className="label_text_email" >Email</div>
											<div className="sub_label_text">Subject</div>
											<TextField
												className={"textField"}
												value={emailSubject}
												onChange={emailSubjectChange}
												onFocus={handleFocus}
												onBlur={handleBlur}
												id="outlined-basic"
												variant="outlined"
												fullWidth
												placeholder="Subject" />
											{subjectError && <span className='errorMsg'>{subjectError}</span>}
										</Grid>
										<div className="body_text_label" >Body Text</div>
										<Grid item xs={12} className='richTextEditor'>

											<Editor
												editorState={emailBody}
												onEditorStateChange={handleEmailBodyTextChange}
												wrapperClassName="demo-wrapper"
												editorClassName="demo-editor"
												placeholder="Type welcome text here..."
												toolbar={{
													options: ['history', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
													inline: { inDropdown: true },
													list: { inDropdown: false },
													textAlign: { inDropdown: false },
													link: { inDropdown: false },
													history: { inDropdown: false },
												}}
												ref={editorRef}
											/>
										</Grid>
										{bodyTextError && <span className='errorMsg'>{bodyTextError}</span>}
									</Grid>
									<Grid item xs={12} className='saveCancelDiv'>
										<Button
											variant="contained"
											aria-label="Cancel"
											type="submit"
											className="Cancelbtn" onClick={handleClickcancel}>
											Cancel
										</Button>
										<Button
											onClick={handleClicksave}
											variant="contained"
											aria-label="Save"
											type="submit"
											className={(emailBody === "" || emailSubject === "" || messageText === "") ? "disablesavebn" : "Savebtn"}>
											Save
										</Button>

									</Grid>
								</Grid>
							</Grid>
						</Grid>

					</Grid>
				</Box>
			</Modal>
		</div>
	)

}

export default AutoResponsePopup