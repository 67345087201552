export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';

export const Error = (data) => ({
  type: ERROR,
  data:data
});

export const Success = () => ({
    type: SUCCESS
  });