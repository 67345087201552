import axios from 'axios';
import Swal from 'sweetalert2';
import store from '../Redux/store'; // import your Redux store
import { Error } from '../Redux/actions';
import CustomLanguageservice from "./CustomLanguageservice";
import { URL } from "../Constants/apiUrl";

const axiosInterceptor = axios.interceptors.request.use(


	config => {
		let jwttoken;
		if(document.URL.includes("agent")){
			jwttoken = localStorage.getItem("Agent_token")
		}
		else{
			jwttoken = localStorage.getItem("token")
		}
		
		const token = "Bearer " + jwttoken;
		if (token) {
			config.headers['Authorization'] = token
		}

		// if some api does not require application/json
		//let sampleurl="https://api.npms.io/v2/search?q=react"
		let sampleurl = URL.UPLOAD_MEMBERLIST_CSV;
		if (config.url === sampleurl) {
			config.headers['content-type'] = 'multipart/form-data';
			config.headers['Accept'] = 'multipart/form-data';
		}
		else {
			config.headers['content-type'] = 'application/json';
			config.headers['Accept'] = 'application/json';
		}
		// config.headers['content-type'] = 'application/json'
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
axios.interceptors.response.use(function (response) {

	// console.log("i am authorized");
	return response;
}, (error) => {
	// localisation data
	let language;
	const isBrowser = () => typeof window !== "undefined";
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}
	let errormsg = CustomLanguageservice.getMethod("Oops! Something went wrong. Please log in again and try later.", language);
	let ok = CustomLanguageservice.getMethod("Ok", language);
	let errortitle = CustomLanguageservice.getMethod("error", language);
	
	if (error.response && error.response.status === 401) {
		return Promise.reject(error.response.data);
	}
	else if (error.response) {

		if (error.response.data.message) {
			if (document.URL.includes("Agent") || document.URL.includes("agent")) {
				if(error.response.data.message.includes("Your session has timed out")){
					store.dispatch(Error("Token Expired Agent"));
				}
				else{
					commonalert(error.response.data.message, ok, errortitle, error);
				}
			}
			
		}
		else {
			commonalert(errormsg, ok, errortitle, error);
		}
	}
	else {
		commonalert(errormsg, ok, errortitle, error);
		return Promise.reject(error.response.data);
	}

	return Promise.reject(error.message);
});

function commonalert(message, ok, errortitle, error) {
	Swal.fire({
		title: errortitle,
		text: message,
		icon: 'error',
		allowOutsideClick: false,
		confirmButtonColor: "var(--brandcolor)",
		confirmButtonText: ok,
	}).then((result) => {
		if (result.isConfirmed) {
			console.log('User clicked OK!');
			// if(error.request.responseURL.includes("onboarding")){
			if (document.URL.includes("Member") || document.URL.includes("member")) {
				store.dispatch(Error("Token Expired Member"));
			}
			else if (document.URL.includes("Survey") || document.URL.includes("survey")) {
				store.dispatch(Error("Token Expired survey"));
			}
			else if (document.URL.includes("Agent") || document.URL.includes("agent")) {
				store.dispatch(Error("Agent Error"));
				if(message.includes("Your session has timed out")){
					store.dispatch(Error("Token Expired Agent"));
				}
				else if(message.includes("Maximum Campaign creation limit reached")){
					store.dispatch(Error("Maximum Campaign creation limit reached"));
				}
			}
			else {
				store.dispatch(Error("Token Expired"));
			}

		}
	});
}

export default axiosInterceptor