import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';

const CustomScreen = () => {
  return (
    <Box 
      sx={{
        backgroundColor: '#090947',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img 
        src="http://d23bj7ser2frgh.cloudfront.net/asset/customcompanylogo.png" 
        alt="Company Logo"
        style={{ marginBottom: '20px' }}
      />
      <Container 
        sx={{
          textAlign: 'center',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          maxWidth:'500px',
          width: {
            lg: '30vw',
            md: '40vw',
            xs:'90vw'
          }, // or any desired width
          height: '350px'
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: '#B9D9EB',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
            marginBottom: '20px'
          }}
        >
          <Typography variant="h6" gutterBottom>
            The survey link you are trying to access is invalid
          </Typography>
        </Box>
        <Box 
          sx={{
            marginTop: '60px',
            textAlign: 'center'
          }}
        >
          <Typography variant="body1" gutterBottom>
            Click here for member login:
          </Typography>
          <Link 
            href="http://circleactivate.stellar.com.s3-website-us-east-1.amazonaws.com/member-login" 
            color="primary" 
            underline="hover"
            target="_blank"
            rel="noopener"
          >
            Member Login
          </Link>
          <br />
          <br />
          <Typography variant="body1" gutterBottom>
            Click here for Trusty Care:
          </Typography>
          <Link 
            href="https://www.trusty.care/" 
            color="primary" 
            underline="hover"
            target="_blank"
            rel="noopener"
            
          >
            Trusty Care
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default CustomScreen;
