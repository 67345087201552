export const surveyOptionType = {
	YES_NO: "YNO",
	YES_NO_INPUT: "YNI",
	SINGLE_SELECTION: "SSO",
	SINGLE_SELECTION_INPUT: "SSI",
	CHECK_ALL_THAT_APPLY: "CAO",
	CHECK_ALL_THAT_APPLY_INPUT: "CAI",
	FINAL: "FIN",
	INPUT_ONLY: "INP",
	EDITABLE_FORM : "EDF",
	CHECK_ALL_THAT_APPLY_COUNT: "CAC",
	EMPTY_ANSWER: "EMP",
	PROMOTION_TYPE:"PRO"
}

export const fianlTemplate = {
	TALK_TO_AN_AGENT : "talk to an agent",
	FINAL_ACTION_ITEM : "final action item",
	SCHEDULE_APPOINTMENT : "schedule an appointment"
}

export const editableForm = {
	EDIT_FORM : "editable form"
}

export const languageId = {
	"en" : "1",
	"es": "2",
	"vi": "3"
}

export const laguageCodes = ["en", "es", "vi"]


export const surveyNames = [
    "Wellness Check Appointment",
    "Post-enrollment Agent Survey",
    "Post-enrollment Plan Survey",
    "Post-enrollment Provider Survey",
    "1 month check-in flow",
    "Social",
    "Economic",
    "Health Literacy",
    "Physical Environment",
    "Overall Well-Being"
];

export const promotionNames = [
    "Mammogram",
    "Colonoscopy",
    "Lung scan",
    "Cervical cancer screening",
    "Prostate cancer screening",
    "Heart scan",
    "Re-enrollment"
];


export const updateSurveyNameVariant = {
	"save_qn" : "save_question",
	"save_info" : "save_description",
	"save_inv" : "save_invitation",
	"save_welcome" : "save_welcome",
	"save_thankyou" : "save_thankyou",
	"save_custom_qns" : "save_custom_qns"
}

export const updatePromotionNameVariant = {
	"save_desc" : "save_description",
	"save_info" : "save_information",
	"save_inv" : "save_invitation",
}

export const preferredChannel = {
	"0" : "Text",
	"1" : "Email",
	"2" : "Phone"
}

export const preferredFrequency = {
	"0" : "Often",
	"1" : "Periodically",
	"2" : "Seldom"
}