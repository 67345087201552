import "./Reports.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const CheckTypeOneAccord = ({ type, answer }) => {
	return (
		<Grid xs={12}>
			<Item style={{ marginTop: "5px" }} className="data-box">
				<p className="accordion-type-2-text">{answer.answer_text}</p>
				{type !== "INP" ? (
					<div className="variable-container">
						{answer.answer_type_id === "SSO" ||
						answer.answer_type_id === "YNO" ? (
							<div className="variable-1-data">
								{answer.response_selected_count}
							</div>
						) : (
							<div className="variable-1-data">
								{answer.selected_percentage}
							</div>
						)}
						{type == "SSO" ||
						type == "SSI" ||
						type == "YNO" ||
						type == "YNI" ? (
							""
						) : (
							<div className="variable-2-data">
								{answer.response_selected_count}
							</div>
						)}
					</div>
				) : (
					""
				)}
			</Item>
		</Grid>
	);
};

export default CheckTypeOneAccord;
