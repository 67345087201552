import React from "react";
import "./InputwithcalenderTemplate.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonComponent from "../../../../Shared/Commonfields/ButtonComponent";
import Textarea from '@mui/joy/Textarea';

import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import { languageId } from "../../../../Constants/static";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import DOMPurify from 'dompurify';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

function InputwithcalenderTemplate({ data, NextQuestion, BackTracking, surveyname, Done }) {

	const isBrowser = () => typeof window !== "undefined";
	const [disablepastDates, setDisablepastDates] = useState(false);
	const [disablefutureDates, setDisablefutureDates] = useState(false);
	const [optionselected, setoptionselected] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [nextqn, setNextQn] = useState("")
	const [otheranswertext, setOtheranswertext] = useState("");
	const [appointmentdate, setappointmentdate] = useState("");

	let member_data = localStorage.getItem("member_data");
	let member_data_parsed = JSON.parse(member_data)


	const member_id = localStorage.getItem("Survey_member_id")
	const campaign_id = localStorage.getItem("Survey_campaign_id")
	const language_code = localStorage.getItem("Survey_language_code")
	const Survey_reference=  localStorage.getItem("Survey_reference")
	const Survey_reference_order = localStorage.getItem("Survey_reference_order")

	const questionData = data;

	let language
	if (isBrowser() === true) {
		language = languageId[language_code]
	}

	let Pleasespecify = CustomLanguageservice.getMethod("Please specify", language)


	useEffect(() => {
		// Find the selected answer
		const selectedAnswer = questionData.answers.find(answer => answer.is_selected);
		
		setNextQn(selectedAnswer);

		if(member_data_parsed.doctor_name !==""){
		setOtheranswertext(member_data_parsed.doctor_name)
		}
		else{
		setOtheranswertext(member_data_parsed.provider_name)	
		}
		
		if (member_data_parsed.appointment_date !== null) {
			let date = dayjs(member_data_parsed.appointment_date).format('MM/DD/YYYY');
			setappointmentdate(date)
		}
		else {
			setappointmentdate("");
		}
		// If a selected answer is found, set optionselected to its answer_id
		if (selectedAnswer) {
			setoptionselected(selectedAnswer.answer_id);
		}

		if (questionData.answers[0].is_future_date === true) {
			setDisablefutureDates(false);
			setDisablepastDates(true)
		}
		else {
			setDisablefutureDates(true);
			setDisablepastDates(false)
		}


	}, [questionData]);

	const handletextareavalue = (event) => {
		const sanitizedInput = DOMPurify.sanitize(event.target.value);
		// Then, filter out numbers and special characters, allowing only letters and spaces
		const filteredInput = sanitizedInput.replace(/[^a-zA-Z\s]/g, '');
		setOtheranswertext(filteredInput)
	}

	const handleDateChange = (date) => {
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		if (changeddate === "Invalid Date") {
			setappointmentdate("");
		}
		else {
			setappointmentdate(changeddate);
		}
	};


	const submitButtonClick = (btnname) => {
		let otheranwertext_dup;
		otheranwertext_dup = otheranswertext;

		let previous_question_id = questionData.previous_question_id
		previous_question_id = previous_question_id === "" ? 0 : parseInt(previous_question_id)

		let NextQnPayload = {
			"member_id": member_id,
			"language_code": "en",
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": questionData.question_id,
			"answers": [
				{
					"answer_id": questionData.answers[0].answer_id,
					"next_question_id": questionData.answers[0].next_question_id !== null ? questionData.answers[0].next_question_id : 0,
					"future_question_id": questionData.answers[0].future_question_id !== null ? questionData.answers[0].future_question_id : 0,
					"other_answer_text": "",
					"save_futre_text": JSON.stringify(questionData.question),
					"future_questions": questionData.answers[0].future_questions !== null ? questionData.answers[0].future_questions : [],
					"notification_id": questionData.answers[0].notification_id
				}
			],
			"provider_name": otheranwertext_dup,
			"appointment_date": appointmentdate,
			"previous_question_id": previous_question_id
		}
		NextQuestion(NextQnPayload);
	}

	const PreviousQuestion = () => {
		let previous_question_id = questionData.previous_question_id
		let PreviousQnPayload = {
			"member_id": member_id,
			"language_code": language_code,
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": parseInt(previous_question_id)
		}

		if (previous_question_id !== "" && previous_question_id !== 0 && previous_question_id !== undefined) {
			BackTracking(PreviousQnPayload)
		}

	}

	return (
		<Box className={"SurveyTemplate5container"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />
			<Grid container xs={12} className={"Survey_bodygrid_Template1"}>
				{questionData.previous_question_id !== "" && questionData.previous_question_id !== 0 && questionData.previous_question_id !== undefined &&
					<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }} >
						<img src="/asset/leftarrow.png" alt="leftarrow" tabIndex="0" onClick={PreviousQuestion} 
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								PreviousQuestion();
							}
						  }}/>
					</Grid>
				}

				<Grid xs={11} >
					<div className={"Heading"}>
						{surveyname}
					</div>

				</Grid>


				<Grid xs={12} > <br></br><br></br>
					<div className={"questiontext"} tabIndex="0">
						<span dangerouslySetInnerHTML={{ __html: questionData.question[language_code] }} />
					</div>

				</Grid>

				<Grid xs={12} lg={12}><br></br><br></br>
					<Textarea placeholder={Pleasespecify + "...."}
						className={"textarea"}
						// defaultValue={questionData.answers[0].other_answer_text}
						value={otheranswertext}
						onChange={handletextareavalue}
					/>
				</Grid>

				<Grid xs={12} lg={12}><br></br>
					<LocalizationProvider dateAdapter={AdapterDayjs} >
						<MobileDatePicker className="datepicker" onChange={handleDateChange}
							value={dayjs(appointmentdate)}
							maxDate={disablefutureDates === true && disablepastDates === false ? dayjs() : null}
							minDate={disablefutureDates === false && disablepastDates === true ? dayjs() : null}
						/>
					</LocalizationProvider>
				</Grid>

				<Grid xs={12} lg={12}><br></br><br></br>
					<ButtonComponent
						id="submitID"
						variant="contained"
						arialabel="Done"
						type="submit"
						onClick={() => submitButtonClick("")}
						className={otheranswertext !== "" && appointmentdate !== "" ? "Nextbtn" : "disablebtn"}
						buttonname={Done}
					/>

				</Grid>



			</Grid>

		</Box>
	)
}

export default InputwithcalenderTemplate;

