import React from 'react'
import { useEffect, useState } from "react";
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Navigate, useLocation ,useNavigate } from 'react-router-dom';

import HeaderOnBoarding from '../../../Shared/HeaderOnBoarding/HeaderOnBoarding';
import CustomLanguageservice from '../../../Services/CustomLanguageservice';
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { apiurl } from "../../../Environment/Environment";
import { ROUTES_MEMBER_LOGIN, ROUTES_SURVEY_THANK_YOU } from '../../../Constants/routes';


const SurveyThankYou = () => {
	window.history.pushState({}, '', ROUTES_SURVEY_THANK_YOU);
	const Navigate = useNavigate();
	
	const isBrowser = () => typeof window !== "undefined";
	const location = useLocation();
	const [firstname, setfirstname] = React.useState("");
	const [isLoading, setIsLoading] = useState(false);

	let currentPath = location.pathname;
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	let thankYouText = CustomLanguageservice.getMethod("Thanks,", language);
	let firstPartAboveTextOfBtns = CustomLanguageservice.getMethod("Your personal information and preferences are stored on your", language);
	let secondPartAboveTextOfBtns = CustomLanguageservice.getMethod("account page.", language);
	let thirdPartAboveTextOfBtns = CustomLanguageservice.getMethod("You can visit that page at any time to make changes to your profile or preferences.", language);
	let scheduleAppointmentBtnText = CustomLanguageservice.getMethod("Schedule appointment with Agent", language);
	let talkAgentBtnText = CustomLanguageservice.getMethod("Talk to an Agent", language);
	let lastsentence = CustomLanguageservice.getMethod("You can also visit that page to schedule an appointment or talk to an agent.", language)

	const accountPage = () => {
		localStorage.setItem("TokenExpired", "true");
		Navigate(ROUTES_MEMBER_LOGIN)
	}

	useEffect(() => {
		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			setfirstname(response.data.data.first_name)
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
		})
	}, []);


	return (
		<Box className="CommonLoader">
			 {isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />

			<Grid container direction="column" justifyContent="center" alignItems="center">
				<Grid xs={12} className="messageSec">
					<div className="informationbox2">
						<Box component="div"
							className="namelabeltitle biggerFont"
						>
							{thankYouText} <b>{firstname}!</b>
						</Box>
						<Box component="div" className='statementText'>
							<span> {firstPartAboveTextOfBtns} </span>
							&nbsp;<span className='underline' onClick={accountPage}>{secondPartAboveTextOfBtns}</span> {thirdPartAboveTextOfBtns} {lastsentence}
						</Box>
					</div>
				</Grid>
			</Grid>
		</Box>
	)
}

export default SurveyThankYou;
