import React, { useState, useEffect } from "react";

import Grid from '@mui/material/Unstable_Grid2';
import { Button, Typography, Box, Skeleton } from "@mui/material";

import "./MyDoctor.css"
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import AppointmentTable from "../../../Shared/Table/Table";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import RequestCallbackPopup from "../../../Shared/Popups/RequestCallbackPopup/RequestCallbackPopup";
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import ScheduleCallback from "../../../Shared/Popups/ScheduleCallback/ScheduleCallback";
import CallbackAlertPopup from "../../../Shared/Popups/CallbackAlertPopup/CallbackAlertPopup";
import ButtonComponent from "../../../Shared/Commonfields/ButtonComponent";


function createData(medicalAdvisor, appointmentType, date, time) {
	return { medicalAdvisor, appointmentType, date, time };
}

function MyDoctor() {
	const isBrowser = () => typeof window !== "undefined";

	const [isLoading, setIsLoading] = useState(false);
	const [rows, setrows] = useState([]);
	const [doctordetail_apidata, setdoctordetail_apidata] = useState([]);
	const [doctordetail_apidataPhonenum, setdoctordetail_apidataPhonenum] = useState("");
	const [doctorlogo, setDoctorlogo] = useState("/asset/Member/doctor-profile.png");
	const [extappointment, setExtappointment] = useState("");
	const [dialingnum, setDialingnum] = useState("");
	const [agentid, setAgentid] = useState("")
	const [slot, setSlot] = useState("Mon-Sat")
	const [slottime, setSlottime] = useState("9:00 AM - 5:00 PM (EST)")
	const [isavailable, setIsavailable] = useState("")
	const [reqcallbackDetails, setRequestCallbackDetails] = useState("")
	const [cancelcallDetails, setCancelCallDetails] = useState("")
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [memberphone, setMemberphone] = useState("")
	const [apiDetails, setApiDetails] = useState("")

	let endpoint = URL.MEMBER_MY_DOCTOR;
	let scheduleCall = URL.MEMBER_SCHEDULE_CALLBACK;
	let confirmCall = URL.MEMBER_CONFIRM_CALLBACK;

	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	let MyDoctor = CustomLanguageservice.getMethod("My Doctor", language);
	let Facility = CustomLanguageservice.getMethod("Facility", language)
	let Address = CustomLanguageservice.getMethod("Address", language)
	let Email = CustomLanguageservice.getMethod("Email", language)
	let Contact = CustomLanguageservice.getMethod("Contact", language)
	let MakeanAppointment = CustomLanguageservice.getMethod("Make an Appointment", language)
	let NeedhelpschedulingyourappointmentYourAgentcanhelp = CustomLanguageservice.getMethod("Need help scheduling your appointment? Your Agent can help!", language)
	let UpcomingAppointment = CustomLanguageservice.getMethod("Upcoming Appointment", language)
	let CancelorReschedule = CustomLanguageservice.getMethod("Cancel or Reschedule", language)
	let RequestaCallback = CustomLanguageservice.getMethod("Request a Callback", language)
	let ScheduleaCallback = CustomLanguageservice.getMethod("Schedule a Callback", language)
	let Confirm = CustomLanguageservice.getMethod("Confirm", language)
	let Cancel = CustomLanguageservice.getMethod("Cancel", language)
	let CallNow = CustomLanguageservice.getMethod("Call Now", language)
	let CallbackRequestinQueue = CustomLanguageservice.getMethod("Callback Request in Queue", language)
	let Alert = CustomLanguageservice.getMethod("Alert", language)
	let OK = CustomLanguageservice.getMethod("Ok", language)
	let error = CustomLanguageservice.getMethod("error", language)
	let Youdonthaveanyappointments = CustomLanguageservice.getMethod("You dont have any appointments", language)
	let Done_text = CustomLanguageservice.getMethod("Done", language);
	let Hi = CustomLanguageservice.getMethod("Hi", language);
	let youragent = CustomLanguageservice.getMethod("your agent", language);
	let willcallyouat = CustomLanguageservice.getMethod("will call you at", language);
	let tocancelthecall = CustomLanguageservice.getMethod("to cancel the call", language);

	useEffect(() => {
		setIsLoading(true);

		Apiservice.getMethod(endpoint).then(response => {
			let details = response.data.data
			setApiDetails(details)
			setIsLoading(false);
			setdoctordetail_apidata(response.data.data);
			if (details.doctor_contact !== "" && details.doctor_contact !== null && details.doctor_contact !== undefined) {
				setdoctordetail_apidataPhonenum(details.doctor_contact.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'));
				setDialingnum(details.doctor_contact)
			}
			else {
				if (details.request_callback.agent_number !== "" && details.request_callback.agent_number !== null && details.request_callback.agent_number !== undefined) {
					setDialingnum(details.request_callback.agent_number)
				}

			}
			if (details.doctor_img !== "" && details.doctor_img !== null && details.doctor_img !== undefined) {
				setDoctorlogo(details.doctor_img)
			}
			if (details.doctor_appointment !== "" && details.doctor_appointment !== null && details.doctor_appointment !== undefined) {
				setExtappointment(details.doctor_appointment)
			}
			setAgentid(response.data.data.request_callback.agent_id)
			let upcomingAppointments = details.upcoming_appointments.upcoming_appointments;
			let data = [
				...upcomingAppointments.map(appointment => {
					const { medical_advisor, appointment_type, appointment_date, appointment_time } = appointment;
					return createData(medical_advisor, appointment_type, appointment_date, appointment_time);
				}),

			];

			let memberPhone = details.request_callback.member_phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
			setMemberphone(memberPhone)

			setSlot(details.request_callback.agent_availability.slot_name)
			setSlottime(details.request_callback.agent_availability.slot_time)
			setIsavailable(details.request_callback.agent_availability.is_available)

			let text = "Hi " + "<strong>" + details.request_callback.member_name + "</strong>" + " your agent " + details.request_callback.agent_name + " will call you at " + "<strong>" + memberPhone + "</strong>"
			setRequestCallbackDetails(text)
			let cancelCallText = "Hi " + "<strong>" + details.request_callback.member_name + "</strong>" + " your agent " + details.request_callback.agent_name + " will call you at " + "<strong>" + memberPhone + "</strong>" + " " + "to cancel the call!"
			setCancelCallDetails(cancelCallText)

			setrows(data)

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})


	}, []);


	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleSelect = (date, time, comment, varient) => {

		setIsLoading(true);
		if (isavailable === true) {
			let data = {
				"preferred_date": date,
				"preferred_time": time,
				"message": (varient === "requestCall") ? comment : "Want to cancel an appointment.",
				"agent_id": agentid.toString()
			}

			apiCall(confirmCall, data)

		}
		else {
			let data = {
				"preferred_date": date,
				"preferred_time": time,
				"message": comment,
				"agent_id": agentid.toString()
			}

			apiCall(scheduleCall, data)
		}
	}

	const apiCall = (url, data) => {

		Apiservice.postMethod(url, data).then(response => {
			setIsLoading(false);
			let val = response.data.message
			if (val === "You already have a pending request." || val === "There is existing callback exists") {
				CallbackAlertPopup(CallbackRequestinQueue, OK, Alert, error)
			}
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}


	const conditionCheck = (variable) => {
		if (variable !== "" && variable !== undefined && variable !== null) {
			return variable
		}
		else {
			return "-"
		}
	}

	const skeletonLoad = (value) => {
		return isLoading ? <Skeleton animation="wave" sx={{ width: "100px" }} /> : value
	}

	const submitButtonClick = (data) => {
		sessionStorage.removeItem("isnavigated");
		window.location.reload();
	}

	return (

		<Box display="flex" justifyContent="center" className={"MydoctorContainerMember"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container sx={{ justifyContent: "center" }} className={"doctorParent"}>
				<Grid xs={12} className={"doctorPageheading"} pt={1}>
					<p tabIndex="0" >{MyDoctor}</p>
				</Grid>
				<Grid container xs={11} lg={8} mt={{ xs: 1, md: 5 }} spacing={0} >
					<Grid xs={12} md={3} alignSelf={"end"}>
						<img tabIndex="0" alt="doctorlogo" src={doctorlogo} className="docImage"></img>
					</Grid>
					<Grid container xs={12} md={5} sx={{ textAlign: "left" }}>
						<Grid xs={12}>
							<p className="mydoctorSubheading docName" tabIndex="0">{skeletonLoad(doctordetail_apidata.doctor_name)}</p>
							<p className="doctorDetails aboutDocmargin" tabIndex="0">{skeletonLoad(doctordetail_apidata.doctor_specialization)}</p>
							<div className="aboutDocFlex aboutDocmargin"><p className="mydoctorSubheading" tabIndex="0">{Facility}:&nbsp;</p>
								<p tabIndex="0">{skeletonLoad(doctordetail_apidata.facility_name)}</p></div>
							<div className="aboutDocFlex aboutDocmargin"><p tabIndex="0"><strong>{Address}:&nbsp;</strong>
								{skeletonLoad(conditionCheck(doctordetail_apidata.facility_address))}
							</p></div>
							<div className="aboutDocFlex aboutDocmargin"><p className="mydoctorSubheading" tabIndex="0">{Email}:&nbsp;</p>
								<p style={{maxWidth:"100%", wordWrap:"break-word", overflow:"hidden"}}>{isLoading ?
									<Skeleton animation="wave" sx={{ width: "100px" }} /> :
									<>
										{(doctordetail_apidata.doctor_email !== "" && doctordetail_apidata.doctor_email !== null && doctordetail_apidata.doctor_email !== undefined) ?
											(
												<p style={{ borderBottom: "1px solid black"}}>
													<a style={{ cursor: "pointer" }} tabIndex="0"
														onClick={() => window.location.href = `mailto:${doctordetail_apidata.doctor_email}`}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
															window.location.href = `mailto:${doctordetail_apidata.doctor_email}`
															}
															}} >
														{doctordetail_apidata.doctor_email}
													</a>
												</p>
											)
											: (
												"-"
											)

										}
									</>
								}
								</p>

							</div>
							<div className="aboutDocFlex aboutDocmargin"><p className="mydoctorSubheading" tabIndex="0">{Contact}:&nbsp;</p>
								<p tabIndex="0">{skeletonLoad(doctordetail_apidataPhonenum)}</p>
							</div>
						</Grid>
					</Grid>
					<Grid container xs={12} md={4} sx={{ textAlign: "left", mt: { xs: 3, md: "auto" } }} >


						{(extappointment !== "") ? (
							// MakeAppointment flag from back end
							// This will open a webpage new tab of the browser.
							<>
								<Button variant="contained" aria-label="confirmAppointmentbtn" className="makeAppointment"
									onClick={() => window.open(extappointment, '_blank', 'noopener,noreferrer')}
								>
									{MakeanAppointment}</Button>
								{/* <div><p className="aboutDocmargin">{NeedhelpschedulingyourappointmentYourAgentcanhelp}</p></div> */}
							</>
						) : (
							(dialingnum !== "" && (screenWidth < 799)) ? (
								// if doctor contact number is available then make a call option.
								<>
									<Button variant="contained" aria-label="confirmAppointmentbtn" className="makeAppointment"
										onClick={() => window.location.href = `tel:${dialingnum}`}>{CallNow}
									</Button>
									<div><p className="aboutDocmargin" >{NeedhelpschedulingyourappointmentYourAgentcanhelp}</p></div>
								</>
							) : (
								// isavailable ? (
								// 	// if agent is available request a callback option.
								// 	/* Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted 
								// 	varient={"cancelCall"} is for making the button white color apply different css */
								// 	<RequestCallbackPopup text={reqcallbackDetails} handleSelect={handleSelect} heading={RequestaCallback} btn1={Confirm} btn2={Cancel} varient={"requestCall"} />
								// ) : (
								// 	// if agent is not available then schedule a callback option.
								// 	// Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted 
								// 	<ScheduleCallback handleSelect={handleSelect} heading={ScheduleaCallback} btn1={Confirm} btn2={Cancel} />
								// )
								<>
									{isLoading ? <Skeleton animation="wave" sx={{ width: "100%", height: "90px", borderRadius: "10px", background: "var(--btncolor)" }} /> :
										(<>
											<ScheduleCallback handleSelect={handleSelect} heading={ScheduleaCallback} btn1={Confirm} btn2={Cancel} language={language} />
											<div><p className="aboutDocmargin" tabIndex="0">{NeedhelpschedulingyourappointmentYourAgentcanhelp}</p></div>
										</>
										)}

								</>
							)
						)
						}
					</Grid>
					<Grid xs={12}>
						<br></br>
						<hr></hr>
					</Grid>
				</Grid>
				<Grid container xs={11} lg={8} sx={{ mt: "24px !important", mb: 5 }} mt={3} mb={5}>
					<Grid xs={12} textAlign={"left"}>
						<p className="mydoctorSubheading" tabIndex="0">{UpcomingAppointment}</p>
					</Grid>

					{isLoading ? <Skeleton animation="wave" sx={{ width: "100%", height: "90px", borderRadius: "10px", background: "var(--brandcolor)" }} /> :
						(<>
							{Object.keys(rows).length !== 0 ? (
								<>
									<Grid xs={12} mt={3}>
										{/* Sending row data as props to the AppointmentTable component */}
										<AppointmentTable rows={rows} language={language} />
									</Grid>
									<Grid container xs={12} mt={3} justifyContent={"flex-end"}>
										<Grid xs={12} md={4}>
											{/* Cancellation of appointment */}
											{/* Sending handleSelect' functions as props so that we can get the values of  'Date', 'Time' and the 'text' inputted 
													varient={"cancelCall"} is for making the button white color apply different css*/}
											<RequestCallbackPopup 
												text={
														`${Hi} <strong>${apiDetails.request_callback.member_name}</strong> ${youragent} ${apiDetails.request_callback.agent_name} ${willcallyouat}<strong>${memberphone}</strong> ${tocancelthecall}
													`} 
												handleSelect={handleSelect}
												heading={CancelorReschedule} 
												btn1={Confirm} 
												btn2={Cancel} 
												varient={"cancelCall"} />
										</Grid>
									</Grid></>
							) : (
								<>
									<Grid xs={12} mt={3}>
										<Typography variant="body1" align="left" className={"upcommingAppointment"} tabIndex="0" aria-label={Youdonthaveanyappointments}>
											{Youdonthaveanyappointments}
										</Typography>
									</Grid>

								</>
							)
							}
						</>
						)
					}
				</Grid>

				<Grid container xs={11}>
					<ButtonComponent
						id="submitID"
						variant="contained"
						arialabel="Donebtn"
						type="submit"
						onClick={() => submitButtonClick("Some Data")}
						className="Donebtn"
						buttonname={Done_text}
					/>
				</Grid>

			</Grid >

		</Box>

	)
}

export default MyDoctor;