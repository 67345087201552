import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import { Skeleton, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import "./PlanInformation.css"
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import Apiservice from "../../../Services/Apiservice";
import { URL } from "../../../Constants/apiUrl";


function PlanInformation() {

	const skeletonLoad = (value) => {
		return isLoading ? <Skeleton animation="wave" sx={{width:"150px"}} /> : value
	}

	const isBrowser = () => typeof window !== "undefined";

	const [plandetails, setPlandetails] = useState("");
	const [ planId, setPlanId ] = useState("");

	let endpoint = URL.MEMBER_PLAN_INFO;


	const [isLoading, setIsLoading] = useState(false);

	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	useEffect(() => {
		setIsLoading(true);
		Apiservice.getMethod(endpoint).then(response => {
			setIsLoading(false);
			setPlandetails(response.data.data);

			let numStr = response.data.data.record_id.toString();
			let paddedNum = numStr.padStart(10, '0');
			let formattedStr = 'CE' + paddedNum;

			setPlanId(formattedStr)
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}, [])


	let PrimaryCarePhysician = CustomLanguageservice.getMethod("Primary Care Physician", language)
	let PlanName = CustomLanguageservice.getMethod("Plan Name", language)
	let LineofBusiness = CustomLanguageservice.getMethod("Line of Business", language)
	let Location = CustomLanguageservice.getMethod("Location", language)
	let EffectiveDate = CustomLanguageservice.getMethod("Effective Date", language)
	let RecordID = CustomLanguageservice.getMethod("Record ID", language)
	let PlanInformation = CustomLanguageservice.getMethod("Plan Information", language)

	return (

		<Box display="flex" justifyContent="center" className={"PlanInformationContainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container sx={{ justifyContent: "center" }} xs={12} className={"planParent"}>
				<Grid xs={12} className={"planPageheading"} pt={1}>
					<p tabIndex="0" aria-label={PlanInformation}>{PlanInformation}</p>
				</Grid>



				<Grid container xs={12} mt={{ xs: 3 }}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						minHeight: { md: "80vh" },
						marginTop: { xs: "10px", md: "inherit" }

					}}
				>
					<Grid container item xs={11} md={8} pt={{ md: 6 }} pb={{ md: 6 }} sx={{ background: "var(--lightgrey)", borderRadius: "10px" }}>

						<Grid item className={"accountPlanParent"} md={4} xs={12}>
							<Grid pl={{ md: 1 }}>
								{/* padding left is given as it comes to left of the screen */}
								<div className="planInfoLeftalign">
									<p className="memberInputlabel" tabIndex="0">{PlanName}</p>
									<p tabIndex="0">{skeletonLoad(plandetails.plan_name)}</p>
								</div>
							</Grid>
						</Grid>
						<Grid item className={"accountPlanParent"} md={4} xs={12}>
							<Grid>
								<div className="planInfoLeftalign">
									<p className="memberInputlabel" tabIndex="0">{LineofBusiness}</p>
									<p tabIndex="0">{skeletonLoad(plandetails.business_line)}</p>
								</div>
							</Grid>
						</Grid>
						<Grid item className={"accountPlanParent"} md={4} xs={12}>
							<Grid pr={{ md: 1 }}>
								{/* padding right is given as it comes to right of the screen */}
								<div className="planInfoLeftalign">
									<p className="memberInputlabel" tabIndex="0">{Location}</p>
									<p tabIndex="0">{skeletonLoad(plandetails.location)}</p>
								</div>
							</Grid>
						</Grid>
						<Grid item className={"accountPlanParent"} md={4} xs={12} >
							<Grid pt={{ md: 4 }} pl={{ md: 1 }}>
								{/* padding left is given as it comes to left of the screen */}
								<div className="planInfoLeftalign">
									<p className="memberInputlabel" tabIndex="0">{EffectiveDate}</p>
									<p tabIndex="0">{skeletonLoad(plandetails.effective_date)}</p>
								</div>
							</Grid>
						</Grid>
						<Grid item className={"accountPlanParent"} md={4} xs={12}>
							<Grid pt={{ md: 4 }}>
								<div className="planInfoLeftalign">
									<p className="memberInputlabel" tabIndex="0">{RecordID}</p>
									{/* <p>{skeletonLoad(plandetails.record_id)}</p> */}
									<p tabIndex="0"> {planId}</p>
								</div>
							</Grid>
						</Grid>
						<Grid item className={"accountPlanParent"} md={4} xs={12}>
							<Grid pt={{ md: 4 }} pr={{ md: 1 }}>
								{/* padding right is given as it comes to right of the screen */}
								<div className="planInfoLeftalign">
									<p className="memberInputlabel" tabIndex="0">{PrimaryCarePhysician}</p>
									<p tabIndex="0">{skeletonLoad(plandetails.primary_physician)}</p>
								</div>
							</Grid>
						</Grid >

						<Grid xs={12} style={{ background: "var(--lightgrey)", paddingBottom: "30px", borderRadius: "10px" }}>

						</Grid>


					</Grid>
				</Grid>
			</Grid>
		</Box>

	)
}

export default PlanInformation;