import React, { useEffect, useState, useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import FonthandlePopup from "../Popups/FonthandlePopup/FonthandlePopup";
import { ThemeSelectorContext } from "../../Services/Theme";
import Box from '@mui/material/Box';

import CommoncircularProgress from "../CommoncircularProgress/CommoncircularProgress"

function HeaderOnBoarding() {
    const { toggleTheme } = useContext(ThemeSelectorContext);
    const [logo, setlogo] = React.useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		// get api data
		setlogo("/asset/customcompanylogo.png");
	}, []);


	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

    const handleOK = (value) => {
		sessionStorage.setItem("ThemeValue", value);
        console.log(value)
        handleCloseModal();
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            toggleTheme(value)

        }, 1000);
    };


    return (
        <Box className={"HeaderContainer"}>
            <Grid container xs={12} className={"headerGrid-Profile"}>
                <Grid xs={10}>
                    <div className={"headingimgdiv_profile"} tabIndex="0">
                        <img src={logo}  alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                    </div>
                </Grid>

                <Grid xs={2} className={"headingcol2_profile"}>
                    <div className={"headingimgdiv2_profile"}>
                        <img src="/asset/fontimage.png" alt="fontChangeimage" tabIndex="0" style={{ width: "100%", height: "100%", objectFit: "contain", cursor: "pointer" }}
                            onClick={handleOpenModal} 
							onKeyDown={(e) => {
								if (e.key === "Enter") {
								handleOpenModal();
								}
								}} />
                    </div>
                    <FonthandlePopup
                        isOpen={isModalOpen}
                        title="User Modal Title"
                        description="This is the user modal description."
                        handleClose={handleCloseModal}
                        handleOK={handleOK}
                    />
                </Grid>

                {isLoading &&
                    <CommoncircularProgress></CommoncircularProgress>
                }

            </Grid>
        </Box>


    )

}


export default HeaderOnBoarding;
