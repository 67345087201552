import "./Reports.css";
import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
// import NormalAccordion from "./normalaccord";
import CheckAccordion from "./check_accord";

export default function SurveyAccordion({ report }) {
	let i = 0;
	const reportsRender = report?.survey_data.map((item, index) => {
		console.log(index)
		if (item.question_type_id !== "FIN") {
			i += 1;
			return (
				<CheckAccordion key={index + item.question_id} item={item} index={i} />
			);
			
		}
	});
	return (
		<div className="survey-results-container">
			<div className="survey-details-banner">
				<p className="survey-details-banner-text">Survey Results</p>
			</div>
			<div className="survey-data-container">
				<>{reportsRender ? reportsRender : ""}</>
			</div>
		</div>
	);
}
