import React from "react";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from 'react-router-dom';

import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import CommonService from "../../../../Services/CommonService";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import { apiurl } from "../../../../Environment/Environment";
import Apiservice from "../../../../Services/Apiservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import SuccessPopup from "../../../../Shared/Popups/SuccessPopup/SuccessPopup";

function EditProfileInformation() {
	const isBrowser = () => typeof window !== "undefined";
	const Navigate = useNavigate();
	const location = useLocation();
	const [selectedState, setselectedState] = React.useState("");
	const [Statearray, setStatearray] = React.useState([]);
	const [progress, setProgress] = React.useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [contain5zero, setcontain5zero] = useState(false);
	const [isSameAddress, setIsSameAddress] = useState("true");

	const [changed_firstname, setchanged_firstname] = useState("");
	const [changed_lastname, setchanged_lastname] = useState("");
	const [changed_phonenumber, setchanged_phonenumber] = useState("");
	const [changed_email, setchanged_email] = useState("");
	const [changed_billingAdress, setchanged_billingAdress] = useState("");
	const [changed_city, setchanged_city] = useState("");
	const [changed_state, setchanged_state] = useState("");
	const [changed_zipcode, setchanged_zipcode] = useState("");

	const {
		register,
		control,
		handleSubmit,
		formState: { errors }, clearErrors,
		reset
	} = useForm({
		mode: "onSubmit"
	});

	let currentPath = location.pathname;

	// localisation data
	let language;
	let ProfileInformationapiData;
	let containemptydata;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
		containemptydata = localStorage.getItem("containemptydata")
	}

	let Profileinformation = CustomLanguageservice.getMethod("Profile information", language);
	let Aboutyou = CustomLanguageservice.getMethod("About you", language);
	let Firstname = CustomLanguageservice.getMethod("First Name", language);
	let Lastname = CustomLanguageservice.getMethod("Last Name", language);
	let PhoneNumber = CustomLanguageservice.getMethod("Phone Number", language);
	let Emailaddress = CustomLanguageservice.getMethod("Email address", language);
	let BillingAddress = CustomLanguageservice.getMethod("Billing Address", language);
	let Streetaddress = CustomLanguageservice.getMethod("Street address", language);
	let City = CustomLanguageservice.getMethod("City", language);
	let State = CustomLanguageservice.getMethod("State", language);
	let Zipcode = CustomLanguageservice.getMethod("Zip code", language);
	let cannotbeempty = CustomLanguageservice.getMethod("cannot be empty", language);
	let Invalid = CustomLanguageservice.getMethod("Invalid", language);
	let mustbe10digits = CustomLanguageservice.getMethod("must be 10 digits", language);
	let mustbe5digits = CustomLanguageservice.getMethod("must be 5 digits", language);
	let Pleasecheckyourprofileforaccuracyandfillinanymissinginformation = CustomLanguageservice.getMethod("Please check your profile for accuracy and fill in any missing information", language);
	let Next = CustomLanguageservice.getMethod("Next", language);
	let Pleaseselectonestatefromthedropdownlist = CustomLanguageservice.getMethod("Please select one state from the drop-down list", language)
	let Requiredfields = CustomLanguageservice.getMethod("Required fields", language);

	useEffect(() => {
		setProgress((100 / 8) * 2);
	}, []);


	useEffect(() => {
		let dataarray = CommonService.getMethod();
		setStatearray(dataarray);
		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			ProfileInformationapiData = response.data;
			let number;

			if (ProfileInformationapiData.data.phone === "" || ProfileInformationapiData.data.phone === null) {
				number = ""
			}
			else {
				number = ProfileInformationapiData.data.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
			}
			setIsSameAddress(ProfileInformationapiData.data.is_same_address)
			setselectedState(ProfileInformationapiData.data.billing_state);
			setTimeout(() => {
				reset({
					Firstname: ProfileInformationapiData.data.first_name,
					Lastname: ProfileInformationapiData.data.last_name,
					Emailaddress: ProfileInformationapiData.data.email,
					Streetaddress: ProfileInformationapiData.data.billing_street,
					City: ProfileInformationapiData.data.billing_city,
					Zipcode: ProfileInformationapiData.data.billing_zip,
					PhoneNumber: number
				});
			}, 100);

			if (containemptydata === "true") {
				setTimeout(() => {
					document.getElementById('submitButton').click();
				}, 500);
			}

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})



	}, []);



	function profileinforsave(data) {
		localStorage.removeItem("containemptydata")
		const jsonData =
		{
			componentname: "Profile Information",
			name: data.Firstname + " " + data.Lastname,
			streetaddress: data.Streetaddress,
			city: data.City,
			state: data.state,
			zipcode: data.Zipcode,
			PhoneNumber: data.PhoneNumber,
			Emailaddress: data.Emailaddress,
			image: "/asset/tick.png",
			changed_firstname: changed_firstname,
			changed_lastname: changed_lastname,
			changed_phonenumber: changed_phonenumber,
			changed_email: changed_email,
			changed_billingAdress: changed_billingAdress,
			changed_city: changed_city,
			changed_state: changed_state,
			changed_zipcode: changed_zipcode

		};

		// api call
		setIsLoading(true);
		let endpoint = "/user/save_profile_info"
		let url = apiurl + endpoint;
		let apidata =
		{
			"user": {
				"first_name": data.Firstname,
				"last_name": data.Lastname,
				"email": data.Emailaddress,
				"phone": data.PhoneNumber.replace(/\D/g, '')
			},
			"is_same_address": isSameAddress,
			"is_mailing": 0,
			"billing": {
				"address": data.Streetaddress,
				"city": data.City,
				"state": data.state,
				"zipcode": data.Zipcode.toString()
			},
			"route_name": currentPath
		}

		Apiservice.putMethod(url, apidata).then(response => {
			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			setIsLoading(false);
			if (changed_firstname === true || changed_lastname === true || changed_phonenumber === true ||
				changed_email === true || changed_billingAdress === true || changed_city === true ||
				changed_state === true || changed_zipcode === true
			) {
				setIsModalOpen(true);
				setTimeout(() => {
					setIsModalOpen(false);
					Navigate('/AdressInformation');
				}, 3000);
			}
			else {
				Navigate('/AdressInformation');
			}

		})
			.catch(error => {
				setIsLoading(false);
			})


	}


	function back() {
		Navigate('/ProfileInformation');
	}


	const handleInputChange_Firstname = (event, field) => {
		let inputValue = event.target.value.replace(/\s+/g, '');
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
		field.onChange(capitalizedValue);
		setchanged_firstname(true);

	}

	const handleInputChange_Lastname = (event, field) => {
		let inputValue = event.target.value.replace(/\s+/g, '');
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
		field.onChange(capitalizedValue);
		setchanged_lastname(true)
	}

	const handleInputChange_StreetAdress = (event, field) => {
		let inputValue = event.target.value;
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
		// let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
		field.onChange(capitalizedValue);
		setchanged_billingAdress(true);
	}

	const handleInputChange_City = (event, field) => {
		let inputValue = event.target.value;
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
		field.onChange(capitalizedValue);
		setchanged_city(true);
	}

	const handleEmailChange = (event, field) => {
		let inputValue = event.target.value.replace(/\s+/g, '');
		let lowercasedValue = inputValue.toLowerCase();
		field.onChange(lowercasedValue);
		setchanged_email(true);
	}

	const handleChange_Phone = (e, field) => {
		// Remove all non-numeric characters from input
		const formattedPhoneNumber = e.target.value.replace(/\D/g, '');
		if (formattedPhoneNumber === '0000000000' || formattedPhoneNumber === '0000000000000') {
			return;
		}
		// Format the phone number as (###) ### ####
		const formattedPhoneNumberWithSpaces = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		// Update the input value
		field.onChange(formattedPhoneNumberWithSpaces);
		setchanged_phonenumber(true)
	};

	function handleChange_state(event) {
		const newValue = event.target.value;
		setselectedState(newValue)
		// this makes error msg go away on change data
		clearErrors('state');
		setchanged_state(true);

	}

	const handleInputChangeZipcode = (event, field) => {
		let inputValue = event.target.value.replace(/\D/g, '');
		if (inputValue === "00000") {
			setcontain5zero(true)
		}
		else {
			setcontain5zero(false)
		}
		field.onChange(inputValue);
		setchanged_zipcode(true);
	}


	return (
		<Box className={"EditProfilecontainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />

			<Grid container xs={12} className={"bodygrid_profileInformation"}>

				<SuccessPopup
					isOpen={isModalOpen}
					title="User Modal Title"
					description="This is the user modal description."
				/>


				<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }} >  <br></br>
					{containemptydata != "true" &&
						<img src="/asset/leftarrow.png" tabIndex="0" alt="leftarrow" onClick={() => back()} 
						onKeyDown={(e) => {
							if (e.key === "Enter") {
							back();
							}
							}}/>
					}
				</Grid>

				<Grid xs={11} >  <br></br>
					<div className={"Profileinfo"} tabIndex="0">
						{Profileinformation}
					</div>

				</Grid>

				<Grid xs={12} > <br></br>
					<LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
				</Grid>

				<form onSubmit={handleSubmit(profileinforsave)} ><br></br>
					<Grid container xs={12} className={"bodyformgrid"}>
						{containemptydata === "true" &&
							<Grid xs={12}>
								<div className="textlabel1" tabIndex="0">
									{Pleasecheckyourprofileforaccuracyandfillinanymissinginformation}
								</div>
							</Grid>
						}

						<Grid xs={12}>
							<div className="textlabel1" tabIndex="0">
								{Aboutyou}
							</div>
						</Grid>

						<Grid item xs={12} textAlign={"right"} sx={{ fontFamily: "var(--fontfamily)" }}>
							<InputLabel className={"memberInputlabel"}>* {Requiredfields}</InputLabel>
						</Grid>
						{/* <Grid container xs={12} spacing={3}> */}
							<Grid  xs={6} lg={6}>
								<Controller
									name="Firstname"
									control={control}
									rules={{ required: true, pattern: /^[A-Za-z.]+$/ }}
									render={({ field }) =>
										<div><br></br>
											<InputLabel htmlFor="my-input" className={"inputlabel"}>{Firstname}*</InputLabel>
											<div className={"textbox2"} style={{ background: "var(--defaultgrey)", borderRadius: "10px",fontSize:"var(--fontsizesmall)" }}>
												<TextField
													variant="outlined"
													placeholder={Firstname}
													value={field.value || ''}
													onChange={(e) => handleInputChange_Firstname(e, field)}
													InputProps={{
														className: 'customInput1',
														style: {
															border: errors?.Firstname?.type === "required" || errors?.Firstname?.type === "pattern" ? "1px solid red" : "transparent",fontSize:"var(--fontsizesmall)"
														}

													}}
												/>
											</div>
											{errors?.Firstname?.type === "required" &&
												<div className={"errormsg"} tabIndex="0">{Firstname} {cannotbeempty}</div>}

											{errors?.Firstname?.type === "pattern" &&
												<div className={"errormsg"} tabIndex="0">{Invalid} {Firstname}</div>}

										</div>




									}
								/>




							</Grid>

							<Grid  xs={6} lg={6} className="aligncontainer">
								<Controller
									name="Lastname"
									control={control}
									rules={{ required: true, pattern: /^[A-Za-z.]+$/ }}
									render={({ field }) =>
										<div ><br></br>
											<InputLabel htmlFor="my-input" className={"inputlabel"}>{Lastname}*</InputLabel>
											<div className={"textbox2"} style={{ background: "var(--defaultgrey)" ,borderRadius:"10px"}}>
												<TextField
													variant="outlined"
													placeholder={Lastname}
													value={field.value || ''}
													onChange={(e) => handleInputChange_Lastname(e, field)}

													InputProps={{
														className: 'customInput1',
														style: {
															border: errors?.Lastname?.type === "required" || errors?.Lastname?.type === "pattern" ? "1px solid red" : "transparent",fontSize:"var(--fontsizesmall)"
														}

													}}

												/>
											</div>
											{errors?.Lastname?.type === "required" &&
												<div className={"errormsg"} tabIndex="0">{Lastname} {cannotbeempty}</div>}

											{errors?.Lastname?.type === "pattern" &&
												<div className={"errormsg"} tabIndex="0">{Invalid} {Lastname}</div>}

										</div>




									}
								/>




							</Grid>
						{/* </Grid> */}


						<Grid xs={12} lg={6}>
							<Controller
								name="PhoneNumber"
								control={control}
								rules={{ required: true, pattern: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ }}
								render={({ field }) =>
									<div><br></br>
										<InputLabel htmlFor="my-input" className={"inputlabel"}>{PhoneNumber}*</InputLabel>
										<div className={"textbox1"}>
											<TextField
												variant="outlined"
												placeholder={PhoneNumber}
												value={field.value || ''}
												onChange={(e) => handleChange_Phone(e, field)}
												type="tel"
												inputProps={{
													maxLength: 10 
												  }}
												InputProps={{
													className: 'customInput',
													style: {
														border: errors?.PhoneNumber?.type === "required" || errors?.PhoneNumber?.type === "pattern" ? "1px solid red" : "transparent"
													}

												}}


											// {...register('PhoneNumber', { required: true, pattern: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ })}

											/>
										</div>
										{errors?.PhoneNumber?.type === "required" &&
											<div className={"errormsg"} tabIndex="0">{PhoneNumber} {cannotbeempty}</div>}

										{errors?.PhoneNumber?.type === "pattern" &&
											<div className={"errormsg"} tabIndex="0">{Invalid} {PhoneNumber}, {mustbe10digits}</div>}


									</div>




								}
							/>




						</Grid>

						<Grid xs={12} lg={6} className="aligncontainer">
							<Controller
								name="Emailaddress"
								control={control}
								rules={{ pattern: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ }}
								render={({ field }) =>
									<div><br></br>
										<InputLabel htmlFor="my-input" className={"inputlabel"}>{Emailaddress}</InputLabel>
										<div className={"textbox1"}>
											<TextField
												variant="outlined"
												placeholder={Emailaddress}
												value={field.value || ''}
												onChange={(e) => handleEmailChange(e, field)}
												InputProps={{
													className: 'customInput',
													style: {
														border: errors?.Emailaddress?.type === "required" || errors?.Emailaddress?.type === "pattern" ? "1px solid red" : "transparent",
													}

												}}

											/>
										</div>

										{errors?.Emailaddress?.type === "pattern" &&
											<div className={"errormsg"} tabIndex="0">{Invalid} {Emailaddress}</div>}

									</div>

								}
							/>

						</Grid>

						<Grid xs={12}>
							<div className="textlabel1" tabIndex="0"><br></br>
								{BillingAddress}
							</div>
						</Grid>

						<Grid xs={12}>
							<Controller
								name="Streetaddress"
								control={control}
								rules={{ required: true }}
								render={({ field }) =>
									<div><br></br>
										<InputLabel htmlFor="my-input" className={"inputlabel"}>{Streetaddress}*</InputLabel>
										<div className={"textbox1Streetaddress"}>
											<TextField
												variant="outlined"
												placeholder={Streetaddress}
												value={field.value || ''}
												onChange={(e) => handleInputChange_StreetAdress(e, field)}

												InputProps={{
													className: 'customInput',
													style: {
														border: errors?.Streetaddress?.type === "required" || errors?.Streetaddress?.type === "pattern" ? "1px solid red" : "transparent",
													}

												}}

											/>
										</div>
										{errors?.Streetaddress?.type === "required" &&
											<div className={"errormsg"} tabIndex="0">{Streetaddress} {cannotbeempty}</div>}

									</div>

								}
							/>

						</Grid>


						<Grid xs={12} lg={4}>
							<Controller
								name="City"
								control={control}
								rules={{ required: true, pattern: /^[A-Za-z\s]+$/ }}
								render={({ field }) =>
									<div><br></br>
										<InputLabel htmlFor="my-input" className={"inputlabel"}>{City}*</InputLabel>
										<div className={"textbox1city"}>
											<TextField
												variant="outlined"
												placeholder={City}
												value={field.value || ''}
												onChange={(e) => handleInputChange_City(e, field)}
												InputProps={{
													className: 'customInput',
													style: {
														border: errors?.City?.type === "required" || errors?.City?.type === "pattern" ? "1px solid red" : "transparent",
													}

												}}


											/>
										</div>
										{errors?.City?.type === "required" &&
											<div className={"errormsg"} tabIndex="0">{City} {cannotbeempty}</div>}

										{errors?.City?.type === "pattern" &&
											<div className={"errormsg"} tabIndex="0">{Invalid} {City}</div>}

									</div>

								}
							/>

						</Grid>

						<Grid xs={6} lg={4}><br></br>
							<div className={"inputlabel"}>
								{State}*
							</div>
							<FormControl>
								{((typeof selectedState === 'undefined' || selectedState === null || selectedState === "") && (
									<InputLabel id="demo-simple-select-label" translate="no" className={"inputlabelDrpdownselect"}>
										{State}
									</InputLabel>
								))}
								<Select
									{...register('state', { required: true })}
									className={"Drpdownselect"}
									id="demo-simple-select"
									// labelId="demo-simple-select-label"
									// label="State"
									value={selectedState} // Set the value prop to selectedState
									onChange={handleChange_state}
									translate="no"
								>
									{Statearray.map((data, index) => (
										<MenuItem value={data.code} key={index} translate="no">{data.code}</MenuItem>
									))}
								</Select>
								{errors.state && errors.state.type === "required" &&
									<div className={"errormsg"} tabIndex="0">{Pleaseselectonestatefromthedropdownlist}</div>}
							</FormControl>

						</Grid >

						<Grid xs={6} lg={4} className="aligncontainer" mb={1}>
							<Controller
								name="Zipcode"
								control={control}
								rules={{ required: true, pattern: /^(?!00000)\d{5}(?:[-\s]\d{4})?$/ }}
								render={({ field }) =>
									<div><br></br>
										<InputLabel htmlFor="my-input" className={"inputlabel"}>{Zipcode}*</InputLabel>
										<div className={"textboxzipcode"}>
											<TextField
												variant="outlined"
												placeholder={Zipcode}
												value={field.value || ''}
												onChange={(e) => handleInputChangeZipcode(e, field)}
												type="tel"
												inputProps={{
													maxLength: 5 
												  }}
												InputProps={{
													className: 'customInputzipcode',
													style: {
														border: errors?.Zipcode?.type === "required" || errors?.Zipcode?.type === "pattern" ? "1px solid red" : "transparent",
													}

												}}

											/>
										</div>
										{errors?.Zipcode?.type === "required" &&
											<div className={"errormsg"} tabIndex="0">{Zipcode} {cannotbeempty}</div>}

										{errors?.Zipcode?.type === "pattern" && contain5zero === true &&
											<div className={"errormsg"} tabIndex="0">{Invalid} {Zipcode}</div>}

										{errors?.Zipcode?.type === "pattern" && contain5zero === false &&
											<div className={"errormsg"} tabIndex="0">{Invalid} {Zipcode}, {mustbe5digits}</div>}


									</div>

								}
							/>

						</Grid>

					</Grid>

					<Grid xs={12}>
						<div>
							<Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"} id="submitButton"
							>{Next}</Button>
						</div>
					</Grid>

				</form>
			</Grid>
		</Box>
	)
}


export default EditProfileInformation
