import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectComponent({ dropdownData, selectedValue, onValueChange, idKey, valueKey}) {
	
    return (
        <Select
            fullWidth
            value={selectedValue}
            onChange={(event) => onValueChange(event)}
			sx={{fontSize:"var(--fontsizesmall) !important"}}
        >
			{dropdownData.map((data, index) => (
				<MenuItem key={index} value={data[idKey]} sx={{ height: "60px",fontSize:"var(--fontsizesmall)" }}>{data[valueKey]}</MenuItem>
			))}
        </Select>
    );
}

export default SelectComponent;