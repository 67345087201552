// ComboChart.js
import React, { useEffect, useRef, memo } from "react";
import Chart from "chart.js/auto";

const ComboChart = ({ labels, barData, barColors, lineData }) => {
	const chartRef5 = useRef(null);

	useEffect(() => {
		const ctx = chartRef5.current.getContext("2d");
		const myChart5 = new Chart(ctx, {
			type: "bar",
			data: {
				labels: labels,
				datasets: [
					{
						type: "bar",
						label: "Total Invites Sent",
						data: barData,
						backgroundColor: barColors,
						borderColor: "rgba(0, 0, 0, 0.1)",
						borderWidth: 1,
						// barThickness: 40,
						minBarLength: 8,
						maxBarThickness: 70,
						order: 2,
					},
					{
						type: "line",
						label: "User Activity",
						data: lineData,
						backgroundColor: "rgba(54, 162, 235, 0.2)",
						borderColor: "rgba(54, 162, 235, 1)",
						borderWidth: 2,
						fill: false,
						order: 1,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						beginAtZero: true,
						categoryPercentage: 0, // Set the category percentage
						barPercentage: -1,
					},
					y: {
						beginAtZero: true,
					},
				},
				plugins: {
					legend: {
						display: true, // Display legend
					},
				},
			},
		});

		return () => {
			myChart5.destroy();
		};
	}, [labels, barData, barColors, lineData]);

	return (
		<div
			style={{
				width: "100%",
				minHeight: "50vh",
				maxHeight: "60vh",
				overflowX: "auto",
			}}
		>
			<canvas
				ref={chartRef5}
				style={{
					minWidth: "100%",
					maxHeight: "100%",
					marginLeft: "15px",
				}}
			></canvas>
		</div>
	);
};

export default ComboChart;
