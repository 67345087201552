
import React from 'react';
import "./InformationPopup.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Unstable_Grid2';


function InformationPopup({ isOpen, text1, text2, text3, text4, text5, text6, text7, handleOKButton }) {

	function handleClickcancel(data) {
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOKButton(data);
	};


	return (
		<div >
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className={"InformationPopupcontainer"}>
					<Grid container xs={12} className={"CommonLoadermaingrid"}>
						<Grid xs={12} className={"GridIcon"}><br></br>
							<img src="/asset/Member/cancelicon.png" alt="cancelIcon" tabIndex="0" width="20px" height="20px" 
							onClick={handleClickcancel} 
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleClickcancel();
								}
								}} />
						</Grid>

						<Grid xs={12} className={"Gridtext"}>
							<div className={"textlabel1"} tabIndex="0">
								{text1}
							</div>

							<div className={"textlabel2"} tabIndex="0"> <br></br>
								{text2}
							</div>

							<div className={"textlabel2"} tabIndex="0"><br></br>
								{text3}
								<b> {text4} </b>
								{text5}
								<b> {text6}</b>
								{text7}
							</div>


						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	)

}

export default InformationPopup