
import React from "react";
import axios from 'axios';

const headers = {
  'Authorization': 'Bearer my-token',
  'My-Custom-Header': 'foobar',
  'Access-Control-Allow-Origin': '*',
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
  'Content-Security-Policy': 'self',
  'X-Frame-Options': 'SAMEORIGIN',
  'X-Content-Type-Options': 'nosniff',
  'Referrer-Policy': 'no-referrer, strict-origin-when-cross-origin',
  'Permissions-Policy': 'self',
  'Accept': "application/octet-stream",
};


const Apiservice= {
  getMethod: function(url) {
    return axios.get(url, { headers })
  },

  getMethodParams: function(url,params) {
    return axios.get(url, { params },{ headers })
  },

  postMethod: function(url,body) {
    return axios.post(url, body, { headers })
  },

  patchMethod: function(url,body) {
    return axios.patch(url, body, { headers })
  },

  putMethod: function(url,body) {
    return axios.put(url, body, { headers })
  },

  deleteMethod: function(url) {
    return axios.delete(url, { headers })
  }
};

export default Apiservice;
