import React from "react";



const CustomLanguageservice = {

	getMethod: function (data, selectedlanguage) {
		let jsondata;
		if (selectedlanguage === "English" || selectedlanguage === "1" || selectedlanguage === undefined || selectedlanguage === null) {
			jsondata = [
				{
					"text": "cannot be empty",
					"translatedtext": "cannot be empty",
				},
				{
					"text": "Invalid",
					"translatedtext": "Invalid",
				},
				{
					"text": "Incorrect",
					"translatedtext": "Incorrect",
				},
				{
					"text": "Please enter your Member ID and Date of Birth",
					"translatedtext": "Please enter your Member ID and Date of Birth",
				},
				{
					"text": "Code",
					"translatedtext": "Code",
				},
				{
                    "text": "Agent Log In",
                    "translatedtext": "Agent Log In",
                },
				{
                    "text": "Forgot My Password",
                    "translatedtext": "Forgot My Password",
                },
				{
                    "text": "Reset Password",
                    "translatedtext": "Reset Password",
                },
				{
					"text": "Member ID",
					"translatedtext": "Member ID",
				},
				{
                    "text": "Email Address",
                    "translatedtext": "Email Address",
                },
				{
					"text": "Date of Birth",
					"translatedtext": "Date of Birth",
				},
				{
                    "text": "Password",
                    "translatedtext": "Password",
                },
				{
                    "text": "New Password",
                    "translatedtext": "New Password",
                },
				{
                    "text": "Confirm Password",
                    "translatedtext": "Confirm Password",
                },
				{
                    "text": "Not Matching",
                    "translatedtext": "Not Matching",
                },
				{
					"text": "Proceed",
					"translatedtext": "Proceed",
				},
				{
                    "text": "Log In",
                    "translatedtext": "Log In",
                },
				{
					"text": "Let's get started",
					"translatedtext": "Let's get started",
				},
				{
					"text": "Profile information",
					"translatedtext": "Profile information",
				},
				{
					"text": "Let's make sure we have your correct contact information",
					"translatedtext": "Let's make sure we have your correct contact information",
				},
				{
					"text": "Is this information accurate",
					"translatedtext": "Is this information accurate",
				},
				{
					"text": "Address",
					"translatedtext": "Address",
				},
				{
					"text": "Phone",
					"translatedtext": "Phone",
				},
				{
					"text": "Email",
					"translatedtext": "Email",
				},
				{
					"text": "Everything is Correct",
					"translatedtext": "Everything is Correct",
				},
				{
					"text": "Edit Profile Information",
					"translatedtext": "Edit Profile Information",
				},
				{
					"text": "About you",
					"translatedtext": "About you",
				},
				{
					"text": "Phone Number",
					"translatedtext": "Phone Number",
				},
				{
					"text": "Email address",
					"translatedtext": "Email address",
				},
				{
					"text": "Billing Address",
					"translatedtext": "Billing Address",
				},
				{
					"text": "Street address",
					"translatedtext": "Street address",
				},
				{
					"text": "City",
					"translatedtext": "City",
				},
				{
					"text": "State",
					"translatedtext": "State",
				},
				{
					"text": "Zip code",
					"translatedtext": "Zip code",
				},
				{
					"text": "Next",
					"translatedtext": "Next",
				},
				{
					"text": "Thanks for Updating your Profile Information",
					"translatedtext": "Thanks for Updating your Profile Information",
				},
				{
					"text": "Address information",
					"translatedtext": "Address information",
				},
				{
					"text": "Is your mailing address the same as your billing address?",
					"translatedtext": "Is your mailing address the same as your billing address?",
				},
				{
					"text": "Yes, they are the same",
					"translatedtext": "Yes, they are the same",
				},
				{
					"text": "No, my mailing address is different",
					"translatedtext": "No, my mailing address is different",
				},
				{
					"text": "Please add your mailing address",
					"translatedtext": "Please add your mailing address",
				},
				{
					"text": "Mailing Address",
					"translatedtext": "Mailing Address",
				},
				{
					"text": "Mailing address added",
					"translatedtext": "Mailing address added",
				},
				{
					"text": "Mailing address is same as your billing address",
					"translatedtext": "Mailing address is same as your billing address",
				},
				{
					"text": "Text Message",
					"translatedtext": "Text Message",
				},
				{
					"text": "Communication Preference",
					"translatedtext": "Communication Preferences",
				},
				{
					"text": "Preferred Channel",
					"translatedtext": "Preferred Channel",
				},
				{
					"text": "Please let us know which method is best for reaching out to you.",
					"translatedtext": "Please let us know which method is best for reaching out to you.",
				},
				{
					"text": "Preferred Frequency",
					"translatedtext": "Preferred Frequency",
				},
				{
					"text": "Please let us know how frequently you'd like us to message you with.",
					"translatedtext": "Please let us know how frequently you'd like us to message you with.",
				},
				{
					"text": "Often",
					"translatedtext": "Often",
				},
				{
					"text": "Please send relevant news and information, along with surveys and challenges.",
					"translatedtext": "Please send relevant news and information, along with surveys and challenges.",
				},
				{
					"text": "Periodically",
					"translatedtext": "Periodically",
				},
				{
					"text": "Include me in monthly surveys and healthcare challenges.",
					"translatedtext": "Include me in monthly surveys and healthcare challenges.",
				},
				{
					"text": "Seldom",
					"translatedtext": "Seldom",
				},
				{
					"text": "Only include me in the most critical surveys and challenges.",
					"translatedtext": "Only include me in the most critical surveys and challenges.",
				},
				{
					"text": "Phone Type",
					"translatedtext": "Phone Type",
				},
				{
					"text": "What type of phone number is this?",
					"translatedtext": "What type of phone number is this?",
				},
				{
					"text": "This is a cell phone",
					"translatedtext": "This is a cell phone",
				},
				{
					"text": "This is a landline",
					"translatedtext": "This is a landline",
				},
				{
					"text": "If you have a mobile number, enter it here: (optional)",
					"translatedtext": "If you have a mobile number, enter it here: (optional)",
				},
				{
					"text": "Mobile Number",
					"translatedtext": "Mobile Number",
				},
				{
					"text": "must be 10 digits",
					"translatedtext": "must be 10 digits",
				},
				{
					"text": "Cell Phone",
					"translatedtext": "Cell Phone",
				},
				{
					"text": "Landline",
					"translatedtext": "Landline",
				},
				{
					"text": "must be 5 digits",
					"translatedtext": "must be 5 digits",
				},
				{
					"text": "Please check your profile for accuracy and fill in any missing information",
					"translatedtext": "Please check your profile for accuracy and fill in any missing information",
				},
				{
					"text": "Language Confirmation",
					"translatedtext": "Language Confirmation",
				},
				{
					"text": "Should we continue to message you in",
					"translatedtext": "Should we continue to message you in",
				},
				{
					"text": "Message me in the selected Language",
					"translatedtext": "Message me in the selected Language",
				},
				{
					"text": "Welcome Back",
					"translatedtext": "Welcome Back",
				},
				{
					"text": "Welcome",
					"translatedtext": "Welcome",
				},
				{
					"text": "Keep Going",
					"translatedtext": "Keep Going",
				},
				{
					"text": "Thanks,",
					"translatedtext": "Thanks,",
				},
				{
					"text": "Your personal information and preferences are stored on your",
					"translatedtext": "Your personal information and preferences are stored on your",
				},
				{
					"text": "account page.",
					"translatedtext": "account page.",
				},
				{
					"text": "You can visit that page at any time to make changes to your profile or preferences.",
					"translatedtext": "You can visit that page at any time to make changes to your profile or preferences.",
				},
				{
					"text": "Schedule appointment with Agent",
					"translatedtext": "Schedule appointment with Agent",
				},
				{
					"text": "Talk to an Agent",
					"translatedtext": "Talk to an Agent",
				},
				{
					"text": "Please select one state from the drop-down list",
					"translatedtext": "Please select one state from the drop-down list"
				},
				{
					"text": "We will get in touch with you regularly.",
					"translatedtext": "We will get in touch with you regularly."
				},
				{
					"text": "We will get in touch with you frequently each month.",
					"translatedtext": "We will get in touch with you frequently each month."
				},
				{
					"text": "We will get in touch with you occasionally.",
					"translatedtext": "We will get in touch with you occasionally."
				},
				{
					"text": "We will text at",
					"translatedtext": "We will text at"
				},
				{
					"text": "We will email at",
					"translatedtext": "We will email at"
				},
				{
					"text": "We will call at",
					"translatedtext": "We will call at"
				},
				{
					"text": "You can also visit that page to schedule an appointment or talk to an agent.",
					"translatedtext": "You can also visit that page to schedule an appointment or talk to an agent.",
				},
				{
					"text": "Oops! Something went wrong. Please log in again and try later.",
					"translatedtext": "Oops! Something went wrong. Please log in again and try later.",
				},
				{
					"text": "Ok",
					"translatedtext": "Ok",
				},
				{
					"text": "error",
					"translatedtext": "error",
				},
				{
					"text": "We will reach out to you by phone from time to time with question about your care.",
					"translatedtext": "We will reach out to you by phone from time to time with question about your care."
				},
				{
					"text": "Profile Settings",
					"translatedtext": "Profile Settings"
				},
				{
					"text": "First Name",
					"translatedtext": "First Name"
				},
				{
					"text": "Last Name",
					"translatedtext": "Last Name"
				},
				{
					"text": "Alternate Number",
					"translatedtext": "Alternate Number"
				},
				{
					"text": "Optional",
					"translatedtext": "Optional"
				},
				{
					"text": "Email Address",
					"translatedtext": "Email Address"
				},
				{
					"text": "Zip Code",
					"translatedtext": "Zip Code",
				},
				{
					"text": "Mailing Address",
					"translatedtext": "Mailing Address",
				},
				{
					"text": "Street Address",
					"translatedtext": "Street Address"
				},
				{
					"text": "Same as Billing Address",
					"translatedtext": "Same as Billing Address"
				},
				{
					"text": "Different from Billing Address",
					"translatedtext": "Different from Billing Address"
				},
				{
					"text": "Submit Changes",
					"translatedtext": "Submit Changes"
				},

				{
					"text": "Primary Care Physician",
					"translatedtext": "Primary Care Physician"
				},
				{
					"text": "Plan Name",
					"translatedtext": "Plan Name"
				},
				{
					"text": "Line of Business",
					"translatedtext": "Line of Business"
				},
				{
					"text": "Location",
					"translatedtext": "Location"
				},
				{
					"text": "Effective Date",
					"translatedtext": "Effective Date"
				},
				{
					"text": "Record ID",
					"translatedtext": "Record ID"
				},
				{
					"text": "Plan Information",
					"translatedtext": "Plan Information"
				},

				{
					"text": "My Doctor",
					"translatedtext": "My Doctor"
				},
				{
					"text": "Facility",
					"translatedtext": "Facility"
				},
				{
					"text": "Contact",
					"translatedtext": "Contact"
				},
				{
					"text": "Make an Appointment",
					"translatedtext": "Make an Appointment"
				},
				{
					"text": "Need help scheduling your appointment? Your Agent can help!",
					"translatedtext": "Need help scheduling your appointment? Your Agent can help!"
				},
				{
					"text": "Upcoming Appointment",
					"translatedtext": "Upcoming Appointment"
				},
				{
					"text": "Doctor",
					"translatedtext": "Doctor"
				},
				{
					"text": "Appointment Type",
					"translatedtext": "Appointment Type"
				},
				{
					"text": "Date",
					"translatedtext": "Date"
				},
				{
					"text": "Time",
					"translatedtext": "Time"
				},
				{
					"text": "Cancel or Reschedule",
					"translatedtext": "Cancel or Reschedule"
				},
				{
					"text": "Profile",
					"translatedtext": "Profile"
				},
				{
					"text": "My Agent",
					"translatedtext": "My Agent"
				},
				{
					"text": "Upcoming Appointment",
					"translatedtext": "Upcoming Appointment"
				},
				{
					"text": "Preferences",
					"translatedtext": "Preferences"
				},
				{
					"text": "Personal Information",
					"translatedtext": "Personal Information"
				},
				{
					"text": "Preferences Settings",
					"translatedtext": "Preferences Settings"
				},
				{
					"text": "We will reach out to you with information and tips as well as surveys and notifications",
					"translatedtext": "We will reach out to you with information and tips as well as surveys and notifications"
				},
				{
					"text": "Thanks For Updating Your Preferences",
					"translatedtext": "Thanks For Updating Your Preferences"
				},
				{
					"text": "We will reach out approximately once a month with an important notification or survey",
					"translatedtext": "We will reach out approximately once a month with an important notification or survey"
				},
				{
					"text": "We will only send the most critical notifications and questionnaires",
					"translatedtext": "We will only send the most critical notifications and questionnaires"
				},
				{
					"text": "Logout",
					"translatedtext": "Logout"
				},
				{
					"text": "Welcome To Your Account Site",
					"translatedtext": "Welcome To Your Account Site"
				},
				{
					"text": "What Do You Want To Do Today",
					"translatedtext": "What Do You Want To Do Today"
				},
				{
					"text": "View or Change My Profile Information",
					"translatedtext": "View or Change My Profile Information"
				},
				{
					"text": "View or Change My Preferences",
					"translatedtext": "View or Change My Preferences"
				},
				{
					"text": "Make or Change a Medical Appointment",
					"translatedtext": "Make or Change a Medical Appointment"
				},
				{
					"text": "Communicate With My Health Broker",
					"translatedtext": "Communicate With My Health Broker"
				},
				{
					"text": "See My Health Plan Info",
					"translatedtext": "See My Health Plan Info"
				},
				{
					"text": "Request a Callback",
					"translatedtext": "Request a Callback"
				},
				{
					"text": "Schedule a Callback",
					"translatedtext": "Schedule a Callback"
				},
				{
					"text": "Confirm",
					"translatedtext": "Confirm"
				},
				{
					"text": "Cancel",
					"translatedtext": "Cancel"
				},
				{
					"text": "Call Now",
					"translatedtext": "Call Now"
				},
				{
					"text": "Medical Advisor",
					"translatedtext": "Medical Advisor"
				},
				{
					"text": "Preferred Time",
					"translatedtext": "Preferred Time"
				},
				{
					"text": "Is there anything the agent needs to know?",
					"translatedtext": "Is there anything the agent needs to know?"
				},
				{
					"text": "Preferred Date",
					"translatedtext": "Preferred Date"
				},
				{
					"text": "You dont have any appointments",
					"translatedtext": "You don't have any appointments"
				},
				{
					"text": "You dont have any upcomming calls",
					"translatedtext": "You don't have any upcoming calls"
				},
				{
					"text": "Upcomming Calls",
					"translatedtext": "Upcoming Calls"
				},
				{
					"text": "Callback Request in Queue",
					"translatedtext": "There is already a callback request in your queue."
				},
				{
					"trxt": "Alert",
					"translatedtext": "Alert"
				},
				{
					"text": "Write your comments here",
					"translatedtext": "Write your comments here."
				},
				{
					"text": "Required fields",
					"translatedtext": "Required fields"
				},
				{
					"text": "Done",
					"translatedtext": "Done"
				},
				{
					"text": "Cell Phone added",
					"translatedtext": "Cell Phone added"
				},
				{
					"text": "We will reach out to you at",
					"translatedtext": "We will reach out to you at"
				},
				{
					"text": "with short surveys and notifications related to your healthcare.",
					"translatedtext": "with short surveys and notifications related to your healthcare."
				},
				{
					"text": "Hi",
					"translatedtext": "Hi"
				},
				{
					"text": "your agent",
					"translatedtext": "your agent"
				},
				{
					"text": "will call you at",
					"translatedtext": "will call you at"
				},
				{
					"text": "Member",
					"translatedtext": "Member"
				},
				{
					"text": "to cancel the call",
					"translatedtext": "to cancel the call"
				},
				{
					"text": "I Don't Know",
					"translatedtext": "I Don't Know"
				},
				{
					"text": "Welcome to the club",
					"translatedtext": "Welcome to the club"
				},
				{
					"text": "We built this platform to keep you in sync with your healthcare partners at plan and provider.",
					"translatedtext": "We built this platform to keep you in sync with your healthcare partners at plan and provider."
				},
				{
					"text": "This is YOUR channel for tracking your benefits, and it's OUR channel for tracking your healthcare journey",
					"translatedtext": "This is YOUR channel for tracking your benefits, and it's OUR channel for tracking your healthcare journey"
				},
				{
					"text": "Let's pick up where we left off",
					"translatedtext": "Let's pick up where we left off"
				},
				{
					"text": "Let's start by making sure we've got your contact information down right",
					"translatedtext": "Let's start by making sure we've got your contact information down right"
				},
				{
					"text": "Need to update your personal information or preferences? Want to schedule an appointment with",
					"translatedtext": "Need to update your personal information or preferences? Want to schedule an appointment with"
				},
				{
					"text": "or communicate with",
					"translatedtext": "or communicate with"
				},
				{
					"text": "Click here",
					"translatedtext": "Click here"
				},
				{
					"text": "tap here",
					"translatedtext": "tap here"
				},
				{
					"text": "your primary care physician",
					"translatedtext": "your primary care physician"
				},
				{
					"text": "Your primary care physician",
					"translatedtext": "Your primary care physician"
				},
				{
					"text": "your doctor",
					"translatedtext": "your doctor"
				},
				{
					"text": "Agent",
					"translatedtext": "Agent"
				},
				{
					"text": "the Payer",
					"translatedtext": "the Payer"
				},
				{
					"text": "the Provider",
					"translatedtext": "the Provider"
				},
				{
					"text": "the",
					"translatedtext": "the"
				},
				{
					"text": "Please specify",
					"translatedtext": "Please specify"
				},
				{
					"text": "the clinic or hospital",
					"translatedtext": "the clinic or hospital"
				},
				{
					"text": "your health-plan broker",
					"translatedtext": "your health-plan broker"
				},
				{
					"text": "your health plan",
					"translatedtext": "your health plan"
				},
				{
					"text": "Your health plan",
					"translatedtext": "Your health plan"
				},
				{
					"text": "agent",
					"translatedtext": "agent"
				},
				{
					"text": "The",
					"translatedtext": "The"
				},
				{
					"text": "The campaign has not started yet",
					"translatedtext": "The campaign has not started yet"
				},
				{
					"text": "You have already completed the survey",
					"translatedtext": "You have already completed the survey"
				},
				{
					"text": "The campaign has ended",
					"translatedtext": "The campaign has ended"
				},
			]
		}
		else if (selectedlanguage === "Spanish" || selectedlanguage === "2") {
			jsondata = [
				{
					"text": "cannot be empty",
					"translatedtext": "No puede estar vacía",
				},
				{
					"text": "Invalid",
					"translatedtext": "Inválida",
				},
				{
					"text": "Incorrect",
					"translatedtext": "incorrecta",
				},
				{
					"text": "Please enter your Member ID and Date of Birth",
					"translatedtext": "Por favor ingrese su ID de miembro y fecha de nacimiento",
				},
				{
					"text": "Member ID",
					"translatedtext": "Identificación de miembro",
				},
				{
					"text": "Date of Birth",
					"translatedtext": "Fecha de nacimiento",
				},
				{
					"text": "Proceed",
					"translatedtext": "Proceder",
				},
				{
					"text": "Let's get started",
					"translatedtext": "Empecemos",
				},
				{
					"text": "Profile information",
					"translatedtext": "Información del perfil",
				},
				{
					"text": "Let's make sure we have your correct contact information",
					"translatedtext": "Asegurémonos de tener su información de contacto correcta",
				},
				{
					"text": "Is this information accurate",
					"translatedtext": "¿Es esta información exacta",
				},
				{
					"text": "Address",
					"translatedtext": "DIRECCIÓN",
				},
				{
					"text": "Phone",
					"translatedtext": "Teléfono",
				},
				{
					"text": "Email",
					"translatedtext": "Correo electrónico",
				},
				{
					"text": "Everything is Correct",
					"translatedtext": "Todo es correcto",
				},
				{
					"text": "Edit Profile Information",
					"translatedtext": "Editar información de perfil",
				},
				{
					"text": "About you",
					"translatedtext": "Acerca de ti",
				},
				{
					"text": "Phone Number",
					"translatedtext": "Número de teléfono",
				},
				{
					"text": "Email address",
					"translatedtext": "Dirección de correo electrónico",
				},
				{
					"text": "Billing Address",
					"translatedtext": "Dirección de Envio",
				},
				{
					"text": "Street address",
					"translatedtext": "Dirección",
				},
				{
					"text": "City",
					"translatedtext": "Ciudad",
				},
				{
					"text": "State",
					"translatedtext": "Estado",
				},
				{
					"text": "Zip code",
					"translatedtext": "Código postal",
				},
				{
					"text": "Next",
					"translatedtext": "Próxima",
				},
				{
					"text": "Thanks for Updating your Profile Information",
					"translatedtext": "Gracias por actualizar la información de su perfil",
				},
				{
					"text": "Address information",
					"translatedtext": "Datos del Domicilio",
				},
				{
					"text": "Is your mailing address the same as your billing address?",
					"translatedtext": "¿Su dirección postal es la misma que su dirección de facturación?",
				},
				{
					"text": "Yes, they are the same",
					"translatedtext": "Si ellas son las mismas",
				},
				{
					"text": "No, my mailing address is different",
					"translatedtext": "No, mi dirección postal es diferente.",
				},
				{
					"text": "Please add your mailing address",
					"translatedtext": "Por favor agregue su dirección postal",
				},
				{
					"text": "Mailing Address",
					"translatedtext": "Dirección de envio",
				},
				{
					"text": "Mailing address added",
					"translatedtext": "Dirección postal agregada",
				},
				{
					"text": "Mailing address is same as your billing address",
					"translatedtext": "La dirección postal es la misma que su dirección de facturación",
				},
				{
					"text": "Text Message",
					"translatedtext": "Mensaje de texto",
				},
				{
					"text": "Communication Preference",
					"translatedtext": "Preferencia de comunicación",
				},
				{
					"text": "Preferred Channel",
					"translatedtext": "canal preferido",
				},
				{
					"text": "Please let us know which method is best for reaching out to you.",
					"translatedtext": "Háganos saber qué método es mejor para comunicarnos con usted.",
				},
				{
					"text": "Preferred Frequency",
					"translatedtext": "Frecuencia preferida",
				},
				{
					"text": "Please let us know how frequently you'd like us to message you with.",
					"translatedtext": "Háganos saber con qué frecuencia desea que le envíe mensajes.",
				},
				{
					"text": "Often",
					"translatedtext": "A menudo",
				},
				{
					"text": "Please send relevant news and information, along with surveys and challenges.",
					"translatedtext": "Envíe noticias e información relevantes, junto con encuestas y desafíos.",
				},
				{
					"text": "Periodically",
					"translatedtext": "Periódicamente",
				},
				{
					"text": "Include me in monthly surveys and healthcare challenges.",
					"translatedtext": "Inclúyeme en encuestas mensuales y desafíos de atención médica.",
				},
				{
					"text": "Seldom",
					"translatedtext": "Rara vez",
				},
				{
					"text": "Only include me in the most critical surveys and challenges.",
					"translatedtext": "Inclúyame solo en las encuestas y desafíos más críticos.",
				},
				{
					"text": "Thanks,",
					"translatedtext": "Gracias,",
				},
				{
					"text": "Your personal information and preferences are stored on your",
					"translatedtext": "Su información personal y sus preferencias se almacenan en la",
				},
				{
					"text": "account page.",
					"translatedtext": "página de su cuenta. ",
				},
				{
					"text": "You can visit that page at any time to make changes to your profile or preferences.",
					"translatedtext": "Puede visitar esa página en cualquier momento para realizar cambios en su perfil o preferencias.",
				},
				{
					"text": "Schedule appointment with Agent",
					"translatedtext": "Programar cita con el agente",
				},
				{
					"text": "Talk to an Agent",
					"translatedtext": "Hablar con un agente",
				},
				{
					"text": "Phone Type",
					"translatedtext": "tipo de telefono",
				},
				{
					"text": "What type of phone number is this?",
					"translatedtext": "¿Qué tipo de número de teléfono es este?",
				},
				{
					"text": "This is a cell phone",
					"translatedtext": "este es un celular",
				},
				{
					"text": "This is a landline",
					"translatedtext": "este es un telefono fijo",
				},
				{
					"text": "If you have a mobile number, enter it here: (optional)",
					"translatedtext": "Si tienes un número de móvil, ingrésalo aquí: (opcional)",
				},
				{
					"text": "Mobile Number",
					"translatedtext": "número de teléfono móvil",
				},
				{
					"text": "must be 10 digits",
					"translatedtext": "debe tener 10 dígitos",
				},
				{
					"text": "Cell Phone",
					"translatedtext": "Teléfono móvil",
				},
				{
					"text": "Landline",
					"translatedtext": "Teléfono fijo",
				},
				{
					"text": "must be 5 digits",
					"translatedtext": "debe tener 5 dígitos",
				},
				{
					"text": "Please check your profile for accuracy and fill in any missing information",
					"translatedtext": "Por favor verifique la precisión de su perfil y complete la información faltante",
				},
				{
					"text": "Language Confirmation",
					"translatedtext": "Confirmación de idioma",
				},
				{
					"text": "Should we continue to message you in",
					"translatedtext": "¿Deberíamos continuar enviándote mensajes en",
				},
				{
					"text": "Message me in the selected Language",
					"translatedtext": "Envíame un mensaje en el idioma seleccionado",
				},
				{
					"text": "Welcome Back",
					"translatedtext": "Bienvenido de nuevo",
				},
				{
					"text": "Welcome",
					"translatedtext": "Bienvenido",
				},
				{
					"text": "Keep Going",
					"translatedtext": "sigue adelante",
				},
				{
					"text": "Please select one state from the drop-down list",
					"translatedtext": "Seleccione un estado de la lista desplegable"
				},
				{
					"text": "We will get in touch with you regularly.",
					"translatedtext": "Nos pondremos en contacto con usted periódicamente."
				},
				{
					"text": "We will get in touch with you frequently each month.",
					"translatedtext": "Nos comunicaremos con usted frecuentemente cada mes."
				},
				{
					"text": "We will get in touch with you occasionally.",
					"translatedtext": "Nos pondremos en contacto con usted ocasionalmente."
				},
				{
					"text": "We will text at",
					"translatedtext": "Nosotras enviaremos un mensaje de texto a"
				},
				{
					"text": "We will email at",
					"translatedtext": "Le enviaremos un correo electrónico a"
				},
				{
					"text": "We will call at",
					"translatedtext": "Nosotras llamaremos a"
				},
				{
					"text": "You can also visit that page to schedule an appointment or talk to an agent.",
					"translatedtext": "También puede visitar esa página para programar una cita o hablar con un agente.",
				},
				{
					"text": "Oops! Something went wrong. Please log in again and try later.",
					"translatedtext": "¡Ups! Algo salió mal. Inicie sesión nuevamente e inténtelo más tarde.",
				},
				{
					"text": "Ok",
					"translatedtext": "OK",
				},
				{
					"text": "error",
					"translatedtext": "error",
				},
				{
					"text": "We will reach out to you by phone from time to time with question about your care.",
					"translatedtext": "Nos comunicaremos con usted por teléfono de vez en cuando si tiene preguntas sobre su atención."
				},
				{
					"text": "Profile Settings",
					"translatedtext": "Configuración de perfil"
				},
				{
					"text": "First Name",
					"translatedtext": "Nombre de pila"
				},
				{
					"text": "Last Name",
					"translatedtext": "Apellido"
				},
				{
					"text": "Alternate Number",
					"translatedtext": "Número Alternativo"
				},
				{
					"text": "Optional",
					"translatedtext": "Opcional"
				},
				{
					"text": "Email Address",
					"translatedtext": "Dirección de correo electrónico"
				},
				{
					"text": "Zip Code",
					"translatedtext": "Código postal",
				},
				{
					"text": "Mailing Address",
					"translatedtext": "Dirección de envio",
				},
				{
					"text": "Street Address",
					"translatedtext": "Dirección"
				},
				{
					"text": "Same as Billing Address",
					"translatedtext": "Igual que la dirección de facturación"
				},
				{
					"text": "Different from Billing Address",
					"translatedtext": "Diferente de la dirección de facturación"
				},
				{
					"text": "Submit Changes",
					"translatedtext": "Presentar cambios"
				},

				{
					"text": "Primary Care Physician",
					"translatedtext": "Medica de atencion primaria"
				},
				{
					"text": "Plan Name",
					"translatedtext": "Nombre del plan"
				},
				{
					"text": "Line of Business",
					"translatedtext": "Línea de negocio"
				},
				{
					"text": "Location",
					"translatedtext": "Ubicación"
				},
				{
					"text": "Effective Date",
					"translatedtext": "Fecha efectiva"
				},
				{
					"text": "Record ID",
					"translatedtext": "ID de registro"
				},
				{
					"text": "Plan Information",
					"translatedtext": "Información del plan"
				},

				{
					"text": "My Doctor",
					"translatedtext": "Mi doctora"
				},
				{
					"text": "Facility",
					"translatedtext": "Instalación"
				},
				{
					"text": "Contact",
					"translatedtext": "Contacto"
				},
				{
					"text": "Make an Appointment",
					"translatedtext": "Haga una cita"
				},
				{
					"text": "Need help scheduling your appointment? Your Agent can help!",
					"translatedtext": "¿Necesita ayuda para programar su cita? ¡Tu agente puede ayudarte!"
				},
				{
					"text": "Upcoming Appointment",
					"translatedtext": "Próxima cita"
				},
				{
					"text": "Doctor",
					"translatedtext": "Doctora"
				},
				{
					"text": "Appointment Type",
					"translatedtext": "Tipo de cita"
				},
				{
					"text": "Date",
					"translatedtext": "Fecha"
				},
				{
					"text": "Time",
					"translatedtext": "Tiempo"
				},
				{
					"text": "Cancel or Reschedule",
					"translatedtext": "Cancelar o Reprogramar"
				},
				{
					"text": "Profile",
					"translatedtext": "Perfil"
				},
				{
					"text": "My Agent",
					"translatedtext": "Mi agente"
				},
				{
					"text": "Upcoming Appointment",
					"translatedtext": "Próxima cita"
				},
				{
					"text": "Preferences",
					"translatedtext": "Preferencias"
				},
				{
					"text": "Personal Information",
					"translatedtext": "Informacion personal"
				},
				{
					"text": "Preferences Settings",
					"translatedtext": "Configuración de preferencias"
				},
				{
					"text": "We will reach out to you with information and tips as well as surveys and notifications",
					"translatedtext": "Nos comunicaremos con usted con información y consejos, así como con encuestas y notificaciones"
				},
				{
					"text": "Thanks For Updating Your Preferences",
					"translatedtext": "Gracias por actualizar sus preferencias"
				},
				{
					"text": "We will reach out approximately once a month with an important notification or survey",
					"translatedtext": "Nos comunicaremos aproximadamente una vez al mes con una notificación o encuesta importante"
				},
				{
					"text": "We will only send the most critical notifications and questionnaires",
					"translatedtext": "Solo enviaremos las notificaciones y cuestionarios más críticos"
				},
				{
					"text": "Logout",
					"translatedtext": "Cerrar sesión"
				},
				{
					"text": "Welcome To Your Account Site",
					"translatedtext": "Bienvenido al sitio de su cuenta"
				},
				{
					"text": "What Do You Want To Do Today",
					"translatedtext": "Qué quieres hacer hoy"
				},
				{
					"text": "View or Change My Profile Information",
					"translatedtext": "Ver o cambiar la información de mi perfil"
				},
				{
					"text": "View or Change My Preferences",
					"translatedtext": "Ver o cambiar mis preferencias"
				},
				{
					"text": "Make or Change a Medical Appointment",
					"translatedtext": "Hacer o cambiar una cita médica"
				},
				{
					"text": "Communicate With My Health Broker",
					"translatedtext": "Comunicarse con mi corredora de salud"
				},
				{
					"text": "See My Health Plan Info",
					"translatedtext": "Ver información de mi plan de salud"
				},
				{
					"text": "Request a Callback",
					"translatedtext": "Solicitar una devolución de llamada"
				},
				{
					"text": "Schedule a Callback",
					"translatedtext": "Programar una devolución de llamada"
				},
				{
					"text": "Confirm",
					"translatedtext": "Confirmar"
				},
				{
					"text": "Cancel",
					"translatedtext": "Cancelar"
				},
				{
					"text": "Call Now",
					"translatedtext": "Llama ahora"
				},
				{
					"text": "Medical Advisor",
					"translatedtext": "Asesora medica"
				},
				{
					"text": "Preferred Time",
					"translatedtext": "Hora preferida"
				},
				{
					"text": "Preferred Date",
					"translatedtext": "Fecha preferida"
				},
				{
					"text": "Is there anything the agent needs to know?",
					"translatedtext": "¿Hay algo que la agente necesite saber?"
				},
				{
					"text": "You dont have any appointments",
					"translatedtext": "No tienes citas"
				},
				{
					"text": "You dont have any upcomming calls",
					"translatedtext": "No tienes ninguna llamada entrante"
				},
				{
					"text": "Upcomming Calls",
					"translatedtext": "Próximas llamadas"
				},
				{
					"text": "Callback Request in Queue",
					"translatedtext": "Ya hay una solicitud de devolución de llamada en su cola."
				},
				{
					"trxt": "Alert",
					"translatedtext": "Alerta"
				},
				{
					"text": "Write your comments here",
					"translatedtext": "Escribe tus comentarios aquí"
				},
				{
					"text": "Required fields",
					"translatedtext": "Campos requeridos"
				},
				{
					"text": "Done",
					"translatedtext": "Hecho"
				},
				{
					"text": "Cell Phone added",
					"translatedtext": "Teléfono celular agregado"
				},
				{
					"text": "We will reach out to you at",
					"translatedtext": "Nos comunicaremos con usted en"
				},
				{
					"text": "with short surveys and notifications related to your healthcare.",
					"translatedtext": "con encuestas breves y notificaciones relacionadas con su atención médica."
				},
				{
					"text": "Hi",
					"translatedtext": "Hola"
				},
				{
					"text": "your agent",
					"translatedtext": "tu agente"
				},
				{
					"text": "will call you at",
					"translatedtext": "te llamaré al"
				},
				{
					"text": "Member",
					"translatedtext": "Miembro"
				},
				{
					"text": "to cancel the call",
					"translatedtext": "para cancelar la llamada"
				},
				{
					"text": "I Don't Know",
					"translatedtext": "No sé"
				},
				{
					"text": "Welcome to the club",
					"translatedtext": "Bienvenida al club"
				},
				{
					"text": "We built this platform to keep you in sync with your healthcare partners at plan and provider.",
					"translatedtext": "Creamos esta plataforma para mantenerlo sincronizado con sus socios de atención médica en el plan y el proveedor."
				},
				{
					"text": "This is YOUR channel for tracking your benefits, and it's OUR channel for tracking your healthcare journey",
					"translatedtext": "Este es SU canal para realizar un seguimiento de sus beneficios y es NUESTRO canal para realizar un seguimiento de su recorrido por la atención médica"
				},
				{
					"text": "Let's pick up where we left off",
					"translatedtext": "Vamos a continuar donde lo dejamos"
				},
				{
					"text": "Let's start by making sure we've got your contact information down right",
					"translatedtext": "Comencemos asegurándonos de tener su información de contacto correctamente"
				},
				{
					"text": "Need to update your personal information or preferences? Want to schedule an appointment with",
					"translatedtext": "¿Necesita actualizar su información personal o preferencias? Quiere programar una cita con"
				},
				{
					"text": "or communicate with",
					"translatedtext": "o comunicarse con"
				},
				{
					"text": "Click here",
					"translatedtext": "Bấm vào đây"
				},
				{
					"text": "tap here",
					"translatedtext": "pulse aquí"
				},
				{
					"text": "your primary care physician",
					"translatedtext": "Tu medica de atencion primaria"
				},
				{
					"text": "Your primary care physician",
					"translatedtext": "Tu medica de atencion primaria"
				},
				{
					"text": "your doctor",
					"translatedtext": "Tu doctora"
				},
				{
					"text": "Agent",
					"translatedtext": "Agente"
				},
				{
					"text": "the Payer",
					"translatedtext": "La pagadora"
				},
				{
					"text": "the Provider",
					"translatedtext": "La proveedora"
				},
				{
					"text": "the",
					"translatedtext": "La"
				},
				{
					"text": "Please specify",
					"translatedtext": "Por favor especifica"
				},
				{
					"text": "the clinic or hospital",
					"translatedtext": "la clínica u hospital"
				},
				{
					"text": "your health-plan broker",
					"translatedtext": "su agente de planes de salud"
				},
				{
					"text": "your health plan",
					"translatedtext": "tu plan de salud"
				},
				{
					"text": "Your health plan",
					"translatedtext": "Tu plan de salud"
				},
				{
					"text": "agent",
					"translatedtext": "agente"
				},
				{
					"text": "The",
					"translatedtext": "La"
				},
				{
					"text": "The campaign has not started yet",
					"translatedtext": "La campaña aún no ha comenzado"
				},
				{
					"text": "You have already completed the survey",
					"translatedtext": "Ya has completado la encuesta"
				},
				{
					"text": "The campaign has ended",
					"translatedtext": "La campaña ha terminado"
				},

			]
		}

		else if (selectedlanguage === "Vietnamese" || selectedlanguage === "3") {
			jsondata = [
				{
					"text": "cannot be empty",
					"translatedtext": "không thể trống",
				},
				{
					"text": "Invalid",
					"translatedtext": "không hợp lệ",
				},
				{
					"text": "Incorrect",
					"translatedtext": "không đúng",
				},
				{
					"text": "Please enter your Member ID and Date of Birth",
					"translatedtext": "Vui lòng nhập ID thành viên và ngày sinh của bạn",
				},
				{
					"text": "Member ID",
					"translatedtext": "Mã thành viên",
				},
				{
					"text": "Date of Birth",
					"translatedtext": "Ngày sinh",
				},
				{
					"text": "Proceed",
					"translatedtext": "Tiếp tục",
				},
				{
					"text": "Let's get started",
					"translatedtext": "Bắt đầu nào",
				},
				{
					"text": "Profile information",
					"translatedtext": "Thông tin cá nhân",
				},
				{
					"text": "Let's make sure we have your correct contact information",
					"translatedtext": "Hãy đảm bảo chúng tôi có thông tin liên hệ chính xác của bạn",
				},
				{
					"text": "Is this information accurate",
					"translatedtext": "Thông tin này có chính xác không",
				},
				{
					"text": "Address",
					"translatedtext": "Địa chỉ",
				},
				{
					"text": "Phone",
					"translatedtext": "Điện thoại",
				},
				{
					"text": "Email",
					"translatedtext": "E-mail",
				},
				{
					"text": "Everything is Correct",
					"translatedtext": "Mọi thứ đều chính xác",
				},
				{
					"text": "Edit Profile Information",
					"translatedtext": "Chỉnh sửa thông tin hồ sơ",
				},
				{
					"text": "About you",
					"translatedtext": "Về bạn",
				},
				{
					"text": "Phone Number",
					"translatedtext": "Số điện thoại",
				},
				{
					"text": "Email address",
					"translatedtext": "Địa chỉ email",
				},
				{
					"text": "Billing Address",
					"translatedtext": "Địa chỉ thanh toán",
				},
				{
					"text": "Street address",
					"translatedtext": "Địa chỉ đường phố",
				},
				{
					"text": "City",
					"translatedtext": "Thành phố",
				},
				{
					"text": "State",
					"translatedtext": "Tình trạng",
				},
				{
					"text": "Zip code",
					"translatedtext": "Mã Bưu Chính",
				},
				{
					"text": "Next",
					"translatedtext": "Kế tiếp",
				},
				{
					"text": "Thanks for Updating your Profile Information",
					"translatedtext": "Cảm ơn bạn đã cập nhật thông tin hồ sơ của bạn",
				},
				{
					"text": "Address information",
					"translatedtext": "Thông tin địa chỉ",
				},
				{
					"text": "Is your mailing address the same as your billing address?",
					"translatedtext": "Địa chỉ gửi thư của bạn có giống với địa chỉ thanh toán của bạn không?",
				},
				{
					"text": "Yes, they are the same",
					"translatedtext": "Vâng, Chúng giống nhau",
				},
				{
					"text": "No, my mailing address is different",
					"translatedtext": "Không, địa chỉ gửi thư của tôi khác",
				},
				{
					"text": "Please add your mailing address",
					"translatedtext": "Vui lòng thêm địa chỉ gửi thư của bạn",
				},
				{
					"text": "Mailing Address",
					"translatedtext": "Địa chỉ gửi thư",
				},
				{
					"text": "Mailing address added",
					"translatedtext": "Đã thêm địa chỉ gửi thư",
				},
				{
					"text": "Mailing address is same as your billing address",
					"translatedtext": "Địa chỉ gửi thư giống với địa chỉ thanh toán của bạn",
				},
				{
					"text": "Text Message",
					"translatedtext": "Tin nhắn văn bản",
				},
				{
					"text": "Communication Preference",
					"translatedtext": "Tùy chọn giao tiếp",
				},
				{
					"text": "Preferred Channel",
					"translatedtext": "kênh ưa thích",
				},
				{
					"text": "Please let us know which method is best for reaching out to you.",
					"translatedtext": "Vui lòng cho chúng tôi biết phương pháp nào là tốt nhất để liên hệ với bạn.",
				},
				{
					"text": "Preferred Frequency",
					"translatedtext": "Tần suất ưa thích",
				},
				{
					"text": "Please let us know how frequently you'd like us to message you with.",
					"translatedtext": "Vui lòng cho chúng tôi biết tần suất bạn muốn tôi nhắn tin cho bạn.",
				},
				{
					"text": "Often",
					"translatedtext": "Thường",
				},
				{
					"text": "Please send relevant news and information, along with surveys and challenges.",
					"translatedtext": "Vui lòng gửi tin tức và thông tin có liên quan, cùng với các cuộc khảo sát và thách thức.",
				},
				{
					"text": "Periodically",
					"translatedtext": "định kỳ",
				},
				{
					"text": "Include me in monthly surveys and healthcare challenges.",
					"translatedtext": "Đưa tôi vào các cuộc khảo sát hàng tháng và các thách thức về chăm sóc sức khỏe.",
				},
				{
					"text": "Seldom",
					"translatedtext": "Hiếm khi",
				},
				{
					"text": "Only include me in the most critical surveys and challenges.",
					"translatedtext": "Chỉ đưa tôi vào những cuộc khảo sát và thử thách quan trọng nhất.",
				},
				{
					"text": "Phone Type",
					"translatedtext": "loại điện thoại",
				},
				{
					"text": "What type of phone number is this?",
					"translatedtext": "Đây là loại số điện thoại gì?",
				},
				{
					"text": "This is a cell phone",
					"translatedtext": "Đây là một chiếc điện thoại di động",
				},
				{
					"text": "This is a landline",
					"translatedtext": "Đây là điện thoại cố định",
				},
				{
					"text": "If you have a mobile number, enter it here: (optional)",
					"translatedtext": "Nếu bạn có số điện thoại di động, hãy nhập số đó vào đây: (tùy chọn)",
				},
				{
					"text": "Mobile Number",
					"translatedtext": "Số điện thoại",
				},
				{
					"text": "must be 10 digits",
					"translatedtext": "phải có 10 chữ số",
				},
				{
					"text": "Landline",
					"translatedtext": "Điện thoại cố định",
				},
				{
					"text": "must be 5 digits",
					"translatedtext": "phải có 5 chữ số",
				},
				{
					"text": "Please check your profile for accuracy and fill in any missing information",
					"translatedtext": "Vui lòng kiểm tra hồ sơ của bạn để đảm bảo tính chính xác và điền mọi thông tin còn thiếu",
				},
				{
					"text": "Language Confirmation",
					"translatedtext": "Xác nhận ngôn ngữ",
				},
				{
					"text": "Should we continue to message you in",
					"translatedtext": "Chúng tôi có nên tiếp tục nhắn tin cho bạn trong",
				},
				{
					"text": "Message me in the selected Language",
					"translatedtext": "Nhắn tin cho tôi bằng ngôn ngữ đã chọn",
				},
				{
					"text": "Welcome Back",
					"translatedtext": "chào mừng trở lại",
				},
				{
					"text": "Welcome",
					"translatedtext": "Chào mừng",
				},
				{
					"text": "Keep Going",
					"translatedtext": "tiếp tục đi",
				},
				{
					"text": "Thanks,",
					"translatedtext": "Cảm ơn,",
				},
				{
					"text": "Your personal information and preferences are stored on your",
					"translatedtext": "Thông tin cá nhân và sở thích của bạn được lưu trữ trên",
				},
				{
					"text": "account page.",
					"translatedtext": "trang tài khoản.",
				},
				{
					"text": "You can visit that page at any time to make changes to your profile or preferences.",
					"translatedtext": "Bạn có thể truy cập trang đó bất kỳ lúc nào để thực hiện các thay đổi đối với hồ sơ hoặc tùy chọn của mình.",
				},
				{
					"text": "Schedule appointment with Agent",
					"translatedtext": "Đặt lịch hẹn với Đại lý",
				},
				{
					"text": "Talk to an Agent",
					"translatedtext": "Nói chuyện với một đại lý",
				},
				{
					"text": "Please select one state from the drop-down list",
					"translatedtext": "Vui lòng chọn một trạng thái từ danh sách thả xuống"
				},
				{
					"text": "Cell Phone",
					"translatedtext": "Điện thoại di động"
				},
				{
					"text": "We will get in touch with you regularly.",
					"translatedtext": "Chúng tôi sẽ liên lạc với bạn thường xuyên."
				},
				{
					"text": "We will get in touch with you frequently each month.",
					"translatedtext": "Chúng tôi sẽ liên lạc với bạn thường xuyên mỗi tháng."
				},
				{
					"text": "We will get in touch with you occasionally.",
					"translatedtext": "Thỉnh thoảng chúng tôi sẽ liên lạc với bạn."
				},
				{
					"text": "We will text at",
					"translatedtext": "Chúng tôi sẽ nhắn tin vào lúc"
				},
				{
					"text": "We will email at",
					"translatedtext": "Chúng tôi sẽ gửi email tại"
				},
				{
					"text": "We will call at",
					"translatedtext": "Chúng tôi sẽ gọi vào lúc"
				},
				{
					"text": "You can also visit that page to schedule an appointment or talk to an agent.",
					"translatedtext": "Bạn cũng có thể truy cập trang đó để đặt lịch hẹn hoặc nói chuyện với đại lý.",
				},
				{
					"text": "Oops! Something went wrong. Please log in again and try later.",
					"translatedtext": "Ối! Đã xảy ra lỗi. Vui lòng đăng nhập lại và thử lại sau.",
				},
				{
					"text": "Ok",
					"translatedtext": "Được rồi",
				},
				{
					"text": "error",
					"translatedtext": "lỗi",
				},
				{
					"text": "We will reach out to you by phone from time to time with question about your care.",
					"translatedtext": "Thỉnh thoảng chúng tôi sẽ liên hệ với bạn qua điện thoại nếu có thắc mắc về việc chăm sóc của bạn."
				},
				{
					"text": "My Doctor",
					"translatedtext": "Bác sĩ của tôi"
				},
				{
					"text": "Profile Settings",
					"translatedtext": "Cài đặt cấu hình"
				},
				{
					"text": "First Name",
					"translatedtext": "Tên đầu tiên"
				},
				{
					"text": "Last Name",
					"translatedtext": "Họ"
				},
				{
					"text": "Alternate Number",
					"translatedtext": "Con số khác"
				},
				{
					"text": "Optional",
					"translatedtext": "Không bắt buộc"
				},
				{
					"text": "Email Address",
					"translatedtext": "Địa chỉ email"
				},
				{
					"text": "Zip Code",
					"translatedtext": "Mã Bưu Chính",
				},
				{
					"text": "Mailing Address",
					"translatedtext": "Địa chỉ gửi thư",
				},
				{
					"text": "Street Address",
					"translatedtext": "Địa chỉ đường phố"
				},
				{
					"text": "Same as Billing Address",
					"translatedtext": "Tương tự như Địa chỉ thanh toán"
				},
				{
					"text": "Different from Billing Address",
					"translatedtext": "Khác với địa chỉ thanh toán"
				},
				{
					"text": "Submit Changes",
					"translatedtext": "Gửi thay đổi"
				},

				{
					"text": "Primary Care Physician",
					"translatedtext": "Bác sĩ chăm sóc chính"
				},
				{
					"text": "Plan Name",
					"translatedtext": "Tên kế hoạch"
				},
				{
					"text": "Line of Business",
					"translatedtext": "Ngành kinh doanh"
				},
				{
					"text": "Location",
					"translatedtext": "Vị trí"
				},
				{
					"text": "Effective Date",
					"translatedtext": "Ngày có hiệu lực"
				},
				{
					"text": "Record ID",
					"translatedtext": "ID hồ sơ"
				},
				{
					"text": "Plan Information",
					"translatedtext": "Thông tin kế hoạch"
				},

				{
					"text": "Facility",
					"translatedtext": "Cơ sở"
				},
				{
					"text": "Contact",
					"translatedtext": "Liên hệ"
				},
				{
					"text": "Make an Appointment",
					"translatedtext": "Đặt lịch hẹn"
				},
				{
					"text": "Need help scheduling your appointment? Your Agent can help!",
					"translatedtext": "Cần trợ giúp sắp xếp cuộc hẹn của bạn? Đại lý của bạn có thể giúp đỡ!"
				},
				{
					"text": "Upcoming Appointment",
					"translatedtext": "Cuộc hẹn sắp tới"
				},
				{
					"text": "Doctor",
					"translatedtext": "Bác sĩ"
				},
				{
					"text": "Appointment Type",
					"translatedtext": "Loại cuộc hẹn"
				},
				{
					"text": "Date",
					"translatedtext": "Ngày"
				},
				{
					"text": "Time",
					"translatedtext": "Thời gian"
				},
				{
					"text": "Cancel or Reschedule",
					"translatedtext": "Hủy bỏ hoặc lên lịch lại"
				},
				{
					"text": "Profile",
					"translatedtext": "Hồ sơ"
				},
				{
					"text": "My Agent",
					"translatedtext": "Đặc vụ của tôi"
				},
				{
					"text": "Upcoming Appointment",
					"translatedtext": "Cuộc hẹn sắp tới"
				},
				{
					"text": "Preferences",
					"translatedtext": "Sở thích"
				},
				{
					"text": "Personal Information",
					"translatedtext": "Thông tin cá nhân"
				},
				{
					"text": "Preferences Settings",
					"translatedtext": "Cài đặt tùy chọn"
				},
				{
					"text": "We will reach out to you with information and tips as well as surveys and notifications",
					"translatedtext": "chúng tôi sẽ liên hệ với bạn để cung cấp thông tin và mẹo cũng như khảo sát và thông báo"
				},
				{
					"text": "Thanks For Updating Your Preferences",
					"translatedtext": "Cảm ơn vì đã cập nhật tùy chọn của bạn"
				},
				{
					"text": "We will reach out approximately once a month with an important notification or survey",
					"translatedtext": "Chúng tôi sẽ liên hệ khoảng mỗi tháng một lần khi có thông báo hoặc khảo sát quan trọng"
				},
				{
					"text": "We will only send the most critical notifications and questionnaires",
					"translatedtext": "Chúng tôi sẽ chỉ gửi những thông báo và bảng câu hỏi quan trọng nhất"
				},
				{
					"text": "Logout",
					"translatedtext": "Đăng xuất"
				},
				{
					"text": "Welcome To Your Account Site",
					"translatedtext": "Chào mừng đến với trang tài khoản của bạn"
				},
				{
					"text": "What Do You Want To Do Today",
					"translatedtext": "Bạn muốn làm gì hôm nay"
				},
				{
					"text": "View or Change My Profile Information",
					"translatedtext": "Xem hoặc thay đổi thông tin hồ sơ của tôi"
				},
				{
					"text": "View or Change My Preferences",
					"translatedtext": "Xem hoặc Thay đổi Tùy chọn của tôi"
				},
				{
					"text": "Make or Change a Medical Appointment",
					"translatedtext": "Đặt hoặc Thay đổi Cuộc hẹn Y tế"
				},
				{
					"text": "Communicate With My Health Broker",
					"translatedtext": "Giao tiếp với nhà môi giới sức khỏe của tôi"
				},
				{
					"text": "See My Health Plan Info",
					"translatedtext": "Xem Thông tin về Chương trình Sức khỏe của Tôi"
				},
				{
					"text": "Request a Callback",
					"translatedtext": "Yêu cầu gọi lại"
				},
				{
					"text": "Schedule a Callback",
					"translatedtext": "Lên lịch gọi lại"
				},
				{
					"text": "Confirm",
					"translatedtext": "Xác nhận"
				},
				{
					"text": "Cancel",
					"translatedtext": "Hủy bỏ"
				},
				{
					"text": "Call Now",
					"translatedtext": "Gọi ngay bây giờ"
				},
				{
					"text": "Medical Advisor",
					"translatedtext": "Cố vấn y khoa"
				},
				{
					"text": "Preferred Time",
					"translatedtext": "Thời gian ưa thích"
				},
				{
					"text": "Preferred Date",
					"translatedtext": "Ngày ưa thích"
				},
				{
					"text": "Is there anything the agent needs to know?",
					"translatedtext": "Có điều gì mà người đại diện cần biết không?"
				},
				{
					"text": "You dont have any appointments",
					"translatedtext": "Bạn không có cuộc hẹn nào"
				},
				{
					"text": "You dont have any upcomming calls",
					"translatedtext": "Bạn không có cuộc gọi đến nào"
				},
				{
					"text": "Upcomming Calls",
					"translatedtext": "Cuộc gọi sắp tới"
				},
				{
					"text": "Callback Request in Queue",
					"translatedtext": "Đã có yêu cầu gọi lại trong hàng đợi của bạn."
				},
				{
					"trxt": "Alert",
					"translatedtext": "Báo động"
				},
				{
					"text": "Write your comments here",
					"translatedtext": "Viết bình luận của bạn ở đây"
				},
				{
					"text": "Required fields",
					"translatedtext": "Phần bắt buộc"
				},
				{
					"text": "Done",
					"translatedtext": "Xong"
				},
				{
					"text": "Cell Phone added",
					"translatedtext": "Teléfono celular agregado"
				},
				{
					"text": "We will reach out to you at",
					"translatedtext": "Chúng tôi sẽ liên hệ với bạn tại"
				},
				{
					"text": "with short surveys and notifications related to your healthcare.",
					"translatedtext": "với các cuộc khảo sát và thông báo ngắn liên quan đến việc chăm sóc sức khỏe của bạn."
				},
				{
					"text": "Hi",
					"translatedtext": "CHÀO"
				},
				{
					"text": "your agent",
					"translatedtext": "đại lý của bạn"
				},
				{
					"text": "will call you at",
					"translatedtext": "sẽ gọi cho bạn theo số"
				},
				{
					"text": "Member",
					"translatedtext": "Thành viên"
				},
				{
					"text": "to cancel the call",
					"translatedtext": "hủy cuộc gọi"
				},
				{
					"text": "I Don't Know",
					"translatedtext": "Tôi không biết"
				},
				{
					"text": "Welcome to the club",
					"translatedtext": "Chào mừng đến với câu lạc bộ"
				},
				{
					"text": "We built this platform to keep you in sync with your healthcare partners at plan and provider.",
					"translatedtext": "Chúng tôi đã xây dựng nền tảng này để giúp bạn đồng bộ hóa với các đối tác chăm sóc sức khỏe của mình tại chương trình và nhà cung cấp."
				},
				{
					"text": "This is YOUR channel for tracking your benefits, and it's OUR channel for tracking your healthcare journey",
					"translatedtext": "Đây là kênh CỦA BẠN để theo dõi các lợi ích của bạn và là kênh CỦA CHÚNG TÔI để theo dõi hành trình chăm sóc sức khỏe của bạn"
				},
				{
					"text": "Let's pick up where we left off",
					"translatedtext": "Hãy bắt đầu từ nơi chúng ta đã dừng lại"
				},
				{
					"text": "Let's start by making sure we've got your contact information down right",
					"translatedtext": "Hãy bắt đầu bằng cách đảm bảo chúng tôi có đúng thông tin liên hệ của bạn"
				},
				{
					"text": "Need to update your personal information or preferences? Want to schedule an appointment with",
					"translatedtext": "Cần cập nhật thông tin cá nhân hoặc sở thích của bạn? Muốn đặt lịch hẹn với"
				},
				{
					"text": "or communicate with",
					"translatedtext": "hoặc giao tiếp với"
				},
				{
					"text": "Click here",
					"translatedtext": "Bấm vào đây"
				},
				{
					"text": "tap here",
					"translatedtext": "nhấn vào đây"
				},
				{
					"text": "your primary care physician",
					"translatedtext": "bác sĩ chăm sóc chính của bạn"
				},
				{
					"text": "Your primary care physician",
					"translatedtext": "bác sĩ chăm sóc chính của bạn"
				},
				{
					"text": "your doctor",
					"translatedtext": "bác sĩ của bạn"
				},
				{
					"text": "Agent",
					"translatedtext": "Đại lý"
				},
				{
					"text": "the Payer",
					"translatedtext": "Người trả tiền"
				},
				{
					"text": "the Provider",
					"translatedtext": "nhà cung cấp"
				},
				{
					"text": "the",
					"translatedtext": "nhà"
				},
				{
					"text": "Please specify",
					"translatedtext": "Xin hãy chỉ ra cụ thể"
				},
				{
					"text": "the clinic or hospital",
					"translatedtext": "phòng khám hoặc bệnh viện"
				},
				{
					"text": "your health-plan broker",
					"translatedtext": "người môi giới chương trình sức khỏe của bạn"
				},
				{
					"text": "your health plan",
					"translatedtext": "kế hoạch sức khỏe của bạn"
				},
				{
					"text": "Your health plan",
					"translatedtext": "Chương trình sức khỏe của bạn"
				},
				{
					"text": "agent",
					"translatedtext": "đại lý"
				},
				{
					"text": "The",
					"translatedtext": "Các"
				},
				{
					"text": "The campaign has not started yet",
					"translatedtext": "Chiến dịch chưa bắt đầu"
				},
				{
					"text": "You have already completed the survey",
					"translatedtext": "Bạn đã hoàn thành khảo sát rồi"
				},
				{
					"text": "The campaign has ended",
					"translatedtext": "Chiến dịch đã kết thúc"
				},
			

			]
		}

		let returndata;
		for (let i = 0; i < jsondata.length; i++) {

			if (jsondata[i]["text"] === data.trim()) {

				returndata = jsondata[i]["translatedtext"];
			}
		}

		return returndata;
	},
}

export default CustomLanguageservice;
