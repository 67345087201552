import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Textarea from '@mui/joy/Textarea';

import "./FinalQuestionTemplate.css";
import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import ButtonComponent from "../../../../Shared/Commonfields/ButtonComponent";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { fianlTemplate, languageId } from "../../../../Constants/static";
import ScheduleCallback from "../../../../Shared/Popups/ScheduleCallback/ScheduleCallback";
import RequestCallbackPopup from "../../../../Shared/Popups/RequestCallbackPopup/RequestCallbackPopup";
import CallbackAlertPopup from "../../../../Shared/Popups/CallbackAlertPopup/CallbackAlertPopup";
import Apiservice from "../../../../Services/Apiservice";
import { URL } from "../../../../Constants/apiUrl";
import { ROUTES_MEMBER_LOGIN } from "../../../../Constants/routes";
import { phoneFormatter } from "../../../../Utilities/utils";



function FinalQuestionTemplate({ data, surveyname }) {
	// window.history.pushState({}, '', "survey-surveytemplate");

	const Navigate = useNavigate();
	const [optionselected, setoptionselected] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [nextqn, setNextQn] = useState("")
	const [otheranswertext, setOtheranswertext] = useState("");
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [talktoAgentFlag, setTalktoAgentFlag] = useState(false);
	const [makeAppointment, setMakeAppointment] = useState(false);
	const [reqcallbackDetails, setRequestCallbackDetails] = useState("")
	const [isagentAvailable, setIsAgentAvailable] = useState(false)
	const [apicalled, setapicalled] = useState("")
	const [agentNum, setAgentNum] = useState("")
	const [externalAppointmentUrl, setExternalAppointmentUrl] = useState("")

	const member_id = localStorage.getItem("Survey_member_id")
	const campaign_id = localStorage.getItem("Survey_campaign_id")
	const language_code = localStorage.getItem("Survey_language_code")
	const Survey_reference=  localStorage.getItem("Survey_reference")
	const Survey_reference_order = localStorage.getItem("Survey_reference_order")

	let member_data = localStorage.getItem("member_data")
	member_data = JSON.parse(member_data)

	const language = languageId[language_code]

	let questionData = data;

	let RequestaCallback = CustomLanguageservice.getMethod("Request a Callback", language)
	let ScheduleaCallback = CustomLanguageservice.getMethod("Schedule a Callback", language)
	let Confirm = CustomLanguageservice.getMethod("Confirm", language)
	let Cancel = CustomLanguageservice.getMethod("Cancel", language)
	let CallNow = CustomLanguageservice.getMethod("Call Now", language)
	let CallbackRequestinQueue = CustomLanguageservice.getMethod("Callback Request in Queue", language)
	let Alert = CustomLanguageservice.getMethod("Alert", language)
	let OK = CustomLanguageservice.getMethod("Ok", language)
	let error = CustomLanguageservice.getMethod("error", language)
	let needtoupdate_text = CustomLanguageservice.getMethod("Need to update your personal information or preferences? Want to schedule an appointment with", language)
	let orcommunicatewith_text = CustomLanguageservice.getMethod("or communicate with", language)
	let Clickhere = CustomLanguageservice.getMethod("Click here", language)
	let taphere = CustomLanguageservice.getMethod("tap here", language)
	let theDoctor = CustomLanguageservice.getMethod("your doctor", language)
	let Agent = CustomLanguageservice.getMethod("Agent", language)
	let the = CustomLanguageservice.getMethod("the", language);
	let Hi = CustomLanguageservice.getMethod("Hi", language);
	let youragent = CustomLanguageservice.getMethod("your agent", language);
	let willcallyouat = CustomLanguageservice.getMethod("will call you at", language)
	let yourhealthplanbroker = CustomLanguageservice.getMethod("your health-plan broker", language);
	let MakeanAppointment = CustomLanguageservice.getMethod("Make an Appointment", language);

	useEffect(() => {
		if (member_data.appointment_extn_url !== null || member_data.appointment_extn_url !== "") {
			setExternalAppointmentUrl(member_data.appointment_extn_url)
		}
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		localStorage.setItem("surveycompleted", "true");
		thankYouApiCall()
	}, [])

	const thankYouApiCall = () => {
		const apiData = {
			"agent_id": member_data["agent_id"],
			"language_code": language_code
		}

		Apiservice.postMethod(URL.SURVEY_THANK_YOU, apiData).then((response) => {
			console.log(response.data.data.agent_availability.is_available, "available");
			setIsAgentAvailable(response.data.data.agent_availability.is_available)
			setAgentNum(response.data.data.agent_number)
			let requestCallText = Hi + " " + "<strong>" + member_data.first_name + "</strong>" + " " + youragent + " " + member_data.agent_first_name + " " + willcallyouat + " " + "<strong>" + "<br/>" + phoneFormatter(member_data.phone_number) + "</strong>"
				setRequestCallbackDetails(requestCallText)
			setapicalled(true);
			setIsLoading(false)
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})

		questionData.answers.forEach(element => {
			if (element.answer_text["en"] === fianlTemplate.TALK_TO_AN_AGENT) {
				setTalktoAgentFlag(true);
				// let requestCallText = Hi + " " + "<strong>" + member_data.name + "</strong>" + " " + youragent + " " + member_data.agent_name + " " + willcallyouat + " " + "<strong>" + "<br/>" + phoneFormatter(member_data.phone_number) + "</strong>"
				// setRequestCallbackDetails(requestCallText)
			}

			if (element.answer_text["en"] === fianlTemplate.SCHEDULE_APPOINTMENT) {
				setMakeAppointment(true);
				// let requestCallText = Hi + " " + "<strong>" + member_data.name + "</strong>" + " " + youragent + " " + member_data.agent_name + " " + willcallyouat + " " + "<strong>" + "<br/>" + phoneFormatter(member_data.phone_number) + "</strong>"
				// setRequestCallbackDetails(requestCallText)
			}
		});
	}

	const handleSelect = (date, time, comment, varient) => {

		// varient was used in memberAccount cancel callback request dont remove varient
		setIsLoading(true);
		let apiData = {
			"preferred_date": date,
			"preferred_time": time,
			"message": comment,
			"agent_id": member_data.agent_id,
			"member_id": member_id,
			"language_code": language_code
		}

		Apiservice.postMethod(URL.SURVEY_SCHEDULE_CALLBACK, apiData).then(response => {
			setIsLoading(false);
			let val = response.data.message
			if (val === "You already have a pending request." || val === "There is existing callback exists") {
				CallbackAlertPopup(CallbackRequestinQueue, OK, Alert, error)
			}
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}

	const handleNavigation = (e) => {
		// Navigate(ROUTES_MEMBER_LOGIN);
		localStorage.setItem("TokenExpired", "true");
		localStorage.setItem("member_id_member", member_id);
	};


	return (
		<Box className={"SurveyTemplate3container"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />
			<Grid container xs={12} className={"Survey_bodygrid_Template1"}>


				<Grid xs={12} >
					<div className={"Heading"}>
						{surveyname}
					</div>

				</Grid>


				<Grid xs={12} > <br></br><br></br>
					<div className={"questiontext"} tabIndex="0">
						<span dangerouslySetInnerHTML={{ __html: questionData.question[language_code] }} />
						{/* {questionData.question} */}
					</div>

				</Grid>

				<Grid xs={12} lg={12}><br></br><br></br>
					{talktoAgentFlag && (
						<>
							{/* original format start */}
							{screenWidth < 799 ?
								(
									<>
										<ButtonComponent
											id="submitID"
											variant="contained"
											arialabel="callNow"
											type="submit"
											onClick={() => window.location.href = `tel:${agentNum}`}
											className={"Nextbtn"}
											buttonname={CallNow}
										/>
									</>
								)
								:
								(
									<div>
										{(isagentAvailable && apicalled) &&
											<RequestCallbackPopup text={reqcallbackDetails} handleSelect={handleSelect} heading={RequestaCallback} btn1={Confirm} btn2={Cancel} varient={"requestCall"} />
										}

										{(isagentAvailable === false && apicalled) &&
											<ScheduleCallback handleSelect={handleSelect} heading={ScheduleaCallback} btn1={Confirm} btn2={Cancel} language={language} />
										}
									</div>
								)

							}
							{/* original format end */}
						</>
					)
					}

					{(externalAppointmentUrl !== "" && makeAppointment) ? (
						<>
							<ButtonComponent
								id="submitID"
								variant="contained"
								arialabel="makeAppointment"
								type="submit"
								onClick={() => window.location.href = `${externalAppointmentUrl}`}
								className={"Nextbtn"}
								buttonname={MakeanAppointment}
							/>
						</>
					) : (makeAppointment &&
						<>
							{screenWidth < 799 ?
								(
									<>
										<ButtonComponent
											id="submitID"
											variant="contained"
											arialabel="callNow"
											type="submit"
											// onClick={() => window.location.href = `tel:${agentNum}`}
											className={"Nextbtn"}
											buttonname={CallNow}
										/>
									</>
								)
								:

								<div>
									{(isagentAvailable && apicalled) &&
										<RequestCallbackPopup text={reqcallbackDetails} handleSelect={handleSelect} heading={RequestaCallback} btn1={Confirm} btn2={Cancel} varient={"requestCall"} />
									}

									{(isagentAvailable === false && apicalled) &&
										<ScheduleCallback handleSelect={handleSelect} heading={ScheduleaCallback} btn1={Confirm} btn2={Cancel} language={language} />
									}
								</div>
							}
						</>
					)}

					<Grid mt={5} tabIndex="0">
						<Typography variant="body1" sx={{ fontSize: "var(--fontsizesmall)" }}>
							{needtoupdate_text} {theDoctor} {orcommunicatewith_text} {yourhealthplanbroker}?
							&nbsp;<a href={ROUTES_MEMBER_LOGIN} target="_blank" onClick={handleNavigation}>
								{screenWidth > 1280 ?
									Clickhere
									:
									taphere
								}
							</a>.
						</Typography>

					</Grid>



				</Grid>
			</Grid>

		</Box>
	)
}

export default FinalQuestionTemplate;

