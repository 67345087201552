import React from "react";
import "./Preferences.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import SelectComponent from "../../../../Shared/Commonfields/SelectComponent";
import ButtonComponent from "../../../../Shared/Commonfields/ButtonComponent";
import CommonService from "../../../../Services/CommonService";
import { URL } from "../../../../Constants/apiUrl";
import Apiservice from "../../../../Services/Apiservice";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import PreferencesuccessPopup from "../../../../Shared/Popups/PreferencesuccessPopup/PreferencesuccessPopup";
import { languageId } from "../../../../Constants/static";

function Preferences() {
	const isBrowser = () => typeof window !== "undefined";
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [Langaugeid, setLangaugeid] = React.useState("");
	const [Channel, setChannel] = React.useState("");
	const [Frequency, setFrequency] = React.useState("");
	const [Langaugevalue, setLangaugevalue] = React.useState("");
	const [Frequencyvalue, setFrequencyvalue] = React.useState("");
	const [Channelvalue, setChannelvalue] = React.useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [commontext, setcommontext] = useState("");
	const [commontextFrequency, setcommontextFrequency] = useState("");
	const [apiresponsedata, setapiresponsedata] = useState([]);
	const [dropdownChanneldata, setdropdownChanneldata] = useState([]);
	const [changeFlag, setchangeFlag] = useState(false);
	const [disableflag, setDisableflag] = useState(false);
	const [changedChannel_flag, setchangedChannel_flag] = useState("");
	const [changedfrequency_flag, setchangedfrequency_flag] = useState("");
	const [changedlanguage_flag, setchangedlanguage_flag] = useState("");

	// const [channelflag , setChannelflag] = useState("")


	// localisation data
	let language;
	let dropdowndata;
	// let dropdownChanneldata;
	let dropdownFrequencydata;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
		dropdowndata = CommonService.getLanguage();
		// dropdownChanneldata = CommonService.getChannel(language);
		dropdownFrequencydata = CommonService.getFrequency(language);
	}

	let PreferencesSettings_text = CustomLanguageservice.getMethod("Preferences Settings", language);
	let PreferredChannel_text = CustomLanguageservice.getMethod("Preferred Channel", language);
	let PreferredFrequency_text = CustomLanguageservice.getMethod("Preferred Frequency", language);
	let LanguageConfirmation_text = CustomLanguageservice.getMethod("Language Confirmation", language);
	let Wewilltextat = CustomLanguageservice.getMethod("We will text at", language);
	let Wewillemailat = CustomLanguageservice.getMethod("We will email at", language);
	let Wewillcallat = CustomLanguageservice.getMethod("We will call at", language);
	let ThanksForUpdatingYourPreferences = CustomLanguageservice.getMethod("Thanks For Updating Your Preferences", language);
	let wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications = CustomLanguageservice.getMethod("We will reach out to you with information and tips as well as surveys and notifications", language);
	let Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey = CustomLanguageservice.getMethod("We will reach out approximately once a month with an important notification or survey", language);
	let Wewillonlysendthemostcriticalnotificationsandquestionnaires = CustomLanguageservice.getMethod("We will only send the most critical notifications and questionnaires", language);
	let SubmitChanges_text = CustomLanguageservice.getMethod("Submit Changes", language);


	useEffect(() => {
		setIsLoading(true);
		let endpoint = URL.MEMBER_PROFILE_INFO;
		let apidata = {
			"route_name": ""
		}
		Apiservice.postMethod(endpoint, apidata).then(response => {
			setIsLoading(false);
			setapiresponsedata(response.data.data);
			let contactType;
			if (response.data.data.is_landline === "false") {
				// then its a Cell Phone
				contactType = "Cell Phone"
			}
			else {
				if (response.data.data.mobile === "" || response.data.data.mobile === null) {
					contactType = "Landline"
				}
				else {
					contactType = "Cell Phone"
				}

			}

			let channelFlag = 0
			if (contactType === "Cell Phone" && response.data.data.email !== "") {
				setdropdownChanneldata(CommonService.getChannel(language));
				channelFlag = parseInt(response.data.data.pref_channel)
				setChannel(channelFlag)
			}
			else if (contactType === "Landline" && response.data.data.email !== "") {
				setdropdownChanneldata(CommonService.getChannel1(language))
				if (parseInt(response.data.data.pref_channel) === 0) {
					channelFlag = 1
					setchangeFlag(true);
					setchangedChannel_flag(true);
				}
				else{
					channelFlag = parseInt(response.data.data.pref_channel)
				}
				setChannel(channelFlag)
			}
			else if (contactType === "Cell Phone" && response.data.data.email === "") {
				setdropdownChanneldata(CommonService.getChannel2(language));
				if (parseInt(response.data.data.pref_channel) === 1) {
					channelFlag = 2
					setchangeFlag(true);
					setchangedChannel_flag(true);
				}
				else{
					channelFlag = parseInt(response.data.data.pref_channel)
				}
				setChannel(channelFlag)
			}
			else if (contactType === "Landline" && response.data.data.email === "") {
				setdropdownChanneldata(CommonService.getChannel3(language));
				channelFlag = 2
				setChannel(channelFlag)
				if (parseInt(response.data.data.pref_channel) !== 2) {
					setchangeFlag(true)
					setchangedChannel_flag(true);
				}
				
			}


			// set the dynamic text below the dropdown
			commonfunction("ChangeChannel", channelFlag, response.data.data)
			commonfunction("ChangeLanguage", parseInt(languageId[response.data.data.language_id]), response.data.data)
			commonfunction("FrequencyLanguage", parseInt(response.data.data.com_frequency), response.data.data)

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})

	}, []);



	const commonfunction = (type, value, apiresponsedatanew) => {

		if (type === "ChangeLanguage") {
			setLangaugeid(value);
			for (let i = 0; i < dropdowndata.length; i++) {
				if (dropdowndata[i].id === value) {
					setLangaugevalue(dropdowndata[i].value)
				}
			}
		}

		else if (type === "ChangeChannel") {
			setChannel(value);
			for (let i = 0; i < dropdownChanneldata.length; i++) {
				if (dropdownChanneldata[i].id === value) {
					setChannelvalue(dropdownChanneldata[i].value)
				}
			}
			if (value === 0) {
				if (apiresponsedatanew && apiresponsedatanew.phone !== undefined && apiresponsedatanew.phone !== null && apiresponsedatanew.phone !== "") {
					let phonenum
					if (apiresponsedatanew.is_landline === "true"){
						phonenum = apiresponsedatanew.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
					else{
						phonenum = apiresponsedatanew.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
					
					setcommontext(Wewilltextat + " " + phonenum);
				}
			}
			else if (value === 1) {
				setcommontext(Wewillemailat + " " + apiresponsedatanew.email)
			}
			else if (value === 2) {
				if (apiresponsedatanew && apiresponsedatanew.phone !== undefined && apiresponsedatanew.phone !== null && apiresponsedatanew.phone !== "") {
					let phonenum = apiresponsedatanew.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					setcommontext(Wewillcallat + " " + phonenum)
				}
			}


		}

		else if (type === "FrequencyLanguage") {
			setFrequency(value);

			for (let i = 0; i < dropdownFrequencydata.length; i++) {
				if (dropdownFrequencydata[i].id === value) {
					setFrequencyvalue(dropdownFrequencydata[i].value)
				}
			}

			if (value === 0) {
				setcommontextFrequency(wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications)
			}
			else if (value === 1) {
				setcommontextFrequency(Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey)
			}
			else if (value === 2) {
				setcommontextFrequency(Wewillonlysendthemostcriticalnotificationsandquestionnaires)
			}
		}

	}

	const handleSelectChangeLanguage = (event) => {
		commonfunction("ChangeLanguage", event.target.value, apiresponsedata);
		setchangeFlag(true);
		setchangedlanguage_flag(true);
	};


	const handleSelectChangeChannel = (event) => {
		commonfunction("ChangeChannel", event.target.value, apiresponsedata);
		setchangeFlag(true);
		setchangedChannel_flag(true);

	};

	const handleSelectFrequencyLanguage = (event) => {
		commonfunction("FrequencyLanguage", event.target.value, apiresponsedata);
		setchangeFlag(true);
		setchangedfrequency_flag(true);
	};


	const handleOKButton = (value) => {
		setIsModalOpen(false);
	};

	const submitButtonClick = (data) => {
		// api call
		let url = URL.MEMBER_SAVE_COMM_PREFERENCE;
		let apidata = {
			"pref_language": Langaugeid.toString(),
			"com_frequency": Frequency.toString(),
			"pref_channel": Channel.toString(),
			"route_name": "/thank-you",
		}
		setIsLoading(true);
		Apiservice.putMethod(url, apidata).then(response => {
			setIsModalOpen(true);
			localStorage.setItem("Preferredlanguage", Langaugeid.toString())

			// localised data for channel to display is success popup
			let Wewilltextat_dup = CustomLanguageservice.getMethod("We will text at", Langaugeid.toString());
			let Wewillemailat_dup = CustomLanguageservice.getMethod("We will email at", Langaugeid.toString());
			let Wewillcallat_dup = CustomLanguageservice.getMethod("We will call at", Langaugeid.toString());
			let wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications_dup = CustomLanguageservice.getMethod("We will reach out to you with information and tips as well as surveys and notifications", Langaugeid.toString());
			let Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey_dup = CustomLanguageservice.getMethod("We will reach out approximately once a month with an important notification or survey", Langaugeid.toString());
			let Wewillonlysendthemostcriticalnotificationsandquestionnaires_dup = CustomLanguageservice.getMethod("We will only send the most critical notifications and questionnaires", Langaugeid.toString());

			let Channelvalue_localized;
			let Frequencyvalue_localized;
			for (let i = 0; i < dropdownChanneldata.length; i++) {
				if (dropdownChanneldata[i].id === Channel) {
					Channelvalue_localized = CustomLanguageservice.getMethod(dropdownChanneldata[i].value_eng, Langaugeid.toString());
					setChannelvalue(Channelvalue_localized)
				}
			}
			if (Channel === 0) {
				if (apiresponsedata && apiresponsedata.phone !== undefined && apiresponsedata.phone !== null && apiresponsedata.phone !== "") {
					// let phonenum = apiresponsedata.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					let phonenum
					if (apiresponsedata.is_landline === "true"){
						phonenum = apiresponsedata.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
					else{
						phonenum = apiresponsedata.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					}
					setcommontext(Wewilltextat_dup + " " + phonenum);
				}
			}
			else if (Channel === 1) {
				setcommontext(Wewillemailat_dup + " " + apiresponsedata.email)
			}
			else if (Channel === 2) {
				if (apiresponsedata && apiresponsedata.phone !== undefined && apiresponsedata.phone !== null && apiresponsedata.phone !== "") {
					let phonenum = apiresponsedata.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
					setcommontext(Wewillcallat_dup + " " + phonenum)
				}
			}



			// localised data for Frequency to display is success popup
			for (let i = 0; i < dropdownFrequencydata.length; i++) {
				if (dropdownFrequencydata[i].id === Frequency) {
					Frequencyvalue_localized = CustomLanguageservice.getMethod(dropdownFrequencydata[i].value_eng, Langaugeid.toString());
					setFrequencyvalue(Frequencyvalue_localized)
				}
			}

			if (Frequency === 0) {
				setcommontextFrequency(wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications_dup)
			}
			else if (Frequency === 1) {
				setcommontextFrequency(Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey_dup)
			}
			else if (Frequency === 2) {
				setcommontextFrequency(Wewillonlysendthemostcriticalnotificationsandquestionnaires_dup)
			}


			setTimeout(() => {
				setIsModalOpen(false);
				window.location.reload();
				setIsLoading(false);

			}, 3000);

		})
			.catch(error => {
				setIsLoading(false);
				setIsModalOpen(false);
			})
	};




	return (

		<Box className={"PreferrenceContainerMember"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			

			<PreferencesuccessPopup
				isOpen={isModalOpen}
				handleOKButton={handleOKButton}
				heading={ThanksForUpdatingYourPreferences}
				text1={PreferredChannel_text}
				text2={Channelvalue}
				text3={commontext}
				text5={PreferredFrequency_text}
				text6={Frequencyvalue}
				text7={commontextFrequency}
				text8={LanguageConfirmation_text}
				text9={Langaugevalue}
				changedChannel_flag={changedChannel_flag}
				changedfrequency_flag= {changedfrequency_flag}
				changedlanguage_flag={changedlanguage_flag}
			/>

			<Grid container>
				<Grid xs={12} className={"memberPageheading"} pt={1}>
					<p tabIndex="0" aria-label={PreferencesSettings_text}>{PreferencesSettings_text}</p>
				</Grid>
			</Grid>

			<Grid container className={"maingridcontainer"}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					minHeight: { md: "80vh" },
					marginTop: { xs: "10px", md: "inherit" }
				}}
			>
				{/* <Grid xs={12} lg={3} >
				</Grid> */}

				<Grid container xs={11} lg={8} >

					<Grid xs={12} lg={6} >
						<div className={"gridtext"} tabIndex="0" aria-label={PreferredChannel_text}>
							{PreferredChannel_text}
						</div>

					</Grid>

					<Grid xs={12} lg={6} className={"Gridcontent"}>
						<FormControl className={"Drpdownselect"}>
							<SelectComponent 
								dropdownData={dropdownChanneldata}
								selectedValue={Channel}
								onValueChange={handleSelectChangeChannel}
								idKey="id"
								valueKey="value"
							/>
						</FormControl>

						<div className={"textlabel"} tabIndex="0">
							{commontext}
						</div>
					</Grid>


					<Grid xs={12} lg={6}>
						<div className={"gridtext"} tabIndex="0">
							{PreferredFrequency_text}
						</div>
					</Grid>

					<Grid xs={12} lg={6} className={"Gridcontent"}>
						<FormControl className={"Drpdownselect"}>
							<SelectComponent
								dropdownData={dropdownFrequencydata}
								selectedValue={Frequency}
								onValueChange={handleSelectFrequencyLanguage}
								idKey="id"
								valueKey="value"
							/>
						</FormControl>

						<div className={"textlabel"} tabIndex="0">
							{commontextFrequency}
						</div>
					</Grid>

					<Grid xs={12} lg={6}>
						<div className={"gridtext"} tabIndex="0">
							{LanguageConfirmation_text}
						</div>

					</Grid>

					<Grid xs={12} lg={6} className={"Gridcontent"}>
						<FormControl className={"Drpdownselect"}>
							<SelectComponent
								dropdownData={dropdowndata}
								selectedValue={Langaugeid}
								onValueChange={handleSelectChangeLanguage}
								idKey="id"
								valueKey="value"
							/>
						</FormControl>

					</Grid>

					<Grid xs={12} lg={12}><br></br><br></br>
						<ButtonComponent
							id="submitID"
							variant="contained"
							arialabel="Submit Changes"
							type="submit"
							onClick={() => submitButtonClick("Some Data")}
							className={changeFlag ? "submitbtn" : "disablebtn"}
							buttonname={SubmitChanges_text}
						/>

					</Grid>

				</Grid>


			</Grid>
		</Box>

	)
}

export default Preferences; 