import React from 'react';
import "./PreferencesuccessPopup.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Unstable_Grid2';


function PreferencesuccessPopup({ isOpen, handleOKButton, heading, text1, text2, text3, text5, text6, text7, text8, text9, changedChannel_flag, changedfrequency_flag, changedlanguage_flag }) {
	function handleClickcancel(data) {
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOKButton(data);
	};


	return (
		<div >
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className={"PreferencesuccessPopup"}>
					<Grid container xs={12} className={"CommonLoadermaingrid"}>

						<Grid xs={12} className={"Gridtext"}>
							<div className={"successimage"}>
								<img src="/asset/tick.png" alt="success" />
							</div>

							<div className={"heading"}>
								{heading}
							</div>
							<br></br><br></br>
						</Grid>


						{changedChannel_flag === true &&
							<Grid container xs={12} className={"gridrow"}>
								<Grid xs={12} lg={6} >
									<div className={"text1"}>
										{text1}
									</div>

								</Grid>

								<Grid xs={12} lg={6}>
									<div className={"text2"}>
										{text2}
									</div>

									<div className={"text3"}>
										{text3}
									</div>
								</Grid>
							</Grid>
						}

						{changedfrequency_flag === true &&
							<Grid container xs={12} className={"gridrow"}>
								<Grid xs={12} lg={6} >
									<div className={"text1"}>
										{text5}
									</div>

								</Grid>

								<Grid xs={12} lg={6}>
									<div className={"text2"}>
										{text6}
									</div>

									<div className={"text3"}>
										{text7}
									</div>
								</Grid>
							</Grid>
						}

						{changedlanguage_flag === true &&
							<Grid container xs={12} className={"gridrow"}>
								<Grid xs={12} lg={6} >
									<div className={"text1"}>
										{text8}
									</div>

								</Grid>

								<Grid xs={12} lg={6}>
									<div className={"text2"}>
										{text9}
									</div>


								</Grid>
							</Grid>
						}

					</Grid>
				</Box>
			</Modal>
		</div>
	)

}

export default PreferencesuccessPopup