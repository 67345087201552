
import React, { createContext, useState, useEffect } from "react";

const themes = {
	lightFontstyleDefault: {
		primary: "#000000",
		secondary: "#FFFFFF",
		brandcolor: "#0C0C47",
		tertiary: "#00E0BE",
		defaultgrey: "#E1E1E1",
		grey2: "#98A2B3",
		lightblue: "#DEE7FF",
		fontsizesmall: "16px",
		fontsizemedium: "18px",
		fontsizelarge: "20px",
		fontFamily: "Inter",
		btncolor : "#B30404",
		secondarybtncolor: "#98A2B3",
		lightgrey: "#DADADA"
	},
	lightFontstyleMedium: {
		primary: "#000000",
		secondary: "#FFFFFF",
		brandcolor: "#0C0C47",
		tertiary: "#00E0BE",
		defaultgrey: "#E1E1E1",
		grey2: "#98A2B3",
		lightblue: "#DEE7FF",
		fontsizesmall: "19px",
		fontsizemedium: "21px",
		fontsizelarge: "23px",
		fontFamily: "Inter",
		btncolor : "#B30404",
		secondarybtncolor: "#98A2B3",
		lightgrey: "#DADADA"
	},
	lightFontstyleLarge: {
		primary: "#000000",
		secondary: "#FFFFFF",
		brandcolor: "#0C0C47",
		tertiary: "#00E0BE",
		defaultgrey: "#E1E1E1",
		grey2: "#98A2B3",
		lightblue: "#DEE7FF",
		fontsizesmall: "22px",
		fontsizemedium: "24px",
		fontsizelarge: "26px",
		fontFamily: "Inter",
		btncolor : "#B30404",
		secondarybtncolor: "#98A2B3",
		lightgrey: "#DADADA"
	},
	dark: {
		primary: "#ffffff",
		secondary: "#ffffff",
		background: "#000000",
		fontsize: "20px",
		fontFamily: "Inter"
	},
};


const setCSSVariables = theme => {
	for (const value in theme) {
		document.documentElement.style.setProperty(`--${value}`, theme[ value ]);
	}
};

export const ThemeSelectorContext = createContext({
	toggleTheme: () => { },
});

export default ({ children }) => {
	const [ theme, setTheme ] = useState("light");


	// theme changes on value passed
	const toggleTheme = (value) => {
		if (value === 1) {
			setTheme(themes.lightFontstyleDefault);
		}
		else if (value === 2) {
			setTheme(themes.lightFontstyleMedium);
		}
		else if (value === 3) {
			setTheme(themes.lightFontstyleLarge);
		}
		else if (value === 4) {
			setTheme(themes.dark);
		}
		else{
			setTheme(themes.lightFontstyleDefault);
		}
	};

	useEffect(() => {
		setCSSVariables(theme);
	});

	return (
		<ThemeSelectorContext.Provider value={{ toggleTheme }}>
			{children}
		</ThemeSelectorContext.Provider>
	);
};
