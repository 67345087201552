import "./Reports.css";
import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import CheckTypeOneAccord from "./check_accord_type_one";
import SubAccord from "./check_sub_accord";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const CheckAccordion = ({ item, index }) => {
	let isOpen = index===1;
	const [isParentExpanded, setIsParentExpanded] = useState(isOpen);

	const handleParentChange = (_, expanded) => {
		setIsParentExpanded(expanded);
	};
	return (
		<Accordion
			style={{ marginTop: "10px" }}
			expanded={isParentExpanded}
			onChange={handleParentChange}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1-content"
				id="panel1-header"
				className="accordion-summary"
			>
				<div dangerouslySetInnerHTML={{
					__html: `${index}. ${item.question_text}`,
				}} ></div>
			</AccordionSummary>
			<AccordionDetails className="accordion-details">
				<div className="accordion-data-container">
					<Box
						style={{ marginTop: "10px", position: "relative" }}
						sx={{ flexGrow: 1 }}
					>
						{item.question_type_id !== "INP" ? (
							item.question_type_id == "SSO" ||
							item.question_type_id == "SSI" ||
							item.question_type_id == "YNO" ||
							item.question_type_id == "YNI" ? (
								<p
									style={{
										textAlign: "right",
										fontSize: "14px",
										fontWeight: "700",
										width: "99%",
										marginTop: "0px",
									}}
								>
									Total selections made
								</p>
							) : (
								<div
									className="variable-name-container"
									style={{ paddingBottom: "0px" }}
								>
									<p
										style={{
											textAlign: "right",
											fontSize: "14px",
											fontWeight: "700",
											width: "99%",
											marginTop: "0px",
											// alignSelf: "end",
										}}
									>
										Total response selected
									</p>
									<p
										style={{
											textAlign: "right",
											fontSize: "14px",
											fontWeight: "700",
											width: "99%",
											marginTop: "0px",
										}}
									>
										Selection percentage
									</p>
								</div>
							)
						) : (
							""
						)}

						<Grid
							style={{ paddingTop: "0px" }}
							container
							spacing={1}
						>
							{item?.answers.map((answer, index) => {
								if (
									answer.answer_type_id === "SSO" ||
									answer.answer_type_id === "CAO" ||
									answer.answer_type_id === "YNO"
								) {
									return (
										<CheckTypeOneAccord
											type={item.question_type_id}
											key={
												index +
												answer.answer_id.toString()
											}
											answer={answer}
											index={index}
										/>
									);
								} else {
									return (
										<SubAccord
											isParentExpanded={isParentExpanded}
											type={item.question_type_id}
											key={
												index +
												answer.answer_id.toString()
											}
											answer={answer}
											index={index}
										/>
									);
								}
							})}

							{/*  */}
						</Grid>
					</Box>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default CheckAccordion;
