
import { createStore, combineReducers } from 'redux';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  auth: authReducer
  // Add other reducers here if any
});

const store = createStore(rootReducer);

export default store;