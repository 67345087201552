import "./Reports.css";
import * as React from "react";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DetailsSummaryComponent from "./campaign_details_summary";
import SurveyAccordion from "./surveyreports";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import html2pdf from 'html2pdf.js';
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";

import {
	ROUTES_CAMPAIGN_DETAILS,
	ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS,
} from "../../../Constants/routes";

function AccordNotification() {
	const [report, setReport] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [NotificationCount, setNotificationCount] = useState(false);

	let fromHistory = JSON.parse(sessionStorage.getItem('from_history_to_generate_report'));

	useEffect(() => {
		let campaign_code;
		if(fromHistory){
			campaign_code = fromHistory.campaign_code;
		}else{
			campaign_code = sessionStorage.getItem("campaign_code")
		}
		setIsLoading(true);
		let apiurl = URL.GENERATE_REPORT;

		var params = new URLSearchParams();
		params.append("campaign_code", campaign_code);

		Apiservice.getMethodParams(apiurl, params)
			.then((response) => {
				setIsLoading(false);
				setReport(response.data.data);
				if (parseInt(response.data.data.additional_data.total_notification_count) - parseInt(response.data.data.additional_data.resolved_notification_count) > 99) {
					setNotificationCount("99+");
				}
				else {
					setNotificationCount(parseInt(response.data.data.additional_data.total_notification_count) -  parseInt(response.data.data.additional_data.resolved_notification_count));
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.error("There was an error!", error);
			});
	}, []);

	const Navigate = useNavigate();
	const handle_BackToCampaignTracker = () => {
		if (sessionStorage.getItem("from_history_to_generate_report")) {
			sessionStorage.removeItem("from_history_to_generate_report");
			Navigate(ROUTES_CAMPAIGN_DETAILS);
		} else {
			Navigate(ROUTES_CAMPAIGN_DETAILS);
		}
	};

	const handle_adminNotificationPage = () => {
		Navigate(ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS);
	};

	const handleDownloadPdf = () => {
		let campaign_code;
		if (sessionStorage.getItem("from_history_to_generate_report")) {
			campaign_code = sessionStorage.getItem(
				"from_history_to_generate_report"
			);
		} else {
			campaign_code = sessionStorage.getItem("campaign_code");
		}
		var params = new URLSearchParams();
		params.append("campaign_code", campaign_code);

		setIsLoading(true);
		let apiurl = URL.DOWNLOAD_REPORT;
		Apiservice.getMethodParams(apiurl, params)
			.then((response) => {
				downloadapireport(response.data.data)
			})
			.catch((error) => {
				setIsLoading(false);
				console.error("There was an error!", error);
			});
	};

	const downloadapireport = (data) => {
		// URL of the file to be downloaded
		//const url1 = 'https://s3.amazonaws.com/circleactivate.stellar.com/asset/dynamic_page.html';
		const url = data;
		setIsLoading(true);
		// Create an iframe to fetch and load the HTML content
		const iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);

		// Fetch the HTML content
		fetch(url)
			.then(response => response.text())
			.then(html => {
				// Load the HTML into the iframe
				iframe.contentDocument.open();
				iframe.contentDocument.write(html);
				iframe.contentDocument.close();

				// Use html2pdf.js to convert the content to PDF
				const element = iframe.contentDocument.body;
				 // Define options for html2pdf
				 const options = {
					margin: [10, 10, 10, 10], // top, left, bottom, right
					image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { scale: 2 },
					jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait',  autoPaging: true } // A4 size
				};
				html2pdf().from(element).set(options).toPdf().get('pdf').then(pdf => {
					// Use file-saver to save the PDF
					 let date = new Date();
					 let timeStamp = (date.getFullYear() + "" +  ((date.getMonth() + 1) < 10 ? ("0" + date.getMonth() + 1) : (date.getMonth() + 1)) + ((date.getDate()) < 10 ? ("0" + date.getDate()) : date.getDate()) + "_" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds())
					pdf.save(`${report?.additional_data?.survey_name}_${timeStamp}.pdf`);
				});
				setIsLoading(false);
				// Clean up
				document.body.removeChild(iframe);
			})
			.catch(error => {
				setIsLoading(false);
				console.error('Error downloading or converting the file:', error);
			});
	}

	return (
		<Stack
			spacing={3}
			direction="column"
			className="accord-notifiction-container"
		>
			<div className="header-nav-links">
				<Button
					className="navigate-back"
					variant="text"
					onClick={() => handle_BackToCampaignTracker()}
				>
					<img
						src="/asset/Agent/backarraow.png"
						alt="Icon"
						className="image"
						width="24px"
					/>
					<span style={{ marginLeft: "5px" }}>
						Back To Campaign Tracker
					</span>
				</Button>
				<div className="download-unresolved-navigate-container">
					<Button
						className="administrator-notifications"
						variant="contained"
						onClick={() => handle_adminNotificationPage()}
					>
						Administrator Notifications{" "}
						<span className="hanger">{NotificationCount}</span>
					</Button>
					<Button
						className="download-report"
						variant="outlined"
						onClick={handleDownloadPdf}
					>
						Download Report
					</Button>
				</div>
			</div>
			{/* campaign details summary component */}
			<DetailsSummaryComponent report={report} />
			{/* generate survey records component */}
			<SurveyAccordion report={report} />

			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
		</Stack>
		
	);
}

export default AccordNotification;
