import React, { useState } from "react";
import dayjs from 'dayjs'; // Import dayjs library

import { Grid, Box, Button, Typography, Modal, InputLabel, TextField } from '@mui/material';

import "./ScheduleCallback.css"
import FormDatepicker from '../../FormDatepicker/FormDatepicker';
import FormTimepicker from '../../FormTimepicker/FormTimepicker';
import CustomLanguageservice from "../../../Services/CustomLanguageservice";

const FormatDateTime = (value, flag) => {
	const dateObject = new Date(value);

	const hours = dateObject.getHours();
	const minutes = dateObject.getMinutes();
	const seconds = dateObject.getSeconds();
	const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
	const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

	// Combining hours, minutes, and seconds
	const formattedTime = hours + ':' + formattedMinutes + ':' + formattedSeconds;

	const date = dateObject.getDate();
	const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
	const year = dateObject.getFullYear();

	const formattedDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);

	if (flag === "Date") {
		return formattedDate
	}
	else {
		return formattedTime
	}
}

const ScheduleCallback = (props) => {

	// 'Confirm' 'Cancel' 'handleSelect' function from the parent to send the selected time value to parent
	//  'heading' 'btn1' 'btn2' are the verbages from the parent with localized texts
	const { handleSelect, heading, btn1, btn2, language} = props

	const today = dayjs();

	const [open, setOpen] = useState(false);
	const [date, setDate] = useState(FormatDateTime(today, "Date"))
	const [Time, setTime] = useState(FormatDateTime(today, "Time"))
	const [text, setText] = useState("")
	const [errorflag, setErrorflag] = useState(false)

	let PreferredTime = CustomLanguageservice.getMethod("Preferred Time", language)
	let PreferredDate = CustomLanguageservice.getMethod("Preferred Date", language)
	let Isthereanythingtheagentneedstoknow = CustomLanguageservice.getMethod("Is there anything the agent needs to know?", language)
	let Writeyourcommentshere = CustomLanguageservice.getMethod("Write your comments here", language)


	const handleOpen = () => {
		setOpen(true);
	};

	const handleConfirm = () => {
		if (!errorflag) {
			setOpen(false);
			// handleSelect is accessed from the parent file here it is accessed as propss.
			handleSelect(date, Time, text)
			// Sending the value of date and Time which are setted inside the child file, and text is accessd inside this file only.
			setText("") // Setting the text back to '' after sending the value to parent
		}

	}

	const handleCancel = () => {
		setOpen(false);
		setText("") // Setting the text back to '' after cancellation
		setErrorflag(false)
	}

	const selectDate = (value) => {
		// used to send to child as prop and call this function inside the child where it sets the valut to setDate
		setDate(value);
	}

	const selectTime = (value) => {
		// used to send to child as prop and call this function inside the child where it sets the valut to setTime
		setTime(value);
	}

	const handleTextChange = (event) => {
		setText(event.target.value);
	}

	const handleError = (value) => {
		// Error handling from child
		// This will be always false for now in future if needed uncomment the handleError(true) line in FormDatepicker inside the else condition of FormDAtepicker component
		setErrorflag(value);
	}

	return (

		<Box className={"scheduleCallbackParent"}>
			<Button onClick={handleOpen} className='scheduleCallback'>{heading}</Button>
			<Modal
				open={open}
				// onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className={"schCallbackModal"}>

					<Grid container>
						<Grid item xs={12}>
							<div><p><strong tabIndex="0">{heading}</strong></p></div>
						</Grid>
						<Grid container item xs={12} justifyContent={"space-between"}>
							<Grid item xs={5.8}>
								<Typography variant='body1' tabIndex="0" aria-label={PreferredDate}>{PreferredDate}</Typography>
								<Grid mt={1}>
									{/* Access the date and error state from child by sending a function named 'selectDate' as prop and setting the value */}
									<>
									<FormDatepicker selectDate={selectDate} handleError={handleError}/>
									{/* This is not required now Infutre if needed uncomment this segment.
									{errorflag && 
									<p style={{color:"red", margin:"3px"}}>Passed Date</p>
									} */}

									</>
								</Grid>
							</Grid>
							<Grid item xs={5.8}>
								<Typography variant='body1' tabIndex="0" aria-label={PreferredDate} >{PreferredTime}</Typography>
								<Grid mt={1}>
									{/* Access the date from child by sending a function named 'selectTime' as prop and setting the value */}
									<FormTimepicker selectTime={selectTime} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} mt={2}>
							<InputLabel htmlFor="my-input" tabIndex="0" aria-label={Isthereanythingtheagentneedstoknow} className={"memberInputlabel"}>{Isthereanythingtheagentneedstoknow}</InputLabel>
							<TextField
								variant="outlined"
								placeholder={Writeyourcommentshere}
								className='scheduleInput'
								value={text}
								onChange={handleTextChange}
								InputProps={{
									className: 'memberCusomInput',
									style: {
										width: "100%"
									}
								}}
							/>
						</Grid>
						<Grid container item mt={5} mb={3} justifyContent={"space-between"}>
							<Grid item xs={12} md={6}>
								<Button variant="contained" aria-label="confirmAppointmentbtn" className="callbackConfirm" onClick={handleConfirm}>{btn1}</Button>
							</Grid>
							<Grid item xs={12} md={6} sx={{ mt: { xs: 2, md: 0 } }}>
								<Button variant="contained" aria-label="cancelAppointmentbtn" className="callbackCancel" onClick={handleCancel}>{btn2}</Button>
							</Grid>
						</Grid>
					</Grid>

				</Box>
			</Modal>
		</Box>
	)
}

export default ScheduleCallback;