
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Textarea from '@mui/joy/Textarea';

import "./MultiSelectionTemplate.css";
import ButtonComponent from "../../../../Shared/Commonfields/ButtonComponent";
import CheckboxComponent from "../../../../Shared/Commonfields/CheckboxComponent";
import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";



import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { languageId } from "../../../../Constants/static";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";

function MultiSelectionTemplate({ data, NextQuestion, BackTracking, surveyname, Next }) {

	const isBrowser = () => typeof window !== "undefined";

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [checkboxdataselectedarray, setcheckboxdataselectedarray] = useState([]);

	const [textareaarray, settextareaarray] = useState([]);
	const [otheranswertext, setOtheranswertext] = useState(null);

	const member_id = localStorage.getItem("Survey_member_id")
	const campaign_id = localStorage.getItem("Survey_campaign_id")
	const language_code = localStorage.getItem("Survey_language_code")
	const Survey_reference=  localStorage.getItem("Survey_reference")
	const Survey_reference_order = localStorage.getItem("Survey_reference_order")


	let questionData = data;

	let language
	if (isBrowser() === true) {
		language = languageId[language_code]
	}

	let Pleasespecify = CustomLanguageservice.getMethod("Please specify", language)

	useEffect(() => {
		const selectedAnswers = questionData.answers.filter(answer => answer.is_selected);

		if (selectedAnswers.length > 0) {
			const selectedKeys = selectedAnswers.map(answer => answer.answer_id);
			setcheckboxdataselectedarray(prevSelected => [...prevSelected, ...selectedKeys]);

			selectedAnswers.forEach(answer => {
				let updatedAnswer = {
					answer_id: answer.answer_id,
					next_question_id: answer.next_question_id,
					future_question_id: answer.future_question_id === null ? 0 : answer.future_question_id,
					other_answer_text: answer.hasOwnProperty('other_answer_text') ? answer.other_answer_text : "",
					future_questions: answer.future_questions !== null ? answer.future_questions : [],
					copay_future_id: answer.hasOwnProperty('copay_future_id') ? answer.copay_future_id : 0,
					priority: answer.priority,
					answer_type_id: answer.answer_type_id,
					negative_question_id : answer.negative_question_id,
					notification_id: answer.notification_id

				};
				settextareaarray(prevSelected => [...prevSelected, updatedAnswer]);
				setOtheranswertext(null)
				// settextareaarray(updatedAnswer)
			});
		}
	}, [questionData]);

	const handlecheckboxchange = (event, data) => {
		if (event.target.checked) {
			setcheckboxdataselectedarray((prevSelected) => [...prevSelected, data.answer_id]);

			// for the api response data to send
			let updatedAnswers = {
				answer_id: data.answer_id,
				next_question_id: data.next_question_id,
				future_question_id: data.future_question_id === null ? 0 : data.future_question_id,
				other_answer_text: "",
				save_futre_text: JSON.stringify(questionData.question),
				future_questions: data.future_questions !== null ? data.future_questions : [],
				copay_future_id: data.hasOwnProperty('copay_future_id') ? data.copay_future_id : 0,
				priority: data.priority,
				answer_type_id: data.answer_type_id,
				negative_question_id : data.negative_question_id,
				notification_id: data.notification_id

			}
			settextareaarray((prevSelected) => [...prevSelected, updatedAnswers]);

			if (data.answer_type_id === "CAI") {
				setOtheranswertext("");
			}
			else{
				const filteredArray = textareaarray.filter(item => item.answer_type_id === "CAI");
				const otherAnswerTextEmptyInFilteredArray = filteredArray.some(item => item.other_answer_text === "");
				if (otherAnswerTextEmptyInFilteredArray) {
					setOtheranswertext("");
				}
				else {
					setOtheranswertext(null)
				}
			}
		}
		else {
			setcheckboxdataselectedarray((prevSelected) =>
				prevSelected.filter((id) => id !== data.answer_id)
			);

			// for the api response data to send
			settextareaarray(prevSelected =>
				prevSelected.filter(item => item.answer_id !== data.answer_id)
			);

			if (data.answer_type_id === "CAI") {
				// setOtheranswertext(null)
				let temp_array = textareaarray.filter(item => item.answer_id !== data.answer_id)
				const filteredArray = temp_array.filter(item => item.answer_type_id === "CAI");
				const otherAnswerTextEmptyInFilteredArray = filteredArray.some(item => item.other_answer_text === "");
				if (otherAnswerTextEmptyInFilteredArray) {
					setOtheranswertext("");
				}
				else {
					setOtheranswertext(null)
				}
			}
		}
	};
	// need to change to priority
	// textareaarray.sort((a, b) => a.priority - b.priority);

	const handletextareavalue = (event, data) => {
		const index = textareaarray.findIndex(item => item.answer_id === data.answer_id);

		// If found, update the existing entry
		const updatedArray = [...textareaarray];
		updatedArray[index] = {
			...updatedArray[index],
			other_answer_text: event.target.value
		};
		settextareaarray(updatedArray);

		const filteredArray = updatedArray.filter(item => item.answer_type_id === "CAI");
		const otherAnswerTextEmptyInFilteredArray = filteredArray.some(item => item.other_answer_text === "");

		if (otherAnswerTextEmptyInFilteredArray) {
			setOtheranswertext("");
		}
		else {
			setOtheranswertext(event.target.value)
		}
	}

	const submitButtonClick = () => {
		let previous_question_id = questionData.previous_question_id
		let NextQnPayload = {
			"member_id": member_id,
			"language_code": language_code,
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": questionData.question_id,
			"answers": [],
			"qn_ans_type": questionData.answer_type_id,
			"previous_question_id": previous_question_id
		}

		textareaarray.sort((a, b) => a.priority - b.priority);
		let newArray = textareaarray.map(({ priority, ...rest }) => rest);
		for (let i = 1; i < newArray.length; i++) {
			const obj = newArray[i];

			// Remove future_question_id from all objects except the first one

			// Replace future_question_id with nextQn_id if both are defined
			if (obj.hasOwnProperty('next_question_id') && obj.hasOwnProperty('future_question_id')) {
				obj.future_question_id = obj.next_question_id;
			}
		}

		for (let i = 0; i < newArray.length; i++) {
			if (newArray[i].hasOwnProperty('answer_type_id')) {
				delete newArray[i].answer_type_id;
			}

			NextQnPayload.answers.push(newArray[i]);
		}

		// for (let i = 0; i < textareaarray.length; i++) {
		// 	NextQnPayload.answers.push(textareaarray[i]);
		// }
		NextQuestion(NextQnPayload);
	}


	const PreviousQuestion = () => {
		let previous_question_id = questionData.previous_question_id
		let PreviousQnPayload = {
			"member_id": member_id,
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": previous_question_id,
			"language_code": language_code
		}
		BackTracking(PreviousQnPayload)
	}
	return (
		<Box className={"SurveyTemplate2container"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />
			<Grid container xs={12} className={"Survey_bodygrid_Template3"}>
				<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }} >
					<img src="/asset/leftarrow.png" alt="leftarrow" tabIndex="0" onClick={() => PreviousQuestion()} 
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							PreviousQuestion();
						}
					  }}/>
				</Grid>

				<Grid xs={11} >
					<div className={"Heading"}>
						{surveyname}
					</div>

				</Grid>

				<Grid xs={12} > <br></br><br></br>
					<div className={"questiontext"} tabIndex="0">
						<span dangerouslySetInnerHTML={{ __html: questionData.question[language_code] }} />
						{/* {questionData.question} */}
					</div>

				</Grid>

				<Grid xs={12} lg={12}><br></br><br></br>

					<div>
						{questionData.answers.map((data, index) => (
							<FormGroup key={index} >
								<FormControlLabel className={checkboxdataselectedarray.includes(data.answer_id) ? "checkboxdivselected" : "checkboxdiv"}
									control={
										<Checkbox key={index}
											checked={checkboxdataselectedarray.includes(data.answer_id)}
											value={data["answer_id"]}
											onChange={(event) => handlecheckboxchange(event, data)}
										/>
									}
									label={data["answer_text"][language_code]}
								/>
								{checkboxdataselectedarray.includes(data.answer_id) && data.answer_type_id === "CAI" &&
									<Textarea
										placeholder={data.placeholder !== "" ? data.placeholder[language_code] : Pleasespecify + "...."}
										className={"textarea"}
										defaultValue={data.other_answer_text}
										onChange={(event) => handletextareavalue(event, data)}
									// onBlur={(event) => handletextareavalue(event, data)}
									/>
								}


							</FormGroup>
						))
						}
					</div>

				</Grid>


				<Grid xs={12} lg={12}><br></br><br></br>
					<ButtonComponent
						id="submitID"
						variant="contained"
						arialabel="Next"
						type="submit"
						onClick={submitButtonClick}
						className={checkboxdataselectedarray.length !== 0 && otheranswertext !== "" ? "Nextbtn" : "disablebtn"}
						buttonname={Next}
					/>

				</Grid>


			</Grid>
		</Box>
	)
}

export default MultiSelectionTemplate;
