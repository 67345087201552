import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { Typography } from '@mui/material';
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { URL } from "../../../Constants/apiUrl";
import { languageId } from "../../../Constants/static";
import './AgentLogin.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ROUTES_AGENT_FORGOT_PASSWORD, ROUTES_CAMPAIGN_DETAILS } from "../../../Constants/routes"

function AgentLogin() {
	const isBrowser = () => typeof window !== "undefined";
	const Navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [validcred, Setvalidcred] = useState(true);
	const [emailtyped, Setemailtyped] = useState("");
	const [Submitclicked, setSubmitclicked] = useState(false);
	const [EmailAddressdefault, setEmailAddressdefault] = useState("");
	const [Password, setPassword] = useState("");
	const [logo, setlogo] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false); // State for password visibility
	const [emailfromDB, SetemailfromDB] = useState("");
	const textFieldRef = useRef(null);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		mode: "onChange"
	});


	useEffect(() => {
		// get api data
		setlogo("http://d23bj7ser2frgh.cloudfront.net/asset/customcompanylogo.png");

		let email_address = localStorage.getItem("email_address");
		if (email_address === "" || email_address === undefined || email_address === null) {
			setTimeout(() => {
				reset({
					EmailAddress: "",
				});
			}, 500);
		} else {
			let TokenExpired = localStorage.getItem("TokenExpired");
			if (TokenExpired === "true") {
				setTimeout(() => {
					reset({
						EmailAddress: email_address,
					});
					setEmailAddressdefault(email_address);
				}, 500);
			}
		}
	}, []);

	const handleOpenModal = () => {
		if (EmailAddressdefault !== "") {
			if (errors?.EmailAddress?.type === "pattern" === false) {
				sessionStorage.setItem("AgentEmail", EmailAddressdefault);
				let body = {
					"user_email": EmailAddressdefault
				}
				setIsLoading(true);
				let apiurl = URL.SENT_OTP;
				Apiservice.postMethod(apiurl, body).then(response => {
					setIsLoading(false);
					setIsModalOpen(true);

				})
					.catch(error => {
						setIsLoading(false);
						setIsModalOpen(false);
						console.error('There was an error!', error);
						if (error.message === "Email doesn't exist") {
							SetemailfromDB(false);
						}
					})
			}
		}
		else {
			Setemailtyped(false)
		}
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleOKButton = () => {
		handleCloseModal();
		Navigate(ROUTES_AGENT_FORGOT_PASSWORD)
	};

	function loginval(data) {
		setSubmitclicked(true);

		if (Password === "") {
		} else {
			setIsLoading(true);
			let url = URL.AGENT_LOGIN;
			let apidata = {
				"agent_email": data.EmailAddress,
				"agent_password": Password
			};

			Apiservice.postMethod(url, apidata).then(response => {
				setIsLoading(false);
				Setvalidcred(true);
				localStorage.removeItem("TokenExpired");
				localStorage.setItem("Preferredlanguage", "1")
				localStorage.setItem("Agent_token", response.data.data.access_token);
				localStorage.setItem("Agent_Refreshtoken", response.data.data.refresh_token);
				localStorage.setItem("Agent_first_name", response.data.data.agent_first_name);
				localStorage.setItem("Agent_last_name", response.data.data.agent_last_name);
				localStorage.setItem("Agent_Email", data.EmailAddress);
				sessionStorage.setItem("tabselected", "0");
				Navigate(ROUTES_CAMPAIGN_DETAILS)
			})
				.catch(error => {
					setIsLoading(false);
					if (error.message === "Incorrect email address or password") {
						Setvalidcred(false)
					}
				});
		}
	}

	const handleEmailChange_onchangemethod = (event, field) => {
		const value = event.target.value; // Extract value from event
		setEmailAddressdefault(value);
		field.onChange(event.target.value)
		SetemailfromDB("");
	}

	const handleEmailChange = (event, field) => {
		const value = event.target.value; // Extract value from event
		setEmailAddressdefault(value); // Update local state
		Setvalidcred(true); // Perform additional validation logic if needed
		Setemailtyped(true);
		if (event.target.value.trim() === "") {
			Setemailtyped(false);
		}

		SetemailfromDB("");
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
		Setvalidcred(true);
		SetemailfromDB("");
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Box>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container sx={{ background: "white" }} alignItems={"center"}>
				<Grid xs={12} lg={6} sx={{ background: "#004791", height: { lg: '100vh', xs: '20vh' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div >
						<img src={logo} alt="Companylogo"
						// style={{ width: "100%", height: "100%", objectFit: "contain" }}
						/>
					</div>
				</Grid>
				<Grid xs={12} lg={6} justifyContent={"center"} alignItems={"center"} sx={{ height: { lg: '100vh', xs: '80vh' }, display: { lg: "inherit" } }} >
					<br />
					<br />
					<Grid xs={12} md={8} lg={6} sx={{ padding: 2 }}>
						<div className="AgentLogincontainer">
							<div className="boxcontent" translate="no">
								Agent Login
							</div>
						</div>
						<br />
						<br />
						<form onSubmit={handleSubmit(loginval)}>
							<Grid className="AgentLogincontainer" justifyContent={"center"} xs={10} lg={12} sx={{ margin: "auto" }}>
								<Controller
									name="EmailAddress"
									control={control}
									rules={{ pattern: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ }}
									render={({ field }) =>
										<div >
											<InputLabel htmlFor="my-input" className="inputlabel" translate="no">Email Address</InputLabel>
											<div className={"textbox1"} >
												<TextField
													translate="no"
													ref={textFieldRef}
													type="text"
													variant="outlined"
													placeholder="Enter Your Email ID"
													value={field.value || ''}
													onBlur={(event) => {
														handleEmailChange(event, field); // Call your custom handler
														field.onBlur(); // Ensure react-hook-form's onBlur is also called
													}}
													onChange={(event) => handleEmailChange_onchangemethod(event, field)}

													// onKeyUp={handleEmailChange}
													sx={{ width: "100%" }}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<img src="/asset/Person.png" alt="UserIcon" style={{ height: '24px', marginLeft: "2px", marginRight: '8px' }} />
																<img src="/asset/Line 1.png" alt="Line" style={{ height: '24px', marginRight: '5px' }} />
															</InputAdornment>
														),
														style: {
															border: errors?.EmailAddress?.type === "required" ? "1px solid red" : "transparent",
														}
													}}
												// {...register('EmailAddress', { required: true })}
												/>
											</div>

											{(errors?.EmailAddress?.type === "required" || EmailAddressdefault === "") && emailtyped === false &&
												<div className={"errormsg"}>Email Address cannot be empty</div>}

											{errors?.EmailAddress?.type === "pattern" && emailtyped === true &&
												<div className={"errormsg"}>Email Address is invalid</div>}

											{emailfromDB === false &&
												<div className={"errormsg"}>Email Address doesn't exist</div>}

										</div>
									}
								/>
								<Controller
									name="Password"
									control={control}
									render={() =>
										<div><br />
											<InputLabel htmlFor="my-input" className={"inputlabel"} translate="no">Password</InputLabel>
											<div >
												<div className={"textbox2"}>
													<TextField
														translate="no"
														type={showPassword ? 'text' : 'password'} // Toggle between text and password type
														variant="outlined"
														placeholder="Enter Your Password"
														onKeyUp={handlePasswordChange}
														InputProps={{
															className: 'customInput',
															startAdornment: (
																<InputAdornment position="start">
																	<img src="/asset/Locklow.png" alt="PasswordIcon" style={{ height: '24px', marginLeft: "15px", marginRight: '8px' }} />
																	<img src="/asset/Line 1.png" alt="Line" style={{ height: '24px' }} />
																</InputAdornment>
															),
															endAdornment: (
																<InputAdornment position="end" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
																	<img src={showPassword ? '/asset/eye-visible.png' : '/asset/eye-invisible.png'} alt="TogglePasswordVisibility" style={{ width: '24px' }} />
																</InputAdornment>
															),
															style: {
																border: Password === "" && Submitclicked === true ? "1px solid red" : "transparent",
															}
														}}
														{...register('Password', { required: true })}
													/>


												</div>

												{(errors?.Password?.type === "required") &&
													<div className={"errormsg"}>Password cannot be empty</div>}

												{validcred === false && Password !== "" && errors?.EmailAddress?.type !== "required" &&
													<div className={"errormsg"} translate="no">Incorrect Email Address / Password</div>
												}
											</div>
										</div>
									}
								/>
								<Typography className={"inputlabel"} sx={{ cursor: 'pointer' }}><br />
									<span style={{ borderBottom: "1px solid black" }} onClick={handleOpenModal}>Forgot My Password</span>
								</Typography>
								{/* Dialog for Forgot My Password */}
								<Dialog
									open={isModalOpen}
									// onClose={handleCloseModal}
									aria-labelledby="forgot-password-dialog-title"
									className="AgentLogincontainer"
									container={() => document.getElementById('right-grid-container')}
									sx={{ // add this style to position the dialog
										position: 'absolute',
										top: '50%',
										// right: '50px', // adjust this value to position the dialog relative to the right grid
										transform: 'translateY(-50%)',
										height: '100%',
										margin: 0,

									}}

								>
									<IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: 8, right: 8, }}>
										<CloseIcon />
									</IconButton>
									<br />
									<br />
									<DialogTitle id="forgot-password-dialog-title" sx={{ textAlign: 'left', marginLeft: '10px', marginBottom: '10px' }} className="boxcontent" >
										Verify It's You!
									</DialogTitle>
									<DialogContent>
										<Typography variant="body1" sx={{ marginLeft: '10px' }}>
											A code has been sent to your email address. Please click "Okay" to reset your password.
										</Typography>
									</DialogContent>
									<DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
										<Button variant='contained' className="popcustombutton" onClick={handleOKButton} >
											Okay!
										</Button>
									</DialogActions>
									<br />
								</Dialog>


								<Button
									variant="contained"
									aria-label="LogIn"
									type="submit"
									className={(EmailAddressdefault === "" || Password === "" || errors?.EmailAddress?.type === "pattern") ? "submitbtndisabled" : "submitbtn"}
									translate="no"
									id="submitButton"
								>
									Login
								</Button>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Grid>


		</Box>
	)
}

export default AgentLogin;
