import React from "react";
import Box from '@mui/material/Box';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState, useRef } from "react";


function Bargraph({ barchartData }) {
	const chartRef = useRef(null);
	const myChartRef = useRef(null);

	useEffect(() => {
		if (!barchartData || !barchartData.datasets || barchartData.datasets.length === 0) {
			return; // Exit if barchartData is not available yet
		  }
		setTimeout(() => {
			const ctx = chartRef.current.getContext('2d');

			// Destroy the previous chart instance if it exists
			if (myChartRef.current) {
				myChartRef.current.destroy();
			}
			
			// Chart.register(ChartDataLabels);
		

			// Create the new chart instance
			myChartRef.current = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: [barchartData.labels],
					datasets: [
						{
							label: barchartData.datasets[0].label,
							data: [barchartData.datasets[0].data],
							backgroundColor: "#156082",
							borderColor: "#156082",
							borderWidth: 1,
						},
						{
							label: barchartData.datasets[1].label,
							data: [barchartData.datasets[1].data],
							backgroundColor: '#E97132',
							borderColor: '#E97132',
							borderWidth: 1,
						},
						{
							label: barchartData.datasets[2].label,
							data: [barchartData.datasets[2].data],
							backgroundColor: '#196B24',
							borderColor: '#196B24',
							borderWidth: 1,
						},
					],
				},
				options: {
					responsive: true,
					maintainAspectRatio: false, // Allow chart to fill the container
					plugins: {
						legend: {
							display: true,
							align: 'start',
							position: 'bottom',
							labels: {
								padding: 20,
								color: '#5a5a5a' // Set the legend labels color to white

							}
						},
						datalabels: {
							color: 'white', // Set the data labels color to white
						}
					},

					scales: {
						y: {
							beginAtZero: true,
							// max: 40,
						},
					},
				},
				plugins: [ChartDataLabels]
			});
		}, 500);
	}, [barchartData]);



	return (
		<div style={{ height: "100%", width: "100%" }}>
			<canvas ref={chartRef} style={{ maxHeight: '100%', maxWidth: '100%'}}/>
		</div>


	)
}

export default Bargraph;

