import React, { useState, useEffect } from 'react';

import MyAgent from "../MyAgent/MyAgent";
import Preferences from "../Profile/Preferences/Preferences";
import MyDoctor from "../MyDoctor/MyDoctor";
import ProfileView from "../Profile/ProfileView";
import PlanInformation from "../PlanInformation/PlanInformation";
import Sidebar from "../../../Shared/SideBarMember/Sidebar"
import Header from '../../../Shared/HeaderMember/Header';
import MemberEditprofile from "../../../Components/Members/Profile/EditProfile/MemberEditprofile"


function Layoutcomponent(props) {
	const [sidebarOpen, setSideBarOpen] = useState(false);

	const handleViewSidebar = () => {
		setSideBarOpen(!sidebarOpen);
	};


	return (
		<div>
			<Header isOpen={sidebarOpen}></Header>
			<Sidebar onClick={handleViewSidebar} isOpen={sidebarOpen}></Sidebar>
			<div className={sidebarOpen ? 'ComponentMainContainerclose' : 'ComponentMainContainer'} id="maincomponentdiv">
				
					{ props.component === "ProfileView" && <ProfileView /> }

					{ props.component === "MemberEditprofile" && <MemberEditprofile/> }

					{ props.component === "Preferences" && <Preferences /> }

					{ props.component === "PlanInformation" && <PlanInformation/> }

					{ props.component === "MyDoctor" && <MyDoctor /> }
					
					{ props.component === "MyAgent" && <MyAgent /> }
			</div>
		</div>
	)
}

export default Layoutcomponent;