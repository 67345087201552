import React, { useState } from "react";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from 'react-router-dom';

import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding"
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import Apiservice from "../../../../Services/Apiservice";
import { apiurl } from "../../../../Environment/Environment";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";

function ProfileInformation() {
    const isBrowser = () => typeof window !== "undefined";
    const [progress, setProgress] = React.useState(0);
    const Navigate = useNavigate();
	const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [ProfileInformationapiData, setProfileInformationapiData] = useState("");
	const [apiPhoneNumber, setapiPhoneNumber] = React.useState("");

	let currentPath = location.pathname;

    // localisation data
    let language;
    if (isBrowser() === true) {
        language = localStorage.getItem("Preferredlanguage");
    }

    let Profileinformation = CustomLanguageservice.getMethod("Profile information", language);
    let text1 = CustomLanguageservice.getMethod("Let's make sure we have your correct contact information", language);
    let text2 = CustomLanguageservice.getMethod("Is this information accurate", language);
    let Address = CustomLanguageservice.getMethod("Address", language);
    let Phone = CustomLanguageservice.getMethod("Phone", language);
    let Email = CustomLanguageservice.getMethod("Email", language);
    let EverythingisCorrect = CustomLanguageservice.getMethod("Everything is Correct", language);
    let EditProfileinformation = CustomLanguageservice.getMethod("Edit Profile Information", language);



    useEffect(() => {
        setProgress((100 / 8) * 1);

        // profile api
        setIsLoading(true);
        let endpoint = "/user/get_user_info";
        let url = apiurl + endpoint;
        let apidata= {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
            setIsLoading(false);
            setProfileInformationapiData(response.data.data);
			let number;
			// debugger
			if(response.data.data.phone === "" || response.data.data.phone === null) {
				setapiPhoneNumber(response.data.data.phone);
			}
			else{
				setapiPhoneNumber(response.data.data.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'));
			}

        })
            .catch(error => {
				setIsLoading(false);
                console.error('There was an error!', error);
            })

    }, []);


    function EditProfile() {
        Navigate('/EditProfileInformation');
    }

    function Correct() {
        Navigate('/AdressInformation');
		localStorage.setItem("routefrom", "/ProfileInformation")
    }

    return (

        <Box className={"Profilecontainer"}>
            {isLoading &&
                <CommoncircularProgress></CommoncircularProgress>
            }

            <HeaderOnBoarding />
            <Grid container xs={12} className={"bodygrid_profile"} >

                <Grid xs={12} >
                    <div className={"Profileinfo"} tabIndex="0"><br></br>
                        {Profileinformation}
                    </div>

                </Grid>

                <Grid xs={12} > <br></br>
                    <LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
                    {/* <img src="/asset/progress1.png" alt="Progressbar" style={{ width: "100%", height: "14px", objectFit: "cover" }} /> */}
                </Grid>


                <Grid xs={12} > <br></br>
                    {/* <div className="text1_profile">
                        {text1}.
                    </div> */}
                    <div className={"text2_profile"} tabIndex="0">
                        {text2}?
                    </div>
                </Grid>




                <Grid xs={12}><br></br>
                    <div className={"informationbox"}>
                        <div className={"namelabel"} tabIndex="0"> <br></br>
                            {ProfileInformationapiData.first_name}   {ProfileInformationapiData.last_name}
                        </div>

                        <div className={"namelabel1"} tabIndex="0"> <br></br>
                            {Address}
                        </div>

                        <div className={"namelabel2"} tabIndex="0">
                            {ProfileInformationapiData.billing_street}
                        </div>
						<div className={"namelabel2"} tabIndex="0">
                            {ProfileInformationapiData.billing_city} {ProfileInformationapiData.billing_state} {ProfileInformationapiData.billing_zip}
                        </div>

                        <div className={"namelabel1"} tabIndex="0"> <br></br>
                            {Phone}
                        </div>

                        <div className={"namelabel2"} tabIndex="0">
                            {apiPhoneNumber}
                        </div>

                        <div className={"namelabel1"} tabIndex="0"> <br></br>
                            {Email}
                        </div>

                        <div className={"namelabel2"} tabIndex="0">
						{ProfileInformationapiData.email === "" ?
						(<>-</>):(<>{ProfileInformationapiData.email}</>)
										}
                            {/* {ProfileInformationapiData.email} */}
                        </div>

                        <div>
                            <br></br>
                        </div>


                    </div>
					<br></br>

                </Grid>


            </Grid >

            <Grid container>
                <Grid xs={12} lg={6}>
                    <Button variant="contained" aria-label="EverythingisCorrect" type="submit" className={"Correctbtn"} onClick={() => Correct()}
                    >{EverythingisCorrect}</Button>
                </Grid>

                <Grid xs={12} lg={6}>
                    <Button variant="contained" aria-label="EditProfileinformation" type="submit" className={"EditProfilebtn"} onClick={() => EditProfile()}
                    >{EditProfileinformation}</Button>
                </Grid>
            </Grid>

        </Box>
    )

}


export default ProfileInformation
