export const ROUTES_REGISTER = "/Register"
export const ROUTES_LOGIN = "/Login";
export const ROUTES_WELCOME = "/Welcome";
export const ROUTES_PROFILE_INFORMATION = "/ProfileInformation";
export const ROUTES_EDIT_PROFILE = "/EditProfileInformation";
export const ROUTES_ADDRESS_INFORMATION = "/AdressInformation";
export const ROUTES_CONTACT_INFORMATION = "/ContactInformation";
export const ROUTES_PREFERRED_CHANNEL = "/PreferredChannel";
export const ROUTES_PREFERRED_FREQUENCY = "/PreferredFrequency";
export const ROUTES_LANGUAGE_CONFIRMATION = "/LanguageConfirmation";
export const ROUTES_THANK_YOU = "/thank-you";
export const ROUTES_MEMBER_LOGIN = "/member-login";
export const ROUTES_MEMBER_PROFILE ="/member-profile";
export const ROUTES_MEMBER_EDIT_PROFILE ="/member-profile/edit";
export const ROUTES_MEMBER_PREFERENCES ="/member-preferences"
export const ROUTES_MEMBER_PLAN_INFORMATION ="/member-plan-information";
export const ROUTES_MEMBER_MYDOCTOR ="/member-mydoctor";
export const ROUTES_MEMBER_AGENT ="/member-myAgent";
export const ROUTES_SURVEY_WELCOME = "/survey-welcome";
export const ROUTES_PROMOTION_WELCOME = "/promotion-welcome";
export const ROUTES_SURVEY_SURVEYTEMPLATE ="/survey-surveytemplate";
export const ROUTES_SURVEY_THANK_YOU = "/survey-thank-you";

export const ROUTES_AGENT_LOGIN = "/agent-login";
export const ROUTES_AGENT_FORGOT_PASSWORD = "/agent-forgotpassword";
export const ROUTES_CAMPAIGN_DETAILS ="/agent-campaigndetails";
export const ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS ="/agent-AdministratorNotifications";
export const ROUTES_AGENT_REPORTS ="/agent-Reports";
// export const ROUTES_AGENT_CAMPAIGN_HISTORY = "";

