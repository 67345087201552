import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useLocation } from 'react-router-dom';

import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import { apiurl } from "../../../../Environment/Environment";
import Apiservice from "../../../../Services/Apiservice";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import { ROUTES_THANK_YOU } from "../../../../Constants/routes";
import SuccessPopup from "../../../../Shared/Popups/SuccessPopup/SuccessPopup";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import CommonService from "../../../../Services/CommonService";


function LanguageConfirmation() {
	const isBrowser = () => typeof window !== "undefined";
	const navigate = useNavigate();
	const [progress, setProgress] = React.useState(0);
	const [Langauge, setLangauge] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [changeflag, setChangeflag] = useState(false);

	const location = useLocation();

	let currentPath = location.pathname;

	// localisation data
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}
	let CommunicationPreference = CustomLanguageservice.getMethod("Communication Preference", language);
	let LanguageConfirmation = CustomLanguageservice.getMethod("Language Confirmation", language);
	let Next = CustomLanguageservice.getMethod("Next", language);
	let Shouldwecontinuetomessageyouin = CustomLanguageservice.getMethod("Should we continue to message you in", language);
	let MessagemeintheselectedLanguage = CustomLanguageservice.getMethod("Message me in the selected Language", language);


	let dropdowndata = CommonService.getLanguage();

	const [Langaugeid, setLangaugeid] = React.useState("");
	const [Langaugevalue, setLangaugevalue] = React.useState("");

	useEffect(() => {
		setProgress((100 / 8) * 7);
		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
		})
		for (let i = 0; i < dropdowndata.length; i++) {
			if (dropdowndata[i].id === parseInt(language)) {
				setLangaugeid(dropdowndata[i].id);
				setLangaugevalue(dropdowndata[i].value)
			}
		}
	}, []);

	const handleChange = (event) => {
		setChangeflag(true)
		setLangaugeid(event.target.value);
		for (let i = 0; i < dropdowndata.length; i++) {
			if (dropdowndata[i].id === event.target.value) {
				setLangaugevalue(dropdowndata[i].value)
			}
		}
	};

	function back() {
		navigate('/PreferredFrequency');
	}


	function nextButtonClick() {

		// api call
		let endpoint = "/user/save_comm_preferences"
		let url = apiurl + endpoint;
		let apidata = {
			"pref_language": Langaugeid,
			"route_name": "/thank-you",
		}
		setIsLoading(true);
		Apiservice.putMethod(url, apidata).then(response => {
			setIsLoading(false);
			localStorage.removeItem("Lastpathbeforeexit");
			localStorage.setItem("Preferredlanguage", Langaugeid)
			let MessagemeintheselectedLanguage1 = CustomLanguageservice.getMethod("Message me in the selected Language", Langaugeid.toString());
			let LanguageConfirmation = CustomLanguageservice.getMethod("Language Confirmation", Langaugeid.toString());
			let data1 = LanguageConfirmation;
			let data2 = "";
			let data3 = "";
			let data4 = Langaugevalue
			// let data6 = 
			let data6 = ""
			const jsonData =
			{
				componentname: "Language Confirmation",
				image: "/asset/tick.png",
				datatitle: "",
				heading1: data1,
				heading2: data2,
				heading3: data3,
				heading4: data4,
				heading5: "",
				heading6: data6,

			};

			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			if (changeflag === true){
				setIsModalOpen(true);
			setTimeout(() => {
				setIsModalOpen(false);
				navigate(ROUTES_THANK_YOU);
			}, 3000);
			}
			else{
				navigate(ROUTES_THANK_YOU);
			}

		})
			.catch(error => {
				setIsLoading(false);
				setIsModalOpen(false);
			})
	}



	return (
		<Box className={"LanguageConfirmation"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />

			<SuccessPopup
				isOpen={isModalOpen}
				title="User Modal Title"
				description="This is the user modal description."
			/>

			<Grid container xs={12} className={"bodygrid_LanguageConfirmation"}>
				<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }}>  <br></br>
					<img src="/asset/leftarrow.png" alt="leftarrow" tabIndex="0" onClick={() => back()} 
					onKeyDown={(e) => {
						if (e.key === "Enter") {
						back();
						}
						}} />
				</Grid>

				<Grid xs={11} >  <br></br>
					<div className={"Profileinfo"} tabIndex="0">
						{CommunicationPreference}
					</div>

				</Grid>

				<Grid xs={12} > <br></br>
					<LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
				</Grid>

				{/* <Grid xs={12} > <br></br>
					<div className="textheading">
						{LanguageConfirmation}
					</div>
				</Grid> */}

				<Grid xs={12} > <br></br>
					<div className="text1_profile" tabIndex="0">
						{Shouldwecontinuetomessageyouin} {Langaugevalue}?
					</div>
				</Grid>

				<Grid xs={12} ><br></br>
					<FormControl>
						{/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={Langaugeid}
							// label="Age"
							className={"Drpdownselect"}
							onChange={handleChange}
						>
							{dropdowndata.map((data, index) => (
								<MenuItem key={index} value={data.id} sx={{ height: "60px" }}>{data.value}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>


				<Grid xs={12}>
					<div>
						<Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"} onClick={() => nextButtonClick()}
						>{Next}</Button>
					</div>
				</Grid>



			</Grid>
		</Box>
	)
}


export default LanguageConfirmation
