import React, { useEffect, useState } from "react";
import "./SingleSelectionTemplate.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import Textarea from '@mui/joy/Textarea';

import ButtonComponent from "../../../../Shared/Commonfields/ButtonComponent";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import { languageId } from "../../../../Constants/static";


function SingleSelectionTemplate({ data, NextQuestion, BackTracking, surveyname, Next }) {

	const isBrowser = () => typeof window !== "undefined";

	const [optionselected, setoptionselected] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [nextqn, setNextQn] = useState("")
	const [otheranswertext, setOtheranswertext] = useState("");


	const member_id = localStorage.getItem("Survey_member_id")
	const campaign_id = localStorage.getItem("Survey_campaign_id")
	const language_code = localStorage.getItem("Survey_language_code")
	const Survey_reference=  localStorage.getItem("Survey_reference")
	const Survey_reference_order = localStorage.getItem("Survey_reference_order")

	const questionData = data;

	let language
	if (isBrowser() === true) {
		language = languageId[language_code]
	}

	let Pleasespecify = CustomLanguageservice.getMethod("Please specify", language)

	useEffect(() => {
		// Find the selected answer
		const selectedAnswer = questionData.answers.find(answer => answer.is_selected);
		setNextQn(selectedAnswer)
		// If a selected answer is found, set optionselected to its answer_id
		if (selectedAnswer) {
			setoptionselected(selectedAnswer.answer_id);
		}
		else {
			setoptionselected("")
		}
	}, [questionData]);

	const optionButtonClick = (data, index) => {
		setNextQn(questionData.answers[index])
		setoptionselected(data.answer_id);
		if(data.answer_type_id === "SSI" || data.answer_type_id == "CAI" || data.answer_type_id == "YNI"){
			setOtheranswertext(null)
		}
		else {
			setOtheranswertext("")
		}
	}

	const handletextareavalue = (event) => {
		setOtheranswertext(event.target.value);
		if(event.target.value.trim() === "") {
			setOtheranswertext(null)
		}
	}

	const submitButtonClick = () => {
		let previous_question_id = questionData.previous_question_id
		previous_question_id = previous_question_id === "" ? 0 : parseInt(previous_question_id)



		let NextQnPayload = {
			"member_id": member_id,
			"language_code": "en",
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": questionData.question_id,
			"answers": [
				{
					"answer_id": nextqn.answer_id,
					"next_question_id": nextqn.next_question_id !== null ? nextqn.next_question_id : 0,
					"future_question_id": nextqn.future_question_id !== null ? nextqn.future_question_id : 0,
					"other_answer_text": otheranswertext,
					"save_futre_text": JSON.stringify(questionData.question),
					"future_questions": nextqn.future_questions !== null ? nextqn.future_questions : [],
					"copay_future_id": nextqn.hasOwnProperty('copay_future_id') ? nextqn.copay_future_id : 0,
					"negative_question_id": nextqn.negative_question_id
				}
			],
			"previous_question_id": previous_question_id
		}

		NextQuestion(NextQnPayload);
	}

	const PreviousQuestion = () => {
		let previous_question_id = questionData.previous_question_id
		let PreviousQnPayload = {
			"member_id": member_id,
			"language_code": language_code,
			"campaign_id": campaign_id,
			"reference":Survey_reference,
			"reference_order":Survey_reference_order,
			"question_id": parseInt(previous_question_id)
		}

		if (previous_question_id !== "" && previous_question_id !== 0 && previous_question_id !== undefined) {
			BackTracking(PreviousQnPayload)
		}


	}

	return (
		<Box className={"SurveyTemplate1container"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />
			<Grid container xs={12} className={"Survey_bodygrid_Template1"}>
				{questionData.previous_question_id !== "" && questionData.previous_question_id !== 0 && questionData.previous_question_id !== undefined &&
					<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }} >
						<img src="/asset/leftarrow.png" alt="leftarrow" tabIndex="0" onClick={() => PreviousQuestion()} 
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								PreviousQuestion();
							}
						  }}/>
					</Grid>
				}

				<Grid xs={11} >
					<div className={"Heading"}>
						{surveyname}
					</div>

				</Grid>


				<Grid xs={12} > <br></br><br></br>
					<div className={"questiontext"} tabIndex="0">
						<span dangerouslySetInnerHTML={{ __html: questionData.question[language_code] }} />
						{/* {questionData.question} */}
					</div>

				</Grid>

				<Grid xs={12} lg={12}><br></br><br></br>
					{questionData.answers.map((data, index) => (
						<div key={index}>
							<ButtonComponent
								id="submitID"
								variant="contained"
								arialabel={data.answer_text[language_code]}
								type="submit"
								onClick={() => optionButtonClick(data, index)}
								className={optionselected === data.answer_id ? "optionsselectedbtn" : "optionsbtn"}
								buttonname={data.answer_text[language_code]}
							/>
							{optionselected === data.answer_id && (data.answer_type_id === "SSI" || data.answer_type_id == "CAI" || data.answer_type_id == "YNI") &&
								<Textarea placeholder = {data.placeholder !== "" ?  data.placeholder[language_code] : Pleasespecify + "...."}
									className={"textarea"}
									defaultValue={data.other_answer_text}
									// onKeyUp={(event) => handletextareavalue(event)}
									onChange={(event) => handletextareavalue(event, data)}
								/>
							}

						</div>
					))}

				</Grid>

				<Grid xs={12} lg={12}><br></br><br></br>
					<ButtonComponent
						id="submitID"
						variant="contained"
						arialabel="Next"
						type="submit"
						onClick={() => submitButtonClick("")}
						className={optionselected !== "" && otheranswertext !== null ? "Nextbtn" : "disablebtn"}
						buttonname={Next}
					/>

				</Grid>


			</Grid>

		</Box>
	)
}

export default SingleSelectionTemplate;

