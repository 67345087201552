import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { AppBar, Box, Button, Card, CardActions, CardContent, Grid, Skeleton, Toolbar, Typography } from "@mui/material";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";

import { URL } from "../../../Constants/apiUrl";
import Apiservice from '../../../Services/Apiservice';
import "./ProfileView.css";
import CustomLanguageservice from '../../../Services/CustomLanguageservice';
import MemberEditprofile from "./EditProfile/MemberEditprofile";
import { ROUTES_MEMBER_EDIT_PROFILE } from "../../../Constants/routes";
import { isEmpty, isNull } from "../../../Utilities/utils";
import ButtonComponent from "../../../Shared/Commonfields/ButtonComponent";

const ProfileView = () => {
	const isBrowser = () => typeof window !== "undefined";

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [profileInformationData, setProfileInformationData] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [cellPhone, setCellPhone] = useState("");

	let language;
	if (isBrowser() === true) language = localStorage.getItem("Preferredlanguage");

	let profileLabel = CustomLanguageservice.getMethod("Profile", language);
	let firstNameLabel = CustomLanguageservice.getMethod("First Name", language);
	let lastNameLabel = CustomLanguageservice.getMethod("Last Name", language);
	let phoneLabel = CustomLanguageservice.getMethod("Phone", language);
	let landlineLabel = CustomLanguageservice.getMethod("Landline", language);
	let alternateNumberLabel = CustomLanguageservice.getMethod("Alternate Number", language);
	let cellPhoneLabel = CustomLanguageservice.getMethod("Cell Phone", language);
	let billingAddressLabel = CustomLanguageservice.getMethod("Billing Address", language);
	let mailingAddressLabel = CustomLanguageservice.getMethod("Mailing Address", language);
	let emailLabel = CustomLanguageservice.getMethod("Email", language);
	let editProfileBtnLabel = CustomLanguageservice.getMethod("Edit Profile Information", language);
	let Done_text = CustomLanguageservice.getMethod("Done", language);


	useEffect(() => {
		setIsLoading(true);
		let endpoint = URL.MEMBER_PROFILE_INFO;
		let apidata = {
			"route_name": ""
		}
		Apiservice.postMethod(endpoint, apidata).then(response => {

			setProfileInformationData(response.data.data);

			if (response.data.data.phone === "" || response.data.data.phone === null) setPhoneNumber("-");
			else {
				setPhoneNumber(
					response.data.data.phone.replace(
						/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'
					) + " " + (
						response.data.data.is_landline === "true" ? "(" + landlineLabel + ")" : "(" + cellPhoneLabel + ")"
					)
				);
			}

			if (response.data.data.is_landline === "true") {
				if (response.data.data.mobile === "" || response.data.data.mobile === null) setCellPhone("-");
				else setCellPhone(response.data.data.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') + " (" + cellPhoneLabel + ")");
			}
			else {
				setCellPhone("-");
			}
			setIsLoading(false);
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})

	}, []);

	const profileInfo = {
		"lable": {
			mt: {
				lg: '15px',
				xl: '15px',
			}
		},
		"cardBody": {
			border: 0,
			backgroundColor: "transparent",
			m: {
				xs: "0vh 6vw 0 9vw",
				sm: "0vh 8vw 0 10vw",
				md: "10vh 13vw 0 13vw",
				lg: "10vh 14vw 0 14vw",
				xl: "12vh 17vw 0 17vw",
			}
		}
	};

	const editProfile = () => { navigate(ROUTES_MEMBER_EDIT_PROFILE); }

	const skeletonLoad = (value) => {
		return isLoading ? <Skeleton animation="wave" /> : value
	}

	const submitButtonClick = (data) => {
		sessionStorage.removeItem("isnavigated");
		window.location.reload();
	}

	return (

		// <div className={"ProfileContainerMember"} onClick={editProfile}>
		// 	edit button {/* <MemberEditprofile /> */}
		// </div>

		<Box display="flex" justifyContent="center" className={"PlanInformationContainerqq"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container className="profileViewContainer">
				<Grid item xs={12} className='viewSection'>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ p: "0 !important" }}>
							<AppBar position="static" sx={{ backgroundColor: "#DADADA", color: "#000" }}>
								<Toolbar className='toolBar' sx={{ minHeight: "48px !important" }}>
									<Typography variant="h6" component="div" sx={{ flexGrow: 1, mt: "14px", fontSize: "var(--fontsizemedium)" }} 
									tabIndex="0" aria-label={profileLabel}>
										<strong >{profileLabel}</strong>
									</Typography>
								</Toolbar>
							</AppBar>

							<Card variant="outlined" sx={profileInfo.cardBody}>
								<CardContent sx={{ backgroundColor: "#DADADA", color: "#000", m: "8px", borderRadius: "10px" }}>
									<Grid container spacing={3} className='profileDetailsSection'>
										<Grid item xs={12} md={6} lg={6} xl={6}>
											<Typography  align="left" className='namelabel' tabIndex="0" aria-label={firstNameLabel}>
												<strong>{firstNameLabel}</strong>
											</Typography>
											<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel' tabIndex="0" aria-label={profileInformationData.first_name}>
												{skeletonLoad(profileInformationData.first_name)}
											</Typography>
										</Grid>

										<Grid item xs={12} md={6} lg={6} xl={6}>
											<Typography align="left" className='namelabel' tabIndex="0"  aria-label={lastNameLabel}>
												<strong>{lastNameLabel}</strong>
											</Typography>
											<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel' tabIndex="0"  aria-label={profileInformationData.last_name}>
												{skeletonLoad(profileInformationData.last_name)}
											</Typography>
										</Grid>

										<Grid item xs={12} md={6} lg={6} xl={6} sx={profileInfo.lable}>
											<Typography  align="left" className='namelabel' tabIndex="0"  aria-label={phoneLabel}>
												<strong>{phoneLabel}</strong>
											</Typography>
											<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel' tabIndex="0"  aria-label={phoneNumber}>
												{skeletonLoad(phoneNumber)}
											</Typography>
										</Grid>

										<Grid item xs={12} md={6} lg={6} xl={6} sx={profileInfo.lable}>
											<Typography  align="left" className='namelabel' tabIndex="0"  aria-label={alternateNumberLabel}>
												<strong>{alternateNumberLabel}</strong>
											</Typography>
											<Typography variant="body1" align="left" sx={{ m: "0 !important" }} 
											    className='namelabel' tabIndex="0"  aria-label={cellPhone}>
												{skeletonLoad(cellPhone)}
											</Typography>
										</Grid>

										{/* Billing Address is here */}
										<Grid item xs={12} md={6} lg={6} xl={6} sx={profileInfo.lable}>
											<Typography align="left" className='namelabel' tabIndex="0"  aria-label={billingAddressLabel}>
												<strong>{billingAddressLabel}</strong>
											</Typography>

											{!isEmpty(profileInformationData.billing_street) ?
												<>
													<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel' tabIndex="0"  aria-label={profileInformationData?.billing_street}>
														{skeletonLoad(profileInformationData?.billing_street + ",")}
													</Typography>
													<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'
													tabIndex="0"  aria-label={profileInformationData?.billing_city + ", " + profileInformationData?.billing_state + " " + profileInformationData?.billing_zip}>
														{skeletonLoad(profileInformationData?.billing_city + ", " + profileInformationData?.billing_state + " " + profileInformationData?.billing_zip)}
													</Typography>
												</>
												:
												<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'>
													-
												</Typography>
											}
										</Grid>

										{/* Mailing Address is here */}
										<Grid item xs={12} md={6} lg={6} xl={6} sx={profileInfo.lable}>
											<Typography  align="left" className='namelabel' tabIndex="0"  aria-label={mailingAddressLabel}>
												<strong>{mailingAddressLabel}</strong>
											</Typography>

											{!isEmpty(profileInformationData.billing_street) ?
												(profileInformationData.is_same_address === "true" ?
													<>
														<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'
														tabIndex="0"  aria-label={profileInformationData.billing_street}>
															{skeletonLoad(profileInformationData.billing_street + ",")}
														</Typography>
														<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'
														tabIndex="0"  aria-label={profileInformationData.billing_city + ", " + profileInformationData.billing_state + " " + profileInformationData.billing_zip}>
															{skeletonLoad(profileInformationData.billing_city + ", " + profileInformationData.billing_state + " " + profileInformationData.billing_zip)}
														</Typography>
													</>
													:
													(!isEmpty(profileInformationData.mailing_street) ?
														<>
															<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'
															tabIndex="0"  aria-label={profileInformationData.mailing_street}>
																{skeletonLoad(profileInformationData.mailing_street + ",")}
															</Typography>
															<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'
															tabIndex="0"  aria-label={profileInformationData.mailing_city + ", " + profileInformationData.mailing_state + " " + profileInformationData.mailing_zip}>
																{skeletonLoad(profileInformationData.mailing_city + ", " + profileInformationData.mailing_state + " " + profileInformationData.mailing_zip)}
															</Typography>
														</>
														:
														<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'>
															-
														</Typography>
													)
												)
												:
												<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'>
													-
												</Typography>
											}
										</Grid>

										<Grid item xs={12} md={6} lg={6} xl={6} sx={profileInfo.lable}>
											<Typography  align="left" className='namelabel' 
											tabIndex="0"  aria-label={emailLabel}>
												<strong>{emailLabel}</strong>
											</Typography>
											<Typography variant="body1" align="left" sx={{ m: "0 !important" }} className='namelabel'
											tabIndex="0"  aria-label={profileInformationData.email}>
												{isLoading ?
													<Skeleton animation="wave" /> :
													(!isNull(profileInformationData.email) && !isEmpty(profileInformationData.email) ?
														profileInformationData.email : "-"
													)
												}
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions className='cardAction'>
									<Button variant="contained" fullWidth aria-label="Edit Profile Information" type="button" onClick={editProfile}>
										<strong>{editProfileBtnLabel}</strong>
									</Button>
								</CardActions>

								<CardActions>
									<ButtonComponent
										id="submitID"
										variant="contained"
										arialabel="Donebtn"
										type="submit"
										onClick={() => submitButtonClick("Some Data")}
										className="Donebtn"
										buttonname={Done_text}
									/>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>

	)
}

export default ProfileView;
