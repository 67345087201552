import React from 'react';
import "./UploadmemberListPopup.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { useState, useRef } from "react";
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../CommoncircularProgress/CommoncircularProgress";
import GlobalSucessPopup from "../GlobalSucessPopup/GlobalSucessPopup";

function UploadmemberListPopup({ isOpen, handleOKButton }) {
	const [file, setFile] = useState(null);
	const fileInputRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	let campaign_code = sessionStorage.getItem("campaign_code");

	const style = {
		position: "absolute",
		top: "43%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "37%",
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		p: 4,

	};
	function handleClickcancel(data) {
		handleOKButton("cancel");
		setFile(null)
	};

	const handleOKButtonsuccess = (data) =>{
		setIsModalOpen(false);
		handleOKButton("");
	}

	const acceptedFileTypes = [
		"text/csv",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	];
	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		const fileTypes = [
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"text/csv",
		];
		if (selectedFile && fileTypes.includes(selectedFile.type)) {
			setFile(selectedFile);
			console.log(selectedFile);
			setError("");
		} else {
			setFile(null);
			setError("Please select a valid CSV or Excel file");
			console.log(error);
		}
	};
	const handleClick = () => {
		document.getElementById("file-input").click();
		console.log(file);
	};
	//// submi file function ////
	const handleSubmitFile = () => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append('file', file);
		formData.append('campaign_code', campaign_code)

		let apiurl = URL.UPLOAD_MEMBERLIST_CSV;
		Apiservice.postMethod(apiurl, formData).then(response => {
			setIsLoading(false);
			setIsModalOpen(true);
			setFile(null)

		})
			.catch(error => {
				setIsLoading(false);
				setFile(null)
				handleOKButton("cancel");
				console.error('There was an error!', error);
			})
	};
	///// drag drop functionaluty /////
	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const droppedFile = e.dataTransfer.files[0];
		if (droppedFile && acceptedFileTypes.includes(droppedFile.type)) {
			setFile(droppedFile);
			console.log(droppedFile);
			setError("");
		} else {
			setFile(null);
			setError("Please select a valid CSV, XLS, or XLSX file");
		}
	};
	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const handleFileInputChange = (e) => {
		const inputFile = e.target.files[0];
		if (inputFile && acceptedFileTypes.includes(inputFile.type)) {
			setFile(inputFile);
			setError("");
		} else {
			setFile(null);
			setError("Please select a valid CSV, XLS, or XLSX file");
		}
	};


	return (
		<div className={"file-upload-container"}>
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className="file-upload-modal-container" sx={style}>
					{isLoading &&
						<CommoncircularProgress></CommoncircularProgress>
					}

					<Button variant="outlined" className="download-file">
						<a href="/asset/Agent/member-upload.csv" download className="hyper-link">
							Download Sample
						</a>
					</Button>
					<Button className="close-file-upload-btn">
						<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="10px" height="10px" onClick={handleClickcancel} />
					</Button>

					<div className="inner-file-upload-container">
						<div
							style={{
								padding: "10px",
								textAlign: "center",
								cursor: "pointer",
							}}
							className="all-btns-container"
							onDrop={handleDrop}
							onDragOver={handleDragOver}
						>

							<p className="drag-drop-info-banner">Drag and Drop here</p>

							<p className="or-info-banner">or</p>
							<input
								type="file"
								accept=".csv, .xls, .xlsx"
								style={{ display: "none" }}
								onChange={handleFileInputChange}
							/>
							{/*  */}
							<input
								type="file"
								id="file-input"
								accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								onChange={handleFileChange}
								style={{ display: "none" }}
							/>
							<Button className="browse-upload-btn" onClick={handleClick}>
								Browse File
							</Button>
						</div>

					</div>


					{file ? (
						<div className="submit-file-contianer">
							<div className="file-name">{file ? file.name : ""}</div>
							<Button
								className="file-submit-btn"
								variant="outlined"
								onClick={handleSubmitFile}
							>
								Upload
							</Button>
						</div>
					) : (
						""
					)}



					<GlobalSucessPopup
						isOpen={isModalOpen}
						Heading="Success"
						body=""
						btnname1="OK"
						handleOKButton={handleOKButtonsuccess}
					/>

				</Box>

			</Modal>
		</div>
	)

}

export default UploadmemberListPopup