import React, { useState, useEffect, useMemo } from 'react';
import './CountdownTimer.css';

function CountdownTimer({ startDateTime, endDateTime }) {
  const start = useMemo(() => new Date(startDateTime), [startDateTime]);
  const end = useMemo(() => new Date(endDateTime), [endDateTime]);

  const [headerText, setHeaderText] = useState("Campaign will start in");
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const calculateTime = () => {
      const now = new Date();
      const gapStart = (start - now) / 1000;
      const gapEnd = (end - now) / 1000;

      if (gapStart > 0) {
        const days = Math.floor(gapStart / 60 / 60 / 24);
        const hours = Math.floor((gapStart / 60 / 60) % 24);
        const minutes = Math.floor((gapStart / 60) % 60);
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setHeaderText("Campaign will start in");
      } else if (gapEnd > 0) {
        const days = Math.floor(gapEnd / 60 / 60 / 24);
        const hours = Math.floor((gapEnd / 60 / 60) % 24);
        const minutes = Math.floor((gapEnd / 60) % 60);
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setHeaderText("Campaign will end in");
      } else {
        setDays(0);
        setHours(0);
        setMinutes(0);
        setHeaderText("Campaign has ended");
      }
    };

    const timerId = setInterval(calculateTime, 1000);
    return () => clearInterval(timerId);
  }, [start, end]); // Dependency array with memoized values

  const formatNumber = (num) => num.toString().padStart(2, '0');

  return (
    <div className="countdown">
      <h1 className="heading">{headerText}</h1>
      <div className="time-container">
        <div className="time">
          <div>{formatNumber(days)}</div>
          <p><strong>Days</strong></p>
        </div>
        <span className="separator">:</span>
        <div className="time">
          <div>{formatNumber(hours)}</div>
          <p><strong>Hours</strong></p>
        </div>
        <span className="separator">:</span>
        <div className="time">
          <div>{formatNumber(minutes)}</div>
          <p><strong>Minutes</strong></p>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
