// BasicModal.js
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';


const style = {
	position: 'absolute',
	bottom: '0%',
	left: '50%',
	transform: 'translate(-50%, -0%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

function FonthandlePopup({ isOpen, title, description, handleClose, handleOK }) {

	let themeValue = sessionStorage.getItem("ThemeValue")
	if (themeValue === null) {themeValue = "1" }

	function handleClickOK(data) {
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOK(data);
	};

	return (
		<div >
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">

				<Box className={"fontPopupcontainer"}>
					<Grid container className={"bodygrid"}>
						<Grid xs={12} sx={{ color: "red" }} textAlign={"center"}>
						
							<Typography variant='h6' tabIndex="0"
							sx={{ color: themeValue === "1" ? "var(--tertiary)" : "black", fontWeight: '600'  }} onClick={() => handleClickOK(1)} p={2}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleClickOK(1);
								}
							}}>
								Normal</Typography>
							<div className={'linediv'}></div>
							<Typography variant='h5' tabIndex="0"
							sx={{ color: themeValue === "2" ? "var(--tertiary)" : "black" ,fontWeight: '600' }} onClick={() => handleClickOK(2)} p={2}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleClickOK(2);
								}
							}}>
								Larger</Typography>
							<div className={'linediv'}></div>
							<Typography variant='h4'tabIndex="0"
							sx={{ color: themeValue === "3" ? "var(--tertiary)" : "black" ,fontWeight: '600' }} onClick={() => handleClickOK(3)} p={2}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									handleClickOK(3);
								}
							}}>
								Largest</Typography>
						</Grid>

					</Grid>
				</Box>
			</Modal>
		</div>
	);
}

export default FonthandlePopup
