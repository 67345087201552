import "./AdministratorNotifications.css";
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import MemberSnapshotPopup from "../../../Shared/Popups/MemberSnapshotPopup/MemberSnapshotPopup";
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";

const UnresolvedNotificationItem = ({ item, viewResolved }) => {

	const [queryInfo, setQueryInfo] = useState("");
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isMemberSnapshotPopupModalOpen, setisMemberSnapshotPopupModalOpen] = useState(false)
	const [getmemberID, setgetmemberID] = useState("");
	const [itemdata, setitemdata] = useState("")
	const [dropdown, setDropdown] = useState(false);

	const postQuery = () => {
		setIsLoading(true);
		let campaign_code = sessionStorage.getItem("campaign_code")
		const body =
		{
			"member_id": item.member_id.toString(),
			"answer_id": item.answer_id,
			"campaign_code": campaign_code,
			"agent_comment": queryInfo,
			"variant": 0
		}
		let apiurl = URL.UNRESOLVED_NOTIFICATION_SUBMIT;
		Apiservice.postMethod(apiurl, body).then((response) => {
			setIsLoading(false);
			window.location.reload();
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}


	const handleMemberSnapshotOKButton = (value) => {
		setisMemberSnapshotPopupModalOpen(false);
	}

	const handleQuerySubmit = (query) => {
		setQueryInfo(query);
		setIsButtonDisabled(false);
		if (!query) {
			setIsButtonDisabled(true);
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		console.log(queryInfo);
		postQuery()
		setQueryInfo("")
	};

	const buttonStyle = {
		opacity: isButtonDisabled ? "0.5" : "1",
		backgroundColor: isButtonDisabled
			? "rgba(33, 148, 242, 1)"
			: "rgba(33, 148, 242, 1)",
		color: isButtonDisabled ? "white" : "white",
		cursor: isButtonDisabled ? "not-allowed" : "pointer",
	};

	const handleClickName = (item) => {
		setitemdata(item)
		setgetmemberID(item.member_id);
		setisMemberSnapshotPopupModalOpen(true);
	}

		const handleDropdown = ()=>{
			setDropdown(prev => !prev)

		}

	return (
		<div key={item.answer_id} className="unresolved-notification-item">
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<div
				style={{
					display: "flex",
					flexDirection : "column",
					width: "100%",
					// justifyContent: "space-between",
					position: 'relative'
				}}
			>

				<p className="item-infotext">
					<span
						className="member-name-link"
						onClick={() => handleClickName(item)}
					>{item.member_name}</span>{" "}
					<span


						dangerouslySetInnerHTML={{
							__html: `${item.notification_text}`,
						}}
					></span>
				</p>
				<p className="item-date-raised">
					{item.raised_date} {item.raised_time}{" "}
				</p>
				{!dropdown ?
				<img src="/asset/Agent/arrowdowncampaign.png" width="30px" height="30px"className="dropdown" onClick={handleDropdown} /> :
				<img src="/asset/Agent/arrowupcampaign.png" width="30px" height="30px"className="dropdown" onClick={handleDropdown} />
				 }
			</div>
			{dropdown ?
			<form
				className="unresolved-item-form"
				action=""
				method=""
				autoComplete="on"
				onSubmit={handleFormSubmit}
			>
				<TextField
					className="unresolved-item-form-text-field"
					id="outlined-size-normal"
					type="text"
					placeholder="Type your comment"
					value={queryInfo}
					onChange={(e) => handleQuerySubmit(e.target.value)}
				/>
				<Button
					className="unresolved-item-form-submit-btn"
					variant="contained"
					type="submit"
					disabled={isButtonDisabled}
					style={buttonStyle}
				>
					Submit
				</Button>
			</form> : ""}
			<MemberSnapshotPopup
				isOpen={isMemberSnapshotPopupModalOpen}
				handleOKButton={handleMemberSnapshotOKButton}
				from="notification"
				data={getmemberID}
				item={itemdata}
				viewResolved={viewResolved}
			/>
		</div>
	);
};

export default UnresolvedNotificationItem;
