import { ROUTES_MEMBER_PLAN_INFORMATION, ROUTES_MEMBER_PROFILE, ROUTES_MEMBER_MYDOCTOR, ROUTES_MEMBER_PREFERENCES,ROUTES_MEMBER_AGENT } from '../Constants/routes';
import CustomLanguageservice from "./CustomLanguageservice"

	const sidemenuService = {
		getSidebarDatafn: function () {
			const isBrowser = () => typeof window !== "undefined";
			let language;
				if (isBrowser() === true) {
					language = localStorage.getItem("Preferredlanguage");
				}
			
				let PersonalInformation = CustomLanguageservice.getMethod("Personal Information",language);
				let Profile = CustomLanguageservice.getMethod("Profile",language);
				let Preferences = CustomLanguageservice.getMethod("Preferences",language);
				let PlanInformation = CustomLanguageservice.getMethod("Plan Information",language);
				let MyDoctor = CustomLanguageservice.getMethod("My Doctor",language);
				let MyAgent = CustomLanguageservice.getMethod("My Agent",language)
			
			let SidebarData = [
				{
					id: 1,
					title: PersonalInformation,
					path: "",
					icon: "/asset/Member/PersinalInformationwhite.png",
					activeIcon: "/asset/Member/PersinalInformationblack.png",
					subchild: [{
						id: 1,
						title: Profile,
						path: ROUTES_MEMBER_PROFILE,
						icon: "/asset/Member/ProfilWhiteicon.png",
						activeIcon: "/asset/Member/ProfilBlueIcon.png",
						isSelected: false,
					 },
					 {
						id: 2,
						title: Preferences,
						path: ROUTES_MEMBER_PREFERENCES,
						icon: "/asset/Member/preferencesWhiteIcon.png",
						activeIcon: "/asset/Member/preferencesBlueIcon.png",
						isSelected: false,
					 }  
					]
				},
				{
					id: 2,
					title: PlanInformation,
					path: ROUTES_MEMBER_PLAN_INFORMATION,
					icon: "/asset/Member/Planinformationwhite.png",
					activeIcon: "/asset/Member/Planinformationblack.png",
					subchild:[]
				},
				{
					id: 2,
					title: MyDoctor,
					path: ROUTES_MEMBER_MYDOCTOR,
					icon: "/asset/Member/doctorwhite.png",
					activeIcon: "/asset/Member/doctorblack.png",
					subchild:[]
				},
				{
					id: 2,
					title: MyAgent,
					path: ROUTES_MEMBER_AGENT,
					icon: "/asset/Member/myagentwhite.png",
					activeIcon: "/asset/Member/myagentblue.png",
					subchild:[]
				}
			];

			return SidebarData
	}
}

	export default sidemenuService;


