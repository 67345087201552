import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import Apiservice from "../../../Services/Apiservice";
import { apiurl } from "../../../Environment/Environment";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import CommonService from "../../../Services/CommonService";



function Welcome() {
	const isBrowser = () => typeof window !== "undefined";
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const Navigate = useNavigate();
	const [logo, setlogo] = React.useState("");
	const [welcometext1, setWelcometext1] = React.useState("")
	const [welcometext2, setWelcometext2] = React.useState("")
	const [welcometext3, setWelcometext3] = React.useState("")
	const [Lastpathbeforeexit, setLastpathbeforeexit] = React.useState("");
	const [apiCalled, setApiCalled] = useState(false);

	let currentPath = location.pathname;
	// localisation data
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}
	let LetsGetStarted = CustomLanguageservice.getMethod("Let's get started", language);
	let WelcomeBack = CustomLanguageservice.getMethod("Welcome Back", language);
	let Welcome = CustomLanguageservice.getMethod("Welcome", language);
	let KeepGoingtext = CustomLanguageservice.getMethod("Keep Going", language);



	useEffect(() => {
		// Welcome get api call
		setIsLoading(true);
		let apidata = {
			"id": parseInt(language),
		}
		let endpoint = "/welcome_message";
		let url = apiurl + endpoint;
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			setlogo(response.data.logo);
			setWelcometext1(response.data.welcome_message);
			setApiCalled(true);
			if (response.data.onboarding_status.onboarding === true) {
				setLastpathbeforeexit(response.data.onboarding_status.route_name)
			}
			else {
				setLastpathbeforeexit("");
			}

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}, []);

	function KeepGoing() {
		Navigate(Lastpathbeforeexit);
	}


	function LetsGetStartedBtn() {

		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			let apidata = response.data;
			let isEmpty = false;
			// Check if value is (empty string, null)
			if (apidata.data.billing_city === "" || apidata.data.billing_city === null || typeof apidata.data.billing_city === "undefined"
				|| apidata.data.first_name === "" || apidata.data.first_name === null || typeof apidata.data.first_name === "undefined"
				|| apidata.data.last_name === "" || apidata.data.last_name === null || typeof apidata.data.last_name === "undefined"
				|| apidata.data.phone === "" || apidata.data.phone === null || typeof apidata.data.phone === "undefined"
				|| apidata.data.billing_state === "" || apidata.data.billing_state === null || typeof apidata.data.billing_state === "undefined"
				|| apidata.data.billing_street === "" || apidata.data.billing_street === null || typeof apidata.data.billing_street === "undefined"
				|| apidata.data.billing_zip === "" || apidata.data.billing_zip === null || typeof apidata.data.billing_zip === "undefined"
			) {
				isEmpty = true;
			}


			if (isEmpty) {
				Navigate('/EditProfileInformation');
				localStorage.setItem("containemptydata", "true")
				
			} else {
				Navigate('/ProfileInformation');
				localStorage.setItem("containemptydata", "false")
			}

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})







	}


	return (
		<Box className={"Welcomecontainer"} >
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}

			<Grid container>

				<Grid xs={12} lg={6} className={"headerGrid"}>
					<div className={"headingimgdiv"}>
						<img src={logo} alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} tabIndex="0" />
					</div>

				</Grid>


				<Grid xs={12} lg={6} className={"bodygrid"}> <br></br>
					{Lastpathbeforeexit === "" || Lastpathbeforeexit === null ?
						<div>
							<div tabIndex="0" className={"welcometext"}>{Welcome}, {welcometext1}</div>  <br></br>

							{/* <div className={"welcometext"}>{welcometext2}</div><br></br>

							<div className={"welcometext"}>{welcometext3}</div> */}
						</div>
						:
						<div className={"welcometext"} tabIndex="0">
							{WelcomeBack},  {welcometext1}
						</div>
					}
				</Grid>




				<Grid xs={12}>
					{Lastpathbeforeexit === "" && apiCalled === true  ?
						<div>
							<Button variant="contained" aria-label="LetsGetStarted" type="submit" className={"submitbtn"} onClick={() => LetsGetStartedBtn()}
							>{LetsGetStarted}!</Button>
						</div>
						:
						<div>
							<Button variant="contained" aria-label="LetsGetStarted" type="submit" className={"submitbtn"} onClick={() => KeepGoing()}
							>{KeepGoingtext}</Button>
						</div>
					}
				</Grid>



			</Grid>
		</Box>
	)
}



export default Welcome