import React from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from "react";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import HeaderOnBoarding from "../../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import { apiurl } from "../../../../Environment/Environment";
import Apiservice from "../../../../Services/Apiservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import SuccessPopup from "../../../../Shared/Popups/SuccessPopup/SuccessPopup"


function PreferredFrequency() {
    const isBrowser = () => typeof window !== "undefined";
    const Navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = React.useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
    const [com_frequencyupdated, setcom_frequencyupdated] = React.useState("");
    const [buttonselectedtextheading, setbuttonselectedtextheading] = useState("");
    const [buttonselectedtextsubheading, setbuttonselectedtextsubheading] = useState("");
    const [buttonselectedtextid, setbuttonselectedtextid] = useState("");
    const [buttonColors, setButtonColors] = useState([]);
	const [changeflag, setChangeflag] = useState(false);

	const location = useLocation();

	let currentPath = location.pathname;
    // localisation data
    let language;
    if (isBrowser() === true) {
        language = localStorage.getItem("Preferredlanguage");
    }

    let CommunicationPreference = CustomLanguageservice.getMethod("Communication Preference", language);
    let PreferredFrequency = CustomLanguageservice.getMethod("Preferred Frequency", language);
    let displaymsg = CustomLanguageservice.getMethod("Please let us know how frequently you'd like us to message you with.", language);
    let Next = CustomLanguageservice.getMethod("Next", language);
    let Often = CustomLanguageservice.getMethod("Often", language);
    let Pleasesendrelevantnewsandinformationalongwithsurveysandchallenges = CustomLanguageservice.getMethod("Please send relevant news and information, along with surveys and challenges.", language);
    let Periodically = CustomLanguageservice.getMethod("Periodically", language);
    let Includemeinmonthlysurveysandhealthcarechallenges = CustomLanguageservice.getMethod("Include me in monthly surveys and healthcare challenges.", language);
    let Seldom = CustomLanguageservice.getMethod("Seldom", language);
    let Onlyincludemeinthemostcriticalsurveysandchallenges = CustomLanguageservice.getMethod("Only include me in the most critical surveys and challenges.", language);

	let Wewillgetintouchwithyouregularly = CustomLanguageservice.getMethod("We will get in touch with you regularly.",language);
	let Wewillgetintouchwithyoufrequentlyeachmonth = CustomLanguageservice.getMethod("We will get in touch with you frequently each month.", language);
	let Wewillgetintouchwithyouoccasionally = CustomLanguageservice.getMethod("We will get in touch with you occasionally.", language);
	let wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications = CustomLanguageservice.getMethod("We will reach out to you with information and tips as well as surveys and notifications", language);
	let Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey = CustomLanguageservice.getMethod("We will reach out approximately once a month with an important notification or survey", language);
	let Wewillonlysendthemostcriticalnotificationsandquestionnaires = CustomLanguageservice.getMethod("We will only send the most critical notifications and questionnaires", language);

	
    useEffect(() => {
        setProgress((100 / 8) * 6);
        // profile api
        setIsLoading(true);
        let endpoint = "/user/get_user_info";
        let url = apiurl + endpoint;
		let apidata= {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
            setIsLoading(false);
            let com_frequency = response.data.data.com_frequency;
            setcom_frequencyupdated(response.data.data.com_frequency);
            let buttonarray;
            buttonarray = [
                {
                    id: "0",
                    heading: Often + ".",
                    subheading: Pleasesendrelevantnewsandinformationalongwithsurveysandchallenges,
					text:Wewillgetintouchwithyouregularly,
					popup_text:wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications,
                    color: "var(--lightblue)"
                },
                {
                    id: "1",
                    heading: Periodically + ".",
                    subheading: Includemeinmonthlysurveysandhealthcarechallenges,
					text:Wewillgetintouchwithyoufrequentlyeachmonth,
					popup_text:Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey,
                    color: "#FFFFFF"
                },
                {
                    id: "2",
                    heading: Seldom + ".",
                    subheading: Onlyincludemeinthemostcriticalsurveysandchallenges,
					text:Wewillgetintouchwithyouoccasionally,
					popup_text:Wewillonlysendthemostcriticalnotificationsandquestionnaires,
                    color: "#FFFFFF"
                }
            ]
            setButtonColors(buttonarray);
        
        
            if (com_frequency === "1") {
                buttonarray = [
                    {
                        id: "0",
                        heading: Often + ".",
                        subheading: Pleasesendrelevantnewsandinformationalongwithsurveysandchallenges,
						text:Wewillgetintouchwithyouregularly,
						popup_text:wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications,
                        color: "#FFFFFF"
                    },
                    {
                        id: "1",
                        heading: Periodically + ".",
                        subheading: Includemeinmonthlysurveysandhealthcarechallenges,
						text:Wewillgetintouchwithyoufrequentlyeachmonth,
						popup_text:Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey,
                        color: "var(--lightblue)"
                    },
                    {
                        id: "2",
                        heading: Seldom + ".",
                        subheading: Onlyincludemeinthemostcriticalsurveysandchallenges,
						text: Wewillgetintouchwithyouoccasionally,
						popup_text:Wewillonlysendthemostcriticalnotificationsandquestionnaires,
                        color: "#FFFFFF"
                    }
                ]
                setButtonColors(buttonarray);
        
        
            }
            else if (com_frequency === "2") {
                buttonarray = [
                    {
                        id: "0",
                        heading: Often + ".",
                        subheading: Pleasesendrelevantnewsandinformationalongwithsurveysandchallenges,
						text:Wewillgetintouchwithyouregularly,
						popup_text:wewillreachouttoyouwithinformationandtipsaswellassurveysandnotifications,
                        color: "#FFFFFF"
                    },
                    {
                        id: "1",
                        heading: Periodically + ".",
                        subheading: Includemeinmonthlysurveysandhealthcarechallenges,
						text:Wewillgetintouchwithyoufrequentlyeachmonth,
						popup_text:Wewillreachoutapproximatelyonceamonthwithanimportantnotificationorsurvey,
                        color: "#FFFFFF"
                    },
                    {
                        id: "2",
                        heading: Seldom + ".",
                        subheading: Onlyincludemeinthemostcriticalsurveysandchallenges,
						text:Wewillgetintouchwithyouoccasionally,
						popup_text:Wewillonlysendthemostcriticalnotificationsandquestionnaires,
                        color: "var(--lightblue)"
        
                    }
                ]
                setButtonColors(buttonarray);
        
            }
        
         
        })
            .catch(error => {
				setIsLoading(false);
                console.error('There was an error!', error);
            })
    }, []);



    const handleButtonClick = (index, heading, subheading, id, text,popup_text) => {
		setChangeflag(true)
        // Create a new array with updated colors
        const newButtonColors = buttonColors.map((data, i) => ({
            ...data,
            color: i === index ? 'var(--lightblue)' : '#FFFFFF'
        }));
        // Set the state with the new array
        setButtonColors(newButtonColors);
        setbuttonselectedtextheading(heading);
        setbuttonselectedtextsubheading(popup_text);
        setbuttonselectedtextid(id)
    }

    function nextButtonClick() {
        let data1 = PreferredFrequency;
        let data2;
        let data3;
		let data5;
		let data6;
        let apidatatosent;
        if (buttonselectedtextheading === "" && buttonselectedtextsubheading === "") {
            if (com_frequencyupdated === "" || com_frequencyupdated === undefined) {
                data5 = buttonColors[0].heading;
                data6 = buttonColors[0].popup_text;
                apidatatosent = buttonColors[0].id;
            }
            else {
                for (let i = 0; i < buttonColors.length; i++) {
					// debugger
                    if (buttonColors[i].id === com_frequencyupdated) {
                        data5 = buttonColors[i].heading;
                        data6 = buttonColors[i].popup_text;
                        apidatatosent = buttonColors[i].id;
                    }
                }

            }
        }
        else {
            data5 = buttonselectedtextheading;
            data6 = buttonselectedtextsubheading;
            apidatatosent = buttonselectedtextid;
        }

        const jsonData =
        {
            componentname: "Preferred Frequency",
            image: "/asset/tick.png",
            datatitle: "",
            heading1: data1,
            heading2: "",
            heading3: "",
            heading4: data5,
			heading5: "",
			heading6: data6,

        };
        


        // api call
		setIsLoading(true);
        let endpoint = "/user/save_comm_preferences"
        let url = apiurl + endpoint;
        let apidata = {
            "com_frequency": apidatatosent,
			"route_name": currentPath,
        }

        Apiservice.putMethod(url, apidata).then(response => {
			setIsLoading(false);
			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			if (changeflag === true){
				setIsModalOpen(true);
                setTimeout(() => {
					setIsModalOpen(false);
                    Navigate('/LanguageConfirmation');
                }, 3000);
			}
			else{
				Navigate('/LanguageConfirmation');
			}
		    
        })
            .catch(error => {
				setIsLoading(false);
				setIsModalOpen(false);
            })
    }


    function back() {
        Navigate('/PreferredChannel');
    }

    return (
        <Box className={"Preferredfrequencycontainer"}>
            {isLoading &&
                <CommoncircularProgress></CommoncircularProgress>
            }
            <HeaderOnBoarding />

			<SuccessPopup
					isOpen={isModalOpen}
					title="User Modal Title"
					description="This is the user modal description."
				/>

            <Grid container xs={12} className={"bodygrid_Preferredfrequency"}>
                <Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }}>  <br></br>
                    <img src="/asset/leftarrow.png" tabIndex="0" alt="leftarrow" onClick={() => back()} 
					onKeyDown={(e) => {
						if (e.key === "Enter") {
						back();
						}
						}} />
                </Grid>

                <Grid xs={11} >  <br></br>
                    <div className={"Profileinfo"} tabIndex="0">
                        {CommunicationPreference}
                    </div>

                </Grid>

                <Grid xs={12} > <br></br>
                    <LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
                </Grid>

                {/* <Grid xs={12} > <br></br>
                    <div className="textheading">
                        {PreferredFrequency}
                    </div>
                </Grid> */}

                <Grid xs={12} > <br></br>
                    <div className="text1_profile" tabIndex="0">
                        {displaymsg}
                    </div>
                </Grid>


                <Grid xs={12} mb={2}>
                    <div>
                        {buttonColors.map((data, index) => (
                            <div
                                key={index} tabIndex="0"
                                variant="contained" type="submit" className={"dynamicMessagebtn"}
                                style={{ backgroundColor: data.color }}
                                onClick={() => handleButtonClick(index, data.heading, data.subheading, data.id, data.text, data.popup_text)} 
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleButtonClick(index, data.heading, data.subheading, data.id, data.text, data.popup_text)
									}
								}}>
                                <span className={"mainspan"}>
                                    <span className={"heading"}>{data.heading} </span>
                                    <span className={"subheading"}>{data.subheading}</span>
                                </span>


                            </div>
                        ))}

                    </div>
                </Grid>


                <Grid xs={12}>
                    <div>
                        <Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"} onClick={() => nextButtonClick()}
                        >{Next}</Button>
                    </div>
                </Grid>

            </Grid>
        </Box>

    )

}

export default PreferredFrequency