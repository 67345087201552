import React from "react";
import CustomLanguageservice from "./CustomLanguageservice";

const CommonService = {

	getLanguage: function () {
		let dropdowndata = [
			{
				id: 1,
				value: "English",
			},
			{
				id: 2,
				value: "Spanish",
			},
			{
				id: 3,
				value: "Vietnamese",
			},
		]

		return dropdowndata
	},

	getChannel: function (languageid) {
		let language = languageid;
		let TextMessage = CustomLanguageservice.getMethod("Text Message", language);
		let Email = CustomLanguageservice.getMethod("Email", language);
		let Phone = CustomLanguageservice.getMethod("Phone", language);

		let dropdownChanneldata = [{
			id: 0,
			value: TextMessage,
			value_eng: "Text Message",
		},
		{
			id: 1,
			value: Email,
			value_eng: "Email",
		},
		{
			id: 2,
			value: Phone,
			value_eng: "Phone",
		}]
		return dropdownChanneldata
	},

	getChannel1: function (languageid) {
		let language = languageid;
		let Email = CustomLanguageservice.getMethod("Email", language);
		let Phone = CustomLanguageservice.getMethod("Phone", language);

		let dropdownChanneldata = [
			{
				id: 1,
				value: Email,
				value_eng: "Email",
			},
			{
				id: 2,
				value: Phone,
				value_eng: "Phone",
			}]
		return dropdownChanneldata
	},

	getChannel2: function (languageid) {
		let language = languageid;
		let TextMessage = CustomLanguageservice.getMethod("Text Message", language);
		let Phone = CustomLanguageservice.getMethod("Phone", language);

		let dropdownChanneldata = [
			{
				id: 0,
				value: TextMessage,
				value_eng: "Text Message",
			},
			{
				id: 2,
				value: Phone,
				value_eng: "Phone",
			}]
		return dropdownChanneldata
	},

	getChannel3: function (languageid) {
		let language = languageid;
		let Phone = CustomLanguageservice.getMethod("Phone", language);

		let dropdownChanneldata = [
			{
				id: 2,
				value: Phone,
				value_eng: "Phone",
			}]
		return dropdownChanneldata
	},

	getFrequency: function (languageid) {
		let language = languageid;
		let Often = CustomLanguageservice.getMethod("Often", language);
		let Periodically = CustomLanguageservice.getMethod("Periodically", language);
		let Seldom = CustomLanguageservice.getMethod("Seldom", language);

		let dropdownFrequencydata = [{
			id: 0,
			value: Often,
			value_eng: "Often",
		},
		{
			id: 1,
			value: Periodically,
			value_eng: "Periodically",
		},
		{
			id: 2,
			value: Seldom,
			value_eng: "Seldom",
		}]
		return dropdownFrequencydata
	},

	getMethod: function () {
		let stateArray = [
			{ "state": "Alabama", "code": "AL" },
			{ "state": "Alaska", "code": "AK" },
			{ "state": "Arizona", "code": "AZ" },
			{ "state": "Arkansas", "code": "AR" },
			{ "state": "California", "code": "CA" },
			{ "state": "Colorado", "code": "CO" },
			{ "state": "Connecticut", "code": "CT" },
			{ "state": "Delaware", "code": "DE" },
			{ "state": "Florida", "code": "FL" },
			{ "state": "Georgia", "code": "GA" },
			{ "state": "Hawaii", "code": "HI" },
			{ "state": "Idaho", "code": "ID" },
			{ "state": "Illinois", "code": "IL" },
			{ "state": "Indiana", "code": "IN" },
			{ "state": "Iowa", "code": "IA" },
			{ "state": "Kansas", "code": "KS" },
			{ "state": "Kentucky", "code": "KY" },
			{ "state": "Louisiana", "code": "LA" },
			{ "state": "Maine", "code": "ME" },
			{ "state": "Maryland", "code": "MD" },
			{ "state": "Massachusetts", "code": "MA" },
			{ "state": "Michigan", "code": "MI" },
			{ "state": "Minnesota", "code": "MN" },
			{ "state": "Mississippi", "code": "MS" },
			{ "state": "Missouri", "code": "MO" },
			{ "state": "Montana", "code": "MT" },
			{ "state": "Nebraska", "code": "NE" },
			{ "state": "Nevada", "code": "NV" },
			{ "state": "New Hampshire", "code": "NH" },
			{ "state": "New Jersey", "code": "NJ" },
			{ "state": "New Mexico", "code": "NM" },
			{ "state": "New York", "code": "NY" },
			{ "state": "North Carolina", "code": "NC" },
			{ "state": "North Dakota", "code": "ND" },
			{ "state": "Ohio", "code": "OH" },
			{ "state": "Oklahoma", "code": "OK" },
			{ "state": "Oregon", "code": "OR" },
			{ "state": "Pennsylvania", "code": "PA" },
			{ "state": "Rhode Island", "code": "RI" },
			{ "state": "South Carolina", "code": "SC" },
			{ "state": "South Dakota", "code": "SD" },
			{ "state": "Tennessee", "code": "TN" },
			{ "state": "Texas", "code": "TX" },
			{ "state": "Utah", "code": "UT" },
			{ "state": "Vermont", "code": "VT" },
			{ "state": "Virginia", "code": "VA" },
			{ "state": "Washington", "code": "WA" },
			{ "state": "West Virginia", "code": "WV" },
			{ "state": "Wisconsin", "code": "WI" },
			{ "state": "Wyoming", "code": "WY" }
		]

		return stateArray

	}


}

export default CommonService;