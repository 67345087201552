import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const SuccessPopup = ({ message, url, onClose, dob }) => {

	return (
		<Dialog open onClose={onClose}>
			<DialogTitle tabIndex="0">Registration Successful</DialogTitle>
			<DialogContent>
				<p tabIndex="0" style={{fontWeight:"bold"}}>Date of Birth : {dob}</p>
				 <p tabIndex="0">Wellness Check Survey : <span dangerouslySetInnerHTML={{ __html: url.wellness_check}} /></p>
				 <p tabIndex="0">Post Enrollment Agent Survey :  <span dangerouslySetInnerHTML={{ __html: url.post_enrollment_agent_survey}} /></p>
				 <p tabIndex="0">Post Enrollment Plan Survey :  <span dangerouslySetInnerHTML={{ __html: url.post_enrollment_plan_survey}} /></p>
				 <p tabIndex="0">Post Enrollment Provider Survey :  <span dangerouslySetInnerHTML={{ __html: url.post_enrollment_provider}} /></p>
				 <p tabIndex="0">One month checkin :  <span dangerouslySetInnerHTML={{ __html: url.one_month_check_in_survey}} /></p>
				 <p tabIndex="0">Social :  <span dangerouslySetInnerHTML={{ __html: url.social}} /></p>
				 <p tabIndex="0">Economic :  <span dangerouslySetInnerHTML={{ __html: url.economic}} /></p>
				 <p tabIndex="0">Health Literacy :  <span dangerouslySetInnerHTML={{ __html: url.health_literacy}} /></p>
				 <p tabIndex="0">Physical Environment :  <span dangerouslySetInnerHTML={{ __html: url.physical_environment}} /></p>
				 <p tabIndex="0">Overall Well-Being :  <span dangerouslySetInnerHTML={{ __html: url.overall_well_being}} /></p>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SuccessPopup;