import React from "react";
import { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLocation } from 'react-router-dom';

import CommonService from "../../../Services/CommonService";
import HeaderOnBoarding from "../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import { apiurl } from "../../../Environment/Environment";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import SuccessPopup from "../../../Shared/Popups/SuccessPopup/SuccessPopup"


function AdressInformation() {
	const isBrowser = () => typeof window !== "undefined";
	const {
		register,
		control,
		handleSubmit,
		formState: { errors }, clearErrors,
		reset
	} = useForm({
		mode: "onChange"
	});

	const [selectedState, setselectedState] = React.useState("");
	const [Statearray, setStatearray] = React.useState([]);
	const [progress, setProgress] = React.useState(0);
	const [radiovalue, setradiovalue] = React.useState(1);
	const [Billingadress, setBillingadress] = React.useState("");
	const [Billingadress1, setBillingadress1] = React.useState("")
	const [Billingadress2, setBillingadress2] = React.useState("")
	const [ProfileInformationapiDataupdated, setProfileInformationapiDataupdated] = React.useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const Navigate = useNavigate();
	const location = useLocation();
	const [contain5zero, setcontain5zero] = useState(false);
	const [scrollflag, setScrollflag] = useState(false);
	const [changeflag, setChangeflag] = useState(false);

	let currentPath = location.pathname;

	// localisation data
	let language;
	let ProfileInformationapiData;
	let routefrom;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
		routefrom = localStorage.getItem("routefrom")
	}
	let Addressinformation = CustomLanguageservice.getMethod("Address information", language);
	let Isyourmailingaddressthesameasyourbillingaddress = CustomLanguageservice.getMethod("Is your mailing address the same as your billing address?", language);
	let Addresstitle = CustomLanguageservice.getMethod("Address", language);
	let Yestheyarethesame = CustomLanguageservice.getMethod("Yes, they are the same", language);
	let Nomymailingaddressisdifferent = CustomLanguageservice.getMethod("No, my mailing address is different", language);
	let Pleaseaddyourmailingaddress = CustomLanguageservice.getMethod("Please add your mailing address", language);
	let Streetaddress = CustomLanguageservice.getMethod("Street address", language);
	let City = CustomLanguageservice.getMethod("City", language);
	let State = CustomLanguageservice.getMethod("State", language);
	let Zipcode = CustomLanguageservice.getMethod("Zip code", language);
	let Next = CustomLanguageservice.getMethod("Next", language);
	let BillingAddresstext = CustomLanguageservice.getMethod("Billing Address", language);
	let Mailingaddresstext = CustomLanguageservice.getMethod("Mailing Address", language);
	let Mailingaddressadded = CustomLanguageservice.getMethod("Mailing address added", language);
	let Mailingaddressissameasyourbillingaddress = CustomLanguageservice.getMethod("Mailing address is same as your billing address", language);
	let mustbe5digits = CustomLanguageservice.getMethod("must be 5 digits", language);
	let cannotbeempty = CustomLanguageservice.getMethod("cannot be empty", language);
	let Invalid = CustomLanguageservice.getMethod("Invalid", language);
	let Pleaseselectonestatefromthedropdownlist = CustomLanguageservice.getMethod("Please select one state from the drop-down list", language)

	let checkboxdata = [
		{
			id: 1,
			name: Yestheyarethesame
		},
		{
			id: 2,
			name: Nomymailingaddressisdifferent
		}];


	useEffect(() => {
		setProgress((100 / 8) * 3)
		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			let ProfileInformationapiData = response.data.data;
			setProfileInformationapiDataupdated(response.data.data)
			let address1 = ProfileInformationapiData.billing_street
			let address2 = ProfileInformationapiData.billing_city + " " + ProfileInformationapiData.billing_state + " " + ProfileInformationapiData.billing_zip
			let address = ProfileInformationapiData.billing_street + ", " + ProfileInformationapiData.billing_city + ", " +
				ProfileInformationapiData.billing_state + ", " + ProfileInformationapiData.billing_zip
			setBillingadress(address);
			setBillingadress1(address1);
			setBillingadress2(address2);
			if (ProfileInformationapiData.is_same_address === "false") {
				setradiovalue(2);
				// set data on load
				setselectedState(ProfileInformationapiData.mailing_state)
				setTimeout(() => {
					reset({
						Streetaddress: ProfileInformationapiData.mailing_street,
						City: ProfileInformationapiData.mailing_city,
						Zipcode: ProfileInformationapiData.mailing_zip,
					});
				}, 100);
			}
			else {
				// on 1st checkbox selection set the value for second checkbox if exist from api else
				// get the billing adress
				setradiovalue(1);
				// if api data empty load the same billing data
				if (ProfileInformationapiData.mailing_street === "" || ProfileInformationapiData.mailing_street === null) {
					setselectedState(ProfileInformationapiData.billing_state)
					setTimeout(() => {
						reset({
							Streetaddress: "",
							City: ProfileInformationapiData.billing_city,
							Zipcode: ProfileInformationapiData.billing_zip,
						});
					}, 100);
				}
				else {
					setselectedState(ProfileInformationapiData.mailing_state)
					setTimeout(() => {
						reset({
							Streetaddress: ProfileInformationapiData.mailing_street,
							City: ProfileInformationapiData.mailing_city,
							Zipcode: ProfileInformationapiData.mailing_zip,
						});
					}, 100);
				}
			}
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);

			})
	}, []);

	useEffect(() => {
		let dataarray = CommonService.getMethod();
		setStatearray(dataarray)
	}, []);


	function handleChangecity(event) {
		setChangeflag(true)
		const newValue = event.target.value;
		setselectedState(newValue)
		// this makes error msg go away on change data
		clearErrors('state');

	}

	function back() {
		if (routefrom === "/ProfileInformation") {
			Navigate('/ProfileInformation');
			localStorage.removeItem("routefrom")
		}
		else {
			Navigate('/EditProfileInformation');
		}
	}

	function handleChange(event) {
		setChangeflag(true)
		setradiovalue(parseInt(event.target.value));
		
		if (event.target.value === "2") {
			setScrollflag(true)
		}
		else {
			setScrollflag(false)
		}
		

	}

	const handleKeyDown = (event, value) => {
		setChangeflag(true)
		if (event.key === 'Enter') {
			setradiovalue(parseInt(value))

			if (value === "2" || value === 2) {
				setScrollflag(true)
			}
			else {
				setScrollflag(false)
			}
		}
	};

	function AddressInfoNext(data) {
		let datatitle;
		let data1;
		let data2;
		let data3;
		let data4;
		let data5;
		let data6;
		if (radiovalue === 1) {
			datatitle = "";
			data1 = Mailingaddressissameasyourbillingaddress + "!"
			data2 = "";
			data3 = "";
			data4 = "";
			data5 = Billingadress1;
			data6 = Billingadress2;
		}
		else {
			datatitle = Mailingaddressadded + "!";
			data1 = BillingAddresstext + ":"
			data2 = Billingadress1;
			data3 = Billingadress2;
			data4 = Mailingaddresstext + ":"
			data5 = data.Streetaddress
			data6 = data.City +" "+ data.state + " " + data.Zipcode
		}

		const jsonData =
		{
			componentname: "Adress Information",
			image: "/asset/tick.png",
			datatitle: datatitle,
			heading1: data1,
			heading2: data2,
			heading3: data3,
			heading4: data4,
			heading5: data5,
			heading6: data6,

		};

		let apidata;
		if (radiovalue === 1) {
			let a = ProfileInformationapiDataupdated.mailing_street;
			apidata =
			{
				"is_same_address": "true",
				"is_mailing": 0,
				"billing": {
					"address": ProfileInformationapiDataupdated.billing_street,
					"city": ProfileInformationapiDataupdated.billing_city,
					"state": ProfileInformationapiDataupdated.billing_state,
					"zipcode": ProfileInformationapiDataupdated.billing_zip.toString(),
				},
				"route_name": currentPath
			}
		}
		else {

			apidata =
			{
				"is_same_address": "false",
				"is_mailing": 1,
				"billing": {
					"address": ProfileInformationapiDataupdated.billing_street,
					"city": ProfileInformationapiDataupdated.billing_city,
					"state": ProfileInformationapiDataupdated.billing_state,
					"zipcode": ProfileInformationapiDataupdated.billing_zip.toString(),
				},
				"mailing": {
					"address": data.Streetaddress,
					"city": data.City,
					"state": data.state,
					"zipcode": data.Zipcode.toString()
				},
				"route_name": currentPath
			}
		}

		// api call
		setIsLoading(true);
		let endpoint = "/user/save_profile_info"
		let url = apiurl + endpoint;
		Apiservice.putMethod(url, apidata).then(response => {
			setIsLoading(false);
			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			if (changeflag === true){
				setIsModalOpen(true);
				setTimeout(() => {
					setIsModalOpen(false);
					Navigate('/ContactInformation');
				}, 3000);
			}
			else{
				Navigate('/ContactInformation');
			}
		})
			.catch(error => {
				setIsLoading(false);
				setIsModalOpen(false);
			})

	}

	const handleInputChange = (event, field) => {
		setChangeflag(true)
		let inputValue = event.target.value;
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
		field.onChange(capitalizedValue);
	}

	const handleInputChangeZipcode = (event, field) => {
		setChangeflag(true)
		let inputValue = event.target.value.replace(/\D/g, '');
		if (inputValue === "00000") {
			setcontain5zero(true)
		}
		else {
			setcontain5zero(false)
		}
		field.onChange(inputValue);
	}


	useEffect(() => {
		if (scrollflag) {
			// Find the div element by its class or id
			const divElement = document.querySelector('.bodygrid_AdressInformation');
	
			if (divElement) {
				// Scroll to the bottom of the div
				divElement.scrollTop = divElement.scrollHeight - divElement.clientHeight;
			}
		}
	}, [scrollflag]);

	return (
		<Box className={"Addresscontainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />

			<Grid container xs={12} className={"bodygrid_AdressInformation"}>

				<SuccessPopup
					isOpen={isModalOpen}
					title="User Modal Title"
					description="This is the user modal description."
				/>

				<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }}>  <br></br>
					<img src="/asset/leftarrow.png" tabIndex="0" alt="leftarrow" onClick={() => back()} 
					onKeyDown={(e) => {
						if (e.key === "Enter") {
						back();
						}
						}}/>
				</Grid>

				<Grid xs={11} >  <br></br>
					<div className={"Profileinfo"} 	tabIndex="0">
						{Addressinformation}
					</div>

				</Grid>

				<Grid xs={12} > <br></br>
					<LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
				</Grid>


				<form onSubmit={handleSubmit(AddressInfoNext)} ><br></br>
					<Grid container xs={12} className={"bodygrid_address"}>
						<Grid xs={12} >
							<div className="text1_profile" 	tabIndex="0">
								{Isyourmailingaddressthesameasyourbillingaddress}
							</div>
						</Grid>

						<Grid xs={12}><br></br>
							<div className={"informationboxAddress"}>
								<div className={"namelabel1"} 	tabIndex="0"> <br></br>
									{Addresstitle}
								</div>

								{/* <div className={"namelabel2"}>
									{Billingadress}
								</div> */}
								<div className={"namelabel2"} 	tabIndex="0">
									{Billingadress1}
								</div>
								<div className={"namelabel2"} 	tabIndex="0">
									{Billingadress2}
								</div>

								<div>
									<br></br>
								</div>
							</div>
						</Grid>

						<Grid xs={12}><br></br>
							{/* <div className="text1_profile">
                                Select one:
                            </div> */}

							<div className={"checkitemdiv"}><br></br>
								{checkboxdata.map((data, index) => (
									<RadioGroup row aria-labelledby="chose" key={index} value={radiovalue} onChange={handleChange}>
										<FormControlLabel control={<Radio value={data.id} />} label={data.name} className="text1_profile" 
										onKeyDown={(event) => handleKeyDown(event, data.id)}
										tabIndex={0}/>
									</RadioGroup>

								))}
							</div>
						</Grid>

						{radiovalue === 2 &&
							<Grid container xs={12}>
								<Grid xs={1}><br></br>
									<div className={"imageline"}>
									</div>
								</Grid>

								<Grid xs={11}>
									<div className="text1_profile" tabIndex="0"><br></br>
										{Pleaseaddyourmailingaddress}
									</div>

									<Grid container xs={12} spacing={1}>
										<Grid xs={12}><br></br>
											<Controller
												name="Streetaddress"
												control={control}
												rules={{ required: true}}
												render={({ field }) =>
													<div>
														<InputLabel htmlFor="my-input" className={"inputlabel"}>{Streetaddress} *</InputLabel>
														<div className={"textbox1"}>
															<TextField
																variant="outlined"
																placeholder={Streetaddress}
																value={field.value || ''}
																onChange={(e) => handleInputChange(e, field)}

																InputProps={{
																	className: 'customInput',
																	style: {
																		border: errors?.Streetaddress?.type === "required" || errors?.Streetaddress?.type === "pattern" ? "1px solid red" : "transparent",
																	}

																}}


															/>
														</div>
														{errors?.Streetaddress?.type === "required" &&
															<div className={"errormsg"} tabIndex="0">{Streetaddress} {cannotbeempty}</div>}

													</div>




												}
											/>
										</Grid>


										<Grid xs={12}>
											<Controller
												name="City"
												control={control}
												rules={{ required: true, pattern: /^[A-Za-z\s]+$/ }}
												render={({ field }) =>
													<div>
														<InputLabel htmlFor="my-input" className={"inputlabel"}>{City}*</InputLabel>
														<div className={"textbox1"}>
															<TextField
																variant="outlined"
																placeholder={City}
																value={field.value || ''}
																onChange={(e) => handleInputChange(e, field)}

																InputProps={{
																	className: 'customInput',
																	style: {
																		border: errors?.City?.type === "required" || errors?.City?.type === "pattern" ? "1px solid red" : "transparent",
																	}

																}}

															/>
														</div>
														{errors?.City?.type === "required" &&
															<div className={"errormsg"} tabIndex="0">{City} {cannotbeempty}</div>}

														{errors?.City?.type === "pattern" &&
															<div className={"errormsg"} tabIndex="0">{Invalid} {City}</div>}

													</div>




												}
											/>
										</Grid>

										<Grid xs={6}>
											<div className={"inputlabel"}>
												{State}*
											</div>
											<FormControl>
												{/* {selectedState === "" &&
													<InputLabel id="demo-simple-select-label" translate="no" className={"inputlabelDrpdownselect"}>{State}</InputLabel>
												} */}
												{((typeof selectedState === 'undefined' || selectedState === null || selectedState === "") && (
													<InputLabel id="demo-simple-select-label" translate="no" className={"inputlabelDrpdownselect"}>
														{State}
													</InputLabel>
												))}
												<Select
													{...register('state', { required: true })}
													className={"Drpdownselect"}
													id="demo-simple-select"
													labelId="demo-simple-select-label"
													label={State}
													value={selectedState} // Set the value prop to selectedState
													onChange={handleChangecity}
													translate="no"
												>
													{Statearray.map((data, index) => (
														<MenuItem value={data.code} key={index} translate="no">{data.code}</MenuItem>
													))}
												</Select>
												{errors.state && errors.state.type === "required" &&
													<div className={"errormsgzip"}>{Pleaseselectonestatefromthedropdownlist}</div>}
											</FormControl>

										</Grid >

										<Grid xs={6}>
											<Controller
												name="Zipcode"
												control={control}
												rules={{ required: true, pattern: /^(?!00000)\d{5}(?:[-\s]\d{4})?$/ }}
												render={({ field }) =>
													<div>
														<InputLabel htmlFor="my-input" className={"inputlabel"}>{Zipcode}*</InputLabel>
														<div className={"textboxzipcode"}>
															<TextField
																// type="number"
																variant="outlined"
																placeholder={Zipcode}
																value={field.value || ''}
																onChange={(e) => handleInputChangeZipcode(e, field)}
																type="tel"
																inputProps={{
																	maxLength: 5 
																  }}
																InputProps={{
																	className: 'customInput',
																	style: {
																		border: errors?.Zipcode?.type === "required" || errors?.Zipcode?.type === "pattern" ? "1px solid red" : "transparent",
																	}

																}}


															/>
														</div>
														{errors?.Zipcode?.type === "required" &&
															<div className={"errormsgzip"} tabIndex="0">{Zipcode} {cannotbeempty}</div>}

														{errors?.Zipcode?.type === "pattern" && contain5zero === true &&
															<div className={"errormsgzip"} tabIndex="0">{Invalid} {Zipcode}</div>}

														{errors?.Zipcode?.type === "pattern" && contain5zero === false &&
															<div className={"errormsgzip"} tabIndex="0">{Invalid} {Zipcode}, {mustbe5digits}</div>}


													</div>

												}
											/>

										</Grid>

									</Grid>




								</Grid>


							</Grid>
						}


					</Grid>

					<Grid xs={12}>
						<div>
							<Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"}
							>{Next}</Button>
						</div>
					</Grid>
				</form>
			</Grid>




		</Box >
	)

}


export default AdressInformation
