import Swal from 'sweetalert2';

const CallbackAlertPopup = (message, ok, errortitle, error) => {
	Swal.fire({
		title: errortitle,
		text: message,
		// icon: 'error',
		allowOutsideClick: false,
		confirmButtonColor: "var(--brandcolor)",
		confirmButtonText: ok,
	}).then((result) => {
		if (result.isConfirmed) {
			console.log('User clicked OK!');
		}
	});
}

export default CallbackAlertPopup