import React, { useState } from 'react';
import dayjs from 'dayjs'; // Import Day.js library

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


import "./FormTimepicker.css"

const FormTimepicker = (props) => {

	// 'selectTime' function from the parent to send the selected time value to parent
	const { selectTime } = props

	const currentTime = dayjs();

	const [time, setTime] = useState(currentTime);

	const handleTimeChange = (value) => {

		setTime(value)

		const dateObject = new Date(value);
		// Extracting hours, minutes, and seconds
		const hours = dateObject.getHours();
		const minutes = dateObject.getMinutes();
		const seconds = dateObject.getSeconds();

		// Padding single digit minutes and seconds with leading zeros if needed
		const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
		const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

		// Combining hours, minutes, and seconds
		const formattedTime = hours + ':' + formattedMinutes + ':' + formattedSeconds;

		// Updating the parent function by sending the 'formattedTime' as value
		selectTime(formattedTime);


		// if (value instanceof Date && !isNaN(value)) {
		// 	setTime(value);	
		// }
	};


	return (

		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MobileTimePicker
				value={time}
				onChange={handleTimeChange}
				defaultValue={currentTime} // Set default value to current time
				className='timepickerForm'
				// value={value}
				sx={{
					"& input": {
						// height: "10px",
						fontFamily: "var(--fontFamily)",
						fontWeight: "bold",
					}
				}} 
				slotProps={{
					mobilePaper: {
						sx: {marginLeft: {xl: "13vw"}}
					}
				}}
				/>
		</LocalizationProvider>
	);
}

export default FormTimepicker;
