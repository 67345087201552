// DEV server


let baseUrl = process.env.REACT_APP_API_URL
let apiurl = `${baseUrl}/onboarding`;
// UAT server
// let apiurl = "http://52.3.101.68:5000/onboarding";
// let baseUrl = "http://52.3.101.68:5000";


// Localhost
// let apiurl = "http://192.168.4.39:5000/onboarding";
// let baseUrl = "http://192.168.4.39:5000"

// let apiurl = "http://127.0.0.1:5000/onboarding";
// let baseUrl = "http://127.0.0.1:5000"






export { apiurl , baseUrl};
