import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useEffect } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Typography } from '@mui/material';

import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import { apiurl } from "../../../Environment/Environment";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { URL } from "../../../Constants/apiUrl";
import InformationPopup from "../../../Shared/Popups/InformationPopup/InformationPopup";
import { languageId } from "../../../Constants/static";

const CalendarIcon = () => (
	<img src="/asset/Line 1.png" alt="Calendar" style={{ height: "1px", marginLeft: "25px", marginRight: '5px' }} />
);


function Login() {
	let valueHistory = [];
	const isBrowser = () => typeof window !== "undefined";
	const Navigate = useNavigate();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [validcred, Setvalidcred] = useState(true);
	const [Submitclicked, setSubmitclicked] = useState(false);
	const [Dob, setDob] = React.useState("");
	const [MemberIDdefault, setMemberIDdefault] = React.useState("");
	const [logo, setlogo] = React.useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		mode: "onChange"
	});


	// localisation data
	let language = "English"
	if (isBrowser() === true) {
		let getlanguage = localStorage.getItem("Preferredlanguage");
		if (getlanguage === null || getlanguage === undefined || getlanguage === "") {
			localStorage.setItem("Preferredlanguage", "1")
		}
	}

	let PleaseenteryourMemberIDandDateofbirth = CustomLanguageservice.getMethod("Please enter your Member ID and Date of Birth", language);
	let MemberID = CustomLanguageservice.getMethod("Member ID", language);
	let Dateofbirth = CustomLanguageservice.getMethod("Date of Birth", language);
	let Proceed = CustomLanguageservice.getMethod("Proceed", language);
	let cannotbeempty = CustomLanguageservice.getMethod("cannot be empty", language);
	let Invalid = CustomLanguageservice.getMethod("Invalid", language);
	let Incorrect = CustomLanguageservice.getMethod("Incorrect", language);


	useEffect(() => {
		// get api data
		setlogo("http://d23bj7ser2frgh.cloudfront.net/asset/customcompanylogo.png")
		// console.log(languageId);

		//setDob("2004/04/24")

		let member_id = localStorage.getItem("member_id");
		let dob = localStorage.getItem("dob");
		if (member_id === "" && dob === "" || member_id === undefined && dob === undefined ||
			member_id === null && dob === null) {
			setTimeout(() => {
				reset({
					MemberID: "",

				});
			}, 500);
		}
		else {
			let TokenExpired = localStorage.getItem("TokenExpired");
			if (TokenExpired === "true") {
				setTimeout(() => {
					reset({
						MemberID: member_id,
					});
					setMemberIDdefault(member_id);
				}, 500);
			}
			else {
				Navigate('/Welcome');
			}

		}
	}, []);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleOKButton = (value) => {
		handleCloseModal();
	};



	function loginval(data) {
		setSubmitclicked(true)

		if (Dob === "" || Dob === "Invalid Date") {
		}
		else {
			// Setvalidcred(false)
			// api call 
			setIsLoading(true);

			let url = URL.GET_TOKEN
			let apidata = {
				"member_id": data.MemberID,
				"dob": Dob
			}
			Apiservice.postMethod(url, apidata).then(response => {
				setIsLoading(false);
				Setvalidcred(true);
				localStorage.removeItem("TokenExpired")
				localStorage.setItem("token", response.data.data.token);
				localStorage.setItem("member_id", data.MemberID);
				localStorage.setItem("member_id_member", data.MemberID);
				localStorage.setItem("dob", Dob);
				// localStorage.setItem("Preferredlanguage", response.data.data.member_details.language_id);
				localStorage.setItem("Preferredlanguage", languageId[response.data.data.member_details.language_id]);
				Navigate('/Welcome');
			})
				.catch(error => {
					setIsLoading(false);
					if (error.message === "Incorrect Member Id or Date of birth") {
						Setvalidcred(false)
					}
				})

			console.log(apidata);


		}

	}

	const handleDateChangekeyMember = (data) => {
		setMemberIDdefault(data);
		Setvalidcred(true)
		console.log(data.target.value);
		reset({
			MemberID: data.target.value.replace(/[^0-9]/g, '')
		});
	}

	const handleDateChange = (date) => {
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setDob(changeddate);
		Setvalidcred(true);
	};


	const handleDateChangekey = (event) => {

		const input = event.target;
		const { selectionStart, value } = input;

		if (event.key === "Enter") {
			setTimeout(() => {
				document.getElementById('submitButton').click();
			}, 500);
		}

		const isMobile = window.innerWidth <= 1024
		if (isMobile) {
			// event.preventDefault();	
			//if (event.key === "Backspace") {
			// Append the current value to the array and keep only the last two values
			valueHistory.push(event.target.value);
			if (valueHistory.length > 2) {
				valueHistory.shift(); // Remove the oldest value if there are more than two
			}
			let previousvalue = valueHistory[0].split('/');
			let a = event.target.value.split('/');
			let MM;
			let DD;
			let YYYY;

			if (event.target.value.length === 8 || event.target.value.length === 6) {

				if (a.includes("MM") || a.includes("DD") || a.includes("YYYY")) {
					setDob(dayjs().format('MM/DD/YYYY'))
				}
				else {
					if (selectionStart <= 2) {
						console.log('Backspace pressed in MM');
						if (a[0] === "") {

							MM = parseInt(previousvalue[0]) + 1;
						}


						DD = a[1];
						YYYY = a[2];
						let da = MM + "/" + DD + "/" + YYYY;
						setDob(da)
					}
					else if (selectionStart <= 5) {
						console.log('Backspace pressed in DD');
						MM = a[0];
						if (a[1] === "") {
							DD = parseInt(previousvalue[1]) + 1;
						}
						YYYY = a[2];
						let da = MM + "/" + DD + "/" + YYYY;
						setDob(da)
					}
					else {
						console.log('Backspace pressed in YYYY');
						MM = a[0];
						DD = a[1];
						if (a[2] === "") {
							if (previousvalue[2] === "YYYY") {
								YYYY = "0001";
							}
							else {
								YYYY = parseInt(previousvalue[2]) + 1;
							}
						}


						let da = MM + "/" + DD + "/" + YYYY;
						console.log(da)
						setDob(da)
					}
				}
			}
			else {
				if (selectionStart === 9 || selectionStart === 10) {
					console.log('Backspace pressed normally at keeping curser at end');
					setDob("Invalid Date")
				}
				else if (selectionStart === 5) {
					console.log('immediate back button click after type year');
					setTimeout(() => {
						input.setSelectionRange(0, 10);
					}, 10);
				}
			}
		}


	}



	return (

		<Box className={"logincontainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container>
				<Grid xs={12} lg={6} className={"headerGrid"}>

					<div className={"headingimgdiv"} tabIndex="0">
						<img src={logo} alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
					</div>
				</Grid>

				<Grid xs={12} lg={6}>
					<br></br> <br></br>
					<div className={"boxcontent"} translate="no" tabIndex="0">
						{PleaseenteryourMemberIDandDateofbirth}
					</div>



					<Grid xs={12} className={"gridLogin"}> <br></br>

						<form onSubmit={handleSubmit(loginval)} >
							<div className={"Loginbody"}>
								<Controller
									name="MemberID"
									control={control}
									render={() =>
										<div>
											<InputLabel htmlFor="my-input" className={"inputlabel"} translate="no">{MemberID}</InputLabel>
											<div className={"textbox1"}>
												<TextField
													translate="no"
													type="number"
													variant="outlined"
													placeholder={MemberID}
													onKeyUp={(event) => handleDateChangekeyMember(event)}
													InputProps={{
														className: 'customInput',
														startAdornment: (
															<InputAdornment position="start">
																<img src="/asset/people.png" alt="UserIcon" style={{ height: '24px', marginLeft: "15px", marginRight: '8px' }} />
																<img src="/asset/Line 1.png" alt="Line" style={{ height: '24px', marginRight: '5px' }} />
															</InputAdornment>
														),
														//    style: errors?.MemberID?.type === "required" ? textbox1error : textbox1,

														style: {
															border: errors?.MemberID?.type === "required" ? "1px solid red" : "transparent",
														}

													}}


													{...register('MemberID', { required: true })}

												/>
											</div>
											{errors?.MemberID?.type === "required" &&
												<div className={"errormsg"} translate="no">{MemberID} {cannotbeempty}</div>}

										</div>




									}
								/>



								<Controller
									name="DateofBirth"
									control={control}
									render={() =>
										<div> <br></br>
											<InputLabel htmlFor="my-input" className={"inputlabel"} translate="no">{Dateofbirth}</InputLabel>
											<LocalizationProvider dateAdapter={AdapterDayjs} >
												{/* <DemoContainer components={['DatePicker']}> */}

												<div className={Dob === "" && Submitclicked === true ? 'textbox2error' : 'textbox2'}>
													<div className={"customiconcalender"}>
														<span >
															<img src="/asset/calendar.png" alt="Calendar" style={{ height: '24px', marginLeft: "-5px", marginRight: '10px' }} />
															<img src="/asset/Line 1.png" alt="Line" style={{ height: '24px' }} />
														</span>
													</div>
													<DesktopDatePicker onChange={handleDateChange}
														value={dayjs(Dob)}
														className="customDatePicker"
														slotProps={{
															inputAdornment: { position: 'start', },
															textField: {
																translate: "no",
																onKeyUp: (event) => handleDateChangekey(event),

															},
														}}
														slots={{
															openPickerIcon: CalendarIcon
														}}
													/>
												</div>
												{/* </DemoContainer> */}
											</LocalizationProvider>

										</div>


									}
								/>

								{Submitclicked === true && (Dob === "") &&
									<div className={"errormsg"} translate="no">{Dateofbirth} {cannotbeempty}</div>}

								{Submitclicked === true && (Dob === "Invalid Date") &&
									<div className={"errormsg"} translate="no">{Invalid} {Dateofbirth}</div>}


								{validcred === false && Dob !== "" && Dob !== "Invalid Date" && errors?.MemberID?.type !== "required" &&
									<div className={"errormsg"} translate="no" tabIndex="0">{Incorrect} {MemberID} / {Dateofbirth}</div>
								}

								<Typography className={"inputlabel"} sx={{ cursor: 'pointer' }} ><br></br>
									<span style={{ borderBottom: "1px solid black" }} tabIndex="0" onClick={handleOpenModal}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleOpenModal();
										}
										}} >Forgot your ID number?</span>
								</Typography>
								<InformationPopup
									isOpen={isModalOpen}
									text1="Forgot your ID number?"
									text2="You can find this number on the front of your Blue Shield Promise ID card."
									text3="If you need more assistance, you can reach customer service at"
									text4="(480) 515-6296"
									text5="between the hours of"
									text6="Mon-Sat:"
									text7=" 9AM - 6PM (GTM-7:00)"
									handleOKButton={handleOKButton}
								/>
							</div>

							<Grid xs={12}>
								<div><br></br><br></br>
									{((errors?.MemberID?.type === "required") || (MemberIDdefault === "") || (Dob === "" || Dob === "Invalid Date")) ?
										<Button variant="contained" aria-label="Proceed" type="submit" className={"submitbtndisabled"} disabled translate="no" id="submitButton">{Proceed}</Button>
										:
										<Button variant="contained" aria-label="Proceed" type="submit" className={"submitbtn"} translate="no" id="submitButton">{Proceed}</Button>
									}
								</div>
							</Grid>

						</form>

					</Grid>

				</Grid>
			</Grid>

			<br></br>
		</Box>










	)
}

export default Login