import React from 'react';
import Button from '@mui/material/Button';

function ButtonComponent({ id,variant, arialabel, type, onClick, className, buttonname }) {
    return (
        <Button
            variant= {variant}
            aria-label={arialabel}
            type={type}
            className={className}
            onClick={onClick}
        >
            {buttonname}
        </Button>
    );
}

export default ButtonComponent;