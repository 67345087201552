import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Button, Box, Grid, Tooltip }  from '@mui/material';

import "./Sidebar.css";
// import { SidebarData } from "../../Services/Sidemenudataservice";
import {ROUTES_MEMBER_LOGIN} from "../../Constants/routes";
import CustomLanguageservice from "../../Services/CustomLanguageservice";
import sidemenuService from "../../Services/Sidemenudataservice";


const Sidebar = props => {
	let SidebarData = sidemenuService.getSidebarDatafn();
	const isBrowser = () => typeof window !== "undefined";
	const Navigate = useNavigate();
	const [pathname, setPathname] = useState('/');
	const location = useLocation();
	const [isMobile, setIsMobile] = useState("");
	const [isSubChildOpen, setIsSubChildOpen] = useState(Array(SidebarData.length).fill(false));
	const [activeParent, setActiveParent] = useState(null);
	const SidebarMainContainer = props.isOpen ? "SidebarMainContainerclose" : "SidebarMainContainer";


	// localisation data
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}
    
	let Logout_text = CustomLanguageservice.getMethod("Logout", language);


	useEffect(() => {
		// on refresh by deafault sidemenu item selection color
		let value = sessionStorage.getItem("sidebaritem_selected");
		if (value !== undefined && value !== null && value !== "") {
			handleClickArrowicon(parseInt(value))
		}
		else {
			handleClickArrowicon(0)
		}


		if (window.innerWidth < 1280) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
		window.addEventListener("resize", handleResize)
	}, [])


	//choose the screen size 
	const handleResize = () => {
		if (window.innerWidth < 1280) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}



	// calls when sidemenu component is changed
	useEffect(() => {
		let pathname = location.pathname.split('/')[1]
		setPathname(pathname);
	}, [location]);

	const logout= () => {
		localStorage.setItem("TokenExpired", "true");
		Navigate(ROUTES_MEMBER_LOGIN);
		sessionStorage.removeItem("sidebaritem_selected");
		sessionStorage.removeItem("Member_firstname");
		sessionStorage.removeItem("Member_lastname");
	}


	const handleClickArrowicon = (index) => {
		sessionStorage.setItem("sidebaritem_selected", index)
		setActiveParent(index);
		setIsSubChildOpen(prevIsSubChildOpen => {
			// Create a new array to hold the updated state
			const newIsSubChildOpen = prevIsSubChildOpen.map((isOpen, i) => {
				// Close the subitem menu of all other items
				if (i !== index) return false;
				// Toggle the subitem menu of the clicked item
				return !isOpen;
			});
			return newIsSubChildOpen;
		});
	};


	const isSubItemActive = (subItemPath) => {
		return location.pathname.includes(subItemPath);
	};


	return (
		<Box className={"MemberSidebarcontainer"} >
			{pathname !== "Login" && pathname !== "" ?
				<div>
					{isMobile === true &&
						<span>
							{/* Mobile - empty*/}
						</span>
					}
					{isMobile === false &&
						<div className={SidebarMainContainer} id="sidebar">

							{/* Laptop - sidenbar and minisidebar */}

							{/* if condition is true show mini sidemenu else show large sidemenu */}
							{props.isOpen ?

								// mini Sidebar
								<div>
									<div className={"sidebarscrollbody"}>
										<div className={"scrollable-content"}>
											<Grid container>
												<Grid xs={12} className={"toggleimagegridmini"}>
													<img src="/asset/Member/toggleicon.png" onClick={props.onClick} 
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															props.onClick(); 
														}
														}}
														className={"togglebtnimagemini"}
														alt="toggleicon" tabIndex="0"/>
												</Grid>
											</Grid>

											<div className={"sidebardiv"}>
												{SidebarData.map((item, index) => {
													return (
														<div key={index}>
															{item.subchild.length > 0 ?
																<div className="sideitemmainrow">
																	<NavLink onClick={() => handleClickArrowicon(index)} tabIndex="0"
																	onKeyDown={(e) => {
																		if (e.key === "Enter") {
																			handleClickArrowicon(index)
																		}
																		}}
																		className={`${activeParent === index && item.subchild.some(subItem => isSubItemActive(subItem.path)) ? 'actionmini' : 'inActionmini'}`}
																	>

																		&nbsp;&nbsp;&nbsp;
																		{
																			activeParent === index && item.subchild.some(subItem => isSubItemActive(subItem.path)) ? (<Tooltip title={item.title} arrow><img src={item.activeIcon} className={'menuitemiconmini'} alt="menuicon" /></Tooltip>) : (<Tooltip title={item.title} arrow><img src={item.icon} className={'menuitemiconmini'} alt="menuicon" /></Tooltip>)
																		}


																		{activeParent === index && item.subchild.some(subItem => isSubItemActive(subItem.path)) ?
																			<span>

																				<img
																					src={isSubChildOpen[index] ? "/asset/Member/arrowdown.png" : "/asset/Member/arrowleftblue.png"}
																					alt="toggleicon"
																					style={{ width: "12px", height: "12px", marginTop: "-5px", cursor: 'pointer', marginLeft: "5px" }}
																				/>
																			</span>
																			:
																			<span>

																				<img
																					src={isSubChildOpen[index] ? "/asset/Member/arrowdownwhite.png" : "/asset/Member/arrowleftwhite.png"}
																					alt="toggleicon"
																					style={{ width: "12px", height: "12px", marginTop: "-5px", cursor: 'pointer', marginLeft: "5px" }}
																				/>
																			</span>

																		}

																	</NavLink >

																	{item.subchild.length > 0 && index === activeParent && isSubChildOpen[index] && (
																		<div className="subchild-container">
																			{item.subchild.map((subItem, subIndex) => (
																				<NavLink key={subIndex}
																					to={subItem.path}
																					className={({ isActive }) =>
																						'' + (isActive ? 'actionminisubmenu' : 'inActionmini')
																					}
																				>

																					{
																						location.pathname.includes(subItem.path) ? (<Tooltip title={subItem.title} arrow><img src={subItem.activeIcon} className={"sidemenuitemiconsubmenumini"} alt="menuicon" /></Tooltip>) : (<Tooltip title={subItem.title} arrow><img src={subItem.icon} className={'sidemenuitemiconsubmenumini'} alt="menuicon" /></Tooltip>)
																					}



																				</NavLink >
																			))}
																		</div>
																	)}
																</div>

																:

																<div className="sideitemmainrow">
																	<NavLink to={item.path} onClick={() => handleClickArrowicon(index)} tabIndex="0"
																	onKeyDown={(e) => {
																		if (e.key === "Enter") {
																			handleClickArrowicon(index)
																		}
																		}}
																		className={({ isActive }) =>
																			'' + (isActive ? ' actionmini' : ' inActionmini')
																		}
																	>


																		{
																			location.pathname === item.path ? (<Tooltip title={item.title} arrow><img src={item.activeIcon} className={'menuitemiconmini'} alt="menuicon" /></Tooltip>) : (<Tooltip title={item.title} arrow><img src={item.icon} className={'menuitemiconmini'} alt="menuicon" /></Tooltip>)
																		}


																	</NavLink >

																</div>

															}
														</div>
													);
												})}
											</div>
										</div>
									</div>

									<Grid container>
										<Grid xs={12}>
											<div className={"bottomdivmini"}>
											<Tooltip title={Logout_text} arrow>
												<Button variant="contained" aria-label="LetsGetStarted" type="submit" className={"logoutbtnmini"} onClick={() => logout()}
												>
													<img src="/asset/Member/loginicon.png" alt="Icon" className="image" /></Button></Tooltip>
											</div>


										</Grid>
									</Grid>
								</div>
								:

								// big Sidebar
								<div>
									<div className={"sidebarscrollbody"}>
										<div className={"scrollable-content"}>
											<Grid container>
												<Grid item xs={12} className={"toggleimagegrid"}>
													<img src="/asset/Member/toggleicon.png" tabIndex="0" onClick={props.onClick} 
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															props.onClick(); 
														}
														}}
														className={"togglebtnimage"}
														alt="toggleicon" />
												</Grid>
											</Grid>


											<div className={"sidebardiv"}>
												{SidebarData.map((item, index) => {
													return (
														<div key={index} >
															{item.subchild.length > 0 ?
																<div className="sideitemmainrow">
																	<NavLink onClick={() => handleClickArrowicon(index)}
																		className={`${activeParent === index && item.subchild.some(subItem => isSubItemActive(subItem.path)) ? 'action' : 'inAction'}`}
																	>

																		&nbsp;&nbsp;&nbsp;
																		{
																			activeParent === index && item.subchild.some(subItem => isSubItemActive(subItem.path)) ? (<img src={item.activeIcon} className={'menuitemicon'} alt="menuicon" />) : (<img src={item.icon} className={'menuitemicon'} alt="menuicon" />)
																		}

																		&nbsp;&nbsp;&nbsp;
																		<span className={"sidemenuitemtitle"}>
																			{item.title}</span>
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

																		{activeParent === index && item.subchild.some(subItem => isSubItemActive(subItem.path)) ?
																			<span>
																				<img
																					src={isSubChildOpen[index] ? "/asset/Member/arrowdown.png" : "/asset/Member/arrowleftblue.png"}
																					alt="toggleicon"
																					style={{ marginTop: "5px", cursor: 'pointer' }}
																				/>
																			</span>
																			:
																			<span>
																				<img
																					src={isSubChildOpen[index] ? "/asset/Member/arrowdownwhite.png" : "/asset/Member/arrowleftwhite.png"}
																					alt="toggleicon"
																					style={{ marginTop: "5px", cursor: 'pointer' }}
																				/>
																			</span>

																		}

																	</NavLink >

																	{item.subchild.length > 0 && index === activeParent && isSubChildOpen[index] && (
																		<div className="subchild-container">
																			{item.subchild.map((subItem, subIndex) => (
																				<NavLink key={subIndex}
																					to={subItem.path}
																					className={({ isActive }) =>
																						'' + (isActive ? 'actionsubmenu' : 'inAction')
																					}
																				>

																					{
																					location.pathname.includes(subItem.path) ? (<img src={subItem.activeIcon} className={"sidemenuitemiconsubmenu"} alt="menuicon" />) : (<img src={subItem.icon} className={'sidemenuitemiconsubmenu'} alt="menuicon" />)
																					}

																					&nbsp;&nbsp;&nbsp;


																					<div>
																						{subItem.title}</div>

																				</NavLink >
																			))}
																		</div>
																	)}
																</div>

																:

																<div className="sideitemmainrow">
																	<NavLink to={item.path} onClick={() => handleClickArrowicon(index)}
																		className={({ isActive }) =>
																			'' + (isActive ? ' action' : ' inAction')
																		}
																	>

																		&nbsp;&nbsp;&nbsp;
																		{
																			location.pathname === item.path ? (<img src={item.activeIcon} className={'menuitemicon'} alt="menuicon" />) : (<img src={item.icon} className={'menuitemicon'} alt="menuicon" />)
																		}
																		&nbsp;&nbsp;&nbsp;
																		<span className={"sidemenuitemtitle"}>{item.title}</span>
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	</NavLink >

																</div>

															}

														</div>
													);
												})}

											</div>
										</div>
									</div>

									<Grid container>
										<Grid item xs={12}>
											<div className={"bottomdiv"}>
												<Button variant="contained" aria-label="Logout" type="submit" className={"logoutbtn"} onClick={() => logout()}
												>
													<img src="/asset/Member/loginicon.png" alt="Icon" className="image" />&nbsp;
													{Logout_text}</Button>

												{/* <div className={"lastlogin"}><br></br>
													Last Login:  05/05/2024
												</div> */}
											</div>


										</Grid>
									</Grid>




								</div>
							}
						</div>
					}

				</div>
				:
				<span></span>
			}
		</Box >



	)
}

export default Sidebar;