import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import { Button, InputAdornment, InputLabel, TextField, Box } from '@mui/material';

import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import SuccessPopup from "../../../Shared/Popups/SuccessPopup/SuccessRegisterPopup"
import { apiurl } from "../../../Environment/Environment";
import { URL } from "../../../Constants/apiUrl";

function MemberRegister() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [submitClicked, setSubmitClicked] = useState(false);
	const { control, handleSubmit, formState: { errors }, setValue, trigger } = useForm({
		mode: "onChange"
	});
	const [successMessage, setSuccessMessage] = useState("");
	const [successUrl, setSuccessUrl] = useState("");
	const [validcred, setValidcred] = useState(true);
	const [memberID, setMemberID] = useState("");
	const [memberIDError, setMemberIDError] = useState("");
	const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State to manage success popup
	const [errorMessage, setErrorMessage] = useState("");
	const [dob, setDob] = useState("")

	const onSubmit = (data) => {
		setSubmitClicked(true);
		setIsLoading(true);
		let endpoint = "/member/create"
		let url = apiurl + endpoint;
		let formData = new FormData();
		formData.append("memberId", data.MemberID);

		fetch(url, {
			method: 'POST',
			body: formData
		})
			.then(response => {
				setIsLoading(false);
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})

			.then(data => {
				if (data.status === "success") {
					setSuccessMessage(data.message);
					setSuccessUrl(data.url);
					setDob(data.dob)
					setShowSuccessPopup(true); // Show success popup
					console.log(data.status)
				} else if (data.status === "error") {
					setErrorMessage(data.message); // Set error message
					console.log(data.message)
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.error("Error:", error);

			});
	};
	const handleMemberIDChange = (event) => {
		const value = event.target.value;
		setMemberID(value);
		setValue("MemberID", value);
		trigger("MemberID");
		if (value.length > 5) {
			setMemberIDError("Enter valid Member ID"); // Set error message for invalid length
		} else {
			setMemberIDError(""); // Reset error message when within valid length
		}
		setValidcred(true); // Reset validity when MemberID changes
	};
	const closePopup = () => {
		setShowSuccessPopup(false);
	};
	return (
		<Box className={"registerContainer"} sx={{ justifyContent: "center" }}>
			{isLoading && <CommoncircularProgress />}
			<Grid container direction="column" spacing={2}>
				<Grid item xs={12} className="headerGrid">
					<div className={"headingimgdiv"} tabIndex="0">
						<img src="http://d23bj7ser2frgh.cloudfront.net/asset/customcompanylogo.png" alt="Companylogo" style={{ width: "150%", height: "100%", objectFit: "contain" }} />
					</div>
				</Grid>
				<Grid item xs={12} lg={6} sx={{justifyContent:"center"}}>
					<div className={"boxcontent"} translate="no" tabIndex="0">
						Register Member ID
					</div>
					<Grid container item xs={12} className={""} sx={{justifyContent:"center"}}>
						<br />
						<Grid item sx={{ width:{xs:'90vw',md:'50vw', lg:'40vw'}}}>
						<form onSubmit={handleSubmit(onSubmit)} style={{width:"100%"}}>
							<div className={"Loginbody"}>
								<Controller
									name="MemberID"
									control={control}
									render={({ field }) =>
										<div>
											<InputLabel htmlFor="member-id" className={"inputlabel"} translate="no">Member ID</InputLabel>
											<div className={"textbox1"}>
												<TextField
													id="member-id"
													{...field}
													variant="outlined"
													placeholder="Member ID"
													onChange={handleMemberIDChange}
													value={memberID}
													sx={{width:"100%"}}
													InputProps={{
														className: 'customInput',
														startAdornment: (
															<InputAdornment position="start">
																<img src="/asset/people.png" alt="UserIcon" style={{ height: '24px', marginLeft: "15px", marginRight: '8px' }} />
																<img src="/asset/Line 1.png" alt="Line" style={{ height: '24px', marginRight: '5px' }} />
															</InputAdornment>
														),
														style: {
															border: errors?.MemberID?.type === "required" ? "1px solid red" : "transparent"
														}
													}}
												/>
											</div>
											{errors?.MemberID?.type === "required" &&
												<div className={"errormsg"} translate="no">Member ID cannot be empty</div>}
											{memberIDError &&
												<div className={"errormsg"} translate="no">{memberIDError}</div>}
											{errorMessage && // Display error message if it exists
												<div className="errormsg" translate="no">{errorMessage}</div>}
										</div>
									}
									rules={{ required: true }}
								/>
								<Grid item mt={3}>
									<Button
										variant="contained"
										aria-label="Proceed"
										type="submit"
										className={(!memberID || errors.MemberID || memberIDError) ? "submitbtndisabled" : "submitbtn"}
										translate="no"
									>
										Proceed
									</Button>
								</Grid>
							</div>
						</form>
						</Grid>

					</Grid>
				</Grid>
			</Grid>
			{showSuccessPopup && (
				<SuccessPopup
					message={successMessage}
					url={successUrl}
					onClose={closePopup}
					dob={dob}
				/>
			)}
		</Box>
	);
}
export default MemberRegister;
