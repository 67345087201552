import React from 'react';
import { memo } from 'react';
import "./CampaignHistoryPopup.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import ModalContent from './modal-content';

function CampaignHistoryPopup({ isOpen, handleOKButton }) {

	function handleClickcancel(data){
		handleOKButton(data);
	};


	return (
		<div >
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className={"CampaignHistoryPopupcontainer"} >
					<Grid container xs={12} className={"CommonLoadermaingrid"}>
					<Grid xs={12} className={"GridIcon"}><br></br>
							<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="20px" height="20px" onClick={handleClickcancel} />
						</Grid>
						</Grid>


						<ModalContent handleOKButton={handleOKButton}/>

						{/* <Grid xs={12} style={{textAlign:"center"}}><br></br><br></br>
							<div className={"textlabel1"}>
								ftgtgtht
							</div>

						</Grid> */}

						
					
				</Box>

			</Modal>
		</div>
	)

}

export default memo(CampaignHistoryPopup)