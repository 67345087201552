
function colorsGenerator(numb){
    const generateRandomLightHexColor= ()=> {
		// Generates a single light hex color code
		const randomValue = () => Math.floor(Math.random() * 128) + 128; // ensures values between 128 and 255
		const r = randomValue().toString(16).padStart(2, "0");
		const g = randomValue().toString(16).padStart(2, "0");
		const b = randomValue().toString(16).padStart(2, "0");
		return `#${r}${g}${b}`;
	}

	const generateLightHexColorsArray=(count) =>{
		// Generates an array of light hex color codes
		const colorsArray = Array.from(
			{ length: count },
			generateRandomLightHexColor
		);
		return colorsArray;
	}
	let colors = generateLightHexColorsArray(numb) ;

    return colors
}

export default colorsGenerator;