import React, { useState, useRef, useEffect } from "react";
import "./AdministratorNotifications.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import UnresolvedNotificationItem from "./unresolved";
import ResolvedNotificationItem from "./resolved";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import HeaderAgent from "../../../Shared/HeaderAgent/HeaderAgent";
import { useNavigate } from "react-router-dom";
import MemberSnapshotPopup from "../../../Shared/Popups/MemberSnapshotPopup/MemberSnapshotPopup";

import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";


import {ROUTES_CAMPAIGN_DETAILS } from "../../../Constants/routes";


function AdministratorNotifications() {

	const Navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [viewResolved, setViewResolved] = useState(false);
	const [unresolvedNotifications, setunresolvedNotifications] = useState([]);
	const [resolvedNotifications, setresolvedNotifications] = useState([]);
	const [isMemberSnapshotPopupModalOpen, setisMemberSnapshotPopupModalOpen] = useState(false)
	const [getmemberID, setgetmemberID] = useState("") 
	const [itemdata, setitemdata] = useState("")  
	const [itemCampaignName, setitemCampaignName] = useState("")  
	const navigate = useNavigate();
	



	const handleViewChange = () => {
		if (viewResolved) setViewResolved(false);
		else setViewResolved(true);

	};

	const handleMemberSnapshotOKButton = (value) => {
		setisMemberSnapshotPopupModalOpen(false);
	}

	let fromHistory;	
    if(sessionStorage.getItem('from_history_to_adminnotifications')){
		fromHistory = JSON.parse(sessionStorage.getItem('from_history_to_adminnotifications'));
	}else{
		fromHistory = JSON.parse(sessionStorage.getItem('from_history_to_generate_report'))
	}
        


	useEffect(() => {

		let campaign_code;
		if(fromHistory){
			campaign_code = fromHistory.campaign_code;
		}else{
			campaign_code = sessionStorage.getItem("campaign_code")
		}
		
		setIsLoading(true);
		let apiurl = URL.AGENT_NOTIFICATION;
	
		var params = new URLSearchParams();
		params.append("campaign_code", campaign_code)
		params.append("is_resolved", viewResolved)

	
		Apiservice.getMethodParams(apiurl,params).then(response => {
			setIsLoading(false);
			setitemCampaignName(response.data.campaign_name)
			if(viewResolved === true){
				let resolved=  response.data.data
				setresolvedNotifications(resolved);
			}else{
			let unresolved=  response.data.data
			setunresolvedNotifications(unresolved);
			}
			
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})

 	
		
	},[viewResolved]);
	// const handleClickName = (item)=>{
	// 	setitemdata(item);
	// 	setgetmemberID(item.member_id);
	// 	setisMemberSnapshotPopupModalOpen(true)
		
	//   }

	
	const handle_BackToCampaignTracker = () => {
		if(sessionStorage.getItem('from_history_to_adminnotifications')){
			sessionStorage.removeItem('from_history_to_adminnotifications');
			Navigate(ROUTES_CAMPAIGN_DETAILS)
		}else{
			Navigate(ROUTES_CAMPAIGN_DETAILS)
		}
		
		

	}
	const unresolvedNotificationStack = unresolvedNotifications.length !== 0 ? unresolvedNotifications.map(
		(item, index) => {
			return <UnresolvedNotificationItem key={index} item={item} viewResolved={viewResolved} />
			
		}
	) : "";


	const resolvedNotificationsStack = resolvedNotifications.map((item, index) => {
		return <ResolvedNotificationItem key={index} item={item} viewResolved={viewResolved} />

	});

	const noContent = <div className="no-content-page">
	<img src="/asset/no-notification-img.svg" width="145px"  /><div className="no-content-message">No Notifications Raised Yet!</div>
</div>

	

	return (
		<Box className={"AdministratorNotificationsContainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderAgent />

			<div className="notifications-page"><br></br>
				<header className="notification-page-header">
					<Button variant="text" className="navigate-back" onClick={() => handle_BackToCampaignTracker()}>
					<img src="/asset/Agent/backarraow.png" alt="Icon" className="image" width="24px" />
					 &nbsp;&nbsp;Back To Campaign Tracker
					</Button>
					<div className="campaign-name">
						<p className="campaign-banner">{fromHistory ? fromHistory.
campaign_name :
itemCampaignName}</p>
						<p className="resolved-unresolved-link-container">
							<Button
								variant="text"
								className="navigate-resolved-unresolved-btn"
								id = "change-scrren-btn"
								onClick={handleViewChange}
							>
								{viewResolved
									? "View Unresolved Notification"
									: "View Resolved Notification"}
							</Button>
						</p>
					</div>
				</header>
				
				<div className="unresolved-notifications-container">
				<>{ viewResolved === true || !resolvedNotifications.length === 0 ? resolvedNotificationsStack : ""}</>
				<>{ viewResolved === false || !unresolvedNotifications.length === 0 ? unresolvedNotificationStack : ""}</>
				<>{unresolvedNotifications.length === 0  && viewResolved === false && noContent}</>
				<>{resolvedNotifications.length === 0 && viewResolved === true && noContent}</>

				<MemberSnapshotPopup
				isOpen={isMemberSnapshotPopupModalOpen}
				handleOKButton={handleMemberSnapshotOKButton}
				from="notification"
				data={getmemberID}
				item={itemdata}
				viewResolved={viewResolved}
			/>

					
				</div>
			</div>


		</Box>
	)
}

export default AdministratorNotifications;

