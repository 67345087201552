import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Apiservice from "../../../../Services/Apiservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { ROUTES_SURVEY_SURVEYTEMPLATE } from "../../../../Constants/routes";
import { URL } from "../../../../Constants/apiUrl";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import { languageId } from "../../../../Constants/static";
import GlobalAlertPopup2 from "../../../../Shared/Popups/GlobalAlertPopup2/GlobalAlertPopup2";


function PromotionWelcome() {
	const params = useParams();
	const location = useLocation();



	const isBrowser = () => typeof window !== "undefined";
	const [isLoading, setIsLoading] = useState(false);
	const Navigate = useNavigate();
	const [logo, setlogo] = React.useState("/asset/customcompanylogo.png");
	const [memberdata, setMemberdata] = useState("")
	const [isstarted, setIsstarted] = useState(false)
	const [iscompleted, setIscompleted] = useState("");
	const [apicalled, setapicalled] = useState("");
	const [isModalOpenGlobalAlertPopup2, setisModalOpenGlobalAlertPopup2] = useState(false);
	const [GlobalAlertPopup2msg, setGlobalAlertPopup2msg] = useState("");

	// localisation data
	let language = "";
	if (isBrowser() === true) {
		language = languageId[localStorage.getItem("Survey_language_code")]
	}
	let LetsGetStarted = CustomLanguageservice.getMethod("Let's get started", language);
	let WelcomeBack = CustomLanguageservice.getMethod("Welcome Back", language);
	let Welcome = CustomLanguageservice.getMethod("Welcome", language);
	let KeepGoingtext = CustomLanguageservice.getMethod("Keep Going", language);
	let Welcometotheclub = CustomLanguageservice.getMethod("Welcome to the club", language);
	let FirstLine = CustomLanguageservice.getMethod("We built this platform to keep you in sync with your healthcare partners at plan and provider.", language);
	let secondtLine = CustomLanguageservice.getMethod("This is YOUR channel for tracking your benefits, and it's OUR channel for tracking your healthcare journey", language);
	let Letpickupwhereweleftoff = CustomLanguageservice.getMethod("Let's pick up where we left off", language);
	let Letstartbymakingsurewegotyourcontactinformationdownright = CustomLanguageservice.getMethod("Let's start by making sure we've got your contact information down right", language);

	let Thecampaignhasnotstartedyet = CustomLanguageservice.getMethod("The campaign has not started yet", language);
	let Youhavealreadycompletedthesurvey = CustomLanguageservice.getMethod("You have already completed the survey", language);
	let Thecampaignhasended = CustomLanguageservice.getMethod("The campaign has ended", language);

	useEffect(() => {
		localStorage.removeItem("surveycompleted");
		localStorage.removeItem("token");
		
		let campaign_id = atob(params.campaign_id);
		let member_id = atob(params.member_id);
		let language_code = params.language_code !== undefined ? atob(params.language_code) : "en"
		let reference = atob(params.reference);
		let reference_order = atob(params.reference_order);

		localStorage.setItem("survey_pathname", location.pathname)
		localStorage.setItem("Survey_campaign_id", campaign_id)
		localStorage.setItem("Survey_member_id", member_id)
		localStorage.setItem("Survey_language_code", language_code);
		localStorage.setItem("Survey_reference", reference)
		localStorage.setItem("Survey_reference_order", reference_order)
		callWelcomeApi(campaign_id,member_id, language_code, reference, reference_order)
	}, []);


	const LetsGetStartedBtn = () => {
		Navigate(ROUTES_SURVEY_SURVEYTEMPLATE);
	}

	const handleOKButton_GlobalAlertPopup2 = (value) => {
		setisModalOpenGlobalAlertPopup2(false);
	}

	function getFormattedDate(date) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	function getFormattedTime(date) {
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
		return `${hours}:${minutes}:${seconds}`;
	}

	const callWelcomeApi = (campaign_id,member_id, language_code, reference, reference_order) => {
		// setIsLoading(true)

		const apiData = {
			"campaign_id": campaign_id,
			"member_id": member_id,
			"language_code": language_code
		}

		Apiservice.postMethod(URL.SURVEY_WELCOME, apiData).then((response) => {
			let now = new Date();
			const currentDate = getFormattedDate(now)
			const currentTime = getFormattedTime(now)

			let currentDateTime = `${currentDate}T${currentTime}`;
			currentDateTime = new Date(currentDateTime);

			let startDateTime = `${response.data.data.campaign_start_date}T${response.data.data.campaign_start_time}`;	
			startDateTime= new Date(startDateTime);

			let endDateTime = `${response.data.data.campaign_end_date}T${response.data.data.campaign_end_time}`;	
			endDateTime= new Date(endDateTime);

			localStorage.setItem("member_data", JSON.stringify(response.data.data))
			setMemberdata(response.data.data)
			setIscompleted(response.data.data.is_completed)
			setapicalled(true);

			if(response.data.data.is_completed === true){
				setisModalOpenGlobalAlertPopup2(true);
				setGlobalAlertPopup2msg(Youhavealreadycompletedthesurvey)
			}
            else{

				if (currentDateTime < startDateTime) {
					setisModalOpenGlobalAlertPopup2(true);
					setGlobalAlertPopup2msg(Thecampaignhasnotstartedyet)
				}

				else if (currentDateTime >= startDateTime && currentDateTime <= endDateTime) {
					LetsGetStartedBtn();
				} 

				else {
					setisModalOpenGlobalAlertPopup2(true);
					setGlobalAlertPopup2msg(Thecampaignhasended)
				}
			}


			if (response.data.data.last_question_id !== 0) {
				setIsstarted(true);
			}
			// setIsLoading(false)
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}

	return (
		<Box className={"Welcomecontainer"} >
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}

			<Grid container style={{display:"none"}}>

				<Grid xs={12} lg={6} className={"headerGrid"}>
					<div className={"headingimgdiv"} tabIndex="0">
						<img src={logo} alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
					</div>
				</Grid>

				<Grid xs={12} lg={6} className={"bodygrid"}> <br></br>
					<div>
						<div className={"welcometext"} tabIndex="0">
							{isstarted ? WelcomeBack + " " + memberdata.first_name + "!" : Welcometotheclub + ", " + memberdata.first_name + "!"}
						</div>
						<div style={{ textAlign: "left" }} tabIndex="0">
							<p>{FirstLine}</p>
							<p>{secondtLine}.</p>
							<p>
								{
									isstarted ? Letpickupwhereweleftoff + "..." : Letstartbymakingsurewegotyourcontactinformationdownright + "..."

								}

							</p>

							<p></p>
						</div>
					</div>
				</Grid>

				<Grid xs={12}>
					{

						(iscompleted && apicalled) &&

						<Button variant="contained" aria-label="LetsGetStarted" type="submit" className={"submitbtn"} onClick={LetsGetStartedBtn}
						>{KeepGoingtext}</Button>

					}

					{

						(iscompleted === false && apicalled) &&
						<Button variant="contained" aria-label="LetsGetStarted" type="submit" className={"submitbtn"} onClick={LetsGetStartedBtn}
						>{isstarted ? KeepGoingtext : LetsGetStarted}!</Button>


					}

				</Grid>



			</Grid>

			<GlobalAlertPopup2
				isOpen={isModalOpenGlobalAlertPopup2}
				Heading={GlobalAlertPopup2msg}
				body=""
				btnname1="OK"
				handleOKButton={handleOKButton_GlobalAlertPopup2}
			/>
		</Box>
	)
}



export default PromotionWelcome