import React from "react";
import "./MemberSnapshotPopup.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";

import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import {
	preferredChannel,
	preferredFrequency,
} from "../../../Constants/static";
import { phoneFormatter } from "../../../Utilities/utils";

const style = {
	position: "absolute",
	top: "20%",
	left: "50%",
	padding: "10px 0px",
	transform: "translate(-50%, -50%)",
	width: "60%",
	bgcolor: "rgba(237, 237, 237, 1)",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

function MemberSnapshotPopup({
	isOpen,
	handleOKButton,
	from,
	data,
	item,
	viewResolved,
}) {
	const [queryInfo, setQueryInfo] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [memberSnapShotData, setMemberSanpshotData] = useState({});
	const [personalInfo, setPersonalInfo] = useState({});
	const [campaignInfo, setCampaignInfo] = useState({
		active_campaign_data: [
			{
				campaign_id: 91,
				campaign_name: "OVWEL campaign",
				invitation_reminder_count: 0,
				notification_count: 0,
				status: 1,
			},
			{
				campaign_id: 92,
				campaign_name: "PEPRS",
				invitation_reminder_count: 0,
				notification_count: 2,
				status: 2,
			},
		],
		completed_count: 0,
		initiated_count: 1,
		member_active_campaigns_count: 2,
	});

	useEffect(() => {
		let memberId = data;
		// let agentNotificationId;
		if (data != "") {
			let variant = 0;
			setIsLoading(true);
			let apiurl = URL.AGENT_MEMBER_SNAPSHOT;

			var params = new URLSearchParams();
			params.append("member_id", memberId);
			// params.append("agent_notification_id", agentNotificationId)
			params.append("variant", variant);

			Apiservice.getMethodParams(apiurl, params)
				.then((response) => {
					setIsLoading(false);
					setMemberSanpshotData(response.data.data);
					setPersonalInfo(response.data.data.personal_info);
					setCampaignInfo(response.data.data.campaign_info);
				})
				.catch((error) => {
					setIsLoading(false);
					console.error("There was an error!", error);
				});
		}
	}, [data]);

	const postQuery = () => {
		let campaign_code = sessionStorage.getItem("campaign_code");
		setIsLoading(true);
		const body = {
			member_id: data.toString(),
			answer_id: item.answer_id,
			campaign_code: campaign_code,
			agent_comment: queryInfo,
			variant: 0,
		};
		let apiurl = URL.UNRESOLVED_NOTIFICATION_SUBMIT;
		Apiservice.postMethod(apiurl, body)
			.then((response) => {
				setIsLoading(false);
				handleClickcancel();
				window.location.reload();
			})
			.catch((error) => {
				setIsLoading(false);
				console.error("There was an error!", error);
			});
	};

	const handleQuerySubmit = (query) => {
		setQueryInfo(query);
		console.log(query);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		postQuery();
		setQueryInfo("");
	};

	const handlecallbtn = () => { };

	const handleemailbtn = () => {
		window.location.href = "mailto:" + personalInfo.email;
	}

	function handleClickcancel(data) {
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOKButton(data);
	}

	const invitationStatusText = (numb)=> {
		let status = parseInt(numb);
		if(status == 0){
			return "Link not Shared"
		}else if(status == 1){
			return "Invitation received"
		}else if(status == 2){
			return "Invite link followed"
		}else{
			return "Survey completed"
		}
	}

	const campaignInfoRows = campaignInfo.active_campaign_data.map(
		(item, index) => {
			return (
				<React.Fragment key={index}>
					<Grid className="campaign-info-grid-1-cell" xs={3}>
						<div className="campaign-grid-1-cell-data">
							{item.campaign_name !== null
								? item.campaign_name
								: "N/A"}
						</div>
					</Grid>
					<Grid className="campaign-info-grid-1-cell" xs={3}>
						<div className="campaign-grid-1-cell-data">
							{item.notification_count !== null
								? item.notification_count
								: "N/A"}
						</div>
					</Grid>
					<Grid className="campaign-info-grid-1-cell" xs={3}>
						<div className="campaign-grid-1-cell-data">
							{item.invitation_reminder_count !== null
								? item.invitation_reminder_count
								: "N/A"}
						</div>
					</Grid>
					<Grid className="campaign-info-grid-1-cell" xs={3}>
						<div className="campaign-grid-1-cell-data">
							{item.status !== null ? invitationStatusText(item.status) : "N/A"}
						</div>
					</Grid>
				</React.Fragment>
			);
		}
	);

	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className="MemberSnapshot-container" sx={style}>
					{/* <ModalGrid handleClose={handleClose} /> */}
					{/*  */}
					{isLoading && (
						<CommoncircularProgress></CommoncircularProgress>
					)}
					<Box className="modal-container">
						<Box className="grid-container">
							<Grid
								container
								rowSpacing={2}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							>
								<Grid
									className="data-containers container-1"
									xs={12}
								>
									<img
										src="/asset/Agent/Call.png"
										alt="searchicon"
										className="iconsbtn"
										onClick={handlecallbtn}
									/>{" "}
									&nbsp;&nbsp;
									<img
										src="/asset/Agent/Message.png"
										alt="searchicon"
										className="iconsbtn"
										onClick={handlecallbtn}
									/>
									&nbsp;&nbsp;
									<img
										src="/asset/Agent/Email.png"
										alt="searchicon"
										className={personalInfo.email === "" ? "iconsbtndisabled" : "iconsbtn" }
										onClick={handleemailbtn}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<img
										src="/asset/Member/cancelicon.png"
										alt="cancelIcon"
										width="20px"
										height="20px"
										style={{
											cursor: "pointer",
											marginRight: "-20px",
										}}
										onClick={handleClickcancel}
									/>
								</Grid>
								
								{from !== "campaignpage" && (
									<div className="stickydiv">
									<Grid
										className="data-containers container-2"
										xs={12}
									>
										<p className="container-2-infotext">
											<span className="member-name-link">
												{item.member_name}
											</span>{" "}
											<span
												dangerouslySetInnerHTML={{
													__html: `${item.notification_text}`,
												}}
											></span>
										</p>
										<form
											className="container-2-form"
											action=""
											method=""
											autoComplete="on"
											onSubmit={handleFormSubmit}
										>
											<TextField
												className="container-2-form-text-field"
												id="outlined-size-normal"
												defaultValue="Normal"
												type="text"
												placeholder="Type your comment"
												value={
													!viewResolved
														? queryInfo
														: item.agent_comment
												}
												onChange={(e) =>
													handleQuerySubmit(
														e.target.value
													)
												}
												disabled={viewResolved}
											/>
											{viewResolved ? (
												""
											) : (
												<Button
													className="container-2-form-submit-btn"
													variant="contained"
													type="submit"
													disabled={!queryInfo}
												>
													Submit
												</Button>
											)}
										</form>
										
									</Grid>
									<div style={{padding: '10px', position:' sticky', top: '100px',backgroundColor: "rgba(237, 237, 237, 1)"}}></div>
									</div>
								)}
								
								<Grid
									className="data-containers container-3"
									xs={12}
								>
									{/* <PersonalInfoGrid personalInfo={personalInfo} /> */}
									{/*  */}
									<Box
										className="personal-info-box"
										sx={{ width: "100%" }}
									>
										<p className="personal-info-banner">
											Personal Information
										</p>
										<Grid
											className="persoanl-info-data-grid"
											container
											rowSpacing={3}
											columnSpacing={{
												xs: 1,
												sm: 2,
												md: 3,
											}}
										>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Name
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.first_name !==
														null
														? personalInfo.first_name
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Member ID
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.member_id !==
														null
														? personalInfo.member_id
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Gender
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.gender_code !== null ? (
														personalInfo.gender_code === "MALE" ? (
															<span className="cell-content">Male</span>
														) : personalInfo.gender_code === "FEMAL" ? (
															<span className="cell-content">Female</span>
														) : personalInfo.gender_code === "TRANS" ? (
															<span className="cell-content">Transgender</span>
														) : (
															personalInfo.gender_code
														)
													) : (
														"N/A"
													)}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Date of Birth
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.dob !== null
														? personalInfo.dob
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Phone Number
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.phone_number !==
														null
														? phoneFormatter(
															personalInfo.phone_number
														)
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Address
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.street !==
														null
														? personalInfo.street
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Channel Preference
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.pref_channel !==
														null
														? preferredChannel[
														personalInfo
															.pref_channel
														]
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Frequency Preference
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.com_frequency !==
														null
														? preferredFrequency[
														personalInfo
															.com_frequency
														]
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Language
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.language_name !==
														null
														? personalInfo.language_name
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Plan Name
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.plan_name !==
														null
														? personalInfo.plan_name
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Plan Type
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.plan_type_code !==
														null
														? personalInfo.plan_type_code
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													PCP
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.doctor_name !==
														null
														? personalInfo.doctor_name
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Start Date
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.start_date !==
														null
														? personalInfo.start_date
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Enrollment Date
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.enrollment_date !==
														null
														? personalInfo.enrollment_date
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Doctor Phone Number
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.doctor_phone !==
														null
														? phoneFormatter(
															personalInfo.doctor_phone
														)
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Pharmacy
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.pharmacy_name !==
														null
														? personalInfo.pharmacy_name
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Pharmacy Phone Number
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.pharmacy_phone !==
														null
														? personalInfo.pharmacy_name
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Provider Phone Number
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.provider_phone !==
														null
														? personalInfo.provider_phone
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Date of Pharmacy Assignment
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.date_of_pharmacy_assignment !==
														null
														? personalInfo.date_of_pharmacy_assignment
														: "N/A"}
												</div>
											</Grid>
											<Grid
												className="personal-info-grid-cells"
												xs={4}
											>
												<div className="pers-info-cell-variable">
													Date of PCP Assignment
												</div>
												<div className="pers-info-cell-data">
													{personalInfo.date_of_pcp_assignment !==
														null
														? personalInfo.date_of_pcp_assignment
														: "N/A"}
												</div>
											</Grid>
										</Grid>
									</Box>
									{/*  */}
								</Grid>
								<Grid
									className="data-containers container-4"
									xs={12}
								>
									{/* <CampaignInfoGrid campaignInfo={campaignInfo} /> */}
									{/*  */}
									<Box
										className="campaign-info-box"
										sx={{ width: "100%" }}
									>
										<p className="campaign-info-banner">
											Campaign Information
										</p>
										{/* conditional rendering of campaigninfo  */}
										{campaignInfo.active_campaign_data.length !== 0 ?
											<Grid
												className="campaign-info-grid-1"
												container
												rowSpacing={3}
												columnSpacing={{
													xs: 2,
													sm: 2,
													md: 3,
												}}
											>
												<Grid
													className="campaign-info-grid-1-cell"
													xs={3}
												>
													<div
														className="campaign-grid-1-cell-data"
														style={{
															fontWeight: "bold",
														}}
													>
														Currently, active campaign
													</div>
												</Grid>
												<Grid
													className="campaign-info-grid-1-cell"
													xs={3}
												>
													<div
														className="campaign-grid-1-cell-data"
														style={{
															fontWeight: "bold",
														}}
													>
														Number of Flags
													</div>
												</Grid>
												<Grid
													className="campaign-info-grid-1-cell"
													xs={3}
												>
													<div
														className="campaign-grid-1-cell-data"
														style={{
															fontWeight: "bold",
														}}
													>
														Number of reminders sent
														within each
													</div>
												</Grid>
												<Grid
													className="campaign-info-grid-1-cell"
													xs={3}
												>
													<div
														className="campaign-grid-1-cell-data"
														style={{
															fontWeight: "bold",
														}}
													>
														Current status within each
													</div>
												</Grid>
												{campaignInfoRows}
											</Grid> :
											<div className="no-campaign-info-content">Currently not involved in any active campaigns</div>
										}

										{/*  */}

										{/* second grid */}

										<Grid
											className="campaign-info-grid-2"
											container
											rowSpacing={2}
											columnSpacing={{
												xs: 1,
												sm: 2,
												md: 3,
											}}
										>
											<Grid
												className="campaign-info-grid-2-cell"
												xs={6}
											>
												<div className="campaign-grid-2-cell-data">
													Number of past campaign
													initiated{" "}
													{campaignInfo.initiated_count !==
														null
														? campaignInfo.initiated_count
														: "N/A"}
												</div>
											</Grid>
											<Grid
												style={{ marginLeft: "2%" }}
												className="campaign-info-grid-2-cell"
												xs={6}
											>
												<div className="campaign-grid-2-cell-data">
													Number of past campaign
													completed{" "}
													{campaignInfo.completed_count !==
														null
														? campaignInfo.completed_count
														: "N/A"}
												</div>
											</Grid>
										</Grid>
									</Box>
									{/*  */}
								</Grid>
							</Grid>
						</Box>
					</Box>
					{/*  */}
				</Box>
			</Modal>
		</div>
	);
}

export default MemberSnapshotPopup;
