import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import Theme from  "./Services/Theme";

import { Provider } from 'react-redux';
import store from './Redux/store';


// for srr use hydrateroot line 
 //const root = ReactDOM.hydrateRoot(document.getElementById('root'));  
 // for normal use create root line and uncomment comment browserrouter line 15 if commented
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//    <React.StrictMode>
     <Provider store={store}>
     <BrowserRouter>   
     <Theme><App /></Theme>
    
     </BrowserRouter>
     </Provider>
//    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
