import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';

import "./RequestCallbackPopup.css"

const FormatDateTime = (flag) => {
	const dateObject = new Date();
	// const dateObject = new Date(value);

	const hours = dateObject.getHours();
	const minutes = dateObject.getMinutes();
	const seconds = dateObject.getSeconds();
	const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
	const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

	// Combining hours, minutes, and seconds
	const formattedTime = hours + ':' + formattedMinutes + ':' + formattedSeconds;

	const date = dateObject.getDate();
	const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
	const year = dateObject.getFullYear();

	const formattedDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);

	if (flag === "Date"){
		return formattedDate
	}
	else{
		return formattedTime
	}
}


const RequestCallbackPopup = (props) =>{
	const {text, handleSelect, heading, btn1, btn2, varient } = props
	const [open, setOpen] = React.useState(false);


	const handleOpen = () => {
		setOpen(true);
	};

	// const handleClose = () => {
	// 	setOpen(false);
	// 	handleModalOpen(false); // Call parent's function and pass value
	// };

	const handleConfirm = () =>{
		setOpen(false);
		// handleSelect is accessed from the parent file here it is accessed as propss.
		handleSelect(FormatDateTime("Date"), FormatDateTime("Time"), "" ,varient)
	}

	const handleCancel = () =>{
		setOpen(false);
	}


	return (
		<div className='reqCallbackParent'>
			<Button onClick={handleOpen} className={varient}>{heading}</Button>
			<Modal
				open={open}
				// onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className={"reqcallbackModal"}>
					<Grid container >
						<Grid item xs={12}>
							<Typography id="modal-modal-title" variant="h6" sx={{fontSize:"var(--fontsizesmall)"}} >
								<strong tabIndex="0">{heading}</strong>
							</Typography>
							<Typography id="modal-modal-description" mt={3} sx={{fontSize:"var(--fontsizesmall)"}}>
								{/* Hey John, Your agent <strong>Steve Smith</strong> will call you at <strong>(256) 256-9586</strong> */}
								<span tabIndex="0"  dangerouslySetInnerHTML={{ __html: text }} />
							</Typography>

						</Grid>

						<Grid container  mt={5} mb={3} justifyContent={"space-between"}>
							<Grid item xs={6}>
								<Button variant="contained" aria-label="confirmAppointmentbtn" className="callbackConfirm" onClick={handleConfirm}>{btn1}</Button>
							</Grid>
							<Grid item xs={6}>
								<Button variant="contained" aria-label="cancelAppointmentbtn" className="callbackCancel" onClick={handleCancel}>{btn2}</Button>
							</Grid>

						</Grid>
					</Grid>

				</Box>
			</Modal>
		</div>
	);
}


export default RequestCallbackPopup