import "./Reports.css";
import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const SubAccord = ({ type, answer }) => {
	const [subAccord, setSubAccord] = useState(false);

	const handleOpenSubAccord = (e) => {
		e.stopPropagation();
		setSubAccord((prev) => !prev);
		// setIsParentExpanded(expanded);
	};
	const handleOpen = (e) => {
		e.stopPropagation();
		handleOpenSubAccord(e);
	};
	return (
		<Grid xs={12}>
			<Item style={{ marginTop: "5px" }} className="data-box">
				<p className="accordion-type-2-text">
					{answer.answer_text}{" "}
					{answer?.other_answers.length > 0 ? (
						<span
							className="open-sub-accord-btn"
							onClick={handleOpen}
						>
							{" "}
							{subAccord ? " -" : "+"}
						</span>
					) : (
						""
					)}
				</p>
				{type !== "INP" ? (
					<div className="variable-container">
						{type == "SSO" ||
						type == "SSI" ||
						type == "YNO" ||
						type == "YNI" ? (
							<div className="variable-1-data">
								{answer.response_selected_count}
							</div>
						) : (
							""
						)}
						{answer.answer_type_id !== "SSI" ? (
							<div className="variable-1-data">
								{answer.selected_percentage}
							</div>
						) : (
							""
						)}
						{type == "SSO" || type == "SSI" || type == "FIN" ? (
							""
						) : (
							<div className="variable-2-data">
								{answer.response_selected_count}
							</div>
						)}
					</div>
				) : (
					""
				)}
			</Item>
			{!subAccord ? (
				<></>
			) : (
				<div className="subaccordion-data-container">
					{answer.other_answers.map((text, index) => {
						return (
							<div
								key={index}
								className="subaccordion-data-point"
							>
								{text.other_answer_text}
							</div>
						);
					})}

					{/* <div className="subaccordion-data-point">
						lorem djbjbjvfr bjbjdbf hbhjbjbjbjbjfb jbjbjbjbjdf
						jbjbjbjbkbdfv nbjbkbkfbfv kbfkbvkfbvkfvbkfb kbkbvf
					</div> */}
				</div>
			)}
		</Grid>
	);
};

export default SubAccord;
