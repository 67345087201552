import React from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';

import HeaderOnBoarding from "../../../Shared/HeaderOnBoarding/HeaderOnBoarding";
import CustomLanguageservice from "../../../Services/CustomLanguageservice";
import { apiurl } from "../../../Environment/Environment";
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import SuccessPopup from "../../../Shared/Popups/SuccessPopup/SuccessPopup"

function ContactInformation() {
	const isBrowser = () => typeof window !== "undefined";
	const [radiovalue, setradiovalue] = React.useState(1);
	const location = useLocation();
	const [progress, setProgress] = React.useState(0);
	const [apiPhoneNumber, setapiPhoneNumber] = React.useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const Navigate = useNavigate();
	const [scrollflag, setScrollflag] = useState(false);
	const [changeflag, setChangeflag] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors }, clearErrors,
		reset
	} = useForm({
		mode: "onChange"
	});

	let currentPath = location.pathname;


	// localisation data
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	let PhoneTypetext = CustomLanguageservice.getMethod("Phone Type", language);
	let Whattypeofphonenumberisthis = CustomLanguageservice.getMethod("What type of phone number is this?", language);
	let Thisisacellphone = CustomLanguageservice.getMethod("This is a cell phone", language);
	let Thisisalandline = CustomLanguageservice.getMethod("This is a landline", language);
	let Ifyouhaveamobilenumberenterithere = CustomLanguageservice.getMethod("If you have a mobile number, enter it here: (optional)", language);
	let MobileNumber = CustomLanguageservice.getMethod("Mobile Number", language);
	let Invalid = CustomLanguageservice.getMethod("Invalid", language);
	let mustbe10digits = CustomLanguageservice.getMethod("must be 10 digits", language);
	let CellPhone = CustomLanguageservice.getMethod("Cell Phone", language);
	let Landline = CustomLanguageservice.getMethod("Landline", language);
	let Next = CustomLanguageservice.getMethod("Next", language);

	let CellPhoneadded = CustomLanguageservice.getMethod("Cell Phone added", language);

    let checkboxdata = [
        {
            id: 1,
            name: CellPhone,
        },
        {
            id: 2,
            name: Thisisalandline
        }];

	useEffect(() => {
		setProgress((100 / 8) * 4);
		// profile api
		setIsLoading(true);
		let endpoint = "/user/get_user_info";
		let url = apiurl + endpoint;
		let apidata = {
			"route_name": currentPath
		}
		Apiservice.postMethod(url, apidata).then(response => {
			setIsLoading(false);
			let ProfileInformationapiData = response.data.data;
			let number; 
			// debugger
			if(ProfileInformationapiData.mobile === "" || ProfileInformationapiData.mobile === null) {
				number =""
			}
			else{
				number= ProfileInformationapiData.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
			}

			setapiPhoneNumber(ProfileInformationapiData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'));

			setTimeout(() => {
				reset({
					MobileNumber: number,
				});
			}, 100);

			if (ProfileInformationapiData.is_landline === "true") {
				setradiovalue(2);
			}
			else {
				setradiovalue(1);
			}
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}, []);


	function handleChange(event) {
		setChangeflag(true)
		setradiovalue(parseInt(event.target.value))

		if (event.target.value === "2") {
			setScrollflag(true)
		}
		else {
			setScrollflag(false)
		}

	}

	
	const handleKeyDown = (event, value) => {
		setChangeflag(true)
		if (event.key === 'Enter') {
			setradiovalue(parseInt(value))

			if (value === "2" || value === 2) {
				setScrollflag(true)
			}
			else {
				setScrollflag(false)
			}
		}
	};

	const handleChangephone = (e, field) => {
		setChangeflag(true)
		// Remove all non-numeric characters from input
		const formattedPhoneNumber = e.target.value.replace(/\D/g, '');
		if (formattedPhoneNumber === '0000000000' || formattedPhoneNumber === '0000000000000') {
			return;
		}
		// Format the phone number as (###) ### ####
		const formattedPhoneNumberWithSpaces = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		// Update the input value
		field.onChange(formattedPhoneNumberWithSpaces);
	};

    function ContactinfoNext(data) {
        let datatitle;
        let data1;
        let data2;
        let data3;
        let data4;
		let data5;
		// debugger
        if (radiovalue === 1) {
			
            datatitle = ""
            data1 = CellPhone
            data2 = "";
            data3 = "";
            data4 = "";
			data5 = apiPhoneNumber;
        }
        else {
			let cellphontext;
			
			if (data.MobileNumber === undefined || (data.MobileNumber === "")) {
				
				cellphontext = "";
			}
			else{
				cellphontext= CellPhoneadded+"!"
			}
            // datatitle = "Cell Phone added!"
            data1 = Landline
            data2 = apiPhoneNumber;
            data3 = ""
            data4 = cellphontext
			data5 = data.MobileNumber
        }

        const jsonData =
        {
            componentname: "Contact Information",
            image: "/asset/tick.png",
            datatitle: datatitle,
            heading1: data1,
            heading2: data2,
            heading3: data3,
            heading4: data4,
			heading5: data5,


		};



		let apidata;

		if (radiovalue === 1) {
			apidata = {
				"phone": apiPhoneNumber,
				"is_landline": "false",
				"route_name": currentPath
			}
		}
		else {
			let MobileNumber;
			if (data.MobileNumber === undefined) {
				MobileNumber = "";
			}
			else {
				MobileNumber = data.MobileNumber.replace(/\D/g, '');
			}
			apidata = {
				"phone": MobileNumber,
				"is_landline": "true",
				"route_name": currentPath
			}
		}

		// api call
		setIsLoading(true);
		let endpoint = "/user/save_contact_info"
		let url = apiurl + endpoint;
		Apiservice.putMethod(url, apidata).then(response => {
			setIsLoading(false);
			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			if(changeflag === true){
				setIsModalOpen(true);
			setTimeout(() => {
				setIsModalOpen(false);
				Navigate('/PreferredChannel');
			}, 3000);
			}
			else{
				Navigate('/PreferredChannel');
			}
			

		})
			.catch(error => {
				setIsLoading(false);
				setIsModalOpen(false);
			})
	}

	useEffect(() => {
		if (scrollflag) {
			// Find the div element by its class or id
			const divElement = document.querySelector('.bodygrid_ContactInformation');
	
			if (divElement) {
				// Scroll to the bottom of the div
				divElement.scrollTop = divElement.scrollHeight - divElement.clientHeight;
			}
		}
	}, [scrollflag]);


	function back() {
		Navigate('/AdressInformation');
	}

	return (
		<Box className={"Contactcontainer"}>
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />

			<Grid container xs={12} className={"bodygrid_ContactInformation"}>

				<SuccessPopup
					isOpen={isModalOpen}
					title="User Modal Title"
					description="This is the user modal description."
				/>

				<Grid xs={1} style={{ zIndex: 1, cursor: "pointer", marginLeft: "-2vw" }}>  <br></br>
					<img src="/asset/leftarrow.png" tabIndex="0" alt="leftarrow" onClick={() => back()} 
					onKeyDown={(e) => {
						if (e.key === "Enter") {
						back();
						}
						}}/>
				</Grid>

				<Grid xs={11} >  <br></br>
					<div className={"Profileinfo"} tabIndex="0">
						{PhoneTypetext}
					</div>

				</Grid>

				<Grid xs={12} > <br></br>
					<LinearProgress variant="determinate" value={progress} className={"Linierprogress"} tabIndex="0"/>
				</Grid>

				<form onSubmit={handleSubmit(ContactinfoNext)} style={{ width: "100%" }}>

					<Grid container xs={12}>

						<Grid xs={12} > <br></br>
							<div className="text1_profile" tabIndex="0">
								{Whattypeofphonenumberisthis}
							</div>
						</Grid>

						<Grid xs={12}><br></br>
							<div className={"informationboxAddress"}>
								<div className={"namelabel2"} tabIndex="0"> <br></br>
									{apiPhoneNumber}
								</div>
							</div>
						</Grid>


						<Grid xs={12}>

							<div className={"checkitemdiv"}><br></br>
								{checkboxdata.map((data, index) => (
									<RadioGroup row aria-labelledby="chose" key={index} value={radiovalue} onChange={handleChange}>
										<FormControlLabel control={<Radio value={data.id} />} label={data.name} className="text1_profile" 
										onKeyDown={(event) => handleKeyDown(event, data.id)}
										tabIndex={0}/>
									</RadioGroup>

								))}
							</div>
						</Grid>

						{radiovalue === 2 &&

							<Grid container xs={12}>
								<Grid xs={1}><br></br>
									<div className={"imageline"}>
									</div>
								</Grid>

								<Grid xs={11}>
									<div className="text1_profile" tabIndex="0"><br></br>
										{Ifyouhaveamobilenumberenterithere}
									</div>


									<Controller
										name="MobileNumber"
										control={control}
										rules={{ pattern: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ }}
										render={({ field }) =>
											<div><br></br>
												<InputLabel htmlFor="my-input" className={"inputlabel"}>{MobileNumber}</InputLabel>
												<div className={"textbox1"}>
													<TextField
														variant="outlined"
														placeholder={MobileNumber}
														value={field.value || ''}
														onChange={(e) => handleChangephone(e, field)}
														type="tel"
														inputProps={{
															maxLength: 10
														  }}
														InputProps={{
															className: 'customInput',
															style: {
																border: errors?.MobileNumber?.type === "required" || errors?.MobileNumber?.type === "pattern" ? "1px solid red" : "transparent",
															}

														}}

													/>
												</div>

												{errors?.MobileNumber?.type === "pattern" &&
													<div className={"errormsg"} tabIndex="0">{Invalid} {MobileNumber}, {mustbe10digits}</div>}

											</div>




										}
									/>



								</Grid>


							</Grid>



						}




					</Grid>



					<Grid container xs={12}>
						<div>
							<Button variant="contained" aria-label="nextbtn" type="submit" className={"nextbtn"}
							>{Next}</Button>
						</div>
					</Grid>
				</form>

			</Grid>
		</Box>
	)

}


export default ContactInformation