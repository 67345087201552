import React from 'react';
import "./Publish_ErrorPopup.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

function Publish_ErrorPopup({ isOpen, Heading, body,btnname1,btnname2, handlecancelButton, handleEditButton }) {

	function handleEditbtn(data) {
		handleEditButton(data);
	};

	function handleClickcancel(data){
		handlecancelButton(data);
	};


	return (
		<div >
		<Modal
			open={isOpen}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">


			<Box className={"PunlishErrorPopup"}>
				<Grid container xs={12} className={"CommonLoadermaingrid"} spacing={2}>
				<Grid xs={12} className={"GridIcon"}><br></br>
						<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="20px" height="20px" onClick={handleClickcancel} />
					</Grid>

					<Grid xs={12} style={{textAlign:"left"}}><br></br>
						<div className={"textlabel1"}>
							{Heading} &nbsp;

							<img src="/asset/Agent/warning.png" alt="call" style={{ width: "24px" }}/>
						</div>

					</Grid>

					<Grid xs={12} style={{textAlign:"left"}}>
						<div className={"textlabel2"}>
							{body}
						</div>

					</Grid>

					<Grid xs={6} style={{textAlign:"right"}}><br></br><br></br>
						
					
					</Grid>

					<Grid xs={3} style={{textAlign:"right"}}><br></br><br></br>
						<Button variant="contained" aria-label="Ok" className="okbtn" onClick={handleClickcancel}>{btnname1}</Button>
					
					</Grid>

					<Grid xs={3} style={{textAlign:"left"}}><br></br><br></br>
						<Button variant="contained" aria-label="Ok" className="Nobtn" onClick={handleEditbtn}>{btnname2}</Button>
					
					</Grid>


				</Grid>
			</Box>

		</Modal>
	</div>
	)

}

export default Publish_ErrorPopup