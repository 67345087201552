import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./Table.css"

import CustomLanguageservice from '../../Services/CustomLanguageservice';


function AppointmentTable(props) {
	const { rows, language } = props; 

	let Doctor = CustomLanguageservice.getMethod("Doctor",language)
	let AppointmentType = CustomLanguageservice.getMethod("Appointment Type",language)
	let Date = CustomLanguageservice.getMethod("Date",language)
	let Time = CustomLanguageservice.getMethod("Time",language)
	let MedicalAdvisor = CustomLanguageservice.getMethod("Medical Advisor", language) 

	return (
		<TableContainer component={Paper} className='appointmentTable'>
			<Table
				// sx={{ minWidth: 650 }}
				>
				<TableHead className='tableHead'>
					<TableRow >
						<TableCell className='tableHeadcell' style={{borderTopLeftRadius:"10px"}} tabIndex="0">{MedicalAdvisor}</TableCell>
						<TableCell className='tableHeadcell' align="center" tabIndex="0">{AppointmentType}</TableCell>
						<TableCell className='tableHeadcell' align="center" tabIndex="0">{Date}</TableCell>
						<TableCell className='tableHeadcell' align="center" style={{borderTopRightRadius:"10px"}} tabIndex="0">{Time}</TableCell>
					</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row) => (
					<TableRow
						key={row.medicalAdvisor}
						sx={{ 
							// '&:last-child td': { border: 0 },
							border:"1px solid black ", 
						boxShadow: "none !important"
					}}
					>
						<TableCell  className='tablerowcell' tabIndex="0">{row.medicalAdvisor}</TableCell>
						<TableCell className='tablerowcell' align="center" tabIndex="0">{row.appointmentType}</TableCell>
						<TableCell className='tablerowcell' align="center" tabIndex="0">{row.date}</TableCell>
						<TableCell className='tablerowcell' align="center" tabIndex="0">{row.time}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		</TableContainer >
	);
}

export default AppointmentTable;