import React, { useEffect, useState, useContext } from "react";
import "./Header.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import FonthandlePopup from "../Popups/FonthandlePopup/FonthandlePopup";
import { ThemeSelectorContext } from "../../Services/Theme";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';

import CommoncircularProgress from "../CommoncircularProgress/CommoncircularProgress";
import { ROUTES_MEMBER_LOGIN, ROUTES_MEMBER_PLAN_INFORMATION, ROUTES_MEMBER_PROFILE, ROUTES_MEMBER_MYDOCTOR, ROUTES_MEMBER_PREFERENCES, ROUTES_MEMBER_AGENT } from '../../Constants/routes';
import CustomLanguageservice from "../../Services/CustomLanguageservice";

const Header = props => {
	const isBrowser = () => typeof window !== "undefined";
	const [pathname, setPathname] = useState('/');
	const Navigate = useNavigate();
	const location = useLocation();
	const [isMobile, setIsMobile] = useState("");
	const [logo, setlogo] = React.useState("");
	const [Profilelogo, setProfilelogo] = React.useState("");
	const { toggleTheme } = useContext(ThemeSelectorContext);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [Member_firstname, setMember_firstname] = React.useState("");
	const [Member_lastname, setMember_lastname] = React.useState("");

	const [isnavigated, setisnavigated] = React.useState(false);


	// localisation data
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}

	let WelcomeToYourAccountSite_text = CustomLanguageservice.getMethod("Welcome To Your Account Site", language);
	let WhatDoYouWantToDoToday_text = CustomLanguageservice.getMethod("What Do You Want To Do Today", language);
	let VieworChangeMyProfileInformation_text = CustomLanguageservice.getMethod("View or Change My Profile Information", language);
	let VieworChangeMyPreferences_text = CustomLanguageservice.getMethod("View or Change My Preferences", language);
	let MakeorChangeaMedicalAppointment_text = CustomLanguageservice.getMethod("Make or Change a Medical Appointment", language);
	let CommunicateWithMyHealthBroker_text = CustomLanguageservice.getMethod("Communicate With My Health Broker", language);
	let SeeMyHealthPlanInfo_text = CustomLanguageservice.getMethod("See My Health Plan Info", language);
	let Logout_text = CustomLanguageservice.getMethod("Logout", language);
	let Member = CustomLanguageservice.getMethod("Member", language);

	useEffect(() => {
		// on location change ie. on route change we are calling useeffect, as header data needs to be updated
		// get api data
		setMember_firstname(sessionStorage.getItem("Member_firstname"));
		setMember_lastname(sessionStorage.getItem("Member_lastname"));
		setlogo("/asset/customcompanylogo.png");
		setProfilelogo("/asset/Member/Profileicon.png");
	}, [location]);


	// create an event listener
	useEffect(() => {
		// // On load check the screen size
		if (window.innerWidth < 1280) {
			setIsMobile(true)
			checknavigationstatus();

			let themeValue = sessionStorage.getItem("ThemeValue");
				toggleTheme(parseInt(themeValue));
				
		} else {
			setIsMobile(false)
		}
		window.addEventListener("resize", handleResize)
	}, [])

	//choose the screen size 
	const handleResize = () => {
		if (window.innerWidth < 1280) {
			setIsMobile(true);
			checknavigationstatus();
		} else {
			setIsMobile(false)
			document.getElementById("maincomponentdiv").style.display = "block";
		}
	}

	const checknavigationstatus = () => {
		// on refresh or resize dont load home page if page already navigated
	
		let isnavidated = sessionStorage.getItem("isnavigated");
		if (isnavidated === "true") {
			// setTimeout(() => {
				navigationbtn();
			// }, 10);

		}
		else{
			// setTimeout(() => {
			Homebutton("");
		// }, 10);
		}
	}



	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleOK = (value) => {
		sessionStorage.setItem("ThemeValue", value);
		handleCloseModal();
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			toggleTheme(value)

		}, 1000);
	};


	// calls when sidemenu component is changed
	useEffect(() => {
		let pathname = location.pathname.split('/')[1]
		setPathname(pathname);
	}, [location]);

	const navigationbtn = () => {
		// document.getElementById("mySidenav").style.width = "0rem";
		// document.getElementById("mySidenav").style.display = "none";
		setisnavigated(true);
		sessionStorage.setItem("isnavigated", "true");
		document.getElementById("maincomponentdiv").style.display = "block";
	}

	const Homebutton = (data) => {
		// document.getElementById("mySidenav").style.display = "block";
		// document.getElementById("mySidenav").style.width = "100vw";
		setisnavigated(false);
		sessionStorage.removeItem("isnavigated");
		document.getElementById("maincomponentdiv").style.display = "none";
		if(data === "onbuttonclick") {
			window.location.reload();
		}
	}

	const logout= () => {
		localStorage.setItem("TokenExpired", "true");
		sessionStorage.removeItem("isnavigated");
		sessionStorage.removeItem("Member_firstname");
		sessionStorage.removeItem("Member_lastname");
		sessionStorage.removeItem("ThemeValue");
		Navigate(ROUTES_MEMBER_LOGIN);
	}

	return (
		<Box className={"HeaderContainerMember"}>
			{pathname != "Login" && pathname != "" ?
				<div>
					{/* Mobile Header, Mobile Sidebar and Laptop Header */}
					{/* Mobile */}
					{isMobile === true &&
						<div>
							<div className={"HeaderMainContainer"}>
								<Grid container xs={12}>
									<Grid xs={2} className={"headingcol1_profile"}>
										<img src="/asset/Member/Homewhite.png" alt="HomeIcon" onClick={() => Homebutton("onbuttonclick")} />
									</Grid>

									<Grid xs={8} className={"headingimgGrid_mobile"}>
										<div className={"headingimgdivmobile"}>
											<img src={logo} alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
										</div>
									</Grid>

									<Grid xs={2} className={"headingcol2_profile"}>
										<div className={"headingimgdiv2_profile"}>
											<img src="/asset/fontimage.png" alt="fontChangeimage" style={{ width: "100%", height: "100%", objectFit: "contain", cursor: "pointer" }}
												onClick={handleOpenModal} />
										</div>
										<FonthandlePopup
											isOpen={isModalOpen}
											title="User Modal Title"
											description="This is the user modal description."
											handleClose={handleCloseModal}
											handleOK={handleOK}
										/>
									</Grid>

									{isLoading &&
										<CommoncircularProgress></CommoncircularProgress>
									}

								</Grid>
							</div>


							<div id="mySidenav" className={isnavigated===false ? "sidenavmobile" : "sidenavmobileclose"}>
								<Grid container className={"gridcontainermobile"}>
									<Grid xs={12}>
										<div className={"welcometext"}><br></br><br></br>
											{WelcomeToYourAccountSite_text}, {Member_firstname}! {WhatDoYouWantToDoToday_text}?
											<br></br><br></br><br></br><br></br>
										</div>
									</Grid>


									<Grid xs={12} onClick={() => navigationbtn()} >
										<NavLink to={ROUTES_MEMBER_PROFILE}>
											<div className={"buttondivmobile"}>
												<img src="/asset/Member/PersinalInformationblack.png" alt="itemlogo" className={"itemlogomobile"} />
												&nbsp;&nbsp;&nbsp;&nbsp;
												{VieworChangeMyProfileInformation_text}
											</div>
										</NavLink >

										<NavLink to={ROUTES_MEMBER_PREFERENCES} >
											<div className={"buttondivmobile"}>
												<img src="/asset/Member/preferencesBlueIcon.png" alt="itemlogo" className={"itemlogomobile"} />
												&nbsp;&nbsp;&nbsp;&nbsp;
												{VieworChangeMyPreferences_text}
											</div>
										</NavLink >

										<NavLink to={ROUTES_MEMBER_MYDOCTOR}>
											<div className={"buttondivmobile"}>
												<img src="/asset/Member/doctorblack.png" alt="itemlogo" className={"itemlogomobile"} />
												&nbsp;&nbsp;&nbsp;&nbsp;
												{MakeorChangeaMedicalAppointment_text}
											</div>
										</NavLink >

										<NavLink to={ROUTES_MEMBER_AGENT}>
											<div className={"buttondivmobile"}>
												<img src="/asset/Member/myagentblue.png" alt="itemlogo" className={"itemlogomobile"} />
												&nbsp;&nbsp;&nbsp;&nbsp;
												{CommunicateWithMyHealthBroker_text}
											</div>
										</NavLink >

										<NavLink to={ROUTES_MEMBER_PLAN_INFORMATION}>
											<div className={"buttondivmobile"}>
												<img src="/asset/Member/Planinformationblack.png" alt="itemlogo" className={"itemlogomobile"} />
												&nbsp;&nbsp;&nbsp;&nbsp;
												{SeeMyHealthPlanInfo_text}
											</div>
										</NavLink >
									</Grid>


									<Grid xs={12}><br></br><br></br>
										{/* <Button variant="contained" aria-label="Logout" type="submit" className={"logoutbtn"} onClick={() => logout()}
										> */}
										<div className="btndiv" >
											<img src="/asset/Member/loginicon.png" alt="Icon" className="image" onClick={() => logout()} />&nbsp;
											<span className={"linediv"} onClick={() => logout()}>{Logout_text}</span>
											{/* </Button> */}
											</div>
									</Grid>


								</Grid>
							</div>
						</div>
				    }

                     {isMobile === false &&
						<div className={"HeaderMainContainer"} id="content">

							{/* Laptop Header */}
							<Grid container xs={12}>
								<Grid xs={9}>
									<div className={"headingimgdiv_profile"} tabIndex="0">
										<img src={logo} alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
									</div>
								</Grid>

								<Grid xs={2} className={"contentGrid"}>
									<div className={"textlabel"} tabIndex="0">
										{Member_firstname} {Member_lastname}
									</div>

									<div className={"textlabel1"} tabIndex="0">
										{Member}
									</div>

								</Grid>

								<Grid xs={1} className={"contentGrid"}>
									<Tooltip title="Logout" arrow>
										<div className={"headingimgdiv_profileicon"} tabIndex="0" onClick={() => logout()} 
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													logout();
												}
												}}>
											<img src={Profilelogo} alt="Logout" className={"Profileimage"} />
										</div>
									</Tooltip>

								</Grid>
							</Grid>


						</div>
					 }

				</div>
				:
				<span></span>
			}
		</Box>

	)
}

export default Header;