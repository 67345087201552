import React from 'react';
import "./GlobalConfirmationDeletePopup.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

function GlobalConfirmationDeletePopup({ isOpen, Heading, body,btnname1,btnname2, handleYesButton, handleNoButton }) {
  
	function handleYesbtn(data) {
		handleYesButton(data);
	};

	function handleNoBtn(data) {
		handleNoButton(data);
	};

	function handleClickcancel(data){
		handleNoButton(data);
	};


	return (
		<div >
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className={"GlobalConfirmationdeletePopup"}>
					<Grid container xs={12} className={"CommonLoadermaingrid"} spacing={2}>
					<Grid xs={12} className={"GridIcon"}><br></br>
							<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="20px" height="20px" onClick={handleClickcancel} />
						</Grid>

						<Grid xs={12} style={{textAlign:"left"}}><br></br>
							<div className={"textlabel1"}>
								{Heading}
							</div>

						</Grid>

						<Grid xs={12} style={{textAlign:"left"}}>
							<div className={"textlabel2"}>
								{body}
							</div>

						</Grid>

						<Grid xs={6} style={{textAlign:"right"}}><br></br><br></br>
							<Button variant="contained" aria-label="Ok" className="okbtn" onClick={handleYesbtn}>{btnname1}</Button>
						
						</Grid>

						<Grid xs={6} style={{textAlign:"left"}}><br></br><br></br>
							<Button variant="contained" aria-label="Ok" className="Nobtn" onClick={handleNoBtn}>{btnname2}</Button>
						
						</Grid>


					</Grid>
				</Box>

			</Modal>
		</div>
	)

}

export default GlobalConfirmationDeletePopup