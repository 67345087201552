export const isUndefined = (value) => value === undefined;
export const isNull = (value) => (value === null || value === 'null');
export const isEmpty = (value) => value === '';
export const isArrayEmpty = (value) => value.length === 0;
export const isObjectEmpty = (value) => Object.keys(value).length === 0;

export const phoneFormatter = (value) => {
	if (!isUndefined(value) && !isNull(value) && !isEmpty(value)) {
		return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
	} else {
		return "";
	}
};