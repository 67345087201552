import * as React from "react";
import { useState, useEffect,useMemo} from "react";
import "./CampaignHistoryPopup.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import ComboChart from "./barchart1";
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import {
	ROUTES_AGENT_REPORTS,
	ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS,
} from "../../../Constants/routes";
import colorsGenerator from "./colors";


function ModalContent({ handleOKButton }) {
	const [historyData, setHistoryData] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const handleNavigateGenerateReports = (campaignCode,campaignName) => {
		let campDeatils = {
			campaign_name: campaignName,
			campaign_code: campaignCode,
		};
		sessionStorage.setItem("from_history_to_generate_report", JSON.stringify(campDeatils));
		handleOKButton("");
		navigate(ROUTES_AGENT_REPORTS);
	};

	const handleNavigateToAdminNotifications = (
		numb,
		campaignName,
		campaignCode
	) => {
		let campDeatils = {
			campaign_name: campaignName,
			campaign_code: campaignCode,
		};
		if (numb > 0) {
			navigate(ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS);
			sessionStorage.setItem(
				"from_history_to_adminnotifications",
				JSON.stringify(campDeatils)
			);
		}
	};

	useEffect(() => {
		const apiurl = URL.USER_STATS;
		setIsLoading(true);
		Apiservice.getMethod(apiurl)
			.then((response) => {
				setIsLoading(false);
				setHistoryData(response.data.data);
			})
			.catch((error) => {
				setIsLoading(false);
				console.error("There was an error!", error);
			});
	}, []);

	const { labels, barData, lineData, barColors } = useMemo(() => {
		if (!historyData?.surveyList) return { labels: [], barData: [], lineData: [], barColors:[] };
		
		const newLabels = [];
		const newBarData = [];
		const newLineData = [];
		let newBarColors = [];
		
		historyData.surveyList.forEach((stat) => {
			newLabels.push(stat.campaign_name);
			newBarData.push(stat.total_invites);
			newLineData.push(stat.surveys_started);
		});
		let colors = colorsGenerator(historyData?.surveyList.length)
	    newBarColors = [...colors];
	
		return { labels: newLabels, barData: newBarData, lineData: newLineData, barColors: newBarColors };
	}, [historyData]);
	
	// let labels = [];
	// let barData = [];
	// let lineData = [];

	// const generateRandomLightHexColor= ()=> {
	// 	console.log("okkkkkkkk");
	// 	// Generates a single light hex color code
	// 	const randomValue = () => Math.floor(Math.random() * 128) + 128; // ensures values between 128 and 255
	// 	const r = randomValue().toString(16).padStart(2, "0");
	// 	const g = randomValue().toString(16).padStart(2, "0");
	// 	const b = randomValue().toString(16).padStart(2, "0");
	// 	return `#${r}${g}${b}`;
	// }

	// const generateLightHexColorsArray=(count) =>{
	// 	console.log("pppppp");
	// 	// Generates an array of light hex color codes
	// 	const colorsArray = Array.from(
	// 		{ length: count },
	// 		generateRandomLightHexColor
	// 	);
	// 	return colorsArray;
	// }
	// let colors = generateLightHexColorsArray(historyData?.surveyList.length) ;

	////
	// let colors = colorsGenerator(historyData?.surveyList.length)
	// const barColors = [...colors];

	// historyData?.surveyList.map((stat) => {
	// 	labels.push(stat.campaign_name);
	// 	barData.push(stat.total_invites);
	// 	lineData.push(stat.surveys_started);
	// });

	//////   2nd container /////
	let tableData = historyData?.surveyList?.map((stat, index) => {
		return (
			<Grid
				key={stat.campaign_id}
				className="table-data-grid"
				container
				spacing={0}
			>
				<Grid
					className="data-table"
					xs={4}
					sx={{
						overflow: "auto",
						wordWrap: "break-word",
						whiteSpace: "normal",
						textOverflow: "ellipsis",
					}}
				>
					<p
						style={{
							backgroundColor: barColors[index],
							cursor: "pointer",
						}}
						className="table-data-col-1"
						onClick={() => {
							handleNavigateGenerateReports(stat.campaign_code,stat.campaign_name,);
						}}
					>
						{stat.campaign_name}
					</p>
				</Grid>
				<Grid className="data-table" xs={1.15}>
					<p className="table-data-all">{stat.start_date}</p>
				</Grid>
				<Grid className="data-table" xs={1.15}>
					<p className="table-data-all">{stat.end_date}</p>
				</Grid>
				<Grid className="data-table" xs={1.2}>
					<p className="table-data-all">{stat.total_invites}</p>
				</Grid>
				<Grid className="data-table" xs={1.8}>
					<p className="table-data-all">{stat.surveys_completed}</p>
				</Grid>
				<Grid className="data-table" xs={1.2}>
					<p className="table-data-all">{stat.completion_rate}</p>
				</Grid>
				<Grid className="data-table" xs={1.5}>
					<p
						className="table-data-all"
						style={{
							color: "rgba(93, 95, 239, 1)",
							textDecoration: "underline",
							cursor: "pointer",
						}}
						onClick={() => {
							handleNavigateToAdminNotifications(
								stat.unresolved_notification_count,
								stat.campaign_name,
								stat.campaign_code
							);
						}}
					>
						{stat.unresolved_notification_count}
					</p>
				</Grid>
			</Grid>
		);
	});

	return (
		<div className="popup-container">
			<p className="campaign-history-banner">Campaign History</p>
			<div className="data-container-1 data-containers">
				<p className="bar-chart-1-banner">User Stats</p>
				{labels?.length == 0 && barData?.length == 0 ? <div style={{'minHeight': '40vh',display:'flex',flexDirection: 'column', justifyContent:'center', alignItems:'center', color: '#004791', fontWeight: '700', fontSize: '18px',paddingBottom: '20px'}}><img src="/asset/no-notification-img.svg" width="115px"  /><div style={{paddingTop: '15px', marginLeft: '10px'}}>No User-Stats Data</div></div> :
				<ComboChart
					labels={labels}
					barData={barData}
					barColors={barColors}
					lineData={lineData}
				/>
}
			</div>
			<Box
				className="data-container-2 data-containers"
				sx={{ flexGrow: 1 }}
			>
				<p className="data-container-banner">Per-campaign stats</p>

				{labels?.length == 0 && barData?.length == 0 ? <div style={{'minHeight': '40vh',display:'flex',flexDirection: 'column', justifyContent:'center', alignItems:'center', color: '#004791', fontWeight: '700', fontSize: '18px',paddingBottom: '20px'}}><img src="/asset/no-notification-img.svg" width="115px"  /><div style={{paddingTop: '15px', marginLeft: '10px'}}>No Per-Campaign-Stats Data</div></div> :
				<Grid container className="table-grid" spacing={0}>
					<Grid xs={4}>
						<p className="table-headings">Campaign name</p>
					</Grid>
					<Grid xs={1.15}>
						<p className="table-headings">Start date</p>
					</Grid>
					<Grid xs={1.15}>
						<p className="table-headings">End date</p>
					</Grid>
					<Grid xs={1.2}>
						<p className="table-headings">Total invites sent</p>
					</Grid>
					<Grid xs={1.8}>
						<p className="table-headings">
							Total surveys completed
						</p>
					</Grid>
					<Grid xs={1.2}>
						<p className="table-headings">Completion rate</p>
					</Grid>
					<Grid xs={1.5}>
						<p className="table-headings">
							Unresolved Notification
						</p>
					</Grid>
				</Grid>
				}
				{tableData ? tableData : <div></div>}


			</Box>

			{/*  */}

			{/* Pie and horizontal bar Container component */}
			<div className="box-3">
				{/*  */}

				{/* Campaign stats Container */}

				<Box className="data-container-4" sx={{ flexGrow: 1 }}>
					<p className="box-3-campaign-stats-banner">
						Campaign stats
					</p>
					<Grid
						container
						spacing={2}
						className="campaign-stats-data-grid"
					>
						<Grid xs={2}>
							<div
								className="campaign-stats-data-cell"
								style={{
									border: "1px solid rgba(231, 100, 234, 1)",
									backgroundColor: "rgba(255, 216, 240, 1)",
								}}
							>
								<div>
									<p
										style={{
											color: "#E764EA",
											fontWeight: "700",
										}}
										className="campaign-stat-data-point"
									>
										{historyData
											? historyData.additional_data
													.promotion_base_campaign
											: ""}
									</p>
									<p className="campaign-stat-data-variable">
										Total number of promotion base campaigns run
									</p>
								</div>
							</div>
						</Grid>
						<Grid xs={2}>
							<div
								className="campaign-stats-data-cell"
								style={{
									border: "1px solid rgba(56, 182, 255, 1)",
									backgroundColor: "rgba(204, 232, 248, 1)",
								}}
							>
								<div>
									<p
										style={{ color: "#38B6FF" }}
										className="campaign-stat-data-point"
									>
										{historyData
											? historyData.additional_data
													.survey_base_campaign
											: ""}
									</p>
									<p className="campaign-stat-data-variable">
										Total number of survey base campaigns run
									</p>
								</div>
							</div>
						</Grid>
						<Grid xs={2}>
							<div
								className="campaign-stats-data-cell"
								style={{
									border: "1px solid rgba(112, 173, 71, 1)",
									backgroundColor: "rgba(227, 255, 207, 1)",
								}}
							>
								<div>
									<p
										style={{ color: "#70AD47" }}
										className="campaign-stat-data-point"
									>
										{historyData
											? historyData.additional_data
													.total_surveys_completed
											: ""}
									</p>
									<p className="campaign-stat-data-variable">
										Number of surveys completed
									</p>
								</div>
							</div>
						</Grid>
						<Grid xs={2}>
							<div
								className="campaign-stats-data-cell"
								style={{
									border: "1px solid rgba(129, 129, 221, 1)",
									backgroundColor: "rgba(221, 221, 255, 1)",
								}}
							>
								<div>
									<p
										style={{ color: "#8181DD" }}
										className="campaign-stat-data-point"
									>
										{historyData
											? historyData.additional_data
													.total_phone_based_surveys_completed
											: ""}
									</p>
									<p className="campaign-stat-data-variable">
										Phone-Based surveys completed
									</p>
								</div>
							</div>
						</Grid>
						<Grid xs={2}>
							<div
								className="campaign-stats-data-cell"
								style={{
									border: "1px solid rgba(253, 145, 19, 1)",
									backgroundColor: "rgba(255, 235, 212, 1)",
								}}
							>
								<div>
									<p
										style={{ color: "#FD9113" }}
										className="campaign-stat-data-point"
									>
										{historyData
											? historyData.additional_data
													.total_completion_rate
											: ""}
									</p>
									<p className="campaign-stat-data-variable">
										Overall completion rate
									</p>
								</div>
							</div>
						</Grid>
						<Grid xs={2}>
							<div
								className="campaign-stats-data-cell"
								style={{
									border: "1px solid rgba(179, 4, 4, 1)",
									backgroundColor: "rgba(255, 215, 215, 1)",
								}}
							>
								<div>
									<p
										style={{ color: "#B30404" }}
										className="campaign-stat-data-point"
									>
										{historyData
											? historyData.additional_data
													.total_resolved_count
											: ""}
									</p>
									<p className="campaign-stat-data-variable">
										Number of flagged items resolved
									</p>
								</div>
							</div>
						</Grid>
					</Grid>
				</Box>
			</div>
		</div>
	);
}

export default memo(ModalContent);
