import { apiurl, baseUrl } from "../Environment/Environment"

const ONBOARDING_BASE_URL = baseUrl + "/onboarding"
const MEMBER_ACCOUNT_BASE_URL = baseUrl + "/members"
const SURVEY_BASE_URL = baseUrl + "/survey"
const AGENT_BASE_URL = baseUrl + "/agent"

export const URL = {
	GET_TOKEN: ONBOARDING_BASE_URL+ "/auth/get_token",
	WELCOME_MESSAGE: ONBOARDING_BASE_URL+ "/welcome_message",
	MEMBER_PROFILE_INFO: ONBOARDING_BASE_URL+ "/user/get_user_info",
	MEMBER_SAVE_CONTACT_INFO: ONBOARDING_BASE_URL+ "/user/save_contact_info",
	MEMBER_SAVE_PROFILE_INFO: ONBOARDING_BASE_URL+ "/user/save_profile_info",
	MEMBER_SAVE_COMM_PREFERENCE: ONBOARDING_BASE_URL+ "/user/save_comm_preferences",

	MEMBER_PLAN_INFO: MEMBER_ACCOUNT_BASE_URL+ "/member_plan",
	MEMBER_MY_AGENT: MEMBER_ACCOUNT_BASE_URL+ "/my_agent",
	MEMBER_SCHEDULE_CALLBACK: MEMBER_ACCOUNT_BASE_URL+ "/schedule_callback",
	MEMBER_REQUEST_CALLBACK: MEMBER_ACCOUNT_BASE_URL+ "/request_callback",
	MEMBER_CONFIRM_CALLBACK: MEMBER_ACCOUNT_BASE_URL+ "/confirm_callback",
	MEMBER_MY_DOCTOR: MEMBER_ACCOUNT_BASE_URL+ "/my_doctor",

	SURVEY_WELCOME: SURVEY_BASE_URL+ "/welcome",
	SURVEY_FIRST_QUESTION: SURVEY_BASE_URL+ "/start",
	SURVEY_NEXT_QUESTION: SURVEY_BASE_URL+ "/next_question",
	SURVEY_BACK_QUESTION: SURVEY_BASE_URL+ "/backtracking",
	SURVEY_THANK_YOU: SURVEY_BASE_URL+ "/thankyou",
	SURVEY_SCHEDULE_CALLBACK: SURVEY_BASE_URL+ "/schedule_callback",

	// Mmeber registartion for clients
	MEMBER_REGISTRATION: ONBOARDING_BASE_URL + "/member/create",

	//Agent
	AGENT_LOGIN: AGENT_BASE_URL + "/login",
	AGENT_REFESHTOKEN: AGENT_BASE_URL + "/refresh-token",
	AGENT_CAMPAIGNS: AGENT_BASE_URL + "/campaigns",
	SURVEY_PREVIEW: AGENT_BASE_URL + "/preview-survey/",
	AGENT_SURVEYS: AGENT_BASE_URL + "/surveys",
	PROMOTION_PREVIEW: AGENT_BASE_URL + "/preview-promotion/",
	AGENT_PROMOTIONS: AGENT_BASE_URL + "/promotions",
	CAMPAIGN_FILTERDROPDOWN: AGENT_BASE_URL + "/campaign-filter-dropdown",
	CAMPAIGN_DETAILS: AGENT_BASE_URL + "/get-campaign-details",
	UPDATE_CAMPAIGN_FILTER: AGENT_BASE_URL + "/update-campaign-filter",
	CREATE_CAMPAIGN: AGENT_BASE_URL + "/create-campaign",
	CREATE_SURVEY: AGENT_BASE_URL + "/create-survey",
	CREATE_PROMOTION: AGENT_BASE_URL + "/create-promotion",
	GET_AUTORESPONSE: AGENT_BASE_URL + "/get-auto-response",
	UPDATE_PROMOTION: AGENT_BASE_URL + "/update-promotion",
	UPDATE_SURVEY: AGENT_BASE_URL + "/update-survey",
	DELETE_CAMPAIGN: AGENT_BASE_URL + "/delete-campaign",
	SEARCH_MEMBERS: AGENT_BASE_URL + "/search-members",
	PUBLISHED_CAMPAIGN_STATUS: AGENT_BASE_URL + "/published-campaign-status",
	SENT_OTP: AGENT_BASE_URL + "/send-otp",
	UPDATE_PASSWORD: AGENT_BASE_URL + "/update-password",
	PUBLISHED_CAMPAIGN_MEMBER_LIST: AGENT_BASE_URL + "/published-campaign-member-list/",
	UPDATE_CAMPAIGN: AGENT_BASE_URL + "/update-campaign",
	UPLOAD_MEMBERLIST_CSV: AGENT_BASE_URL + "/upload-member-list-csv",
	AGENT_NOTIFICATION: AGENT_BASE_URL + "/campaign-tracker-agent-notifications/",
	CAMPAIGN_PHONECALLS: AGENT_BASE_URL + "/published-campaign-member-list-phone-only",
	CAMPAIGN_TRACKERRESOLVINGISSUE: AGENT_BASE_URL + "/campaign-tracker-agent-resolving-issue",
	AGENT_MEMBER_SNAPSHOT: AGENT_BASE_URL + "/campaign-member-snapshot/",
	UNRESOLVED_NOTIFICATION_SUBMIT: AGENT_BASE_URL + "/campaign-tracker-agent-resolving-issue",
	USER_STATS: AGENT_BASE_URL + "/campaign-user-stats",
	GENERATE_REPORT: AGENT_BASE_URL + "/campaign-report/",
	DOWNLOAD_REPORT: AGENT_BASE_URL + "/download-campaign-report"

	

}
