// BasicModal.js
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';

import CustomLanguageservice from "../../../Services/CustomLanguageservice"




function SucessPopup({ isOpen }) {

	if (isOpen === false) {
		return null;
	}

	const isBrowser = () => typeof window !== "undefined";
	let language;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
	}
	let ThanksforUpdatingyourProfileInformation = CustomLanguageservice.getMethod("Thanks for Updating your Profile Information", language);
	let Addresstitle = CustomLanguageservice.getMethod("Address", language);
	let Phonetitle = CustomLanguageservice.getMethod("Phone", language);
	let Emailtitle = CustomLanguageservice.getMethod("Email", language);


	let componentname;
	let image;
	let name;
	let streetaddress;
	let city;
	let state;
	let zipcode;
	let PhoneNumber;
	let Emailaddress;
	let datatitle;
	let heading1;
	let heading2;
	let heading3;
	let heading4;
	let heading5;
	let heading6;
	let changed_firstname;
	let changed_lastname;
	let changed_phonenumber;
	let changed_email;
	let changed_billingAdress;
	let changed_city;
	let changed_state;
	let changed_zipcode;
	let a = localStorage.getItem("encodedData")
	let decodedData = JSON.parse(a);


	if (decodedData.componentname === "Profile Information") {
		componentname = decodedData.componentname;
		name = decodedData.name;
		image = decodedData.image;
		streetaddress = decodedData.streetaddress;
		city = decodedData.city;
		state = decodedData.state;
		zipcode = decodedData.zipcode;
		PhoneNumber = decodedData.PhoneNumber;
		Emailaddress = decodedData.Emailaddress;
		changed_firstname = decodedData.changed_firstname;
		changed_lastname = decodedData.changed_lastname;
		changed_phonenumber = decodedData.changed_phonenumber;
		changed_email = decodedData.changed_email;
		changed_billingAdress = decodedData.changed_billingAdress;
		changed_city = decodedData.changed_city;
		changed_state = decodedData.changed_state;
		changed_zipcode = decodedData.changed_zipcode;
	}
	else {
		componentname = decodedData.componentname;
		datatitle = decodedData.datatitle;
		heading1 = decodedData.heading1;
		heading2 = decodedData.heading2;
		heading3 = decodedData.heading3;
		heading4 = decodedData.heading4;
		heading5 = decodedData.heading5;
		heading6 = decodedData.heading6;
		image = decodedData.image;

	}







	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">

				{componentname === "Profile Information" ?
					<Box className={"SuccessPopupcontainer"}>
						<Grid container xs={12} className={"CommonLoadermaingrid"}>
							<Grid xs={12} >
								<div className={"successimage"}>
									<img src={image} alt="success" />
								</div>
								<div className={"informationbox"}>
									<div className={"namelabel"} style ={{marginBottom:"15px"}}> <br></br>
										{ThanksforUpdatingyourProfileInformation}
									</div>


									{(changed_firstname === true || changed_lastname === true) &&
										<div className={"namelabel"}> 
											{name}
										</div>
									}



									{changed_phonenumber === true &&
										<div>
											<div className={"namelabel2"}> <br></br>
												{Phonetitle + ":"}
											</div>

											<div className={"namelabeltitle"}>
												{PhoneNumber}
											</div>
										</div>
									}

									{changed_email === true &&
										<div>
											<div className={"namelabel2"}> <br></br>
												{Emailtitle + ":"}
											</div>

											<div className={"namelabeltitle"}>
												{Emailaddress === "" ?
													(<>-</>) : (<>{Emailaddress}</>)
												}
											</div>
										</div>
									}

									{changed_billingAdress === true &&
										<div>
											<div className={"namelabel2"}> <br></br>
												{Addresstitle + ":"}

											</div>

											<div className={"namelabeltitle"}>
												{streetaddress}

											</div>
										</div>
									}

									{(changed_city === true || changed_state === true || changed_zipcode === true) &&
										<div className={"namelabeltitle"}>

											{city} {state} {zipcode}

										</div>
									}






								</div>

							</Grid>
						</Grid>
					</Box>

					:

					<Box className={"SuccessPopupcontainer1"}>
						<div>
							<Grid container xs={12} className={"CommonLoadermaingrid"}>
								<Grid xs={12} >
									<div className={"successimage"} >
										<img src={image} alt="success" />
									</div>

									<div className={"informationbox2"}>
										<div className={"namelabeltitle"}>
											{datatitle}
										</div>

										<div className={"namelabeltitle"}> <br></br>
											{heading1}
										</div>

										{/* <div className={"namelabelFade"}>  */}
										<div className={"namelabel"}>
											{heading2 !== "" && <br></br>}
											{/* <br></br> */}
											{heading2}
										</div>
										{/* <div className={"namelabelFade"}>  */}
										<div className={"namelabel"}>
											{heading3}
										</div>

										<div className={"namelabeltitle"}>
											{heading4 !== "" && <br></br>}
											{/* <br></br> */}
											{heading4}
										</div>

										<div className={"namelabel"}> <br></br>
											{heading5}
										</div>
										<div className={"namelabel"}>
											{heading6}
										</div>



									</div>
								</Grid>
							</Grid>
						</div>
					</Box>
				}


			</Modal>
		</div>
	);
}

export default SucessPopup
