import React, { useState, useRef, useEffect } from "react";
import "./AdministratorNotifications.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import UnresolvedNotificationItem from "./unresolved";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import HeaderAgent from "../../../Shared/HeaderAgent/HeaderAgent";
import { useNavigate } from "react-router-dom";
import MemberSnapshotPopup from "../../../Shared/Popups/MemberSnapshotPopup/MemberSnapshotPopup";

import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";

import { ROUTES_CAMPAIGN_DETAILS } from "../../../Constants/routes";

const ResolvedNotificationItem = ({ item, viewResolved }) => {
	const [queryInfo, setQueryInfo] = useState("");
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isMemberSnapshotPopupModalOpen, setisMemberSnapshotPopupModalOpen] =
		useState(false);
	const [getmemberID, setgetmemberID] = useState("");
	const [itemdata, setitemdata] = useState("");
	const [dropdown, setDropdown] = useState(false);

	const handleMemberSnapshotOKButton = (value) => {
		setisMemberSnapshotPopupModalOpen(false);
	};

	const handleClickName = (item) => {
		setitemdata(item);
		setgetmemberID(item.member_id);
		setisMemberSnapshotPopupModalOpen(true);
	};

	const handleDropdown = () => {
		setDropdown((prev) => !prev);
	};

	return (
		<div className="resolved-notification-item">
			<div
				style={{
					display: "flex",
                    flexDirection : "column",
					width: "100%",
					justifyContent: "space-between",
					borderBottom: "0.5px solid rgba(217, 217, 217, 0.8)",
					position: "relative",
				}}
			>
				<p className="resolved-item-infotext">
					<span
						className="member-name-link"
						onClick={() => handleClickName(item)}
					>
						{item.member_name}
					</span>{" "}
					<span
						dangerouslySetInnerHTML={{
							__html: `${item.notification_text}`,
						}}
					></span>
				</p>

				<p className="resolved-item-date-raised">
					{item.raised_date} {item.raised_time}{" "}
				</p>
				{dropdown ? (
					<img
						src="/asset/Agent/arrowupcampaign.png"
						width="30px"
						height="30px"
						className="dropdown-resolved"
						onClick={handleDropdown}
					/>
				) : (
					<img
						src="/asset/Agent/arrowdowncampaign.png"
						width="30px"
						height="30px"
						className="dropdown-resolved"
						onClick={handleDropdown}
					/>
				)}
			</div>
			{dropdown ? (
				<div className="resolved-item-data">
					<p className="resolved-item-data-text">
						{item.agent_comment}
					</p>
					<div className="resolved-item-date-author">
						<p className="item-author">
							Resolved By {item.agent_first_name}
						</p>
						<p className="item-date-time">
							{item.resolved_date} {item.resolved_time}
						</p>
					</div>
				</div>
			) : (
				""
			)}
			<MemberSnapshotPopup
				isOpen={isMemberSnapshotPopupModalOpen}
				handleOKButton={handleMemberSnapshotOKButton}
				from="notification"
				data={getmemberID}
				item={itemdata}
				viewResolved={viewResolved}
			/>
		</div>
	);
};

export default ResolvedNotificationItem;
