import React from 'react';
import "./GlobalAlertPopup.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

function GlobalAlertPopup({ isOpen, Heading, body,btnname1, handleOKButton }) {

	function handleOkbtn(data) {
		handleOKButton(data);
	};

	function handleClickcancel(data){
		handleOKButton(data);
	};


	return (
		<div >
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">


				<Box className={"GlobalAlertPopupcontainer"}>
					<Grid container xs={12} className={"CommonLoadermaingrid"}>
					<Grid xs={12} className={"GridIcon"}><br></br>
							<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="20px" height="20px" onClick={handleClickcancel} />
						</Grid>

						<Grid xs={12} style={{textAlign:"center"}}><br></br><br></br>
							<div className={"textlabel1"}>
								{Heading}
							</div>

						</Grid>

						<Grid xs={12} style={{textAlign:"center"}}><br></br><br></br>
							
							<Button variant="contained" aria-label="Ok" className="okbtn" onClick={handleOkbtn}>{btnname1}</Button>
						
						</Grid>


					</Grid>
				</Box>

			</Modal>
		</div>
	)

}

export default GlobalAlertPopup