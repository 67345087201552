import React from 'react';
import "./Reports.css";
import { useEffect, useState } from "react";
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Navigate, useLocation ,useNavigate } from 'react-router-dom';

import HeaderOnBoarding from '../../../Shared/HeaderOnBoarding/HeaderOnBoarding';
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { apiurl } from "../../../Environment/Environment";

import AccordNotification from './GenerateReport';


const Reports = () => {
	const Navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	

	

	return (
		<Box className="CommonLoader">
			 {isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<HeaderOnBoarding />

			<AccordNotification />



		</Box>
	)
}

export default Reports;
