import React, { useEffect, useState, useContext } from "react";
import "./HeaderAgent.css";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Tooltip }  from '@mui/material';
import { useNavigate } from "react-router-dom";
import { ROUTES_AGENT_LOGIN } from "../../Constants/routes";

import CommoncircularProgress from "../CommoncircularProgress/CommoncircularProgress"

function HeaderAgent() {
	const Navigate = useNavigate();
	const [logo, setlogo] = React.useState("");
	const [Profilelogo, setProfilelogo] = React.useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [Member_firstname, setMember_firstname] = React.useState("");
	const [Member_lastname, setMember_lastname] = React.useState("");

	useEffect(() => {
		// get api data
		setlogo("/asset/customcompanylogo.png");
		setProfilelogo("/asset/Member/Profileicon.png");
		setMember_firstname(localStorage.getItem("Agent_first_name"));
		setMember_lastname(localStorage.getItem("Agent_last_name"));

	}, []);


   const handle_Logout = () => {
	Navigate(ROUTES_AGENT_LOGIN)
	sessionStorage.removeItem("AgentEmail");
	sessionStorage.removeItem("campaign_code");
	sessionStorage.removeItem("filterdata");
	sessionStorage.removeItem("tabselected");
	sessionStorage.removeItem("setCampaignselector");
	sessionStorage.removeItem("tabvalue_campaign_code");
   }



	return (
		<Box className={"HeaderagentContainer"}>
			    <div  className="HeaderMainContainer_Agent">
				<Grid container xs={12}>
					<Grid xs={9}>
						<div className={"headingimgdiv_profile"}>
							<img src={logo} alt="Companylogo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
						</div>
					</Grid>

					<Grid xs={2} className={"contentGrid"}>
						<div className={"textlabel"}>
							{Member_firstname} {Member_lastname}
						</div>

						{/* <div className={"textlabel1"}>vfgrg
							{Member}
						</div> */}

					</Grid>

					<Grid xs={1} className={"contentGrid"}>
							<div className={"headingimgdiv_profileicon"}>
							<Tooltip title="Logout" arrow>
								<img src={Profilelogo} alt="ProfileImage" className={"Profileimage"} onClick={() => handle_Logout()} />
							</Tooltip>
							</div>


					</Grid>
				</Grid>
				</div>
		</Box>


	)

}


export default HeaderAgent;
