import React from "react";
import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import { TextField, InputLabel, FormControl, MenuItem, Select, Box, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import CommonService from "../../../../Services/CommonService";
import CustomLanguageservice from "../../../../Services/CustomLanguageservice";
import { URL } from "../../../../Constants/apiUrl"
import Apiservice from "../../../../Services/Apiservice";
import CommoncircularProgress from "../../../../Shared/CommoncircularProgress/CommoncircularProgress";
import "./MemberEditprofile.css"
import { ROUTES_MEMBER_PREFERENCES, ROUTES_MEMBER_PROFILE } from "../../../../Constants/routes";
import { phoneFormatter } from "../../../../Utilities/utils";


function MemberEditprofile() {
	const isBrowser = () => typeof window !== "undefined";
	const location = useLocation();
	const Navigate = useNavigate()
	const [selectedState, setselectedState] = React.useState("");
	const [selectedMAilingState, setselectedMAilingState] = React.useState("");
	const [Statearray, setStatearray] = React.useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [contain5zero, setcontain5zero] = useState(false);
	const [contain5zeromail, setcontain5zeromail] = useState(false);
	const [isSameAddress, setIsSameAddress] = useState("true");
	const [type, setType] = React.useState("");
	const [alternatenum, setAlternatenum] = useState("")
	const [alternateflag, setAlternateflag] = useState(0)
	const [radiovalue, setradiovalue] = useState(1);
	const [changeFlag, setChangeFlag] = useState(false)
	const [navtopref, setNavtopref] = useState(false);
	const [scrollflag, setScrollflag] = useState(false);
	const [disableselct, setDisableselected] = useState(true);
	const [prefchannel, setPrefchannel] = useState("");

	const [apidata, setApidata] = useState("");

	let getProfileUrl = URL.MEMBER_PROFILE_INFO;
	let alternateNumUrl = URL.MEMBER_SAVE_CONTACT_INFO;
	let saveProfileUrl = URL.MEMBER_SAVE_PROFILE_INFO;
	let saveCommPreff = URL.MEMBER_SAVE_COMM_PREFERENCE;


	const {
		register,
		control,
		handleSubmit,
		formState: { errors }, clearErrors,
		reset
	} = useForm({
		mode: "onSubmit"
	});

	let language;
	let ProfileInformationapiData;
	let containemptydata;
	if (isBrowser() === true) {
		language = localStorage.getItem("Preferredlanguage");
		containemptydata = localStorage.getItem("containemptydata")
	}


	let Aboutyou = CustomLanguageservice.getMethod("About you", language);
	let Firstname = CustomLanguageservice.getMethod("First Name", language);
	let Lastname = CustomLanguageservice.getMethod("Last Name", language);
	let PhoneNumber = CustomLanguageservice.getMethod("Phone Number", language);
	let AlternatePhoneNumber = CustomLanguageservice.getMethod("Alternate Number", language);
	let Optional = CustomLanguageservice.getMethod("Optional", language);
	let Emailaddress = CustomLanguageservice.getMethod("Email Address", language);
	let BillingAddress = CustomLanguageservice.getMethod("Billing Address", language);
	let MailingAddress = CustomLanguageservice.getMethod("Mailing Address", language);
	let Streetaddress = CustomLanguageservice.getMethod("Street Address", language);
	let City = CustomLanguageservice.getMethod("City", language);
	let State = CustomLanguageservice.getMethod("State", language);
	let Zipcode = CustomLanguageservice.getMethod("Zip Code", language);
	let cannotbeempty = CustomLanguageservice.getMethod("cannot be empty", language);
	let Invalid = CustomLanguageservice.getMethod("Invalid", language);
	let mustbe10digits = CustomLanguageservice.getMethod("must be 10 digits", language);
	let mustbe5digits = CustomLanguageservice.getMethod("must be 5 digits", language);
	let SubmitChanges = CustomLanguageservice.getMethod("Submit Changes", language);
	let ProfileSettings = CustomLanguageservice.getMethod("Profile Settings", language);
	let CellPhone = CustomLanguageservice.getMethod("Cell Phone", language);
	let Landline = CustomLanguageservice.getMethod("Landline", language);
	let SameasBillingAddress = CustomLanguageservice.getMethod("Same as Billing Address", language);
	let DifferentfromBillingAddress = CustomLanguageservice.getMethod("Different from Billing Address", language);
	let Requiredfields = CustomLanguageservice.getMethod("Required fields", language);

	let dropdowndata = [
		{
			id: 0,
			value: CellPhone,
		},
		{
			id: 1,
			value: Landline,
		}
	]

	let checkboxdata = [
		{
			id: 1,
			name: SameasBillingAddress
		},
		{
			id: 2,
			name: DifferentfromBillingAddress
		}];


	useEffect(() => {
		let dataarray = CommonService.getMethod();
		setStatearray(dataarray);
		setIsLoading(true);

		let apidata = {
			"route_name": ""
		}
		Apiservice.postMethod(getProfileUrl, apidata).then(response => {
			setIsLoading(false);
			setApidata(response.data.data)
			ProfileInformationapiData = response.data;
			let number;
			let alternate;

			// if (ProfileInformationapiData.data.phone === "" || ProfileInformationapiData.data.phone === null) { number = "" }
			// else { number = ProfileInformationapiData.data.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); }
			number = phoneFormatter(ProfileInformationapiData.data.phone)

			if (ProfileInformationapiData.data.is_landline === "true") {
				setType(1)

			}
			else {
				setType(0)
			}
			// if (ProfileInformationapiData.data.mobile === "" || ProfileInformationapiData.data.mobile === null) { alternate = "" }
			// alternate = ProfileInformationapiData.data.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

			alternate = phoneFormatter(ProfileInformationapiData.data.mobile)
			setAlternatenum(alternate)

			setPrefchannel(ProfileInformationapiData.data.pref_channel)

			setIsSameAddress(ProfileInformationapiData.data.is_same_address)

			setselectedState(ProfileInformationapiData.data.billing_state);

			ProfileInformationapiData.data.is_same_address === "true" ?
				setselectedMAilingState(ProfileInformationapiData.data.billing_state)
				: setselectedMAilingState(ProfileInformationapiData.data.mailing_state)

			if (ProfileInformationapiData.data.is_same_address === "true") {
				setradiovalue(1)
			}
			else {
				setradiovalue(2)
			}

			setTimeout(() => {
				reset({
					Firstname: ProfileInformationapiData.data.first_name,
					Lastname: ProfileInformationapiData.data.last_name,
					Emailaddress: ProfileInformationapiData.data.email,
					Streetaddress: ProfileInformationapiData.data.billing_street,
					City: ProfileInformationapiData.data.billing_city,
					Zipcode: parseInt(ProfileInformationapiData.data.billing_zip),
					alternatenum: alternate,
					PhoneNumber: number,
					Mailingstreet: (ProfileInformationapiData.data.mailing_street === "" || ProfileInformationapiData.data.mailing_street === null || ProfileInformationapiData.data.mailing_street === undefined) ?
						"" : ProfileInformationapiData.data.mailing_street,

					Mailingcity: (ProfileInformationapiData.data.mailing_city === "" || ProfileInformationapiData.data.mailing_city === null || ProfileInformationapiData.data.mailing_city === undefined) ?
						ProfileInformationapiData.data.billing_city : ProfileInformationapiData.data.mailing_city,

					Mailingstate: (ProfileInformationapiData.data.mailing_state === "" || ProfileInformationapiData.data.mailing_state === null || ProfileInformationapiData.data.mailing_state === undefined) ?
						ProfileInformationapiData.data.billing_state : ProfileInformationapiData.data.mailing_state,

					Mailingzipcode: (ProfileInformationapiData.data.mailing_zip === "" || ProfileInformationapiData.data.mailing_zip === null || ProfileInformationapiData.data.mailing_zip === undefined) ?
						parseInt(ProfileInformationapiData.data.billing_zip) : parseInt(ProfileInformationapiData.data.mailing_zip),
				});
			}, 100);

			if (containemptydata === "true") {
				setTimeout(() => {
					document.getElementById('submitButton').click();
				}, 500);
			}

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})



	}, []);

	const handleChange = (e, field) => {
		setDisableselected(false)
		setNavtopref(true)
		// Set for btn state
		setChangeFlag(true)
		// Remove all non-numeric characters from input
		const formattedPhoneNumber = e.target.value.replace(/\D/g, '');
		if (formattedPhoneNumber === '0000000000' || formattedPhoneNumber === '0000000000000') {
			return;
		}
		const formattedPhoneNumberWithSpaces = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		// Update the input value
		field.onChange(formattedPhoneNumberWithSpaces);
	};

	const handledropdownchange = (e, type) => {
		// Set for btn state
		setChangeFlag(true)
		setNavtopref(true)
		if (type === "phone") {
			setType(e.target.value);
			// setAlternateflag(1)
			for (let i = 0; i < dropdowndata.length; i++) {
				if (dropdowndata[i].id === e.target.value) {
					setType(dropdowndata[i].id)
				}
			}
		}

		else {

			for (let i = 0; i < dropdowndata.length; i++) {
				if (dropdowndata[i].id === e.target.value) {
					setAlternateflag(dropdowndata[i].id)
				}
			}
		}


	}

	const handleInputChange = (event, field) => {
		// Set for btn state
		setChangeFlag(true)

		let inputValue = event.target.value;
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
		field.onChange(capitalizedValue);
	}

	const handleNameChange = (event, field) => {
		// Set for btn state
		setChangeFlag(true)
		let inputValue = event.target.value.replace(/\s+/g, '');
		let capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
		field.onChange(capitalizedValue);
	}

	const handleEmailChange = (event, field) => {
		setNavtopref(true)
		// Set for btn state
		setChangeFlag(true)

		let inputValue = event.target.value;
		let lowercasedValue = inputValue.toLowerCase();
		field.onChange(lowercasedValue);
	}

	function handleChangecity(event, value) {
		// Set for btn state
		setChangeFlag(true)

		if (value === "billing") {
			const newValue = event.target.value;
			setselectedState(newValue)
			// this makes error msg go away on change data
			clearErrors('state');
		}

		else {
			const newValue = event.target.value;
			setselectedMAilingState(newValue)
		}

	}

	const handleRadioChange = (event) => {
		// Set for btn state
		setChangeFlag(true)

		setradiovalue(parseInt(event.target.value));
		if (event.target.value === "2") {
			// For scrolling to the content of mailing address
			setScrollflag(true)
			// setselectedMAilingState((apidata.mailing_state === "" && apidata.mailing_state === null && apidata.mailing_state === undefined) ? apidata.billing_state : apidata.mailing_state)
		}
		else {
			setScrollflag(false)
		}
	}

	const handleKeyDown = (event, value) => {
		if (event.key === 'Enter') {
		// Set for btn state
		setChangeFlag(true)

		setradiovalue(parseInt(value));
		if (value === "2" || value ===2) {
			// For scrolling to the content of mailing address
			setScrollflag(true)
			// setselectedMAilingState((apidata.mailing_state === "" && apidata.mailing_state === null && apidata.mailing_state === undefined) ? apidata.billing_state : apidata.mailing_state)
		}
		else {
			setScrollflag(false)
		}
	}
	}

	useEffect(() => {
		if (scrollflag === true) {
			window.scrollBy({
				top: document.documentElement.scrollHeight - window.innerHeight,
				behavior: 'smooth'
			});
		}
	}, [scrollflag]);

	const handleInputChangeZipcode = (event, field, type) => {
		setChangeFlag(true)
		let inputValue = event.target.value.replace(/\D/g, '');
		if (inputValue === "00000") {
			if (type === "billing") {
				setcontain5zero(true)
			}
			else {
				setcontain5zeromail(true)
			}
		}
		else {
			setcontain5zero(false)
		}
		field.onChange(inputValue);
	}


	async function profileinforsave(data) {
		localStorage.removeItem("containemptydata")
		const jsonData =
		{
			componentname: "Profile Information",
			name: data.Firstname + " " + data.Lastname,
			streetaddress: data.Streetaddress,
			city: data.City,
			state: data.state,
			zipcode: data.Zipcode,
			PhoneNumber: data.PhoneNumber,
			Emailaddress: data.Emailaddress,
			image: "/asset/tick.png"

		};

		// api call
		setIsLoading(true);

		let flag = radiovalue === 1 ? "true" : "false"
		let userData = {
			"user": {
				"first_name": data.Firstname,
				"last_name": data.Lastname,
				"email": data.Emailaddress,
				"phone": data.PhoneNumber.replace(/\D/g, '')
			},
			"is_same_address": flag,
			"is_mailing": 1,
			"route_name": "/thank-you"
		};

		userData.billing = {
			"address": data.Streetaddress,
			"city": data.City,
			"state": selectedState,
			"zipcode": data.Zipcode.toString()
		};

		if (radiovalue === 2) {
			userData.mailing = {
				"address": data.Mailingstreet,
				"city": data.Mailingcity,
				"state": selectedMAilingState,
				"zipcode": data.Mailingzipcode.toString()
			};
		}

		else {
			delete userData.mailing; // Remove mailing address if condition is not met
		}
		let val = alternateflag
		let cellNumber = data.alternatenum
		let contactData = {
			"phone": type === 1 ? ((cellNumber !== undefined) ? cellNumber.replace(/\D/g, '') : "") : data.PhoneNumber.replace(/\D/g, ''),
			// "is_landline": type === 1 ? ("true") : (alternatenum === "" ? "false" : "true"),
			"is_landline": type === 1 ? "true" : "false",
			"route_name": ""
		}

		await Apiservice.putMethod(saveProfileUrl, userData).then(response => {
			localStorage.setItem("encodedData", JSON.stringify(jsonData))

			sessionStorage.setItem("Member_firstname", userData.user.first_name)
			sessionStorage.setItem("Member_lastname", userData.user.last_name)
			setIsLoading(false);
		})
			.catch(error => {
				setIsLoading(false);
			})

		await Apiservice.putMethod(alternateNumUrl, contactData).then(response => {
			localStorage.setItem("encodedData", JSON.stringify(jsonData))
			setIsLoading(false);
			// navtopref ? Navigate(ROUTES_MEMBER_PREFERENCES) : Navigate(ROUTES_MEMBER_PROFILE)

		})
			.catch(error => {
				setIsLoading(false);
			})

		let changeChannel = prefchannel

		if (prefchannel === "0" && type === 1) {
			// prefchannel 0 text 1 email 2 phone
			// type 0 cellphone
			// type 1 landline
			changeChannel = data.Emailaddress !== "" ? "1" : "2"
		}

		else if (prefchannel === "1" && data.Emailaddress === "" && type === 1) {
			changeChannel = alternatenum !== "" ? "0" : "2"
		}
		else if (prefchannel === "1" && data.Emailaddress === "" && type === 0) {
			changeChannel = "0"
		}
		else {
			changeChannel = "2"
		}

		let savePreffData = {
			"pref_channel": changeChannel,
			"route_name": ""
		}

		await Apiservice.putMethod(saveCommPreff, savePreffData).then(response => {
			setIsLoading(false);
			// navtopref ? Navigate(ROUTES_MEMBER_PREFERENCES) : Navigate(ROUTES_MEMBER_PROFILE)
			Navigate(ROUTES_MEMBER_PROFILE)

		})
			.catch(error => {
				setIsLoading(false);
			})
	}


	return (
		<Box className={"memberEditProfilecontainer"} display="flex" justifyContent="center">
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}

			<Grid container sx={{ justifyContent: "center" }} className={"parentEditprofile"}>

				<Grid xs={12} className={"editPageheading"} pt={1}>
					<p tabIndex="0" aria-label={ProfileSettings}>{ProfileSettings}</p>
				</Grid>

				<Grid container xs={11} lg={8} className={"memberParent"}>

					<form onSubmit={handleSubmit(profileinforsave)} style={{ width: "100%" }} ><br></br>
						<Grid container xs={12}>
							<Grid xs={12} mt={3} p={0}>
								<p className="memberSubheading" tabIndex="0">{Aboutyou}</p>
							</Grid>

							<Grid container xs={12} spacing={2}>

								{/*  Firstname */}
								<Grid item xs={12} textAlign={"right"} sx={{ fontFamily: "var(--fontfamily)" }}>
									<InputLabel tabIndex="0" htmlFor="my-input" className={"memberInputlabel"}>* {Requiredfields}</InputLabel>
								</Grid>
								<Grid xs={6} className={"memberName"} pt={0} pb={0}>
									<Controller
										name="Firstname"
										control={control}
										rules={{ required: true, pattern: /^[A-Za-z.]+$/ }}
										render={({ field }) =>
											<div ><br></br>
												<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Firstname} *</InputLabel>
												<div className={"memberTextbox"}>
													<TextField
														variant="outlined"
														placeholder={Firstname}
														value={field.value || ''}
														onChange={(e) => handleNameChange(e, field)}
														sx={{ width: '100%' }}
														InputProps={{
															className: 'memberCusomInput',
															style: {
																border: errors?.Firstname?.type === "required" || errors?.Firstname?.type === "pattern" ? "1px solid red" : "transparent",
																width: "100%"
															}

														}}
													/>
												</div>
												{errors?.Firstname?.type === "required" &&
													<div className={"memberErrormsg"}>{Firstname} {cannotbeempty}</div>}

												{errors?.Firstname?.type === "pattern" &&
													<div className={"memberErrormsg"}>{Invalid} {Firstname}</div>}

											</div>
										}
									/>
								</Grid>

								{/* LAstname */}
								<Grid xs={6} pt={0} pb={0} >
									<Controller
										name="Lastname"
										control={control}
										rules={{ required: true, pattern: /^[A-Za-z.]+$/ }}
										render={({ field }) =>
											<div><br></br>
												<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Lastname} *</InputLabel>
												<div className={"memberTextbox"}>
													<TextField
														variant="outlined"
														placeholder={Lastname}
														value={field.value || ''}
														onChange={(e) => handleNameChange(e, field)}
														sx={{ width: '100%' }}
														InputProps={{
															className: 'memberCusomInput',
															style: {
																border: errors?.Lastname?.type === "required" || errors?.Lastname?.type === "pattern" ? "1px solid red" : "transparent",
															}

														}}

													/>
												</div>
												{errors?.Lastname?.type === "required" &&
													<div className={"memberErrormsg"}>{Lastname} {cannotbeempty}</div>}

												{errors?.Lastname?.type === "pattern" &&
													<div className={"memberErrormsg"}>{Invalid} {Lastname}</div>}

											</div>
										}
									/>
								</Grid>
							</Grid>

							<Grid container xs={12} spacing={2} sx={{ alignItems: "flex-start" }}>

								{/* PhoneNumber */}

								<Grid container xs={12} md={6} mt={1}>
									<Grid xs={12} pt={0} pb={0} >
										{/* <div className={"memberInputlabel"} style={{ height: "auto" }}><br></br>{PhoneNumber}{" *"}
										</div> */}
										<InputLabel sx={{ marginTop: "5px" }} htmlFor="my-input" className={"memberInputlabel"}>{PhoneNumber} *</InputLabel>
									</Grid>

									<Grid md={7} xs={6} className={"memberName"} pt={0} pb={0} pr={1}>
										<Controller
											name="PhoneNumber"
											control={control}
											rules={{ required: true, pattern: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ }}
											render={({ field }) =>
												<div >
													{/* <InputLabel htmlFor="my-input" className={"memberInputlabel"}>{PhoneNumber}*</InputLabel> */}
													<div className={"memberTextbox"}>
														<TextField
															variant="outlined"
															placeholder={PhoneNumber}
															value={field.value || ''}
															onChange={(e) => handleChange(e, field)}
															type="tel"
															sx={{ width: '100%' }}
															InputProps={{
																className: 'memberCusomInput',
																style: {
																	border: errors?.PhoneNumber?.type === "required" || errors?.PhoneNumber?.type === "pattern" ? "1px solid red" : "transparent",
																	width: "100%",

																}

															}}
														/>
													</div>
													{errors?.PhoneNumber?.type === "required" &&
														<div className={"memberErrormsg"}>{PhoneNumber} {cannotbeempty}</div>}

													{errors?.PhoneNumber?.type === "pattern" &&
														<div className={"memberErrormsg"}>{Invalid} {PhoneNumber}, {mustbe10digits}</div>}

												</div>
											}
										/>
									</Grid>

									{/* Phone flag */}
									<Grid md={5} xs={6} className={"memberName"} pt={0} pb={0} pr={1}>
										<div >
											{/* <InputLabel htmlFor="my-input" className={"memberInputlabel"} style={{ height: '100%' }}>&#8203;</InputLabel> */}
											<FormControl sx={{ width: "100%"}}>
												<div className={"memberTextbox"}>
													{disableselct === true ?
														(
															<TextField
																// disabled={true}
																// readOnly
																variant="outlined"
																value={type === 0 ? CellPhone : Landline}
																sx={{ width: '100%' }}
																InputProps={{
																	className: 'memberCusomInput',
																	readOnly: true,
																}}
															/>
														)
														:
														(
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={type}
																className={"memberDrpdownselect"}
																onChange={(event) => handledropdownchange(event, "phone")}
																MenuProps={{
																	classes: {
																		paper: 'custom-menu-paper', // Add a custom class for the dropdown menu
																	},
																}}
																sx={{ width: "100%" }}
															>
																{dropdowndata.map((data, index) => (
																	<MenuItem key={index} value={data.id} sx={{ padding: "5px" }}>{data.value}</MenuItem>
																))}
															</Select>
														)

													}

												</div>
											</FormControl>

										</div>

									</Grid>
								</Grid>

								{(type === 1) &&
									<Grid container md={6} xs={12} mt={1}>

										<Grid xs={12} pt={0} pb={0}>
											{/* <div className={"memberInputlabel"} style={{ height: "auto" }}><br></br>{AlternatePhoneNumber} ({Optional})
										</div> */}
											<InputLabel sx={{ marginTop: "5px" }} htmlFor="my-input" className={"memberInputlabel"}>{AlternatePhoneNumber} ({Optional})</InputLabel>
										</Grid>

										{/* Alternate number */}
										<Grid md={7} xs={6} className={"memberName"} pt={0} pb={0} pr={1}>
											<Controller
												name="alternatenum"
												control={control}
												rules={{ required: false, pattern: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ }}
												render={({ field }) =>
													<div >
														{/* <InputLabel htmlFor="my-input" className={"memberInputlabel"}>Alternate Number (Optional)</InputLabel> */}
														<div className={"memberTextbox"}>
															<TextField
																variant="outlined"
																placeholder="Alternate Number"
																value={field.value || ''}
																onChange={(e) => handleChange(e, field)}
																sx={{ width: '100%' }}
																type="tel"
																InputProps={{
																	className: 'memberCusomInput',
																	style: {
																		// border: errors?.alternatenum?.type === "required" || errors?.alternatenum?.type === "pattern" ? "1px solid red" : "transparent",
																		width: "100%",

																	}

																}}
															/>
														</div>

														{errors?.alternatenum?.type === "pattern" &&
															<div className={"memberErrormsg"}>{Invalid} {PhoneNumber}, {mustbe10digits}</div>}

													</div>
												}
											/>
										</Grid>


										{/* Alternate Number flag */}
										<Grid md={5} xs={6} className={"memberName"} pt={0} pb={0} >
											<div >
												{/* <InputLabel htmlFor="my-input" className={"memberInputlabel"} style={{ height: '100%' }}>&#8203;</InputLabel> */}
												<FormControl sx={{ width: "100%" }}>
													<div className={"memberTextbox"}>
														{/* <Select
															disabled={disableselct}
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={alternateflag}
															className={"memberDrpdownselect"}
															onChange={(event) => handledropdownchange(event, "alternate")}
															MenuProps={{
																classes: {
																	paper: 'custom-menu-paper', // Add a custom class for the dropdown menu
																},
															}}
															sx={{ width: "100%" }}
														>
															{dropdowndata
																.filter(data => data.id !== 1)
																.map((data, index) => (
																	<MenuItem key={index} value={data.id} sx={{ padding: "5px" }}>{data.value}</MenuItem>
																))}
														</Select> */}
														<TextField
															// disabled={true}
															variant="outlined"
															value={type === 0 ? Landline : CellPhone}
															sx={{ width: '100%' }}
															InputProps={{
																className: 'memberCusomInput',
																readOnly: true,
															}}
														/>

													</div>
												</FormControl>


											</div>

										</Grid>
									</Grid>
								}

								{/* Emailaddress */}
								<Grid xs={12} pt={0} pb={0}>
									<Controller
										name="Emailaddress"
										control={control}
										rules={{ pattern: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ }}
										render={({ field }) =>
											<div><br></br>
												<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Emailaddress}</InputLabel>
												<div className={"memberTextbox"}>
													<TextField
														variant="outlined"
														placeholder={Emailaddress}
														value={field.value || ''}
														onChange={(e) => handleEmailChange(e, field)}
														sx={{ width: '100%' }}
														InputProps={{
															className: 'memberCusomInput',
															style: {
																border: errors?.Emailaddress?.type === "required" || errors?.Emailaddress?.type === "pattern" ? "1px solid red" : "transparent",
															}

														}}

													/>
												</div>

												{errors?.Emailaddress?.type === "pattern" &&
													<div className={"memberErrormsg"}>{Invalid} {Emailaddress}</div>}

											</div>

										}
									/>
								</Grid>

							</Grid>

							<Grid container xs={12} spacing={2} mt={3}>
								{/* Billing */}
								<Grid xs={12} pb={0}>
									<p className="memberSubheading" tabIndex="0">{BillingAddress}</p>
								</Grid>

								{/* Billing Street address */}
								<Grid xs={12} pt={0} pb={0} >
									<Controller
										name="Streetaddress"
										control={control}
										rules={{ required: true }}
										render={({ field }) =>
											<div><br></br>
												<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Streetaddress} *</InputLabel>
												<div className={"memberTextbox"}>
													<TextField
														variant="outlined"
														placeholder={Streetaddress}
														value={field.value || ''}
														onChange={(e) => handleInputChange(e, field)}
														sx={{ width: '100%' }}
														InputProps={{
															className: 'memberCusomInput',
															style: {
																border: errors?.Streetaddress?.type === "required" || errors?.Streetaddress?.type === "pattern" ? "1px solid red" : "transparent",
															}
														}}
													/>
												</div>
												{errors?.Streetaddress?.type === "required" &&
													<div className={"memberErrormsg"}>{Streetaddress} {cannotbeempty}</div>}
											</div>
										}
									/>
								</Grid>

								{/* Billing City */}
								<Grid xs={12} md={4} pt={0} pb={0} >
									<Controller
										name="City"
										control={control}
										rules={{ required: true, pattern: /^[A-Za-z\s]+$/ }}
										render={({ field }) =>
											<div><br></br>
												<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{City} *</InputLabel>
												<div className={"memberTextbox"}>
													<TextField
														variant="outlined"
														placeholder={City}
														value={field.value || ''}
														onChange={(e) => handleInputChange(e, field)}
														sx={{ width: '100%' }}
														InputProps={{
															className: 'memberCusomInput',
															style: {
																border: errors?.City?.type === "required" || errors?.City?.type === "pattern" ? "1px solid red" : "transparent",
															}
														}}
													/>
												</div>
												{errors?.City?.type === "required" &&
													<div className={"memberErrormsg"}>{City} {cannotbeempty}</div>}

												{errors?.City?.type === "pattern" &&
													<div className={"memberErrormsg"}>{Invalid} {City}</div>}
											</div>
										}
									/>
								</Grid>

								{/* Billig State */}
								<Grid xs={6} md={4} pt={0} pb={0}><br></br>
									{/* <div className={"memberInputlabel"}>
										{State} *
									</div> */}
									<InputLabel sx={{ marginTop: "0px", paddingBottom: 0 }} htmlFor="my-input" className={"memberInputlabel"} tabIndex="0">{State} *</InputLabel>


									<FormControl sx={{ width: "100%", marginTop: "-1px" }}>
										{((typeof selectedState === 'undefined' || selectedState === null || selectedState === "") && (
											<InputLabel id="demo-simple-select-label" translate="no" className={"inputlabelDrpdownselect"} tabIndex="0">
												{State}
											</InputLabel>
										))}
										<Select
											{...register('state', { required: true })}
											className={"memberDrpdownselect"}
											id="demo-simple-select"
											// labelId="demo-simple-select-label"
											// label="State"
											value={selectedState} // Set the value prop to selectedState
											onChange={(event) => handleChangecity(event, "billing")}
											MenuProps={{
												classes: {
													paper: 'custom-menu-paper', // Add a custom class for the dropdown menu
												},
											}}
											sx={{ width: "100%", marginTop: "3px !important" }}
											translate="no"
										>
											{Statearray.map((data, index) => (
												<MenuItem value={data.code} key={index} translate="no">{data.code}</MenuItem>
											))}
										</Select>
										{errors.state && errors.state.type === "required" &&
											<div className={"errormsg"}>{State} {cannotbeempty}</div>}
									</FormControl>

								</Grid >

								{/* Billing Zipcode */}
								<Grid xs={6} md={4} pt={0} pb={0} >
									<Controller
										name="Zipcode"
										control={control}
										rules={{ required: true, pattern: /^(?!00000)\d{5}(?:[-\s]\d{4})?$/ }}
										render={({ field }) =>
											<div><br></br>
												<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Zipcode} *</InputLabel>
												<div className={"memberTextbox"}>
													<TextField
														variant="outlined"
														placeholder={Zipcode}
														value={field.value || ''}
														onChange={(e) => handleInputChangeZipcode(e, field, "billing")}
														sx={{ width: '100%' }}
														type="tel"
														InputProps={{
															className: 'memberCusomInput',
															style: {
																border: errors?.Zipcode?.type === "required" || errors?.Zipcode?.type === "pattern" ? "1px solid red" : "transparent",
															}
														}}
													/>
												</div>

												{errors?.Zipcode?.type === "required" &&
													<div className={"memberErrormsg"}>{Zipcode} {cannotbeempty}</div>}

												{errors?.Zipcode?.type === "pattern" && contain5zero === true &&
													<div className={"memberErrormsg"}>{Invalid} {Zipcode}</div>}

												{errors?.Zipcode?.type === "pattern" && contain5zero === false &&
													<div className={"memberErrormsg"}>{Invalid} {Zipcode}, {mustbe5digits}</div>}



											</div>
										}
									/>
								</Grid>


							</Grid>


							<Grid container xs={12} spacing={2} mt={3}>
								{/* Mailing heading */}
								<Grid xs={12} md={3} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
									<p className="memberSubheading" tabIndex="0">{MailingAddress}</p>
								</Grid>
								{/* Mailing radio group */}
								<Grid xs={12} md={9} sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }} className={"radioGroup"}>
									{checkboxdata.map((data, index) => (
										<RadioGroup row aria-labelledby="chose" key={index} value={radiovalue} onChange={handleRadioChange}>
											<FormControlLabel control={<Radio value={data.id} />} label={data.name} 
											onKeyDown={(event) => handleKeyDown(event, data.id)}
											/>
										</RadioGroup>
									))}
								</Grid>
							</Grid>

							{radiovalue === 2 &&
								<Grid container xs={12} spacing={2}>
									{/* Mailing Street address */}
									<Grid xs={12} pt={0} pb={0} >
										<Controller
											name="Mailingstreet"
											control={control}
											rules={{ required: true }}
											render={({ field }) =>
												<div><br></br>
													<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Streetaddress} *</InputLabel>
													<div className={"memberTextbox"}>
														<TextField
															variant="outlined"
															placeholder={Streetaddress}
															value={field.value || ''}
															onChange={(e) => handleInputChange(e, field)}
															sx={{ width: '100%' }}
															InputProps={{
																className: 'memberCusomInput',
																style: {
																	border: errors?.Mailingstreet?.type === "required" || errors?.Mailingstreet?.type === "pattern" ? "1px solid red" : "transparent",
																}
															}}
														/>
													</div>
													{errors?.Mailingstreet?.type === "required" &&
														<div className={"memberErrormsg"}>{Streetaddress} {cannotbeempty}</div>}
												</div>
											}
										/>
									</Grid>

									{/* Mailing City */}
									<Grid xs={12} md={4} pt={0} pb={0} >
										<Controller
											name="Mailingcity"
											control={control}
											rules={{ required: true, pattern: /^[A-Za-z\s]+$/ }}
											render={({ field }) =>
												<div><br></br>
													<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{City} *</InputLabel>
													<div className={"memberTextbox"}>
														<TextField
															variant="outlined"
															placeholder={City}
															value={field.value || ''}
															onChange={(e) => handleInputChange(e, field)}
															sx={{ width: '100%' }}
															InputProps={{
																className: 'memberCusomInput',
																style: {
																	border: errors?.Mailingcity?.type === "required" || errors?.Mailingcity?.type === "pattern" ? "1px solid red" : "transparent",
																}
															}}
														/>
													</div>
													{errors?.Mailingcity?.type === "required" &&
														<div className={"memberErrormsg"}>{City} {cannotbeempty}</div>}

													{errors?.Mailingcity?.type === "pattern" &&
														<div className={"memberErrormsg"}>{Invalid} {City}</div>}
												</div>
											}
										/>
									</Grid>

									{/* Mailing State */}
									<Grid xs={6} md={4} pt={0} pb={0}><br></br>
										{/* <div className={"memberInputlabel"}>
											{State} *
										</div> */}
										<InputLabel sx={{ marginTop: "5px" }} htmlFor="my-input" className={"memberInputlabel"}>{State} *</InputLabel>


										<FormControl sx={{ width: "100%", marginTop: "-2px" }}>
											{((typeof selectedState === 'undefined' || selectedState === null || selectedState === "") && (
												<InputLabel id="demo-simple-select-label" translate="no" className={"inputlabelDrpdownselect"}>
													{State}
												</InputLabel>
											))}
											<Select
												{...register('state', { required: true })}
												className={"memberDrpdownselect"}
												id="demo-simple-select"
												// labelId="demo-simple-select-label"
												// label="State"
												value={selectedMAilingState} // Set the value prop to selectedState
												onChange={(event) => handleChangecity(event, "mailing")}
												MenuProps={{
													classes: {
														paper: 'custom-menu-paper', // Add a custom class for the dropdown menu
													},
												}}
												sx={{ width: "100%" }}
												translate="no"
											>
												{Statearray.map((data, index) => (
													<MenuItem value={data.code} key={index} translate="no">{data.code}</MenuItem>
												))}
											</Select>
											{errors.state && errors.state.type === "required" &&
												<div className={"errormsg"}>{State} {cannotbeempty}</div>}
										</FormControl>

									</Grid >

									{/* Mailing Zipcode */}
									<Grid xs={6} md={4} pt={0} pb={0} >
										<Controller
											name="Mailingzipcode"
											control={control}
											rules={{ required: true, pattern: /^(?!00000)\d{5}(?:[-\s]\d{4})?$/ }}
											render={({ field }) =>
												<div><br></br>
													<InputLabel htmlFor="my-input" className={"memberInputlabel"}>{Zipcode} *</InputLabel>
													<div className={"memberTextbox"}>
														<TextField
															variant="outlined"
															placeholder={Zipcode}
															value={field.value || ''}
															onChange={(e) => handleInputChangeZipcode(e, field, "mailing")}
															type="tel"
															sx={{ width: '100%' }}
															InputProps={{
																className: 'memberCusomInput',
																style: {
																	border: errors?.Mailingzipcode?.type === "required" || errors?.Mailingzipcode?.type === "pattern" ? "1px solid red" : "transparent",
																}
															}}
														/>
													</div>

													{errors?.Mailingzipcode?.type === "required" &&
														<div className={"memberErrormsg"}>{Zipcode} {cannotbeempty}</div>}

													{errors?.Mailingzipcode?.type === "pattern" && contain5zeromail === true &&
														<div className={"memberErrormsg"}>{Invalid} {Zipcode}</div>}

													{errors?.Mailingzipcode?.type === "pattern" && contain5zeromail === false &&
														<div className={"memberErrormsg"}>{Invalid} {Zipcode}, {mustbe5digits}</div>}

												</div>
											}
										/>
									</Grid>
								</Grid>
							}

							{/* Submit button */}
							<Grid xs={12} p={0} mt={3} mb={3}>
								<div>
									<Button variant="contained" aria-label="nextbtn" type="submit" className={changeFlag ? "nextbtn" : "disablebtn"}
									>{SubmitChanges}</Button>
								</div>
							</Grid>

						</Grid>

					</form>


				</Grid>

			</Grid>

		</Box >
	)
}
export default MemberEditprofile
