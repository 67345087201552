import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axiosInterceptor from './Services/AxiosInterceptor';
import MemberRegister from './Components/Authentication/Login/Register';
import './App.css';
import Login from './Components/Authentication/Login/Login';
import Welcome from "./Components/Onboarding/Welcome/Welcome";
import CustomScreen from "./Components/BlankScreenHandle/BlankScreen";
import ProfileInformation from './Components/Onboarding/Profile/ProfileInformation/ProfileInformation';
import EditProfileInformation from "./Components/Onboarding/Profile/EditProfileInformation/EditProfileInformation";
import AdressInformation from "./Components/Onboarding/AddressInformation/AdressInformation";
import ContactInformation from "./Components/Onboarding/ContactInformation/ContactInformation";
import PreferredChannel from "./Components/Onboarding/CommunicationPreference/PreferredChannel/PreferredChannel";
import PreferredFrequency from "./Components/Onboarding/CommunicationPreference/PreferredFrequency/PreferredFrequency";
import LanguageConfirmation from "./Components/Onboarding/CommunicationPreference/LanguageConfirmation/LanguageConfirmation"
import CommonLoader from "./Shared/CommonLoader/CommonLoader";
import ThankYou from './Components/Onboarding/ThankYou/ThankYou';
import MemberLogin from "./Components/Authentication/MemberLogin/MemberLogin";
import Layoutcomponent from "./Components/Members/Layout/Layout";
import SurveyWelcome from "./Components/Survey/SurveyWelcome/SurveyWelcome";
import PromotionWelcome from './Components/Survey/SurveyTemplate/PromotionWelcome/PromotionWelcome';
import SurveyTemplate from "./Components/Survey/SurveyTemplate/SurveyTemplate";
import SurveyThankYou from './Components/Survey/SurveyThankYou/SurveyThankYou';

import CampaignCreatorTracker from "./Components/Agent/Campaign/CampaignCreatorTracker";
import AdministratorNotifications from "./Components/Agent/AdministratorNotifications/AdministratorNotifications";

import AgentLogin from './Components/Authentication/AgentLogin/AgentLogin';
import AgentForgotPassword from './Components/Authentication/AgentLogin/AgentForgotPw';
import Reports from "./Components/Agent/Reports/Reports";
import {
	ROUTES_REGISTER, ROUTES_LOGIN, ROUTES_WELCOME, ROUTES_PROFILE_INFORMATION, ROUTES_EDIT_PROFILE, ROUTES_ADDRESS_INFORMATION, ROUTES_CONTACT_INFORMATION, ROUTES_PREFERRED_CHANNEL, ROUTES_PREFERRED_FREQUENCY, ROUTES_LANGUAGE_CONFIRMATION, ROUTES_THANK_YOU,
	ROUTES_MEMBER_PLAN_INFORMATION, ROUTES_MEMBER_LOGIN, ROUTES_MEMBER_PROFILE, ROUTES_MEMBER_EDIT_PROFILE, ROUTES_MEMBER_MYDOCTOR, ROUTES_MEMBER_PREFERENCES, ROUTES_MEMBER_AGENT,
	ROUTES_SURVEY_WELCOME,ROUTES_PROMOTION_WELCOME, ROUTES_SURVEY_SURVEYTEMPLATE, ROUTES_SURVEY_THANK_YOU, ROUTES_CAMPAIGN_DETAILS, ROUTES_AGENT_LOGIN, ROUTES_AGENT_FORGOT_PASSWORD, ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS,
	ROUTES_AGENT_REPORTS
} from './Constants/routes';
import { Error } from './Redux/actions';
import store from './Redux/store'; // import your Redux store
import { ThemeSelectorContext } from './Services/Theme';

import { URL } from "./Constants/apiUrl";
import Apiservice from "./Services/Apiservice";
import CommoncircularProgress from "./Shared/CommoncircularProgress/CommoncircularProgress";
import InactivityChecker from "./Components/Agent/InactivityChecker/InactivityChecker";


const App = ({ data }) => {
	const isBrowser = () => typeof window !== "undefined";
	const location = useLocation();
	const Navigate = useNavigate();
	const [sidebarOpen, setSideBarOpen] = useState(false);
	const { toggleTheme } = useContext(ThemeSelectorContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleViewSidebar = () => {
		setSideBarOpen(!sidebarOpen);
	};

	// localisation data
	if (isBrowser() === true) {
		let getlanguage = localStorage.getItem("Preferredlanguage");
		if (getlanguage === null || getlanguage === undefined || getlanguage === "") {
			localStorage.setItem("Preferredlanguage", "1")
		}
	}

	useEffect(() => {
		let value
		value = parseInt(sessionStorage.getItem("ThemeValue"))
		if (value === undefined || value === "" || value === null) {
			value = 1
		}

		toggleTheme(value)

	}, [])


	useEffect(() => {
		const handleBackButton = (event) => {
			// Check if the previous route was '/login'

			if (event.target.location.pathname === '/Member-Login' || event.target.location.pathname === "/member-login") {

				sessionStorage.removeItem("isnavigated");
				Navigate(ROUTES_MEMBER_PROFILE)
				// window.location.reload()
				// Handle navigation back to the previous route if needed
			}
			else {
				if (window.innerWidth < 1280) {
					sessionStorage.setItem("isnavigated", "true");
					window.location.reload()
				}
			}
		};

		window.addEventListener('popstate', handleBackButton);

		return () => {
			window.removeEventListener('popstate', handleBackButton);
		};
	}, []);



	useEffect(() => {
		if (data === "Token Expired") {
			//   localStorage.clear();
			//  tokenexpired flag is to autofill login detail on tokenexpiry
			localStorage.setItem("TokenExpired", "true");
			Navigate('/Login');
			store.dispatch(Error("Logout"));
		}
		else if (data === "Token Expired Member") {
			localStorage.setItem("TokenExpired", "true");
			Navigate(ROUTES_MEMBER_LOGIN);
			sessionStorage.removeItem("sidebaritem_selected");
			sessionStorage.removeItem("isnavigated");
			store.dispatch(Error("Logout"));
		}
		else if (data === "Token Expired survey") {

		}
		else if (data === "Agent Error") {

		}
		else if (data === "Maximum Campaign creation limit reached") {
			window.location.reload();
		}
		else if (data === "Token Expired Agent") {
			// call the refresh token api
			let jwtrefreshtoken = localStorage.getItem("Agent_Refreshtoken");
			setIsLoading(true);
			let apiurl = URL.AGENT_REFESHTOKEN;
			let body = {
				"refresh_token": jwtrefreshtoken
			}

			Apiservice.postMethod(apiurl, body).then(response => {
				setIsLoading(false);
				localStorage.setItem("Agent_token", response.data.data.access_token);
				localStorage.setItem("Agent_Refreshtoken", response.data.data.refresh_token);
				window.location.reload();
			})
				.catch(error => {
					setIsLoading(false);
					console.error('There was an error!', error);
				})


		}
	}, [data]);
	// Run this effect whenever data changes


	return (
		<div className="App">
			{document.URL.includes("agent") &&
			!document.URL.includes("agent-login") && 
			!document.URL.includes("agent-forgotpassword") &&
            <InactivityChecker timeout={1800000} sessionExpiredTimeout={1800000} />
            }

			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}

			{/* comment out router for ssr if not commented */}
			{/* <Router> */}

			<Routes>
				<Route exact path='/' element={< Login />}></Route>
				<Route exact path={ROUTES_REGISTER} element={< MemberRegister />}></Route>
				<Route exact path={ROUTES_LOGIN} element={< Login />}></Route>
				<Route exact path={ROUTES_WELCOME} element={< Welcome />}></Route>
				<Route exact path={ROUTES_PROFILE_INFORMATION} element={< ProfileInformation />}></Route>
				<Route exact path={ROUTES_EDIT_PROFILE} element={< EditProfileInformation />}></Route>
				<Route exact path={ROUTES_ADDRESS_INFORMATION} element={< AdressInformation />}></Route>
				<Route exact path={ROUTES_CONTACT_INFORMATION} element={< ContactInformation />}></Route>
				<Route exact path={ROUTES_PREFERRED_CHANNEL} element={< PreferredChannel />}></Route>
				<Route exact path={ROUTES_PREFERRED_FREQUENCY} element={< PreferredFrequency />}></Route>
				<Route exact path={ROUTES_LANGUAGE_CONFIRMATION} element={< LanguageConfirmation />}></Route>
				<Route exact path='/CommonLoader/:data' element={< CommonLoader />}></Route>
				<Route exact path={ROUTES_THANK_YOU} element={<ThankYou />}></Route>
				<Route path="*" element={<CustomScreen />}></Route>
				{/* MEMBER PROFILE ROUTES STARTS HERE */}
				<Route exact path={ROUTES_MEMBER_LOGIN} element={< MemberLogin />}></Route>
				<Route exact path={ROUTES_MEMBER_PROFILE} element={< Layoutcomponent component="ProfileView" />}></Route>
				<Route exact path={ROUTES_MEMBER_EDIT_PROFILE} element={< Layoutcomponent component="MemberEditprofile" />}></Route>
				<Route exact path={ROUTES_MEMBER_PREFERENCES} element={< Layoutcomponent component="Preferences" />}></Route>
				<Route exact path={ROUTES_MEMBER_PLAN_INFORMATION} element={< Layoutcomponent component="PlanInformation" />}></Route>
				<Route exact path={ROUTES_MEMBER_MYDOCTOR} element={< Layoutcomponent component="MyDoctor" />}></Route>
				<Route exact path={ROUTES_MEMBER_AGENT} element={< Layoutcomponent component="MyAgent" />}></Route>

				{/* Survey ROUTES STARTS HERE */}
				<Route exact path={ROUTES_SURVEY_WELCOME + "/:reference/:reference_order/:campaign_id/:member_id/:language_code?"} element={<SurveyWelcome />} />
				<Route exact path={ROUTES_PROMOTION_WELCOME + "/:reference/:reference_order/:campaign_id/:member_id/:language_code?"} element={<PromotionWelcome />} />
				<Route exact path={ROUTES_SURVEY_SURVEYTEMPLATE} element={< SurveyTemplate />}></Route>
				<Route exact path={ROUTES_SURVEY_THANK_YOU} element={<SurveyThankYou />}></Route>

				

				{/* AGENT ROUTES STARTS HERE */}
				<Route path={ROUTES_AGENT_LOGIN} element={<AgentLogin />}></Route>
				<Route path={ROUTES_AGENT_FORGOT_PASSWORD} element={<AgentForgotPassword />}></Route>
				<Route exact path={ROUTES_CAMPAIGN_DETAILS} element={<  CampaignCreatorTracker />}></Route>
				<Route path={ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS} element={<AdministratorNotifications />}></Route>
				<Route path={ROUTES_AGENT_REPORTS} element={<Reports />}></Route>
			</Routes>
		</div>
	);
}

const mapStateToProps = state => ({
	data: state.auth.data
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			Error
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(App);
